import React from 'react';
import './StatusApartment.scss';
import {TimeIcon} from '../../icons';

const StatusApartment = ({status, status_description, occupied_until, days_to_availability, ...props}) => {
  if (!status) return null;
  return (
      <React.Fragment>
        <div className="status">
          <div className="status__inner">
            <div className={`status__type 
                ${status === 1 ? 'status__type--free' : ''}
                ${status === 2 ? 'status__type--engaged' : ''}
                ${status === 3 ? 'status__type--booked' : ''}
                `}>
              {status_description}
            </div>
            {(status === 3 && occupied_until) &&
            <React.Fragment>
              <div className="status__date">
                до {occupied_until}
              </div>

              <div className="status__time">
                <div className="status__icon">
                  <TimeIcon/>
                </div>
                <span>через {days_to_availability} дней</span>
              </div>

            </React.Fragment>
            }

          </div>
        </div>
      </React.Fragment>
  )
};

export default StatusApartment;
