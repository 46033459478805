import React, {PureComponent} from 'react';
import CheckboxField from '../CheckboxField';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import {fetchRealtyAmenities} from '../../actions/realtyActions';


class RealtyAmenitiesFields extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      amenities: []
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.type !== this.props.type) {
      this.loadAmenities();
    }
  }

  componentDidMount() {
    this.loadAmenities();
  }

  loadAmenities = () => {
    fetchRealtyAmenities(this.props.type)
        .then(amenities => {
          this.setState({
            amenities: amenities
          })
        })
  };

  render() {
    const {handleChange, currentAmenities} = this.props;
    const {amenities} = this.state;

    return (
        <FormContainer>
          <FormRow>
            <FormColumn sm={12}>
              {amenities.map((item, index) => <CheckboxField key={index}
                                                             checked={currentAmenities.includes(item.id.toString())}
                                                             label={item.name}
                                                             icon={item.icon}
                                                             value={item.id}
                                                             handleChange={handleChange}/>)}
            </FormColumn>
          </FormRow>
        </FormContainer>
    )
  }
}

export default RealtyAmenitiesFields;
