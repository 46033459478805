import React, { PureComponent } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getFormatNumber } from '../Tools';

class BalanceSelect extends PureComponent {

  render() {
    const { options, label, handleChange, value, name, error, disabled, fullWidth, ...props } = this.props;


    return (
      <React.Fragment>
        <FormControl variant="outlined"
                     fullWidth={ fullWidth }
                     style={ props.style }
                     error={ !!error }>
          <InputLabel id={ label }>
            { label }
          </InputLabel>
          <Select name={ name }
                  disabled={ disabled }
                  labelId={ label }
                  value={ value }
                  onChange={ handleChange }
                  label={ label }>
            { options && options.map((option, index) =>
              <MenuItem key={ index }
                        value={ option }>
                { option.name } ({ getFormatNumber(option.balance) })
              </MenuItem>) }

          </Select>
          { error && <FormHelperText>{ error }</FormHelperText> }
        </FormControl>
      </React.Fragment>
    );
  }
}


export default BalanceSelect;
