import React, {useEffect} from 'react';
import SelectField from '../SelectField';
import {useDispatch, useSelector} from 'react-redux';
import {fetchRealtySubTypes} from '../../actions/selectFieldsActions';

const RealtySubTypeSelectField = ({error, handleChange, value, ...props}) => {
  const dispatch = useDispatch();
  const realty_subtypes = useSelector(state => state.autocomplete.realty_subtypes)

  useEffect(() => {
    dispatch(fetchRealtySubTypes())
  }, [dispatch])

  if(!realty_subtypes) {
    return null
  }

  return (
      <SelectField onChange={handleChange}
                   {...props}
                   options={realty_subtypes}
                   error={error}
                   value={value}/>
  );
};

export default RealtySubTypeSelectField;
