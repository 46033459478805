import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import SwitchField from '../SwitchField';
import {Divider} from '@material-ui/core';
import Errors from '../Errors';
import {openSnackbar} from '../../actions/snackbarActions';
import PhoneInput from '../PhoneInput';
import {updateClient} from '../../actions/clientsActions';
import PasswordField from '../PasswordField';
import SuggestPasswordNotification from '../UI/SuggestPasswordNotification';
import ButtonWithProgress from '../ButtonWithProgress';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';
import {reformatPhoneNumber} from '../Tools';

class ClientEditForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      client: {
        id: '',
        name: '',
        email: '',
        password: null,
        active: false,
        phone: '',
        firstName: '',
        lastName: '',
        locationId: ''
      },
      errors: new Errors(),
      showPassword: false,
      suggestPassword: false,
      loading: false
    };
  }

  componentDidMount() {
    const {client} = this.props;

    this.setClient(client);
  }

  setClient = ({id, name, email, active, phone, last_name, first_name, location}) => {
    this.setState(prevState => ({
      client: {
        ...prevState.client,
        id: id,
        name: name,
        email: email,
        active: active,
        phone: phone,
        lastName: last_name,
        firstName: first_name,
        locationId: 1
      },
    }));
  };

  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      client: {
        ...prevState.client,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handlePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleSuggestPassword = () => {
    this.setState({
      suggestPassword: true
    });
  };

  handledCloseSuggestPassword = () => {
    this.setState({
      suggestPassword: false
    });
  };

  handleSetSuggestPassword = (generatePassword) => {
    this.setState(prevState => ({
      client: {
        ...prevState.client,
        password: generatePassword,
      },
      suggestPassword: false
    }));
  };


  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({loading: true});

    const {id, ...client} = this.state.client;

    const data = {
      email: client.email,
      password: client.password,
      active: client.active,
      phone: reformatPhoneNumber(client.phone),
      first_name: client.firstName,
      last_name: client.lastName,
      location_id: client.locationId
    };

    updateClient(id, data)
        .then(client => {
          this.setClient(client);
          this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
          this.setState({
            loading: false,
            errors: new Errors({})
          });
        })
        .catch(errors =>
            this.setState({
              loading: false,
              errors: new Errors(errors)
            })
        );
  };

  render() {
    const {client, errors, showPassword, suggestPassword, loading} = this.state;

    return (
        <div style={{paddingLeft: 24, paddingRight: 24}}>
          <FormContainer>
            <FormRow>
              <FormColumn sm={4}
                          md={3}>
                <TextField error={errors.getError('first_name')}
                           name="firstName"
                           value={client.firstName}
                           onChange={this.handleChange}
                           label="Имя"
                           placeholder="Введите имя"
                />
              </FormColumn>
              <FormColumn sm={4}
                          md={3}>
                <TextField error={errors.getError('last_name')}
                           name="lastName"
                           value={client.lastName}
                           onChange={this.handleChange}
                           label="Фамилия"
                           placeholder="Введите фамилию"
                />
              </FormColumn>

              <FormColumn sm={4}
                          md={3}>
                <PhoneInput error={errors.getError('phone')}
                            name="phone"
                            type="phone"
                            onChange={this.handleChange}
                            value={client.phone}
                            label="Телефон"
                            placeholder="Введите телефон"/>
              </FormColumn>

            </FormRow>

            <FormRow>
              <FormColumn sm={4}
                          md={3}>
                <TextField error={errors.getError('email')}
                           name="email"
                           value={client.email}
                           onChange={this.handleChange}
                           label="Электронная почта"
                           placeholder="Введите электронную почту"
                />
              </FormColumn>
              <FormColumn sm={4}
                          md={3}>
                <PasswordField error={errors.getError('password')}
                               name="password"
                               autoComplete={'false'}
                               type={showPassword ? 'text' : 'password'}
                               value={client.password ?? ''}
                               onFocus={this.handleSuggestPassword}
                               onChange={this.handleChange}
                               label="Пароль"
                               placeholder="Введите пароль"
                               handlePassword={this.handlePassword}
                               showPassword={showPassword}
                />
              </FormColumn>

              <FormColumn sm={4}
                          md={3}
                          style={flex}>
                <SwitchField error={errors.getError('active')}
                             name="active"
                             value={client.active}
                             onChange={this.handleChange}
                             label="Активность"
                />
              </FormColumn>
            </FormRow>

            {(suggestPassword && client.phone.length > 1) &&
            <FormRow>
              <FormColumn sm={6}>
                <SuggestPasswordNotification phone={client.phone}
                                             onClick={this.handleSetSuggestPassword}
                                             onClose={this.handleCloseSuggestPassword}/>
              </FormColumn>
            </FormRow>
            }


            <FormRow>
              <FormColumn xs={12}
                          sm={12}
                          style={{textAlign: 'right'}}>
                <Divider style={{marginBottom: 16}}/>

                <ButtonWithProgress onClick={this.handleSubmit}
                                    loading={loading}
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </div>
    );
  }
}

export default connect(null, {openSnackbar})(ClientEditForm);
