import React from 'react';
import './CalendarApartment.scss';
import CalendarApartmentDay from '../CalendarApartmentDay/CalendarApartmentDay';

const CalendarApartment = props => {
  if(!props.calendar) return null;
  return (
    <React.Fragment>
      <div className="period">
        <div className="period__inner">
          {props.calendar.map((day, index) => <CalendarApartmentDay key={index} {...day}/>)}
        </div>
      </div>
    </React.Fragment>
  )
};

export default CalendarApartment;
