import axios from 'axios';
import queryString from 'query-string'

export const fetchRealtyGroups = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/groups?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}


export const createRealtyGroups = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/groups`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}

export const getRealtyGroup = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/groups/${id}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}

export const updateRealtyGroup = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/groups/${id}`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}

export const removeRealtyGroup = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/groups/${id}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors)
        })
  })
}
