import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import FormRow from '../../FormRow';
import FormColumn from '../../FormColumn';
import Errors from '../../Errors';
import FormContainer from '../../FormContainer';
import { createBookingsUtilitiesInvoices, fetchBookingsUtilitiesLogs } from '../../../actions/bookingsActions';
import BookingsAutocomplete from '../../Autocomplete/BookingsAutocomplete';
import UtilitiesCounterAutocomplete from '../../Autocomplete/UtilitiesCounterAutocomplete';
import PickDateField from '../../PickDateField';
import moment from 'moment';
import TextField from '../../TextField';
import { openSnackbar } from '../../../actions/snackbarActions';
import { withRouter } from 'react-router-dom';
import { ERROR, FORBIDDEN, INVOICE_SUCCESS_CREATE, SUCCESS } from '../../Constants';


const initialState = {
  invoice: {
    booking: '',
    from_date: '',
    until_date: '',
    counter: '',

    initial_indicators: undefined,
    final_indicators: ''
  },
  errors: new Errors(),
};


class UtilityInvoiceCreateForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = initialState;
  };


  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevState.invoice.counter !== this.state.invoice.counter ) {
      const {invoice} = this.state;

      if ( invoice.booking && invoice.counter ) {

        fetchBookingsUtilitiesLogs({
          booking_id: invoice.booking.id,
          realty_utility_id: invoice.counter.id
        }).then(response => {
          this.setState(prevState => ({
            invoice: {
              ...prevState.invoice,
              initial_indicators: response.initial_value,
              final_indicators: response.final_value,
              from_date: moment(response.date_from, 'X').format('DD/MM/YYYY'),
              until_date: moment(response.date_from, 'X').endOf('month').format('DD/MM/YYYY')
            },
            errors: new Errors()
          }));
        }).catch(errors => (
              this.setState({
                errors: new Errors(errors)
              })
            )
        );

      }
    }
  }


  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleBookingChange = (e, value) => {

    if(!value) {
      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          booking: value,
          counter: undefined,
          initial_indicators: undefined,
          final_indicators: undefined,
          from_date: undefined,
          until_date: undefined
        },
      }));
    } else {
      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          booking: value
        }
      }));
    }
  };

  handleCounterChange = (e, value) => {

    if(!value) {
      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          counter: value,
          initial_indicators: undefined,
          final_indicators: undefined,
          from_date: undefined,
          until_date: undefined
        },
      }));
    } else {
      this.setState(prevState => ({
        invoice: {
          ...prevState.invoice,
          counter: value
        }
      }));
    }
  };

  handleChangeDate = (key, value) => {

    this.setState(prevState => ({
      invoice: {
        ...prevState.invoice,
        [key]: value
      }
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {invoice} = this.state;

    const formData = {
      booking_id: invoice.booking.id,
      realty_utility_id: invoice.counter.id || '',
      from_date: moment(invoice.from_date, 'DD/MM/YYYY').unix(),
      until_date: moment(invoice.until_date, 'DD/MM/YYYY').unix(),
      final_indicators: Number(invoice.final_indicators)
    };

    createBookingsUtilitiesInvoices(formData)
        .then(() => {
          this.setState(initialState);
          this.props.loadInvoices();
          this.props.openSnackbar(SUCCESS, INVOICE_SUCCESS_CREATE);
          this.props.location.state = {open: false};
        }).catch(({status, errors}) => {
          if ( status === FORBIDDEN ) {
            this.props.openSnackbar(ERROR, errors);
          }
          this.setState({errors: new Errors(errors)});
        }
    );
  };


  render () {
    const {invoice, errors} = this.state;

    return (
        <React.Fragment>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 6 }>
                <BookingsAutocomplete error={ errors.getError('booking_id') }
                                      value={invoice?.booking?.name ?? ''}
                                      onChange={ this.handleBookingChange }/>
              </FormColumn>

              <FormColumn sm={ 6 }>
                <UtilitiesCounterAutocomplete bookingId={ invoice.booking?.realty_id }
                                              disabled={ !invoice.booking }
                                              value={ invoice.booking ? invoice.counter : '' }
                                              onChange={ this.handleCounterChange }
                                              error={ errors.getError('realty_utility_id') }/>
              </FormColumn>
            </FormRow>

            { (invoice.initial_indicators !== undefined) &&
              <FormRow>
                <FormColumn sm={ 6 }>
                  <TextField name="initial_indicators"
                             value={ invoice.initial_indicators }
                             disabled
                             label="Начальные показатели"/>
                </FormColumn>
                <FormColumn sm={ 6 }>
                  <TextField name="final_indicators"
                             onChange={ this.handleChange }
                             value={ invoice.final_indicators }
                             label="Конечные показатели"/>
                </FormColumn>
              </FormRow>
            }


            <FormRow>
              <FormColumn sm={ 6 }>
                <PickDateField error={ errors.getError('from_date') }
                               value={ invoice.from_date }
                               label="Дата от"
                               type="from_date"
                               onChange={ this.handleChangeDate }/>
              </FormColumn>

              <FormColumn sm={ 6 }>
                <PickDateField error={ errors.getError('until_date') }
                               type="until_date"
                               label="Дата до"
                               value={ invoice.until_date }
                               onChange={ this.handleChangeDate }/>
              </FormColumn>
            </FormRow>


            <FormRow>
              <FormColumn xs="auto" sm="auto" style={ {marginLeft: 'auto'} }>
                <Button color="primary"
                        variant="contained"
                        onClick={ this.handleSubmit }>
                  Добавить
                </Button>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(UtilityInvoiceCreateForm));
