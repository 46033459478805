import React, {PureComponent} from 'react';
import {withRouter} from 'react-router-dom';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import PageHeader from '../../components/PageHeader';
import {fetchNotifications} from '../../actions/notificationsActions';
import NotificationsFilter from '../../components/Notifications/NotificationsFilter';
import NotificationsRow from '../../components/Notifications/NotificationsRow';
import {setPageTitle} from '../../components/Tools';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import {NOT_FOUND} from '../../components/Constants';

class NotificationsListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      meta: null,
      notifications: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Уведомления');
    this.loadNotifications();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadNotifications();
    }
  }

  loadNotifications = () => {
    const query = queryString.parse(this.props.location.search);

    fetchNotifications(query)
        .then(response => {
          this.setState({
            notifications: response.data,
            meta: response.meta
          });
        });
  };

  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  render () {
    const {notifications, meta} = this.state;

    return (
        <Grid container
              spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }
                  style={ {alignItems: 'center'} }>
              <Grid item
                    xs={ 12 }>
                <PageHeader title="Уведомления"
                            size="md"/>
              </Grid>

              <Grid item
                    xs={ 12 }>
                <NotificationsFilter/>
              </Grid>
            </Grid>
          </Grid>


          {!!notifications.length
              ? <Grid item xs={ 12 }>
                  <Paper>
                    <TableContainer style={ {
                      maxWidth: '100%',
                      overflow: 'auto'
                    } }>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Время события</TableCell>
                            <TableCell>Текст события</TableCell>
                            <TableCell>Ссылка</TableCell>
                            <TableCell>Ответственный</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          { notifications.map((notification, index) => <NotificationsRow key={ index }
                                                                                         notification={ notification }/>) }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              : <ErrorLoadingDataMessage title={NOT_FOUND}/>
          }


          { meta?.last_page && meta?.last_page > 1 &&
            <Grid item xs={ 12 }
                  style={ {
                    paddingTop: `24px`,
                    display: `flex`,
                    justifyContent: 'center'
                  } }>
              <Pagination current={ meta.current_page }
                          onChange={ this.handlePageChange }
                          perPage={ meta.per_page }
                          total={ meta.total }/>
          </Grid>
          }

        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(NotificationsListPage));
