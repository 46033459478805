import React, {PureComponent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {IconButton, Grid, Paper, Link, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FormDrawer from './../../components/FormDrawer';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {deleteRole, fetchRoles} from '../../actions/permissionsActions';
import PermissionsCreateForm from '../../components/Permissions/PermissionsCreateForm';
import {PlusIcon} from '../../icons';
import AddingButton from '../../components/AddingButton';
import PageHeader from '../../components/PageHeader';
import {setPageTitle} from '../../components/Tools';
import {ERROR, SUCCESS} from '../../components/Constants';
import { BOOKINGS_ROUTE } from '../../components/RouterConstants';

class PermissionsListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Права доступа');
    this.loadRoles();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadRoles();
    }
  }

  loadRoles = () => {
    fetchRoles()
        .then((response) => {
          this.setState({
            roles: response,
          })
        })
      .catch((error) => {
        this.props.openSnackbar(ERROR, error);
        this.props.history.push({
          pathname: BOOKINGS_ROUTE
        })
      })
  };

  handlerDelete = (roleId) => {
    deleteRole(roleId)
        .then((res) => {
          this.props.openSnackbar(SUCCESS, 'Права успешно удалены');
          this.loadRoles();
        })
        .catch(error => {
          this.props.openSnackbar(ERROR, error);
        })

  };

  render() {
    const {roles} = this.state;

    if (!roles) {
      return null;
    }

    return (
        <Grid container spacing={2}>
          <Grid item
                xs={12}>
            <Grid container
                  spacing={2}
                  style={{alignItems: 'center'}}>
              <Grid item>
                <PageHeader title="Права доступа"
                            size="md"/>
              </Grid>
              <Grid item>
                <FormDrawer title="Добавление прав доступа"
                            button={props => <AddingButton {...props}
                                                           icon={() => <PlusIcon/>}/>}>
                  <PermissionsCreateForm loadPermissions={this.loadRoles}/>
                </FormDrawer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Название</TableCell>
                    <TableCell>Активность</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roles.map(roles => (
                      <TableRow key={roles.id} hover>
                        <TableCell>#{roles.id}</TableCell>
                        <TableCell>
                          <Link
                              color="inherit"
                              component={React.forwardRef((props, ref) => (
                                  <RouterLink
                                      {...props}
                                      ref={ref}
                                      to={`/permissions/${roles.id}`}
                                  />
                              ))}
                          >
                            <b>{roles.name}</b>
                          </Link>
                        </TableCell>
                        <TableCell>{roles.active ? 'Вкл' : 'Выкл'}</TableCell>
                        <TableCell padding="none" style={{width: 75}}>
                          <IconButton
                              onClick={() => this.handlerDelete(roles.id)}
                          >
                            <DeleteIcon fontSize="small"/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>

        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(PermissionsListPage);
