import React from 'react';
import SelectField from '../SelectField';

const roomItemConditions = [
  {name: 'Новое', value: 'new'},
  {name: 'Хорошее', value: 'good'},
  {name: 'Удовлетворительное', value: 'satisfactory'},
  {name: 'Среднее', value: 'average'},
  {name: 'Плохое', value: 'bad'},
  {name: 'Ужасное', value: 'terrible'},
  {name: 'Требуется ремонт', value: 'needs-renovation'},
]


const RealtyRoomItemCondition = ({...props}) => {
  return (
      <SelectField {...props}
                   options={roomItemConditions}/>
  )
}

export default RealtyRoomItemCondition;
