import * as actionTypes from './../actions/actionTypes';

const initialState = {
  isOpen: false,
  message: '',
  role: ''
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SNACKBAR:
      return {
        isOpen: true,
        message: action.message,
        role: action.role
      };
    case actionTypes.CLOSE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};

export default snackbarReducer;
