import React, {memo, useEffect, useState} from 'react';
import {Card, CardHeader, Divider} from '@material-ui/core';
import {setPageTitle} from '../../components/Tools';
import MeetingEditForm from '../../components/Meetings/MeetingEditForm';

const MeetingEditPage = ({match: {params}, ...props}) => {
  const [id, setId] = useState(null)

  useEffect(() => {
    setPageTitle('Редактирование встречи');
    setId(params.id)
  }, [id, params])

  if (!id) {
    return null;
  }

  return (
      <React.Fragment>
        <Card>
          <CardHeader title="Редактирование встречи"
                      subheader={`ID: ${id}`}/>

          <Divider/>

          <div style={{paddingTop: 24, paddingBottom: 24}}>
            <MeetingEditForm id={id}/>
          </div>
        </Card>
      </React.Fragment>
  );
}

export default memo(MeetingEditPage);
