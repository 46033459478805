import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {getColorNotification} from '../Tools';
import {Link} from 'react-router-dom';

const NotificationsRow = ({notification, ...props}) => {
  switch ( notification.data?.type ) {
    case 1:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              Создана новая заявка на объект { notification.data?.realty_name } { notification.data?.realty_type }
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    case 2:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              <Link to={ `/clients/${ notification.data?.client_id }` }>
                { notification.data?.client_first_name } { notification.data?.client_last_name }
              </Link>&nbsp; с заказом&nbsp;
              <Link to={ `/bookings/${ notification.data?.booking_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.rental_agreement }
              </Link>&nbsp;
              просит помощи по объекту&nbsp;
              <Link to={ `/realty/${ notification.data?.realty_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.realty_name }
              </Link>
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    case 3:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              Клиент&nbsp;
              <Link to={ `/clients/${ notification.data?.client_id }` }>
                { notification.data?.client_first_name } { notification.data?.client_last_name }
              </Link>&nbsp;
              внёс корректные показания в заказ&nbsp;
              <Link to={ `/bookings/${ notification.data?.booking_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.rental_agreement }
              </Link>.
              Разница в еденицах по
              счетчикам: { notification.data?.basic_utility_name } ({ notification.data?.meter_name })
              - разница в { notification.data?.meter_data_diff } ед.
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    case 4:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              <Link to={ `/clients/${ notification.data?.client_id }` }>
                { notification.data?.client_first_name } { notification.data?.client_last_name }
              </Link> внёс нулевые показания
              за { notification.data?.basic_utility_name }-{ notification.data?.meter_name }
              &nbsp;по заказу&nbsp;
              <Link to={ `/bookings/${ notification.data?.booking_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </Link>.
              <br/>
              <strong>Нужно проверить менеджеру!</strong>
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    case 5:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              Произошла ошибка при передаче показаний в УК - { notification.data?.management_company_name }.
              Возможно сейчас не период подачи показаний по счетчику
              №{ notification.data?.meter_name } либо счетчик не исправен. В другом случае
              проверьте правильность номера счетчика №{ notification.data?.meter_name } по
              объекту&nbsp;
              <Link to={ `/realty/${ notification.data?.realty_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.realty_name }
              </Link>&nbsp;
              и совпадает ли он с номером в ЛК УК.
              Если совпадают свяжитесь с отделом ИТ.
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    case 6:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              Время аренды объекта&nbsp;
              <Link to={ `/realty/${ notification.data?.realty_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.realty_name }
              </Link>&nbsp;
              больше чем было оплачено в заказе&nbsp;
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.rental_agreement }
              </a>.
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    case 7:
      return (
          <TableRow hover>
            <TableCell>
              { notification.created_at }
            </TableCell>
            <TableCell style={ {maxWidth: 400, ...getColorNotification(notification.data?.type)} }>
              У клиента&nbsp;
              <Link to={ `/clients/${ notification.data?.client_id }` }
                    style={ {textDecoration: 'none'} }>
                { notification.data?.client_first_name }&nbsp;{ notification.data?.client_last_name }
              </Link>&nbsp;
              заканчивается срок действия паспорта&nbsp;
              {notification.data?.passport_validity}.
              Нужно перезаключить с ним договор&nbsp;
              <a href={ `/realty/${notification.data?.booking_id}` }
                 style={ {textDecoration: 'none'} }>
                №&nbsp;{ notification.data?.rental_agreement }
              </a>.
            </TableCell>
            <TableCell style={ getColorNotification(notification.data?.type) }>
              <a href={ notification.data?.url }
                 style={ {textDecoration: 'none'} }>
                { notification.data?.url }
              </a>
            </TableCell>
            <TableCell>
              { notification.data?.responsible_manager }
            </TableCell>
          </TableRow>
      );
    default:
      return null;
  }
};

export default NotificationsRow;
