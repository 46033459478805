import React from 'react';
import './CalendarApartmentDay.scss';

const CalendarApartmentDay = ({status, is_past, name, is_today, status_description, day, date,  ...props}) => {
  return (
    <div className="period-day" title={date}>
      <div className="period-day__inner">
        <div className="period-day__name">{name}</div>
        <div className={`period-day__number 
            ${is_past && status === 1  ? 'period-day__number--noused' :''}
            ${is_past && status === 3 ? 'period-day__number--engaged' : ''}
            ${is_today ? 'period-day__number--today' : ''}
            ${!is_past && status === 1  ? 'period-day__number--past' :''}
            ${is_past && status === 1  ? 'period-day__number--past' :''}
        `}>
          {day}
        </div>
      </div>
    </div>
  )
};

export default CalendarApartmentDay;
