import React, {PureComponent} from 'react';
import {CardHeader, Divider, Card} from "@material-ui/core";
import PermissionsEditFrom from '../../components/Permissions/PermissionsEditFrom';
import {setPageTitle} from '../../components/Tools';


class PermissionsEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    setPageTitle('Редактирование прав доступа');
    this.setState({
      id: this.getId(),
    });
  }

  getId = () => {
    const {match: {params}} = this.props;

    return params.id || null;
  };

  render() {
    const {id} = this.state;

    if (!id) {
      return null;
    }

    return (
        <React.Fragment>
          <Card>
            <CardHeader
                title="Редактирование прав доступа"
                subheader={`ID: ${id}`}
            />

            <Divider/>

            <div style={{ paddingTop: 24, paddingBottom: 24 }}>
              <PermissionsEditFrom id={id} />
            </div>
          </Card>
        </React.Fragment>
    );
  }
}

export default PermissionsEditPage;
