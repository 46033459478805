import React, {PureComponent} from 'react';
import {Grid} from '@material-ui/core';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import {ArchiveIcon, DeleteIcon} from '../../icons';
import Errors from '../Errors';
import {archiveRealtyManagementCompanyCounter, removeRealtyManagementCompanyCounter} from '../../actions/realtyActions';
import BasicUtilitiesIcon from '../Utilities/BasicUtilitiesIcon';
import DialogsRemove from '../DialogsRemove';
import {ERROR, SUCCESS} from '../Constants';


class RealtyManagementCompanyCounterRow extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      counter: {
        meter_name: '',
        meter_value: '',
        basic_utility_id: ''
      },
      errors: new Errors()
    }
  }

  componentDidMount() {
    this.setCounter();
  }

  setCounter = () => {
    const {counter} = this.props;

    this.setState({
      counter: {...counter}
    })
  };


  handleChangeCounter = (e) => {
    const {name, value} = e.target;

    this.setState(prevState => ({
      counter: {
        ...prevState.counter,
        [name]: value
      }
    }))
  };

  handleRemoveManagementCompanyCounter = (_, counter_id) => {
    const {counter} = this.state;

    removeRealtyManagementCompanyCounter(counter.account_id, counter_id)
        .then(() => {
          this.props.loadManagementCompanies();
          this.props.openSnackbar(SUCCESS, 'Счетчик успешно удален')
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          });
          this.props.openSnackbar(ERROR, errors)
        })
  };

  handleArchiveManagementCompanyCounter = (_, counter_id) => {
    const {counter} = this.state;

    archiveRealtyManagementCompanyCounter(counter.account_id, counter_id)
        .then(() => {
          this.props.loadManagementCompanies();
          this.props.openSnackbar(SUCCESS, 'Счетчик успешно заархивирован')
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          });
          this.props.openSnackbar(ERROR, errors)
        })
  };

  // handleSubmitEditCounter = () => {
  //   const {counter} = this.state;
  //
  //   const data = {
  //     meter_name: counter.meter_name
  //   };
  //
  //   updateRealtyManagementCompanyCounter(counter.account_id, counter.id, data)
  //       .then(counter => {
  //         this.setState(prevState => ({
  //           counter: {...prevState.counter, ...counter},
  //           editable: false,
  //           errors: new Errors()
  //         }));
  //         this.props.openSnackbar('success', 'Счетчик успешно изменен')
  //       })
  //       .catch(errors => {
  //         this.setState({
  //           errors: new Errors(errors)
  //         })
  //       })
  //
  // };

  render() {
    const {counter, errors} = this.state;

    return (
        <Grid item
              xs={12}>
          <FormRow>
            <FormRow>
              <div style={{
                padding: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <BasicUtilitiesIcon icon_id={counter.basic_utility_id}/>
              </div>
              <FormColumn sm={2}>
                <TextField fullWidth
                           error={errors.getError('meter_name')}
                           name="meter_name"
                           disabled
                           value={counter.meter_name}
                           onChange={this.handleChangeCounter}
                           label="Счетчик"
                           placeholder="Введите название счетчика"
                />
              </FormColumn>
              <FormColumn sm={2}>
                <TextField fullWidth
                           disabled
                           error={errors.getError('meter_value')}
                           name="meter_value"
                           type="number"
                           value={counter?.last_meter_value ?? counter.meter_value}
                           label="Последний показатель"
                />
              </FormColumn>

              <FormColumn sm={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>


                {counter.removable
                    ? <DialogsRemove position_button="left"
                                     tooltip="Удалить счетчик"
                                     negative_answer="Нет"
                                     positive_answer="Да"
                                     question="Удалить счетчик?"
                                     question_text="Вы действительно хотите удалить счетчик?"
                                     remover={this.handleRemoveManagementCompanyCounter}
                                     id={counter.id}
                                     icon={() => <DeleteIcon/>}/>
                       :  <DialogsRemove position_button="left"
                                         tooltip="Заархивировать счетчик"
                                         negative_answer="Нет"
                                         positive_answer="Да"
                                         question="Заархивировать счетчик?"
                                         question_text="Вы действительно хотите заархивировать счетчик?"
                                         remover={this.handleArchiveManagementCompanyCounter}
                                         id={counter.id}
                                         icon={() => <ArchiveIcon/>}/>
                }




              </FormColumn>
            </FormRow>
          </FormRow>
        </Grid>
    )
  }
}

export default connect(null, {openSnackbar})(RealtyManagementCompanyCounterRow);
