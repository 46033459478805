import React, {useEffect} from 'react';
import SelectField from '../SelectField';
import {useDispatch, useSelector} from 'react-redux';
import {fetchRealtyTypes} from '../../actions/selectFieldsActions';

const RealtyTypeSelectField = ({error, handleChange, value, ...props}) => {
  const dispatch = useDispatch();
  const realty_types = useSelector(state => state.autocomplete.realty_types);

  useEffect(() => {
    dispatch(fetchRealtyTypes());
  }, [dispatch]);

  if ( !realty_types ) {
    return null;
  }

  return (
      <SelectField onChange={ handleChange }
                   { ...props }
                   options={ realty_types }
                   error={ error }
                   value={ value }/>
  );
};

export default RealtyTypeSelectField;
