import * as actionTypes from './actionTypes';

export const openMenuDrawer = () => ({
  type: actionTypes.OPEN_DRAWER,
});

export const closeMenuDrawer = () => ({
  type: actionTypes.CLOSE_DRAWER,
});


export const fetchDrawerCount = ({countType = '', count = ''}) => ({
  type: actionTypes.FETCH_DRAWER_COUNT,
  countType: countType,
  count: count
});

export const fetchCountClientMessage = (payload) => fetchDrawerCount({
  countType: 'clientMessageCount',
  count: payload
});

export const fetchCountSupport = (payload) => fetchDrawerCount({
  countType: 'supportMessageCount',
  count: payload
});

export const fetchCountBookingRequest = (payload) => fetchDrawerCount({
  countType: 'newBookingsRequestsCount',
  count: payload
});
