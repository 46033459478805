import React, {memo, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import FormRow from '../../FormRow';
import FormColumn from '../../FormColumn';
import Errors from '../../Errors';
import FormContainer from '../../FormContainer';
import {updateBookingsUtilitiesInvoices} from '../../../actions/bookingsActions';
import BookingsAutocomplete from '../../Autocomplete/BookingsAutocomplete';
import UtilitiesCounterAutocomplete from '../../Autocomplete/UtilitiesCounterAutocomplete';
import PickDateField from '../../PickDateField';
import moment from 'moment';
import TextField from '../../TextField';
import {openSnackbar} from '../../../actions/snackbarActions';
import {withRouter} from 'react-router-dom';
import ButtonWithProgress from '../../ButtonWithProgress';
import {ERROR, SUCCESS} from '../../Constants';


const UtilityInvoiceEditForm = ({log, ...props}) => {
  const [initialState, setState] = useState({
    invoice: {
      booking: '',
      from_date: '',
      until_date: '',
      counter: '',

      initial_indicators: '',
      final_indicators: ''
    },
    errors: new Errors(),
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      invoice: {
        ...prevState.invoice,
        booking: log?.booking,
        from_date: moment(log?.period?.split('-')[0], 'DD.MM.YYYY').format('DD/MM/YYYY'),
        until_date: moment(log?.period?.split('-')[1], 'DD.MM.YYYY').format('DD/MM/YYYY'),
        initial_indicators: log?.initial_value,
        final_indicators: log?.final_value
      }
    }));
  }, [log]);


  let handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    setState(prevState => ({
      ...prevState,
      invoice: {
        ...prevState.invoice,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };


  let handleChangeDate = (key, value) => {

    setState(prevState => ({
      ...prevState,
      invoice: {
        ...prevState.invoice,
        [key]: value
      }
    }));
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const {invoice} = initialState;

    const data = {
      // booking_id: invoice.booking.id,
      // realty_utility_id: log.realty_utility.id || '',
      from_date: moment(invoice.from_date, 'DD/MM/YYYY').unix(),
      until_date: moment(invoice.until_date, 'DD/MM/YYYY').unix(),
      final_indicators: Number(invoice.final_indicators)
    };

    updateBookingsUtilitiesInvoices(data, log.id)
        .then(() => {
          setState(initialState);
          props.openSnackbar(SUCCESS, 'Инвойс изменен успешно');
          setState(prevState => ({
            ...prevState,
            errors: new Errors({})
          }));
          setLoading(false);
          props.loadInvoices();
          props.closeInvoiceEditModal();
        })
        .catch(errors => {
              setLoading(false);
              props.openSnackbar(ERROR, errors);
            }
        );
  };


  return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={6}>
              <BookingsAutocomplete error={initialState.errors.getError('booking_id')}
                                    disabled
                                    value={initialState.invoice?.booking?.realty?.name}
              />
            </FormColumn>

            <FormColumn sm={6}>
              <UtilitiesCounterAutocomplete bookingId={initialState.invoice.booking?.realty?.id}
                                            disabled
                                            value={initialState.invoice.booking ? String(log?.realty_utility?.id) : ''}
                                            error={initialState.errors.getError('realty_utility_id')}/>
            </FormColumn>
          </FormRow>

          {initialState.invoice.initial_indicators &&
          <FormRow>
            <FormColumn sm={6}>
              <TextField name="initial_indicators"
                         value={initialState.invoice.initial_indicators}
                         disabled
                         label="Начальные показатели"
              />
            </FormColumn>
            <FormColumn sm={6}>
              <TextField name="final_indicators"
                         onChange={handleChange}
                         value={initialState.invoice.final_indicators}
                         label="Конечные показатели"
              />
            </FormColumn>
          </FormRow>
          }


          <FormRow>
            <FormColumn sm={6}>
              <PickDateField error={initialState.errors.getError('from_date')}
                             value={initialState.invoice.from_date}
                             defaultDateFrom={moment().startOf('day')}
                             type="from_date"
                             onChange={handleChangeDate}/>
            </FormColumn>

            <FormColumn sm={6}>
              <PickDateField error={initialState.errors.getError('until_date')}
                             type="until_date"
                             defaultDateFrom={moment().endOf('day')}
                             value={initialState.invoice.until_date}
                             onChange={handleChangeDate}/>
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn xs="auto"
                        sm="auto"
                        style={{marginLeft: 'auto'}}>
              <ButtonWithProgress onClick={handleSubmit}
                                  loading={loading}
                                  label="Сохранить"/>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  log: state.formDrawer.invoiceEdit.payload
});

export default connect(mapStateToProps, {openSnackbar})(withRouter(memo(UtilityInvoiceEditForm)));
