import moment from 'moment-timezone';
import 'moment/locale/ru';

moment.tz.setDefault(process.env.REACT_APP_TIMEZONE);

moment.updateLocale('ru', {
  months : {
    format: 'Января_Февраля_Марта_Апреля_Мая_Июня_Июля_Августа_Сентября_Октября_Ноября_Декабря'.split('_'),
  },
  monthsShort : {
    format: 'Янв_Фев_Мар_Апр_Мая_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'),
  },
});
