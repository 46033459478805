import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';

const GuestRoute = React.memo(({component: Component, ...props}) => {
  const {auth} = props;

  if (auth.isFetching) {
    return null;
  }

  if (auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
      <Route {...props} />
  );
});

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(GuestRoute);
