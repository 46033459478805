import axios from 'axios';
import queryString from 'query-string';
import {BLOB_FILE} from '../components/Tools';

export const fetchBookings = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchBooking = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createBooking = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/bookings`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateBooking = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/bookings/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


//bookings utilities invoices


export const fetchBookingsUtilitiesInvoicesGroup = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/utilities_logs?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createBookingsUtilitiesInvoices = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/bookings/utilities_logs`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response.data);
        })
  });
};


export const updateBookingsUtilitiesInvoices = (data, log_id) => {
  return new Promise((resolve, reject) => {
    axios.put(`/bookings/utilities_logs/${log_id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const removeBookingsUtilitiesInvoices = (log_id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`bookings/utilities_logs/${log_id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};



export const fetchBookingsUtilitiesLogs = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/utilities_logs/preview?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchBookingsUtilitiesInvoices = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/utilities_logs/details?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

//booking payments

export const fetchBookingsPaymentsFromAction = (id, action, query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/${id}/payments/${action}?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createBookingsPayments = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/bookings/${id}/payments`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const payBookingsPayments = (bookingId, id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/bookings/${bookingId}/payments/${id}/pay`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

//booking deposit

export const createBookingsDeposit = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`bookings/${id}/deposit_actions`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


//booking upload documents

export const fetchBookingDocuments = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/${id}/documents`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const uploadBookingDocument = (id, data, config) => {
  return new Promise((resolve, reject) => {
    axios.post(`/booking/${id}/documents`,  data, config)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const removeBookingDocument = (id, docId) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/booking/${id}/documents/${docId}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const downloadBookingDocument = (id, docId, config) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/${id}/documents/${docId}`, {...config, responseType: BLOB_FILE})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


//booking generate contract buttons
//docx, pdf
export const downloadBookingContract = (booking_id, config, format) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/${booking_id}/get_agreement_${format}`, config)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const getContractUpdatedFields = (booking_id, query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/${booking_id}/get_agreement_fields?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const sendContractUpdatedFields = (booking_id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/booking/${booking_id}/post_agreement_fields`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const getRealtyContractName = (realty_id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/contract_name/${realty_id}`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error.response.data.errors);
        })
  });
};

export const getBookingsTotalDeposit = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/total_deposit`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response.data.errors);
        })
  });
};
