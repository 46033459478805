import axios from 'axios';
import * as actionTypes from './actionTypes';
import queryString from 'query-string';


export const fetchClients = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/clients/autocomplete?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchRealtyAutocomplete = () => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/autocomplete`)
        .then(response => {
          dispatch({
            type: actionTypes.FETCH_REALTY,
            realty: response.data.results
          });
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchRealtyFreeAutocomplete = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/autocomplete/free`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const fetchCompanies = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/companies/autocomplete`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchLocations = () => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(`/locations/autocomplete`)
        .then(response => {
          dispatch({
            type: actionTypes.FETCH_LOCATIONS,
            locations: response.data.results
          });
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchUndergroundStation = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/underground_stations/autocomplete?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchBookings = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/bookings/autocomplete?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const fetchManagementCompanies = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/utilities/management_companies/autocomplete`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const fetchRealtyCounter = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty_utilities/${ id }/autocomplete`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchManagers = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/managers/autocomplete?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const fetchCountries = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/countries`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchRealtyGroups = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/groups/autocomplete`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};
