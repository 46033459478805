import React, {PureComponent} from 'react';
import {Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import {fetchTochkaPayments, linkTochkaTransaction} from '../../actions/tochkaActions';
import PageHeader from '../../components/PageHeader';
import TochkaFilter from '../../components/Tochka/TochkaFilter';
import TochkaBalance from '../../components/Tochka/TochkaBalance';
import {setPageTitle} from '../../components/Tools';
import {flexCenter} from '../../components/JSStylesConstants';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import {NOT_FOUND} from '../../components/Constants';

class TochkaPaymentsListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      meta: null,
      payments: null,
    };
  }

  componentDidMount = () => {
    setPageTitle('Лог платежей по банку');
    // updateTochkaPayments();
    this.loadPayments();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadPayments();
    }
  }

  loadPayments = () => {
    const query = queryString.parse(this.props.location.search);

    fetchTochkaPayments(query)
        .then(response => {
          this.setState({
            payments: response.data,
            meta: response.meta,
          });
        });
  };

  handleMove = (id) => {
    linkTochkaTransaction(id)
        .then(() => {
          this.loadPayments();
        });
  };

  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  render () {
    const {payments, meta} = this.state;

    return (
        <Grid container
              spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container
                  spacing={ 2 }>
              <Grid item>
                <PageHeader title="Лог платежей по банку"/>
              </Grid>
              <Grid item
                    style={ {marginLeft: 'auto'} }
                    xs={ 12 }
                    sm={ 12 }
                    md={ 6 }
                    lg={ 4 }>
                <TochkaBalance loadTochkaPayments={ this.loadPayments }/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={ 12 }>
            <PageHeader hasBottom
                        filter={ <TochkaFilter/> }
            />
          </Grid>

          <Grid item xs={ 12 }>
            <Paper>
              <TableContainer style={ {
                maxWidth: '100%',
                overflow: 'auto'
              } }>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Дата</TableCell>
                      <TableCell>Приход</TableCell>
                      <TableCell>Расход</TableCell>
                      <TableCell>Организация</TableCell>
                      <TableCell>Назначение</TableCell>
                      <TableCell>Банк БИК</TableCell>
                      <TableCell>Имя Банка</TableCell>
                      <TableCell>Проведено в кассе</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { payments && payments.map(payment => (
                        <TableRow key={ payment.id } hover>
                          <TableCell>{ payment.payment_date }</TableCell>
                          <TableCell>{ payment.amount > 0 ? payment.amount : null }</TableCell>
                          <TableCell>{ payment.amount < 0 ? payment.amount : null }</TableCell>
                          <TableCell style={ {maxWidth: 400} }>{ payment.counterparty_name }</TableCell>
                          <TableCell style={ {maxWidth: 400} }>{ payment.payment_purpose }</TableCell>
                          <TableCell>{ payment.counterparty_bank_bic }</TableCell>
                          <TableCell>{ payment.counterparty_bank_name }</TableCell>
                          <TableCell>
                            { payment.manager_id

                                ? <React.Fragment>
                                  { payment.manager_id.name }<br/>
                                  { payment.manager_id.created_at }
                                </React.Fragment>

                                : <Button color="primary"
                                          onClick={ () => this.handleMove(payment.id) }
                                          variant="contained">
                                  Провел
                                </Button>

                            }

                          </TableCell>
                        </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            { !payments?.length && <ErrorLoadingDataMessage title={ NOT_FOUND }/> }
          </Grid>

          { (meta && meta.last_page > 1) &&
          <Grid item xs={ 12 }
                style={ {
                  paddingTop: `24px`,
                  ...flexCenter
                } }>
            <Pagination current={ meta.current_page }
                        onChange={ this.handlePageChange }
                        perPage={ meta.per_page }
                        total={ meta.total }/>
          </Grid>
          }

        </Grid>
    );
  }
}

export default withRouter(TochkaPaymentsListPage);
