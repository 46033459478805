import axios from 'axios';
import queryString from 'query-string';

export const fetchManagers = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/managers?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchManager = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/managers/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createManager = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/managers`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateManager = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/managers/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const deleteManager = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/managers/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

