import React from 'react';
import './Sale.scss';
import Number from '../Number';
import Title from '../Title';
import {getFormatNumber} from '../Tools';

const Sale = ({potential_sale_price, sale_price, title, ...props}) => {
  return (
      <React.Fragment>
        <Title title={ title }/>
        <div className="sale__wrap">

          { sale_price ?
              <div className="sale__piece">
                <Number orange
                        size={ 'sm' }
                        value={ getFormatNumber(sale_price) }/>
                <Title size={ 'sm' }
                       title={ 'Цена на продажу' }/>
              </div>
              : null
          }


          { potential_sale_price
              ? <div className="sale__piece">

                <Number green
                        size={ 'sm' }
                        icon
                        value={ potential_sale_price }/>
                <Title size={ 'sm' }
                       title={ 'Потенциальная продажа' }/>
              </div>
              : null
          }

        </div>
      </React.Fragment>
  );
};

export default Sale;
