import React, {PureComponent} from 'react';
import {Grid} from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import FormContainer from '../../components/FormContainer';
import FormRow from '../../components/FormRow';
import TextField from '../../components/TextField';
import Errors from '../../components/Errors';
import FormColumn from '../../components/FormColumn';
import RealtyAddressFields from '../../components/RealtyAddressFields';
import Title from '../../components/Title';
import RealtyAmenitiesFields from '../../components/RealtyAmenitiesFields';
import {createRealty} from '../../actions/realtyActions';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import CompaniesAutocomplete from '../../components/Autocomplete/CompaniesAutocomplete';
import SwitchField from '../../components/SwitchField';
import RealtyRoomCountSelect from '../../components/Realty/RealtyRoomCountSelect';
import RealtyGroupsAutocomplete from '../../components/Autocomplete/RealtyGroupsAutocomplete';
import {APARTMENT, LAND, MANAGER, PANTRY, PARKING, SUCCESS} from '../../components/Constants';
import RealtyStatusField from '../../components/Realty/RealtyStatusField';
import RealtyTypeSelectField from '../../components/Realty/RealtyTypeSelectField';
import RealtySubTypeSelectField from '../../components/Realty/RealtySubTypeSelectField';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import {flex} from '../../components/JSStylesConstants';


const initialState = {
  realty: {
    name: '',
    location: '',
    company: '',
    address: '',
    floor: '',
    type: 'Apartment',
    subtype: 'Apartments_subtype',
    status: 1,
    square: '',
    underground_station: '',
    underground_station_distance: '',
    amenities: [],
    with_partial_rent: false,

    //apartment
    living_space: '',
    kitchen_area: '',
    wifi_password: '',
    section: '',

    //parking
    parking_space_count: '',

    //apartment & office
    room_count: '',


  },
  loading: false,
  errors: new Errors({})
};


class RealtyCreatePage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = initialState;

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevState.realty.type !== this.state.realty.type ) {
      this.setState(prevState => ({
        realty: {
          ...prevState.realty,
          living_space: '',
          kitchen_area: '',
          wifi_password: '',
          section: '',
          parking_space_count: '',
          room_count: '',
          subtype: ''
        }
      }));
    }
  }

  componentDidMount () {
    const {user} = this.props;

    if ( user.userable_type === MANAGER ) {
      this.setState(prevState => ({
        realty: {
          ...prevState.realty,
          company: user.company
        }
      }));
    }
  }

  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleStationChange = (e, value) => {
    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        underground_station: value,
      }
    }));
  };

  handleGroupChange = (e, value) => {
    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        group: value,
      }
    }));
  };

  handleLocationsChange = (e, value) => {
    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        location: value,
        underground_station: '',
        underground_station_distance: ''
      }
    }));
  };

  handleCompanyChange = (e, value) => {
    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        company: value,
      }
    }));
  };

  handleAmenitiesChange = (event) => {
    const {value, checked} = event.target;

    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        amenities: checked ? [...prevState.realty.amenities, value] : prevState.realty.amenities.filter(x => x !== value)
      }
    }));
  };


  handleSubmit = () => {
    const {realty} = this.state;
    const {user} = this.props;
    this.setState({loading: true});

    const data = {
      name: realty.name,
      location_id: realty.location?.id,
      company_id: realty.company?.id || user.company_id,
      address: realty.address,
      city: realty.city,
      floor: realty.floor,
      status: realty.status,
      type: realty.type,
      subtype: realty.subtype,
      square: realty.square,
      underground_station_id: realty.underground_station?.id,
      underground_station_distance: realty.underground_station_distance,
      amenities: realty.amenities,
      with_partial_rent: realty.with_partial_rent,

      //apartment
      living_space: realty.living_space,
      kitchen_area: realty.kitchen_area,
      wifi_password: realty.wifi_password,
      section: realty.section,

      //parking
      parking_space_count: realty.parking_space_count,

      //apartment & office
      room_count: realty.room_count,
      group_id: realty?.group?.id
    };

    createRealty(data)
        .then(res => {
          this.setState(initialState);
          this.props.openSnackbar(SUCCESS, 'Объект создан успешно');
          window.location = `/realty/${ res.id }`;
        })
        .catch(errors => {
              this.setState({
                loading: false,
                errors: new Errors(errors)
              });
            }
        );
  };

  render () {
    const {realty, errors, loading} = this.state;
    const {user} = this.props;

    return (
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 } style={ {alignItems: 'center'} }>
              <Grid item sm={ 2 }>
                <PageHeader title="Создание объекта"
                            size="md"/>
              </Grid>
              <Grid item sm={ 2 } style={ {display: 'flex', justifyContent: 'flex-end'} }>
                <ButtonWithProgress label="Добавить"
                                    loading={ loading }
                                    onClick={ this.handleSubmit }/>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={ 12 } style={ {paddingTop: 24, paddingBottom: 24} }>

            <Grid item xs={ 12 }>
              <FormContainer>
                <FormRow>
                  <FormColumn sm={ 4 }>
                    <TextField
                        error={ errors.getError('name') }
                        name="name"
                        value={ realty.name }
                        onChange={ this.handleChange }
                        label="Название*"
                        placeholder="Введите название"
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>

                  { user.userable_type !== MANAGER &&
                  <FormColumn sm={ 2 }>
                    <CompaniesAutocomplete value={ realty.company }
                                           error={ errors.getError('company_id') }
                                           onChange={ this.handleCompanyChange }/>
                  </FormColumn>
                  }

                  <FormColumn sm={ 2 }>
                    <RealtyStatusField fullWidth
                                       disabled
                                       name="status"
                                       value={ realty.status }
                                       error={ errors.getError('status') }/>
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn sm={ 2 }>
                    <RealtyTypeSelectField handleChange={ this.handleChange }
                                           error={ errors.getError('type') }
                                           name="type"
                                           fullWidth
                                           value={ realty.type }/>
                  </FormColumn>

                  { realty?.type === APARTMENT &&
                  <FormColumn sm={ 2 }>
                    <RealtySubTypeSelectField handleChange={ this.handleChange }
                                              error={ errors.getError('subtype') }
                                              name="subtype"
                                              fullWidth
                                              value={ realty.subtype }/>
                  </FormColumn>
                  }

                </FormRow>
                <FormRow>
                  <FormColumn sm={ 2 }
                              style={ flex }>
                    <SwitchField error={ errors.getError('with_partial_rent') }
                                 name="with_partial_rent"
                                 value={ realty.with_partial_rent }
                                 onChange={ this.handleChange }
                                 label="Частичная занятость"
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn sm={ 2 }>
                    <RealtyGroupsAutocomplete value={ realty.group }
                                              error={ errors.getError('group_id') }
                                              onChange={ this.handleGroupChange }/>
                  </FormColumn>
                </FormRow>
              </FormContainer>
            </Grid>

            <Grid item xs={ 12 }
                  style={ {paddingTop: 24} }>
              <Title title="Адрес"
                     size="xl"
                     style={ {paddingBottom: 24} }/>

              <RealtyAddressFields handleChange={ this.handleChange }
                                   handleStationChange={ this.handleStationChange }
                                   handleLocationsChange={ this.handleLocationsChange }
                                   errors={ errors }
                                   realty_type={ realty.type }
                                   floor={ realty.floor }
                                   location={ realty.location }
                                   underground_station_distance={ realty.underground_station_distance }
                                   underground_station={ realty.underground_station }
                                   address={ realty.address }/>
            </Grid>

            <Grid item xs={ 12 }
                  style={ {paddingTop: 24} }>
              <Title title="Параметры"
                     size="xl"
                     style={ {paddingBottom: 24} }/>

              <Grid item xs={ 12 }>
                <FormContainer>
                  <FormRow>
                    <FormColumn sm={ 2 }>
                      <TextField type="number"
                                 error={ errors.getError('square') }
                                 name="square"
                                 value={ realty.square }
                                 onChange={ this.handleChange }
                                 label="Площадь"
                                 placeholder="Введите площадь"
                      />
                    </FormColumn>
                    { (realty.type !== PARKING && realty.type !== PANTRY && realty.type !== LAND) &&
                      <FormColumn sm={ 2 }>
                        <RealtyRoomCountSelect onChange={ this.handleChange }
                                               value={ realty.room_count }
                                               label="Количество комнат*"
                                               error={ errors.getError('room_count') }/>
                      </FormColumn>
                    }

                    { realty.type === PARKING &&
                    <FormColumn sm={ 2 }>
                      <TextField type="number"
                                 error={ errors.getError('parking_space_count') }
                                 name="parking_space_count"
                                 value={ realty.parking_space_count }
                                 onChange={ this.handleChange }
                                 label="Количество паркомест"
                                 placeholder="Введите количество"
                      />
                    </FormColumn>
                    }

                  </FormRow>

                  { realty.type === APARTMENT &&
                  <>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField type="number"
                                   error={ errors.getError('living_space') }
                                   name="living_space"
                                   value={ realty.living_space }
                                   onChange={ this.handleChange }
                                   label="Жилая площадь"
                                   placeholder="Введите площадь"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField type="number"
                                   error={ errors.getError('kitchen_area') }
                                   name="kitchen_area"
                                   value={ realty.kitchen_area }
                                   onChange={ this.handleChange }
                                   label="Площадь кухни"
                                   placeholder="Введите площадь"
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField error={ errors.getError('wifi_password') }
                                   name="wifi_password"
                                   value={ realty.wifi_password }
                                   onChange={ this.handleChange }
                                   label="Пароль от wifi"
                                   placeholder="Введите пароль"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField error={ errors.getError('section') }
                                   type="number"
                                   name="section"
                                   value={ realty.section }
                                   onChange={ this.handleChange }
                                   label="Секция"
                                   placeholder="Введите номер секции"
                        />
                      </FormColumn>
                    </FormRow>
                  </>
                  }
                </FormContainer>

              </Grid>
              <Grid item xs={ 12 } sm={ 4 }>
                <RealtyAmenitiesFields handleChange={ this.handleAmenitiesChange }
                                       currentAmenities={ realty.amenities }
                                       type={ realty.type }/>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
    );
  }
}

let mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {openSnackbar})(RealtyCreatePage);
