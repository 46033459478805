import React from 'react';
import './Efficiency.scss';
import Number from '../Number';
import Title from '../Title';

const Efficiency = ({
                      profit, percentage, title, yearly_profit, realty_count,
                      actual_yearly_profit, occupied_count, ...props
                    }) => {
  return (
      <React.Fragment>
        <Title title={ title }/>
        <div className="efficiency__wrap">

          { profit ?
              <div className="efficiency__piece">
                {/*{ actual_yearly_profit &&*/}
                {/*  <div className="efficiency__sum">*/}
                {/*    est. { actual_yearly_profit } %*/}
                {/*  </div>*/}
                {/*}*/}
                <Number orange
                        size={ 'sm' }
                        percent
                        value={ profit }/>
                <Title size={ 'sm' }
                       title={ 'Доходность' }/>
              </div>
              : null
          }


          { percentage
              ? <div className="efficiency__piece">
                {/*{ yearly_profit &&*/}
                {/*<div className="efficiency__sum">*/}
                {/*  est. { yearly_profit } %*/}
                {/*</div>*/}
                {/*}*/}

                <Number green
                        size={ 'sm' }
                        percent
                        value={ percentage }/>
                <Title size={ 'sm' }
                       title={ 'Занятость' }/>
              </div>
              : null
          }

          { occupied_count ?
              <div className="efficiency__piece efficiency__counter">
                { `[${ parseInt(occupied_count) }/${ realty_count }]` }
              </div>
              : null
          }
        </div>
      </React.Fragment>
  );
};

export default Efficiency;
