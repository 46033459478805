import React from 'react';
import './404.scss'
import {Grid, Paper, Button} from '@material-ui/core';
import {Alert, AlertTitle} from '@material-ui/lab';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
   height: ''
  },
}));

const Page404 = ({...props}) => {
  const classes = useStyles();

  return (
      <Grid container
            spacing={2}>
        <Grid item
              xs={12}>
          <Paper>
            <Alert severity="warning" className={classes.root}>
              <AlertTitle>404</AlertTitle>
              Страница не найдена<br/>
              <Link to={'/statistic'}
                    style={{textDecoration: 'none'}}>
                <Button color="primary"
                        variant="contained">
                  На статистику
                </Button>
              </Link>

            </Alert>
          </Paper>

        </Grid>
      </Grid>
  )
}

export default Page404;
