import React, {PureComponent} from 'react';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import ManagementCompaniesSelect from '../ManagementCompany/ManagementCompaniesSelect';
import AddingButton from '../AddingButton';
import {ArchiveIcon, ArrowDownIcon, DeleteIcon, PlusIcon} from '../../icons';
import {Button, Divider, Grid, Typography} from '@material-ui/core';
import {createRealtyManagementCompanyCounter, updateRealtyManagementCompany} from '../../actions/realtyActions';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import TextField from '../TextField';
import Errors from '../Errors';
import BasicUtilitiesSelect from '../Utilities/BasicUtilitiesSelect';
import RealtyManagementCompanyCounterRow from './RealtyManagementCompanyCounterRow';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import RealtyManagementCompanyArchiveRow from './RealtyManagementCompanyArchiveCounterRow';
import DialogsRemove from '../DialogsRemove';
import {SUCCESS} from '../Constants';

const initialState = {
  company: {
    id: '',
    login: '',
    password: '',
    account: '',
    payment_details: '',
    debt: '',
    comment: '',
    management_company_id: '',
    realty_utilities: [],
    removable: false
  },
  counter: {
    meter_name: '',
    meter_value: '',
    basic_utility_id: ''
  },
  editable: false,
  adding_counter: false,
  show: false,
  errors: new Errors()
};

class RealtyManagementCompanyRow extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.company !== this.props.company) {
      this.setCompany()
    }
  }


  componentDidMount() {
    this.setCompany()
  }


  setCompany = () => {
    const {company: {login, id, password, account, payment_details, debt, comment, realty_utilities, management_company_id, removable, archived_at}} = this.props;

    this.setState(prevState => ({
      company: {
        ...prevState.company,
        id: id,
        login: login,
        password: password,
        account: account,
        payment_details: payment_details,
        debt: debt,
        comment: comment,
        realty_utilities: realty_utilities,
        management_company_id: management_company_id,
        removable: removable,
        archived_at: archived_at
      }
    }))
  };

  handleEditable = () => {
    this.setState({
      editable: !this.state.editable
    })
  };

  handleAddingCounter = () => {
    this.setState({
      adding_counter: !this.state.adding_counter
    })
  };

  handleChange = (e) => {
    const {name, value} = e.target;

    this.setState(prevState => ({
      company: {
        ...prevState.company,
        [name]: value
      }
    }))
  };

  handleChangeCounter = (e) => {
    const {name, value} = e.target;

    this.setState(prevState => ({
      counter: {
        ...prevState.counter,
        [name]: value
      }
    }))
  };

  handleShowArchiveCounters = () => {
    this.setState({show: !this.state.show})
  }


  handleSubmitEdit = () => {
    const {company} = this.state;
    const {realty_id} = this.props;

    const data = {
      login: company.login,
      password: company.password,
      account: company.account,
      payment_details: company.payment_details,
      debt: company.debt,
      comment: company.comment
    };

    updateRealtyManagementCompany(realty_id, company.id, data)
        .then(response => {
          this.setState(prevState => ({
            company: {...prevState.company, ...response},
            editable: false,
            adding_counter: false
          }));
          this.props.openSnackbar(SUCCESS, 'Управляющая компания успешно изменена')
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          })
        })
  };

  handleSubmitCounter = () => {
    const {company, counter} = this.state;

    const data = {
      basic_utility_id: counter.basic_utility_id,
      meter_name: counter.meter_name,
      meter_value: counter.meter_value
    };

    createRealtyManagementCompanyCounter(company.id, data)
        .then(() => {
          this.setState(prevState => ({
            ...prevState,
            counter: {
              meter_name: '',
              meter_value: '',
              basic_utility_id: ''
            },
            errors: new Errors(),
            adding_counter: false
          }));
          this.props.loadManagementCompanies();
          this.props.openSnackbar(SUCCESS, 'Счетчик успешно создан')
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          })
        })
  };

  render() {
    const {company, counter, adding_counter, errors, show} = this.state;
    const {handleRemoveManagementCompany, handleArchiveManagementCompany, realty_id} = this.props;

    return (
        <>
          <Grid item
                xs={12}>
            <Grid container
                  spacing={2}>
              <Grid item
                    xs={12}>
                <FormRow>
                  <FormColumn sm={2}>
                    <ManagementCompaniesSelect value={company.management_company_id}
                                               disabled
                                               name="management_company_id"/>
                  </FormColumn>
                  <FormColumn sm={2}>
                    <TextField fullWidth
                               error={errors.getError('account')}
                               name="account"
                               disabled={company.archived_at !== null}
                               value={company.account}
                               onChange={this.handleChange}
                               label="Аккаунт"
                               placeholder="Введите аккаунт"
                    />
                  </FormColumn>
                  {!company.archived_at &&
                    <FormColumn sm={2}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                      <AddingButton onClick={this.handleSubmitEdit}
                                    position="left"
                                    tooltip="Сохранить изменения"
                                    icon={() => <SaveOutlinedIcon/>}/>


                      <AddingButton onClick={this.handleAddingCounter}
                                    position="left"
                                    tooltip="Добавить счетчик"
                                    icon={() => <PlusIcon/>}/>

                      {company.removable ?
                          <DialogsRemove position_button="left"
                                         tooltip="Удалить управляющую компанию"
                                         negative_answer="Нет"
                                         positive_answer="Да"
                                         question="Удалить управляющую компанию?"
                                         question_text="Вы действительно хотите удалить управляющую компанию?"
                                         remover={handleRemoveManagementCompany}
                                         id={realty_id}
                                         companyId={company.id}
                                         icon={() => <DeleteIcon/>}/>

                          : <DialogsRemove position_button="left"
                                           tooltip="Заархивировать управляющую компанию"
                                           negative_answer="Нет"
                                           positive_answer="Да"
                                           question="Заархивировать управляющую компанию?"
                                           question_text="Вы действительно хотите заархивировать управляющую компанию?"
                                           remover={handleArchiveManagementCompany}
                                           id={realty_id}
                                           companyId={company.id}
                                           icon={() => <ArchiveIcon/>}/>
                      }
                    </FormColumn>
                  }

                </FormRow>
                <FormRow>
                  <FormColumn sm={2}>
                    <TextField fullWidth
                               error={errors.getError('login')}
                               name="login"
                               disabled={company.archived_at !== null}
                               value={company.login}
                               onChange={this.handleChange}
                               label="Логин"
                               placeholder="Введите логин"
                    />
                  </FormColumn>

                  <FormColumn sm={2}>
                    <TextField fullWidth
                               disabled={company.archived_at !== null}
                               error={errors.getError('password')}
                               name="password"
                               value={company.password}
                               onChange={this.handleChange}
                               label="Пароль"
                               placeholder="Введите пароль"
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn sm={2}>
                    <TextField fullWidth
                               disabled={company.archived_at !== null}
                               error={errors.getError('payment_details')}
                               name="payment_details"
                               value={company.payment_details}
                               onChange={this.handleChange}
                               label="Назначение платежа"
                               placeholder="Введите назначение платежа"
                    />
                  </FormColumn>
                  <FormColumn sm={2}>
                    <TextField fullWidth
                               error={errors.getError('comment')}
                               name="comment"
                               disabled={company.archived_at !== null}
                               value={company.comment}
                               onChange={this.handleChange}
                               label="Коментарий"
                               placeholder="Введите коментарий"
                    />
                  </FormColumn>
                </FormRow>
                <FormRow>
                  <FormColumn sm={2}>
                    <TextField fullWidth
                               error={errors.getError('debt')}
                               name="debt"
                               type="number"
                               disabled={company.archived_at !== null}
                               value={company.debt}
                               onChange={this.handleChange}
                               label="Долг"
                               placeholder="Введите размер долга"
                    />
                  </FormColumn>
                </FormRow>
              </Grid>

              {adding_counter &&
              <Grid item xs={12}>
                <FormRow>
                  <FormColumn sm={2}>
                    <TextField fullWidth
                               error={errors.getError('meter_name')}
                               name="meter_name"
                               value={counter.meter_name}
                               onChange={this.handleChangeCounter}
                               label="Счетчик"
                               placeholder="Введите название счетчика"
                    />
                  </FormColumn>
                  <FormColumn sm={2}>
                    <BasicUtilitiesSelect onChange={this.handleChangeCounter}
                                          name="basic_utility_id"
                                          error={errors.getError('basic_utility_id')}
                                          value={counter.basic_utility_id}/>
                  </FormColumn>

                  <FormColumn sm={2}>
                    <TextField fullWidth
                               error={errors.getError('meter_value')}
                               name="meter_value"
                               type="number"
                               value={counter.meter_value}
                               onChange={this.handleChangeCounter}
                               label="Первый показатель"
                               placeholder="Введите первый показатель"
                    />
                  </FormColumn>

                  <FormColumn sm={2}>
                    <Button color="primary"
                            variant="contained"
                            onClick={this.handleSubmitCounter}>
                      Создать
                    </Button>
                  </FormColumn>
                </FormRow>
              </Grid>
              }

              <Grid item
                    xs={12}>
                {company.realty_utilities &&
                  <Grid container
                        spacing={2}>
                    {company.realty_utilities
                        .filter(c => (c.archived_at == null && c.archived_by == null))
                        .map(counter => (
                            <RealtyManagementCompanyCounterRow key={counter.id}
                                                               loadManagementCompanies={this.props.loadManagementCompanies}
                                                               counter={counter}/>
                        ))}
                  </Grid>
                }
              </Grid>

              {!!company.realty_utilities.filter(c => (c.archived_at !== null && c.archived_by !== null)).length &&
                <Grid item
                      xs={12}>
                  <Grid container
                        spacing={2}>
                    <Grid item
                          sm={4}
                          md={3}
                          lg={2}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                      <Typography style={{
                        fontSize: '20px',
                        cursor: 'pointer'
                      }}
                                  onClick={this.handleShowArchiveCounters}>
                        Архив
                      </Typography>
                      <AddingButton position="right"
                                    onClick={this.handleShowArchiveCounters}
                                    icon={props => <ArrowDownIcon {...props} style={{
                                      transform: show ? 'rotate(180deg)' : '',
                                      transition: '.3s all',
                                    }}/>}/>
                    </Grid>


                    {show &&
                    <Grid item xs={12}>
                      <Grid container
                            spacing={2}>
                        {company.realty_utilities
                            .filter(c => (c.archived_at !== null && c.archived_by !== null))
                            .map(counter => <RealtyManagementCompanyArchiveRow key={counter.id}
                                                                               counter={counter}/>)}
                      </Grid>
                    </Grid>
                    }

                  </Grid>
                </Grid>
              }


            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{marginTop: -1}}/>
          </Grid>
        </>
    )
  }
}

export default connect(null, {openSnackbar})(RealtyManagementCompanyRow);
