import React from 'react';
import './StatisticPayments.scss';
import Title from '../Title';
import Number from '../Number';


const StatisticPayments = ({paidRent, pendingPayments, ...props}) => {
  return (
      <div className="statistic-payments">
        <div className="statistic-payments__inner">
          <div className="statistic-payments__part">
            <Title title="Оплачено аренды"/>
            <Number size={ 'sm' }
                    icon
                    orange
                    value={ paidRent }/>
          </div>
          <div className="statistic-payments__part">
            <Title title="Ожидается оплат"/>
            <Number size={ 'sm' }
                    icon
                    green
                    value={ pendingPayments }/>
          </div>
        </div>
      </div>
  );
};

export default StatisticPayments;
