import React, { useEffect, useRef } from 'react';
import { ScrollContainer } from 'react-indiana-drag-scroll';
import './RealtyStatisticTable.scss';
import { Grid, Tooltip } from '@material-ui/core';
import {
  fullWidth,
  realtyOccupancyColor,
  realtyTableTitle,
  stickyTableCell,
  zIndexCell
} from '../../JSStylesConstants';
import { getRealtyStatisticDayColor, uuidv4 } from '../../Tools';
import moment from 'moment';
import { DATE_FORMAT } from '../../Constants';
import Loader from '../../Loader';


const RealtyStatisticTable = ({ dates, data }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollTo({
      left: ref.current.scrollWidth,
      behavior: 'smooth'
    });
  }, []);

  return (
    <>
      { ( ( data?.length && dates?.length ) ) ?
        <ScrollContainer ref={ ref }>
          <div style={ {
            // maxWidth: '100%',
            // overflow: 'auto',
            maxHeight: 1200
          } }
               className={ 'realty-statistic-table' }>
            { dates &&
              <table style={ {
                ...fullWidth,
                overflowX: 'auto',
                borderSpacing: 0
              } }>
                <thead>
                <tr>
                  <td
                    className={ 'bordered-cell' }
                    style={ {
                      position: 'sticky',
                      width: '200px',
                      backgroundColor: 'white',
                      top: 0,
                      left: '-2px',
                      zIndex: 5
                    } }
                  >
                    <div style={ {
                      ...realtyTableTitle,
                    } }>
                      Обьект
                    </div>
                  </td>
                  { dates?.map((d) => (
                    <td style={ {
                      ...zIndexCell,
                      position: 'sticky',
                      top: 0,
                    } }
                        className={ 'bordered-cell' }
                        key={ uuidv4() }
                        colSpan={ d?.days.length }>
                      <div className={ 'realty-table-head' }>
                        <div className={ 'realty-table-head__month' }>
                          { d.month } ({ d.year })
                        </div>

                        <div className={ 'realty-table-head-amount' }>
                          <div className={ 'realty-table-head-amount__efficiency' }>
                            <div className={ 'realty-table-head-amount__title' }>Эффективность за месяц</div>
                            <div className={ 'realty-table-head-amount__amount' }>
                              <strong style={ { color: realtyOccupancyColor.green } }>
                                { d?.effectiveness }%
                              </strong>
                            </div>
                          </div>
                          <div className={ 'realty-table-head-amount__paydesk' }>
                            <div className={ 'realty-table-head-amount__title' }>Касса за месяц</div>
                            <div className={ 'realty-table-head-amount__amount' }>
                              <strong style={ { color: realtyOccupancyColor.green } }>{ d?.payed }</strong>
                              &nbsp;/&nbsp;
                              <strong style={{color: realtyOccupancyColor.green}}>{ d?.not_payed }</strong>
                            </div>
                          </div>
                        </div>

                        <div className={ 'realty-table-head__days' }>
                          { d?.days.map((item) => (
                            <div className={ 'realty-table-head__day' }
                                 style={ { width: `calc(100% / ${ d?.days.length })` } }
                                 key={ uuidv4() }>
                              <div className={ 'realty-table-head__day-number' }>
                                { moment(item.date, DATE_FORMAT.YYYY_MM_DD).format(DATE_FORMAT.D) }
                              </div>
                            </div>
                          )) }
                        </div>

                      </div>

                    </td>
                  )) }
                </tr>
                </thead>

                { data &&
                  <tbody>
                  { data.map((e) => (
                    <tr key={ uuidv4() }>
                      <Tooltip title={ e.name }
                               placement={ 'top-start' }>
                        <td style={ { ...stickyTableCell, paddingTop: 0, paddingBottom: 0, borderSpacing: 0 } }>
                          <div style={ { ...realtyTableTitle } }
                               className={ 'bordered-cell-title' }>
                            { e.name }
                          </div>
                        </td>
                      </Tooltip>

                      { e && e?.dates.map((c) => (
                        <td className={ 'bordered-cell' }
                            key={ uuidv4() }
                            style={ {
                              // ...zIndexCell,
                              // #418f25
                              backgroundColor: getRealtyStatisticDayColor(c?.days_payed, c?.days_not_payed)
                            } }
                        >
                        </td>
                      )) }
                    </tr>
                  )) }
                  </tbody>
                }

              </table>
            }
          </div>
        </ScrollContainer>

        : <Grid item
                xs={ 12 }>
          <Loader/>
        </Grid>
      }
    </>

  );
};

export default RealtyStatisticTable;
