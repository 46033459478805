import React from 'react';
import './Outcome.scss';
import Title from '../Title';
import Number from '../Number';
import {getFormatNumber} from '../Tools';

const Outcome = ({utilities, ...props}) => {
  return (
      <React.Fragment>
        <Title title={ 'Расход' }/>
        <div className="outcome__wrap">
          <div className="outcome__piece">
            <Number size={ 'sm' }
                    value={ getFormatNumber(utilities) }
                    icon/>
            <Title title={ 'Долги за комуналку' }
                   size={ 'sm' }/>
          </div>
        </div>
      </React.Fragment>
  );
};

export default Outcome;
