import React from 'react';
import InputMask from "react-input-mask";
import TextField from "./TextField";
import {getLocalePhoneMask} from './Tools';

const PhoneInput = (props) => {
  return (
    <InputMask
      mask={getLocalePhoneMask().mask}
      value={ props.value }
      onChange={ props.onChange }
    >
      {() => (
        <TextField
          fullWidth
          error={ props.error }
          name="phone"
          label="Телефон"
          placeholder="Введите телефон"
        />
      )}
    </InputMask>
  );
};


export default PhoneInput;
