export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FETCH_USER = 'FETCH_USER';


export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_REALTY = 'FETCH_REALTY';

export const OPEN_FORM_DRAWER = 'OPEN_FORM_DRAWER';
export const CLOSE_FORM_DRAWER = 'CLOSE_FORM_DRAWER';


export const FETCH_DRAWER_COUNT = 'FETCH_DRAWER_COUNT';

export const FETCH_REALTY_TYPES = 'FETCH_REALTY_TYPES';
export const FETCH_REALTY_SUBTYPES = 'FETCH_REALTY_SUBTYPES';


export const GET_STATISTIC_COMPANY = 'GET_STATISTIC_COMPANY';




