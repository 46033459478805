import React from 'react';
import {Card, CardHeader, Grid} from '@material-ui/core';

const ErrorLoadingDataMessage = ({title, ...props}) => {
  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader style={{textAlign: 'center'}}
                        title={title}/>
          </Card>
        </Grid>
      </Grid>
  );
};

export default ErrorLoadingDataMessage;
