import React from 'react';
import {Grid} from '@material-ui/core';
import ClearFiltersButton from '../ClearFiltersButton';
import SearchField from '../SearchField';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import PickDateField from '../PickDateField';
import NotificationsTypeSelect from './NotificationsTypeSelect';
import moment from 'moment';
import {flexAlignCenter} from '../JSStylesConstants';
import {LABEL_WIDTH_SM} from '../Constants';

const NotificationsFilter = ({...props}) => {
  const query = queryString.parse(props.location.search);

  let handleChange = (e) => {
    const {value, name} = e.target;

    const nextQuery = {
      ...query,
      [name]: value
    }

    if(query.page) nextQuery.page = 1

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    })
  }

  let handleChangeDate = (key, value) => {

    const nextQuery = {
      ...query,
      [key]: value ? moment(value, 'DD/MM/YYYY').format('DD.MM.YYYY') : undefined
    }

    if(query.page) nextQuery.page = 1

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    })
  };

  let getParam = (key) => {
    const query = queryString.parse(props.location.search);


    return query[key] || ''
  };

  return (
      <Grid container
            spacing={2}
            style={{alignItems: 'center'}}>
        <Grid item>
          <PickDateField disableToolbar={false}
                         name="date"
                         type="date"
                         value={moment(getParam('date'), 'DD.MM.YYYY').format('DD/MM/YYYY')}
                         onChange={handleChangeDate}
                         label="Дата"/>
        </Grid>
        <Grid item>
          <SearchField searchField="realty"
                       label="Поиск по объекту"
                       labelWidth={LABEL_WIDTH_SM}
                       placeholder="Имя объекта"/>
        </Grid>
        <Grid item>
          <SearchField searchField="client"
                       label="Клиент"
                       labelWidth={50}
                       placeholder="Имя/телефон/email клиента"/>
        </Grid>

        <Grid item>
          <NotificationsTypeSelect onChange={handleChange}
                                   name="type"
                                   value={getParam('type')}
                                   style={{minWidth: 200}}/>
        </Grid>
        <Grid item
              style={flexAlignCenter}>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  )
}

export default withRouter(NotificationsFilter);
