import React from 'react';
import SelectField from '../SelectField';

const statusType = [
  {value: 1, name: 'Новая'},
  {value: 2, name: 'Недозвон'},
  {value: 3, name: 'Перезвон'},
  {value: 4, name: 'Ожидает оплаты'},
  {value: 5, name: 'Брак'},
  {value: 6, name: 'Отказ'},
  {value: 7, name: 'Оплачено'},
  {value: 8, name: 'Сдано'},
  {value: 9, name: 'Завершено'},
  {value: 10, name: 'Заказ'},
];


const RequestStatusSelect = ({...props}) => {


  return (
      <React.Fragment>
        <SelectField options={statusType}
                     {...props}
                     fullWidth
                     label="Статус"/>
      </React.Fragment>
  )
};

export default RequestStatusSelect;
