import React from 'react';
import Files from 'react-butterfiles';


const UploadFilesButton = ({title, handleChange, handleError, multiple = false, component: Button = null, ...props}) => {
  return (
      <React.Fragment>
        <Files multiple={multiple}
               maxSize="20mb"
               multipleMaxSize="20mb"
               multipleMaxCount={10}
               accept={['application/pdf', 'application/msword',
                 'image/jpg', 'image/jpeg', 'image/png', 'image/bmp', '.doc', '.docx', '.rtf',
                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
               onSuccess={files => handleChange(files)}
               onError={errors => handleError(errors)}>
          {({browseFiles}) => <Button onClick={browseFiles}/>}
        </Files>
      </React.Fragment>

  );
};

export default UploadFilesButton;
