import axios from 'axios';
import queryString from 'query-string';

export const fetchPayments = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/payments?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createPayment = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/payments`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};



export const cancelPayment = (id) => {
  return new Promise((resolve, reject) => {
    axios.put(`/payments/${id}/cancel`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};



// payments balance


export const fetchBalances = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/company_balances`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const transferMoneyBalance = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/company_balances/transfer`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};



