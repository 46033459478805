import React from 'react';
import './RealtyListItem.scss';
import RealtyMainPart from './RealtyMainPart';
import RealtyDocumentsPart from './RealtyDocumentsPart';
import RealtyTariffPart from './RealtyTariffPart';
import RealtyNavigationPart from './RealtyNavigationPart';


const RealtyListItem = ({text_type, status, published,
                          name, id, image, company, documents, slug,
                          active_bookings, created_at, prices, sellable, is_deleted, loadRealty, ...props}) => {

  return (
      <React.Fragment>
        <div className="realty-item">
          <div className="realty-item__part main-part">
            <RealtyMainPart imageSrc={image?.path}
                            id={id}
                            slug={slug}
                            type={text_type}
                            status={status}
                            published={published}
                            activeBookings={active_bookings}
                            name={name}
                            company={company.name}/>
          </div>
          <div className="realty-item__part documents-part">
            <RealtyDocumentsPart documents={documents}/>
          </div>
          <div className="realty-item__part tariff-part">
            <RealtyTariffPart prices={prices}
                              sellable={sellable}/>
          </div>
          <div className="realty-item__part navigation-part">
            <RealtyNavigationPart name={name}
                                  is_deleted={is_deleted}
                                  loadRealty={loadRealty}
                                  id={id}/>
          </div>
        </div>
      </React.Fragment>
  )
};

export default RealtyListItem;
