import React from 'react';
import Tabs from '../Tabs';

const BookingEditTabs = ({ handleChange, value, with_utility_bills, ...props }) => {

  const labels = [
    { label: 'Основное', disabled: false },
    { label: 'Оплата Аренды', disabled: false },
    { label: 'Коммуналка', disabled: !with_utility_bills },
    { label: 'Документы', disabled: false },
  ];

  return (
    <Tabs options={ labels }
          handleChange={ handleChange }
          value={ value }/>
  );
};

export default BookingEditTabs;
