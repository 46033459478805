import { USE_FETCH_ACTION } from './fetchActions';

export const fetchReducer = (state, action) => {
  switch ( action.type ) {
    case USE_FETCH_ACTION.CREATE_ITEM: {
      let { data, id, place } = action.payload;
      let updateData = [];

      if ( id && place ) {
        updateData = state.data.map((x) => x.id === id ? { ...x, [place]: [...x[place], data] } : x);
      } else {
        updateData = [...state.data, data];
      }

      return {
        ...state,
        data: updateData
      };
    }
    case USE_FETCH_ACTION.UPDATE_STATE: {
      let currentItemId = action.payload.id;
      let swapData = state.data.map(x => x.id === currentItemId ? { ...x, ...action.payload } : x);

      return {
        ...state,
        data: swapData
      };
    }
    case USE_FETCH_ACTION.REMOVE_ITEM: {
      let { id, subItemId, place } = action.payload;
      let updateData = [];

      if ( subItemId && place ) {
        updateData = state.data.map((x) => x.id === id ? { ...x, [place]: x[place].filter((x) => x.id !== subItemId) } : x);
      } else {
        updateData = state.data.filter((x) => x.id !== id);
      }

      return {
        ...state,
        data: updateData
      };
    }
    case USE_FETCH_ACTION.SET_DATA:
      return {
        ...state,
        data: action.payload
      };
    case USE_FETCH_ACTION.SET_META:
      return {
        ...state,
        meta: action.payload
      };
    case USE_FETCH_ACTION.SET_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case USE_FETCH_ACTION.LOADING:
      return {
        ...state,
        loading: action.payload ?? !state.loading
      };
    default:
      return state;
  }
};
