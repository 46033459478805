import axios from 'axios';
import queryString from 'query-string';

export const fetchChatList = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/chat/list?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};

export const fetchChatBookingMessages = (bookingId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/chat/show/${ bookingId }`)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};


export const sendChatMessage = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/chat/store`, data)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};


export const maskReadClientMessages = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/chat/read`, data)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};

export const downloadChatDocument = (id, config) => {
  return new Promise((resolve, reject) => {
    axios.get(`/chat/download/${id}`, config)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

//support chat

export const fetchSupportList = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/support/list?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};

export const fetchSupportClientMessages = (clientId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/support/show/${ clientId }`)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};


export const sendSupportMessage = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/support/store`, data)
        .then(response => {
          resolve(response.data.results);
        }).catch(error => {
      reject(error?.response?.data?.errors);
    });
  });
};

export const maskReadSupportMessages = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/support/read`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const fetchUnreadMessageClientSupport = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/chat/unread`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const fetchUnreadMessageTechnicalSupport = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/support/unread`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

export const downloadSupportChatDocument = (id, config) => {
  return new Promise((resolve, reject) => {
    axios.get(`/support/download/${id}`, config)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};

