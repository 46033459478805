import React from 'react';
import SelectField from '../SelectField';


const rent_types = [
  {name: 'Долгосрочно', value: 1},
  {name: 'Посуточно', value: 2},
];


const BookingRequestRentTypeField = ({errors, handleChange, value,  ...props}) => {
  return (
      <SelectField label="Тип аренды"
                   {...props}
                   error={errors.getError('rent_type')}
                   options={rent_types}
                   name="rent_type"
                   onChange={handleChange}
                   value={value}/>
  );
};

export default BookingRequestRentTypeField;
