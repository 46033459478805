import React from 'react';
import {useDispatch} from 'react-redux';
import {IconButton, makeStyles, Toolbar, Typography} from '@material-ui/core';
import MuiAppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import ProfileMenu from './.././ProfileMenu';
import {openMenuDrawer} from '../../actions/drawerActions';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  onlyMobile: {
    color: '#ffffff',
    // [theme.breakpoints.up('xl')]: {
    //   display: 'none',
    // },
  },
}));

const AppBar = ({...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const openMenu = () => {
    dispatch(openMenuDrawer());
  }

  return (
      <MuiAppBar position="fixed"
                 className={classes.appBar}>
        <Toolbar>
          <IconButton className={classes.onlyMobile}
                      onClick={openMenu}>
            <MenuIcon/>
          </IconButton>

          <Typography style={{flexGrow: 1}}/>

          <ProfileMenu/>
        </Toolbar>
      </MuiAppBar>
  );
};

export default AppBar;
