export const USE_FETCH_ACTION = {
  SET_DATA: 'SET_DATA',
  SET_ERROR: 'SET_ERROR',
  SET_META: 'SET_META',
  LOADING: 'LOADING',
  UPDATE_STATE: 'UPDATE_STATE',
  CREATE_ITEM: 'CREATE_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
};

