import * as actionTypes from './../actions/actionTypes';

const initialState = {
  locations: null,
  realty: null,
  realty_types: null,
  realty_subtypes: null
};

const autocompleteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS:
      return {
        ...state,
        locations: [...action.locations]
      };
    case actionTypes.FETCH_REALTY:
      return {
        ...state,
        realty: [...action.realty]
      };
    case actionTypes.FETCH_REALTY_TYPES:
      return {
        ...state,
        realty_types: [...action.realty_types]
      };
    case actionTypes.FETCH_REALTY_SUBTYPES:
      return {
        ...state,
        realty_subtypes: [...action.realty_subtypes]
      };
    default:
      return state;
  }
};

export default autocompleteReducer;
