import React, { useEffect } from 'react';
import { setPageTitle } from '../../components/Tools';
import { Grid } from '@material-ui/core';
import RealtyStatisticTable from '../../components/Realty/RealtyStatisticTable';
import { useFetch } from '../../hooks';
import { API_ROUTES } from '../../api';
import RealtyStatisticFilter from '../../components/Realty/RealtyStatisticFilter';
import Loader from '../../components/Loader';

const RealtyOccupancyListPage = () => {
  const { data, loading } = useFetch({ url: API_ROUTES.realtyOccupancy });

  useEffect(() => {
    setPageTitle('Зайнятость обьектов');
  });

  return (
    <Grid container
          spacing={ 2 }>
      <Grid item>
        <RealtyStatisticFilter/>
      </Grid>
      <Grid item
            xs={ 12 }>

        { (!!data?.dates?.length && !!data?.statistic?.length)
          ? <RealtyStatisticTable dates={ data?.dates }
                                  loading={ loading }
                                  data={ data?.statistic }/>
          : <Grid item
                  xs={ 12 }>
            <Loader/>
          </Grid> }

      </Grid>
    </Grid>
  );
};

export default RealtyOccupancyListPage;
