import axios from 'axios';
import * as actionTypes from './actionTypes';


export const fetchUser = () => async dispatch => {
  const response = await axios.get(`/user`);

  dispatch({
    type: actionTypes.FETCH_USER,
    user: response.data.results
  });

};


export const login = ({ email, password }) => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(`/sanctum/csrf-cookie`).then(() => {
      axios.post(`/auth/login`, {
        email: email,
        password: password
      }).then(({data}) => {
        dispatch(fetchUser());
        resolve(data.results)
      }).catch(error => {
        reject(error?.response?.data?.errors);
      });
    });
  });
};


export const logout = () => dispatch => {
  axios.post('/auth/logout').then(() => {
    dispatch({
      type: actionTypes.LOGOUT
    });
  });
};
