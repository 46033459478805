import React, {useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {DeleteIcon, DownloadIcon, FileIcon} from '../../icons';
import AddingButton from '../AddingButton';
import fileDownload from 'js-file-download';
import ProgressBar from '../ProgressBar';
import {downloadBookingDocument} from '../../actions/bookingsActions';
import DialogsRemove from '../DialogsRemove';
import {getFormatTypeDownloadDocument} from '../Tools';
import {flexAlignCenter} from '../JSStylesConstants';

const BookingOtherDocumentsRow = ({booking_name, document, booking_id, handleRemoveDocument, ...props}) => {
  const [percentLoading, setPercent] = useState(null)

  let handleDownloadDocument = (docId, docType) => {
    let config = {
      onDownloadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (percentCompleted < 100) {
          setPercent(percentCompleted)
        } else {
          setPercent(null)
        }
      }
    };

    downloadBookingDocument(booking_id, docId, config)
        .then(response => {
          let fileFormat = getFormatTypeDownloadDocument(response.data.type);
          fileDownload(response.data, `${booking_name}-${docType}.${fileFormat}`)
        })
  };


  return (
      <TableRow>
        <TableCell style={{
          fontWeight: 500,
          minWidth: 500,
          fontSize: 15,
          color: '#272F4C'
        }}>
          Другие
        </TableCell>
        <TableCell>
          <div style={flexAlignCenter}>
            <div style={{
              marginRight: 8,
              fontSize: 16
            }}>
              <FileIcon/>
            </div>
            <div style={{fontSize: 13}}>
              {document.name}
            </div>
          </div>

        </TableCell>
        <TableCell>{document.user.name}</TableCell>
        <TableCell>{document.created_at}</TableCell>
        <TableCell>
          <div style={flexAlignCenter}>
            <AddingButton icon={() => <DownloadIcon/>}
                          tooltip="Скачать документ"
                          style={{marginRight: 10}}
                          onClick={() => handleDownloadDocument(document.id, 'Другие')}/>

            <DialogsRemove icon={() => <DeleteIcon/>}
                           remover={handleRemoveDocument}
                           negative_answer="Нет"
                           positive_answer="Да"
                           question="Удалить документ?"
                           question_text={`Действительно хотите удалить дополнительный документ?`}
                           tooltip={`Удалить документ?`}
                           id={document.id}/>
          </div>
          {(percentLoading && percentLoading < 100) && <ProgressBar percent={percentLoading}
                                                                    style={{
                                                                      minWidth: '75px',
                                                                      marginTop: 16
                                                                    }}/>}
        </TableCell>
      </TableRow>
  )
}

export default BookingOtherDocumentsRow;
