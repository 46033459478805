import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const StyledTabs = withStyles({
  root: {
    minHeight: '45px',
    overflow: 'auto'
  },
  indicator: {
    backgroundColor: '#FAA003',
    height: 4
  },
  scroller: {
    overflow: 'auto !important'
  }
})(props => <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    minWidth: 130,
    minHeight: '45px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#272F4C',
    zIndex: 2,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    padding: '6px 15px',

    '&:focus': {
      opacity: 1
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  selected: {},
}))(props => <MuiTab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1
  },
  main: {
    height: '45px',
    borderBottom: '1px solid #D2D2D2'
  }
}));

const Tabs = ({handleChange, value, options, with_utility_bills, ...props}) => {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <div className={classes.main}>
          <StyledTabs value={value}
                      onChange={handleChange}>
            {options && options.map((option, index) => <StyledTab key={index}
                                                                  disabled={option.disabled}
                                                                  label={option.label} />)}
          </StyledTabs>
          <Typography/>
        </div>
      </div>
  )
};

export default Tabs;
