import React from 'react';
import PeriodSelectField from '../PeriodSelectField';
import {Grid} from '@material-ui/core';
import FilterSelectField from '../FilterFields/FilterSelectField';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import SearchField from '../SearchField';
import ClearFiltersButton from '../ClearFiltersButton';
import {flexAlignCenter} from '../JSStylesConstants';


const statusOptions = [
  {value: '1', name: 'Свободно'},
  {value: '2', name: 'Забронировано'},
  {value: '3', name: 'Занято'},
];

// const realtiableTypeOptions = [
//   {value: 'Apartment', name: 'Апартаменты'},
//   {value: 'Office', name: 'Офис'},
//   {value: 'Parking', name: 'Паркинг'},
// ];

const rentTypeOptions = [
  {value: '1', name: 'Долгосрочно'},
  {value: '2', name: 'Посуточно'},
];

const debtOptions = [
  {value: '0', name: 'Без долгов'},
  {value: '1', name: 'С долгами'},
];


const StatisticAdvancedFilter = ({...props}) => {

  let clearParams = () => {
    const {date_from, date_to} = queryString.parse(props.location.search);

    const clearSearch = {
      date_from,
      date_to
    };

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(clearSearch)
    });
  };

  return (
      <React.Fragment>

          <Grid item>
            <PeriodSelectField fullWidth
                               style={{minWidth: 220}}/>
          </Grid>
          <Grid item>
            <FilterSelectField options={statusOptions}
                               fullWidth
                               style={{minWidth: 220}}
                               label="Статус"
                               type="status"/>
          </Grid>
          <Grid item>
            <FilterSelectField options={rentTypeOptions}
                               fullWidth
                               style={{minWidth: 220}}
                               label="Тип сдачи"
                               type="rent_type"/>
          </Grid>
          <Grid item>
            <FilterSelectField options={debtOptions}
                               fullWidth
                               style={{minWidth: 220}}
                               label="Оплаты"
                               type="with_debt"/>
          </Grid>
          <Grid item >
            <SearchField searchField="id"
                         fulWidth
                         labelWidth={80}
                         label="Поиск по ID"
                         placeholder="Введите ID"/>
          </Grid>
          <Grid item
                style={flexAlignCenter}>
           <ClearFiltersButton handlerClear={clearParams}/>
          </Grid>

      </React.Fragment>
  )
};

export default withRouter(StatisticAdvancedFilter);
