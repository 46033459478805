import React, {PureComponent} from 'react';
import PageHeader from '../../components/PageHeader';
import {fetchAllRealty} from '../../actions/realtyActions';
import queryString from 'query-string';
import {Grid} from '@material-ui/core';
import Pagination from '../../components/Pagination';
import RealtyListItem from '../../components/Realty/RealtyListItem';
import SearchField from '../../components/SearchField';
import RealtyFilter from '../../components/Realty/RealtyFilter';
import {setPageTitle} from '../../components/Tools';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import {ERROR_500_MESSAGE, REALTY_TYPES} from '../../components/Constants';
import SelectField from '../../components/SelectField';


class RealtyListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      perPage: 25,
      meta: null,
      realty: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Объекты');
    this.loadRealty();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadRealty();
    }
  }

  loadRealty = () => {
    const {perPage} = this.state;
    const query = queryString.parse(this.props.location.search);

    fetchAllRealty({...query, limit: perPage})
        .then(response => {
          this.setState({
            realty: response.data,
            meta: response.meta
          });
        });
  };

  // handlerTabsChange = (e, newValue) => {
  //   const search = queryString.parse(this.props.location.search);
  //
  //   this.props.history.push({
  //     pathname: window.location.pathname,
  //     search: queryString.stringify({
  //       ...search,
  //       page: 1,
  //       type: newValue,
  //     }),
  //   });
  // };

  handleChange = (e) => {
    const {name, value} = e.target;
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: 1,
        [name]: value,
      }),
    });

  };


  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  getParam = (key) => {
    const search = queryString.parse(this.props.location.search);

    return search[key] || '';
  };

  render () {
    const {realty, meta} = this.state;

    return (
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <PageHeader title={ `Объекты [${ meta?.total ?? 0 }]` }
                        size="md"
                        hasBottom
                        addButton={ '/realty/new' }
                        sort={
                          // <RealtySortTabs value={ this.getParam('type') }
                          //                      handleChange={ this.handlerTabsChange }/>
                          <SelectField label={'Типы объектов'}
                                      options={REALTY_TYPES}
                                      style={{minWidth: 220}}
                                      onChange={this.handleChange}
                                      value={this.getParam('type')}
                                      name="type"
                                      fullWidth/>
                        }
                        filter={ <RealtyFilter/> }
                        search={ <SearchField searchField="name"
                                              label="Поиск"
                                              labelWidth={ 40 }
                                              placeholder="Введите имя"/> }
            />
          </Grid>

          {realty.length
              ? <Grid item xs={ 12 }>
                  {realty.map(realty => <RealtyListItem key={ realty.id }
                                                        loadRealty={this.loadRealty}
                                                        { ...realty }/>) }
                </Grid>
              :  <ErrorLoadingDataMessage title={ERROR_500_MESSAGE}/>
          }



          {(meta && meta.last_page > 1) &&
            <Grid item xs={ 12 }
                  style={ {
                    paddingTop: `24px`,
                    display: `flex`,
                    justifyContent: 'center'
                  } }>
              <Pagination current={ meta.current_page }
                          onChange={ this.handlePageChange }
                          perPage={ meta.per_page }
                          total={ meta.total }
              />
            </Grid>
          }


        </Grid>
    );
  }
}

export default RealtyListPage;
