import React, {PureComponent} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import Drawer from './Drawers/MenuDrawer';
import LoginPage from '../pages/Login/LoginPage';
import GuestRoute from './Router/GuestRoute';
import PrivateRoute from './Router/PrivateRoute';
import ScrollToTop from './Router/ScrollToTop';
import Content from './UI/Content';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from './UI/Container';
import AppBar from './UI/AppBar';
import Snackbar from './SnackBar';
import NotFoundPage from '../pages/NotFound/NotFoundPage';
import GlobalErrorHandler from './GlobalErrorHandler';
import {flex} from './JSStylesConstants';
import {NOT_FOUND_ROUTE, ROUTING} from './RouterConstants';


class App extends PureComponent {

  render () {
    const {auth} = this.props;

    if ( !auth.isAuthenticated ) {
      return (
          <Container style={ {...flex, background: '#ffffff'} }>
            <Router>
              <LoginPage/>
              <GuestRoute path="*" render={ () => <Redirect to="/"/> }/>
              <Snackbar/>
              <GlobalErrorHandler/>
            </Router>
          </Container>
      );
    }

    return (
        <React.Fragment>
          <Container>
            <Router>
              <ScrollToTop/>
              <CssBaseline/>


              <AppBar/>
              <Drawer/>


              <Content style={ {height: 'auto'} }>
                <Switch>

                  {process.env.REACT_APP_LANGUAGE && ROUTING[process.env.REACT_APP_LANGUAGE]?.map((route) => (
                      <PrivateRoute key={route.url}
                                    exact
                                    path={ route.url }
                                    component={ route.component }/>
                  ))}


                  <Route exact path={ NOT_FOUND_ROUTE } component={ NotFoundPage }/>

                  <Redirect to={ NOT_FOUND_ROUTE }/>
                </Switch>
              </Content>

              <Snackbar/>
              <GlobalErrorHandler/>
            </Router>
          </Container>
        </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, {})(App);
