import React from 'react';
import SelectField from '../SelectField';
import {REQUEST_INFO, REQUEST_ON_SELL, REQUEST_RENT, REQUEST_TIME_TO_AVAILABILITY} from '../Constants';

const request_type = [
  {value: REQUEST_RENT, name: 'На аренду'},
  {value: REQUEST_INFO, name: 'Информация'},
  {value: REQUEST_ON_SELL, name: 'На продажу'},
  {value: REQUEST_TIME_TO_AVAILABILITY, name: 'Когда освободится'},
];


const RequestTypeSelect = ({...props}) => {
  return (
      <React.Fragment>
        <SelectField options={request_type}
                     fullWidth
                     label="Тип заявки"
                     {...props}/>
      </React.Fragment>
  )
};

export default RequestTypeSelect;
