import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import {openSnackbar} from '../../actions/snackbarActions';
import SwitchField from '../SwitchField';
import FormContainer from '../FormContainer';
import {createRole} from '../../actions/permissionsActions';
import PermissionsCheckboxes from './PermissionsCheckboxes';
import {withRouter} from 'react-router-dom';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';


const initialState = {
  role: {
    name: '',
    permissions: [],
    active: false,
  },
  errors: new Errors(),
};


class PermissionsCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  };


  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      role: {
        ...prevState.role,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleChangeMainPermission = event => {
    const {value, checked} = event.target;

    let newValue = value.split(',');

    this.setState(prevState => ({
      role: {
        ...prevState.role,
        permissions: checked
            ? [...prevState.role.permissions, ...newValue]
            : prevState.role.permissions.filter(x => x.split('.')[0] !== newValue[0].split('.')[0])
      }
    }));
  };

  handleChangeMinorPermission = event => {
    const {value, checked} = event.target;

    this.setState(prevState => ({
      role: {
        ...prevState.role,
        permissions: checked
            ? [...prevState.role.permissions, value]
            : prevState.role.permissions.filter(x => x !== value)
      }
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {role} = this.state;

    createRole({
      name: role.name,
      permissions: role.permissions,
      active: role.active,
    }).then(() => {
      this.setState(initialState);
      this.props.loadPermissions();
      this.props.openSnackbar(SUCCESS, 'Права доступа добавлены успешно');
      this.props.location.state = {open: false};
    }).catch(errors =>
        this.setState({
          errors: new Errors(errors)
        })
    );
  };

  render() {
    const {role, errors} = this.state;

    return (
        <React.Fragment>
          <FormContainer>
            <FormRow>
              <FormColumn sm={6}>
                <TextField
                    error={errors.getError('name')}
                    name="name"
                    value={role.name}
                    onChange={this.handleChange}
                    label="Название прав доступа"
                    placeholder="Введите название"
                />
              </FormColumn>
              <FormColumn sm={6}
                          style={flex}
              >
                <SwitchField
                    error={errors.getError('active')}
                    name="active"
                    value={role.active}
                    onChange={this.handleChange}
                    label="Активность"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={12}>
                <PermissionsCheckboxes oldPermissions={role.permissions}
                                       changeMainPermission={this.handleChangeMainPermission}
                                       changeMinorPermission={this.handleChangeMinorPermission}/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn xs="auto" sm="auto" style={{marginLeft: 'auto'}}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleSubmit}
                >
                  Добавить
                </Button>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(PermissionsCreateForm));
