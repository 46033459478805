import React from 'react';
import './Number.scss';
import {getCurrentCurrencyIcon, getFormatNumber} from '../Tools';

const Number = ({
                  className = '', value, size = '',
                  orange = false, red = false, green = false, est, icon: Icon = null, percent = false,
                  children = null, ...props
                }) => {
  if ( !value ) {
    return null;
  }

  return (
      <div className={ `number ${ className }
                      ${ size ? `number--${ size }` : '' }
                      ${ orange ? 'number--orange' : '' }
                      ${ red ? 'number--red' : '' }
                      ${ green ? 'number--green' : '' }
                      ` }>
        { est &&
          <div className="number__est">est. { getFormatNumber(est) } %</div>
        }
        <span>
          { (value && percent)
              ? parseFloat(value).toFixed(2)
              : (typeof value === 'string') ? value : getFormatNumber(value) }
        </span>

        { Icon &&
        <span className="number__icon">
            { getCurrentCurrencyIcon() }
          </span>
        }

        { percent && <>%</> }

        { children }
      </div>
  );
};

export default Number;
