import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import AddingButton from './AddingButton';


const DialogsRemove = ({style, remover, id, companyId = null, tooltip,
                         position_button, icon: Icon = null, negative_answer, positive_answer,
                         question, question_text, disabled, ...props}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    remover(companyId, id)
    handleClose();
  };

  return (
      <React.Fragment>
        <AddingButton onClick={handleClickOpen}
                      disabled={disabled}
                      style={style}
                      position={position_button}
                      tooltip={tooltip}
                      icon={() => <Icon/>}/>

        <Dialog open={open}
                onClose={handleClose}
                maxWidth="xs">
          <DialogTitle>{question}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {question_text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              {negative_answer}
            </Button>
            <Button onClick={handleRemove} color="primary" autoFocus>
              {positive_answer}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

export default DialogsRemove;
