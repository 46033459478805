import React from 'react';
import {Button} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import BookingUpdateContractInfoModal from './BookingUpdateContractInfoModal';
import {openContractInfoUpdateModal} from '../../actions/formDrawerActions';
import {getContractUpdatedFields} from '../../actions/bookingsActions';

const BookingContractButtons = ({booking_id, client, isHasAllDataForAgreement,
                                  loadBooking, rental_agreement, arrival_date, created_at,
                                  ...props}) => {
  const dispatch = useDispatch();

  let handleGetFields = (format) => {

    getContractUpdatedFields(booking_id, {client_type: client.clientable_type})
        .then(response => {
          const payload = {
            id: booking_id,
            fields: response,
            format: format,
            created_at: created_at,
            rental_agreement: rental_agreement
          }

          dispatch(openContractInfoUpdateModal(payload));
        });
  };


  return (
      <>
        {/*<FormRow>*/}
          {/*<FormColumn sm={4}*/}
          {/*            md={3}*/}
          {/*            lg={2}>*/}
            <Button color="primary"
                    onClick={() => handleGetFields('docx')}
                    fullWidth
                    variant="contained">
              Сформировать договор docx
            </Button>
          {/*</FormColumn>*/}
          {/*<FormColumn sm={4}*/}
          {/*            md={3}*/}
          {/*            lg={2}>*/}
          {/*  <Button color="primary"*/}
          {/*          fullWidth*/}
          {/*          onClick={() => handleGetFields('pdf')}*/}
          {/*          variant="contained">*/}
          {/*    Сформировать договор pdf*/}
          {/*  </Button>*/}
          {/*</FormColumn>*/}
        {/*</FormRow>*/}


        <BookingUpdateContractInfoModal loadBooking={loadBooking}
                                        client={client}/>
      </>
  );
};

export default BookingContractButtons;
