import React, {useEffect, useState} from 'react';
import Autocomplete from './Autocomplete';
import {fetchClients} from '../../actions/autocompleteActions';
import useDebounce from '../Debounce';

const ClientAutocomplete = ({value, ...props}) => {
  const [search, setSearch] = useState( '');
  const [clients, setClients] = useState([]);



  useEffect(() => {
    if(value) {
      setSearch(value)
    }
  }, [value])

  let loadClients = (params) => {
    fetchClients({search: params})
        .then(clients => {
          setClients(clients)
        });
  };

  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {
        if (debouncedSearchTerm) {
          loadClients(debouncedSearchTerm)
        } else {
          setClients([]);
        }
      }, [debouncedSearchTerm]
  );


  return (
      <React.Fragment>
        <Autocomplete {...props}
                     inputValue={search}
                     filterOptions={(options, state) => options}
                     onInputChange={(e, value) => setSearch(value)}
                    // value={(typeof value === 'string' ? clients.find(client => client.id === value) : value) || {}}
                     options={clients}
                     label={`Клиент`}
                     placeholder={`Выберите клиента`}
        />
      </React.Fragment>

  )
};


export default ClientAutocomplete;
