import * as actionTypes from './actionTypes';

export const openSnackbar = (role, message) => dispatch => {
  dispatch({
    type: actionTypes.OPEN_SNACKBAR,
    message: message,
    role: role
  });
};

export const closeSnackbar = (message) => dispatch => {
  dispatch({
    type: actionTypes.CLOSE_SNACKBAR,
  });
};
