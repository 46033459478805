import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Grid} from '@material-ui/core';
import ClearFiltersButton from '../ClearFiltersButton';
import {flexAlignCenter} from '../JSStylesConstants';
import PickDateField from '../PickDateField';
import moment from 'moment';
import queryString from 'query-string';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import SearchField from '../SearchField';
import MeetingTypeFilterSelectField from './MeetingTypeFilterSelectField';
import {LABEL_WIDTH_SM} from '../Constants';


const MeetingsFilter = ({...props}) => {
  const location = useLocation();
  const history = useHistory();

  let handleChangeAutocomplete = (value, type) => {
    const query = queryString.parse(location.search);

    let newQuery = {
      ...query,
      page: query.page ? 1 : query.page,
      [type]: value?.id,
    };


    if ( !value ) delete newQuery[type];

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });
  };

  let handleChangeDate = (key, value) => {
    const query = queryString.parse(location.search);

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        page: query.page ? 1 : query.page,
        [key]: value ?  moment(value, 'DD/MM/YYYY').startOf('day').format('X') : undefined
      })
    });
  };

  let getParam = (params) => {
    const query = queryString.parse(location.search);

    return query[params] || '';
  };

  return (
      <Grid container
            spacing={ 2 }
            style={ {alignItems: 'center'} }>
        <Grid item>
          <MeetingTypeFilterSelectField/>
        </Grid>
        <Grid item>
          <PickDateField name="date"
                         type="date"
                         value={ moment(getParam('date'), 'X').format('DD/MM/YYYY') }
                         onChange={ (key, value) => handleChangeDate(key, value) }
                         label="Дата"/>
        </Grid>
        <Grid item>
          <SearchField searchField="realty"
                       placeholder="Введите имя объекта"
                       label="Поиск по объекту"
                       labelWidth={ LABEL_WIDTH_SM }/>
        </Grid>
        <Grid item>
          <ManagersAutocomplete style={ {minWidth: 240} }
                                onChange={ (e, value) => handleChangeAutocomplete(value, 'manager_id') }
                                value={ getParam('manager_id') }
                                placeholder="Виберите менеджера"/>
        </Grid>
        <Grid item
              style={ flexAlignCenter }>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  );
};

export default MeetingsFilter;
