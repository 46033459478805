export const flex = {
  display: 'flex'
}

export const flexWrap = {
  ...flex,
  flexWrap: 'wrap'
}

export const flexAlignCenter = {
  ...flex,
  alignItems: 'center'
}

export const flexJustifyCenter = {
  ...flex,
  justifyContent: 'center'
}

export const flexCenter = {
  ...flex,
  ...flexJustifyCenter,
  ...flexAlignCenter
}

export const flexJustifyAround = {
  ...flex,
  justifyContent: 'space-around'
}

export const textDecorationNone = {
  textDecoration: 'none'
}

export const textCenter = {
  textAlign: 'center'
}

export const lightOrangeBackground = {
  backgroundColor: 'rgba(250, 160, 3, .5)'
}

export const stickyTableCell = {
  position: 'sticky',
  width: '200px',
  // whiteSpace: 'nowrap',
  // overflow: 'hidden',
  // textOverflow: 'ellipsis',
  backgroundColor: 'white',
  left: '-2px',
  zIndex: 3
};

export const realtyTableTitle = {
  // minWidth: '300px',
  // maxWidth: '300px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor: 'white',
  padding: '0 8px',
  zIndex: 3,
  position: 'relative',
  fontSize: 12,
  // border: '1px solid silver',
  boxSizing: 'border-box'
}


export const zIndexCell = {
  zIndex: 2,
  position: 'relative'
};

export const fullWidth = {
  width: '100%'
};

export const realtyOccupancyColor = {
  green: '#3c8b20',
  lightGreen: '#B6D7AA',
  white: 'white'
}
