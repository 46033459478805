import * as actionTypes from '../actions/actionTypes';

const initialState = {
  metersData: {
    isOpen: false,
    payload: ''
  },
  paydeskCancel: {
    isOpen: false,
    payload: ''
  },
  paydeskTransfer: {
    isOpen: false
  },
  invoiceEdit: {
    isOpen: false,
    payload: ''
  },
  contractInfo: {
    isOpen: false,
    payload: ''
  },
  createClient: {
    isOpen: false,
    payload: {}
  }
};

const formDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_FORM_DRAWER:
      return {
        ...state,
        [action.modalType] : {
          isOpen: true,
          payload: action.payload
        }
      };
    case actionTypes.CLOSE_FORM_DRAWER:
      return {
        ...state,
        [action.modalType]: initialState[action.modalType]
      };
    default:
      return state;
  }
};

export default formDrawerReducer;
