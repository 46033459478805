import React, {memo, useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import StatisticApartment from './index';
import Pagination from '../Pagination';
import {Card, CardHeader, Grid} from '@material-ui/core';
import {fetchStatisticCompanyApartments} from '../../actions/statisticsActions';
import queryString from 'query-string';
import {COMPANY_ID} from '../Constants';

const StatisticApartmentContainer = ({group_id, ...props}) => {
  const [apartments, setApartments] = useState(null);
  const [apartmentsMeta, setApartmentsMeta] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const location = useLocation();
  // const history = useHistory();

  let loadApartment = useCallback(() => {
    const query = queryString.parse(location.search);

    const next_query = {
      ...query,
      // page: query[`apartments${ COMPANY_ID }`],
      page: currentPage,
      company_id: COMPANY_ID,
      group_id: group_id,
    };

    fetchStatisticCompanyApartments(next_query)
        .then(response => {
          setApartments(response.data);
          setApartmentsMeta(response.meta);
        });
  }, [group_id, currentPage, location.search]);

  useEffect(() => {
    loadApartment();
  }, [loadApartment]);

  let handlePageChange = (e, page) => {
    setCurrentPage(page);
    // const search = queryString.parse(location.search);
    //
    // history.push({
    //   pathname: window.location.pathname,
    //   search: queryString.stringify({
    //     ...search,
    //     [`apartments${ COMPANY_ID }`]: page,
    //   }),
    // });
  };

  if ( !apartments ) {
    return null;
  }

  return (
      <div className="statistic-apartment-container">
        { !apartments?.length
            ? <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Card>
                  <CardHeader style={ {textAlign: 'center'} }
                              title="Объектов по заданным критериям не найдено"/>
                </Card>
              </Grid>
            </Grid>
            : <>
              { apartments.map(apartment => <StatisticApartment key={ apartment.id }
                                                                { ...apartment }/>) }

              { apartmentsMeta.last_page > 1
                  ?
                  <Grid item
                        xs={ 12 }
                        style={ {
                          paddingTop: `24px`,
                          display: `flex`,
                          justifyContent: 'center'
                        } }>
                    <Pagination current={ apartmentsMeta.current_page }
                                total={ apartmentsMeta.total }
                                perPage={ apartmentsMeta.per_page }
                                onChange={ handlePageChange }
                    />
                  </Grid>

                  : null
              }
            </>

        }

      </div>
  );
};


export default memo(StatisticApartmentContainer);
