import React, {useState} from 'react';
import './DropZone.scss';
import Files from 'react-butterfiles';
import {PlusIcon} from '../../icons';

const DropZone = ({title, handleChange, handleError, ...props}) => {
  const [dragging, setDragging] = useState(false);


  return (
      <React.Fragment>
        <Files convertToBase64
               multipleMaxCount={10}
               multiple
               multipleMaxSize="20mb"
               accept={["image/jpg", "image/jpeg", "image/png"]}
               maxSize="2mb"
               onSuccess={files => handleChange(files)}
               onError={errors => handleError(errors)}
        >
          {({browseFiles, getDropZoneProps}) => (
              <div
                  {...getDropZoneProps({
                    className: `dropzone ${(dragging ? "dropzone--dragging" : "")}`,
                    onDragEnter: () => setDragging(true),
                    onDragLeave: () => setDragging(false),
                    onDrop: () => setDragging(false)
                  })}
              >
                <li className="new-image"
                    onClick={() => {
                      browseFiles({
                        onErrors: handleError,
                        onSuccess: files => handleChange(files)
                      });
                    }}
                >
                  <div className="new-image__inner">
                    <div className="new-image__icon">
                      <PlusIcon/>
                    </div>
                    <div className="new-image__text">
                      Нажмите или перетяните файл
                    </div>
                  </div>
                </li>
              </div>
          )}
        </Files>
      </React.Fragment>

  )
};

export default DropZone;
