import React from 'react';
import './Button.scss';

const Button = ({children,
                  title = '',
                  style = {},
                  orange = false,
                  icon: Icon = null,
                  filter = false,
                  white = false,
                  className = '',
                  ...props}) => {
  return  (
    <button
        className={`button 
         ${className}
        ${orange ? 'button--orange' : ''} 
        ${white ? 'button--white' : ''}
        ${filter ? 'button--filter' : ''}`}
        style={{...style}}
        {...props}
    >
      {Icon && (
          <div className="button__icon">
            <Icon/>
          </div>
      )}
      <div className="button__text">
        {title}
      </div>
      { children }
    </button>
  )
};

export default Button;
