import React, { useState } from 'react';
import { Collapse, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import theme from '../theme';
import { MANAGER, MODERATOR, VER_UA } from './Constants';
import {
  BOOKING_REQUESTS_ROUTE,
  BOOKINGS_ROUTE,
  CLIENTS_ROUTE,
  INVOICES_ROUTE,
  LOGS_ROUTE,
  MANAGEMENT_COMPANIES_ROUTE,
  MANAGERS_ROUTE,
  MEETINGS_ROUTE,
  METERS_DATA_ROUTE,
  MODERATORS_ROUTE,
  NOTIFICATIONS_ROUTE,
  PAYMENT_ACTS_ROUTE,
  PAYMENT_ORDERS_ROUTE,
  PAYMENTS_ROUTE,
  PERMISSIONS_ROUTE,
  REALTY_GROUPS_ROUTE,
  REALTY_LIST_ROUTE,
  REALTY_OCCUPANCY,
  RESOURCES_ROUTE,
  STATISTIC_ROUTE,
  SUPPORT_ROUTE,
  TECHNICAL_SUPPORT_ROUTE
} from './RouterConstants';
import { requestFromToQuery } from './Tools';

const useStyles = makeStyles(theme => ( {
  listItem: {
    padding: '6px 8px 6px 24px',

    '&.active': {
      textDecoration: 'none',
      backgroundColor: '#FF9D3A',
      color: 'white'
    },
    '&.Mui-disabled': {
      opacity: 1,
    }
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 500
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
} ));

const Menu = ({ user, leadCount, messageCount, messageSupportCount, ...props }) => {
  const classes = useStyles();

  const [open, setOpen] = useState({
    communal: false,
    accounts: false
  });


  const handleClick = (type) => {
    setOpen((prevState) => ( {
      ...prevState,
      [type]: !prevState[type]
    } ));
  };

  const { from: date_from, to: date_to } = requestFromToQuery(1, 'month');


  const links = [
    {
      url: BOOKING_REQUESTS_ROUTE,
      text: 'Заявки',
      count: leadCount > 0 && leadCount,
      current: props.location.pathname === BOOKING_REQUESTS_ROUTE,
      link: true
    },
    {
      url: MEETINGS_ROUTE,
      text: 'Встречи',
      current: props.location.pathname === MEETINGS_ROUTE,
      link: true
    },
    {
      url: SUPPORT_ROUTE,
      text: 'Поддержка клиентов',
      moderator: user.userable_type !== MANAGER,
      count: messageCount > 0 && messageCount,
      current: props.location.pathname === SUPPORT_ROUTE,
      link: true
    },
    {
      url: TECHNICAL_SUPPORT_ROUTE,
      text: 'Техподдержка',
      moderator: user.userable_type === MANAGER,
      count: messageSupportCount > 0 && messageSupportCount,
      current: props.location.pathname === TECHNICAL_SUPPORT_ROUTE,
      link: true
    },
    {
      url: PAYMENTS_ROUTE,
      text: 'Касса',
      current: props.location.pathname === PAYMENTS_ROUTE,
      // locale: VER_UA === process.env.REACT_APP_LANGUAGE,
      moderator: user.userable_type === MODERATOR,
      link: true
    },
    {
      url: BOOKINGS_ROUTE, text: 'Заказы',
      current: props.location.pathname === BOOKINGS_ROUTE,
      link: true
    },
    {
      url: CLIENTS_ROUTE, text: 'Клиенты',
      current: props.location.pathname === CLIENTS_ROUTE,
      link: true
    },
    {
      url: RESOURCES_ROUTE, text: 'Ресурсы',
      current: props.location.pathname === RESOURCES_ROUTE,
      link: true
    },
    {
      url: 'communal',
      name: 'Коммуналка',
      link: false,
      subItems: [
        {
          url: INVOICES_ROUTE, text: 'Инвойсы',
          current: props.location.pathname === INVOICES_ROUTE
        },
        {
          url: METERS_DATA_ROUTE, text: 'Показания',
          current: props.location.pathname === METERS_DATA_ROUTE
        },
        {
          url: PAYMENT_ORDERS_ROUTE,
          text: 'Платежные поручения',
          locale: VER_UA === process.env.REACT_APP_LANGUAGE,
          current: props.location.pathname === PAYMENT_ORDERS_ROUTE
        },
        {
          url: PAYMENT_ACTS_ROUTE,
          text: 'Акты оплаты',
          locale: VER_UA === process.env.REACT_APP_LANGUAGE,
          current: props.location.pathname === PAYMENT_ACTS_ROUTE
        },
      ]
    },
    {
      url: REALTY_LIST_ROUTE, text: 'Объекты',
      current: props.location.pathname === REALTY_LIST_ROUTE,
      link: true
    },
    {
      url: MANAGEMENT_COMPANIES_ROUTE,
      text: 'Управляющие компании',
      current: props.location.pathname === MANAGEMENT_COMPANIES_ROUTE,
      link: true
    },
    {
      url: `${ REALTY_GROUPS_ROUTE }?without_sold=1`,
      text: 'Группы объектов',
      current: props.location.pathname === REALTY_GROUPS_ROUTE,
      link: true
    },
    {
      url: 'accounts',
      name: 'Учетные записи',
      link: false,
      subItems: [
        {
          url: MANAGERS_ROUTE, text: 'Менеджеры',
          current: props.location.pathname === MANAGERS_ROUTE
        },
        {
          url: MODERATORS_ROUTE,
          text: 'Модераторы',
          manager: user.userable_type === MANAGER,
          current: props.location.pathname === MODERATORS_ROUTE
        },
        {
          url: PERMISSIONS_ROUTE,
          text: 'Права доступа',
          manager: user.userable_type === MANAGER,
          current: props.location.pathname === PERMISSIONS_ROUTE
        },
      ]
    },
    {
      url: STATISTIC_ROUTE,
      text: 'Статистика',
      current: props.location.pathname === STATISTIC_ROUTE,
      link: true
    },
    {
      url: LOGS_ROUTE,
      text: 'История действий',
      moderator: user.userable_type !== MODERATOR,
      link: true
    },
    {
      url: NOTIFICATIONS_ROUTE,
      text: 'Уведомления',
      moderator: user.userable_type !== MANAGER,
      link: true
    },
    {
      url: REALTY_OCCUPANCY,
      text: 'Зайнятость обьектов',
      moderator: user.userable_type !== MANAGER,
      link: true,
      params: `date_from=${ date_from }&date_to=${ date_to }&type=day`
    },
  ];


  return (
    <List>
      { links.map((link, index) => {
        if ( !link.link ) {
          return (
            <React.Fragment key={ index }>
              <ListItem button
                        className={ classes.listItem }
                        onClick={ () => handleClick(link.url) }>
                <ListItemText primary={ link.name }
                              classes={ { primary: classes.listItemText } }/>
                { open[link.url] ? <ExpandLess/> : <ExpandMore/> }
              </ListItem>

              <Collapse in={ open[link.url] }
                        timeout="auto"
                        unmountOnExit>
                <List component="div"
                      disablePadding>
                  { link.subItems.map((subLink, index) => (
                    <ListItem key={ index }
                              className={ classes.listItem }
                              style={ { display: subLink.locale ? 'none' : '', paddingLeft: 40 } }
                              component={ NavLink }
                              disabled={ link.current }
                              to={ subLink.url }
                              button
                              onClick={ props.closeDrawer }>
                      <ListItemText primary={ subLink.text }
                                    classes={ { primary: classes.listItemText } }
                      />
                    </ListItem>
                  )) }
                </List>
              </Collapse>
            </React.Fragment>
          );
        }

        return (
          <ListItem key={ index }
                    className={ classes.listItem }
                    component={ NavLink }
                    style={ { display: ( link.moderator || link.locale ) ? 'none' : '' } }
                    to={ `${ link.url }?${ link.params || '' }` }
                    disabled={ link.current }
                    button
                    onClick={ props.closeDrawer }>
            <ListItemText primary={ link.text }
                          classes={ { primary: classes.listItemText } }/>
            { link.count > 0 &&
              <div style={ {
                borderRadius: '50%',
                lineHeight: '25px',
                width: 24,
                height: 24,
                textAlign: 'center',
                color: '#ffffff',
                backgroundColor: theme.palette.secondary.main,
                fontSize: 14
              } }>
                { link.count }
              </div>
            }
          </ListItem>
        );
      }) }
    </List>
  );
};

let mapStateToProps = (state) => ( {
  user: state.auth.user,
  leadCount: state.menuDrawer.newBookingsRequestsCount.count,
  messageCount: state.menuDrawer.clientMessageCount.count,
  messageSupportCount: state.menuDrawer.supportMessageCount.count
} );

export default connect(mapStateToProps, {})(withRouter(Menu));
