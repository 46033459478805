import React, {Fragment, PureComponent} from 'react';
import {Grid} from '@material-ui/core';
import {
  downloadSupportChatDocument,
  fetchSupportClientMessages,
  maskReadSupportMessages
} from '../../actions/chatActions';
import ChatMessageList from '../ClientsSupport/ChatMessageList';
import TechnicalSupportChatForm from './TechnicalSupportChatForm';
import Errors from '../Errors';
import {fetchCountSupport} from '../../actions/drawerActions';
import {connect} from 'react-redux';
import fileDownload from 'js-file-download';
import {getFormatTypeDownloadDocument} from '../Tools';
import {BLOB_TYPE, HIDDEN, TIME_INTERVAL_REQUEST_CHAT, VISIBLE} from '../Constants';
import TechnicalSupportChatLinks from '../TechnicalSupportChatLinks';


class TechnicalSupportChat extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      client_id: props.client_id || null,
      messages: [],
      errors: new Errors()
    };
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( Number(prevProps.chat_info?.unread_count) !== Number(this.props?.chat_info?.unread_count) ) {
      this.handleMaskAsMessageRead(this.state.messages);
    }

    if ( prevProps.client_id !== this.state.client_id ) {
      this.handleClearInterval();
    }
  }

  componentDidMount = () => {
    this.loadMessages();
    this.handleMessageFromInterval();
    this.handleWindowActive();
  };

  componentWillUnmount () {
    this.handleClearInterval();
    document.removeEventListener('visibilitychange', this.handleVisibility);
  }

  handleWindowActive = () => {
    document.addEventListener('visibilitychange', this.handleVisibility);
  };

  handleVisibility = () => {
    if(document.visibilityState === VISIBLE) {
      this.handleMessageFromInterval();
    }

    if(document.visibilityState === HIDDEN) {
      this.handleClearInterval();
    }
  }

  handleMessageFromInterval = () => {
    this.interval = setInterval(() => {
      this.loadMessages();
    }, TIME_INTERVAL_REQUEST_CHAT);
  };

  handleClearInterval = () => {
    clearInterval(this.interval);
  };


  loadMessages = () => {
    const {client_id} = this.state;

    fetchSupportClientMessages(client_id)
        .then(messages => {
          this.setState({
            messages: messages,
          });

          this.handleMaskAsMessageRead(messages);
        });
  };

  handleMaskAsMessageRead = (messages) => {
    const {chat_info} = this.props;

    if ( parseInt(chat_info?.unread_count) > 0 ) {
      maskReadSupportMessages({message_ids: [...messages.filter(x => x.is_admin === false).map(x => x.id)]})
          .then(() => {
            // loadChats();
          })
          .catch(errors => {
            this.setState({errors: new Errors(errors)});
          });
    }
  };

  downloadDocument = (id, name) => {
    downloadSupportChatDocument(id, {responseType: BLOB_TYPE})
        .then(response => {
          let fileFormat = getFormatTypeDownloadDocument(response.type);
          let clearName = name.split('.')[0];

          fileDownload(response, `${ clearName }.${ fileFormat }`);
        })
        .catch(error => {
          console.log(error);
        });
  };

  handleSubmit = () => {
    this.loadMessages();
  };

  render () {
    const {client_id, messages} = this.state;
    const {chat_info} = this.props;

    return (
        <Fragment>
          <Grid item
                xs={ 12 }
                sm={ 8 }
                md={ 6 }
                lg={ 4 }
                style={ {
                  // margin: '-16px -16px 0 0',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  maxHeight: '75vh',
                  background: '#FFFFFF',
                  width: '100%',
                } }>
            <ChatMessageList messages={ messages }
                             downloadDocument={ this.downloadDocument }/>

            <TechnicalSupportChatForm client_id={ client_id }
                                      onSumit={ this.handleSubmit }/>
          </Grid>
          <Grid item
                xs={ 12 }
                lg={ 2 }>
            <TechnicalSupportChatLinks chat_info={chat_info}/>
          </Grid>
        </Fragment>
    );
  }
}

export default connect(null, {fetchCountSupport})(TechnicalSupportChat);
