import React from 'react';
import SelectField from '../SelectField';

const status_types = [
  { name: 'Активный', value: 1 },
  { name: 'Неактивный', value: 2 },
  { name: 'Должник', value: 4 },
  { name: 'Кредитор', value: 5 },
];


const BookingStatusSelectField = ({ fullWidth, disabled, onChange, value, ...props }) => {
  return (
    <SelectField options={ status_types }
                 { ...props }
                 fullWidth
                 disabled={ disabled }
                 name="status"
                 onChange={ onChange }
                 value={ value }/>
  );
};

export default BookingStatusSelectField;
