import React, {useEffect, useState} from 'react';
import Autocomplete from './Autocomplete';
import {fetchRealtyCounter} from '../../actions/autocompleteActions';


const UtilitiesCounterAutocomplete = ({value, bookingId, ...props}) => {
  const [counters, setCounters] = useState([]);


  useEffect(() => {
    if ( !bookingId ) {
      setCounters([]);
    } else {
      loadCounters(bookingId);
    }

  }, [bookingId]);


  let loadCounters = (id) => {
    fetchRealtyCounter(id)
        .then(res => {
          setCounters(res);
        });
  };

  return (
      <Autocomplete
          { ...props }
          disabled={ props.disabled ? props.disabled : !counters.length }
          value={ (typeof value === 'string' ? counters.find(counter => counter.id === +value) : value) || null }
          options={ counters }
          label={ `Счетчик` }
          placeholder={ `Выберите счетчик` }
      />
  );
};


export default UtilitiesCounterAutocomplete;
