import React from 'react';
import {Grid} from '@material-ui/core';

const FormRow = ({children, ...props}) => {
  return (
      <Grid item
            xs={12}>
        <Grid container
              spacing={3}
              {...props}>
          {children}
        </Grid>
      </Grid>
  );
};

export default FormRow;
