import React from 'react';
import {MenuItem, Select, InputLabel, FormHelperText, FormControl} from '@material-ui/core';


const SelectField = ({label, handleChange, value, options, name, error, disabled, fullWidth,  ...props}) => {

  return (
      <React.Fragment>
        <FormControl variant="outlined"
                     fullWidth={fullWidth}
                     error={!!error}>
          <InputLabel id={label}>
            {label}
          </InputLabel>
          <Select name={name}
                  disabled={disabled}
                  labelId={label}
                  value={value}
                  onChange={handleChange}
                  label={label}>
            {options && options.map((option, index) => <MenuItem key={index}
                                                                 value={option.rent_type}>{option.text_rent_type}</MenuItem>)}

          </Select>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      </React.Fragment>
  )
};

export default SelectField;
