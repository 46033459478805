import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchRealtyTypes = () => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/types`)
        .then(response => {
          dispatch({
            type: actionTypes.FETCH_REALTY_TYPES,
            realty_types: response.data.results
          })
        })
        .catch(error => {
          reject(error?.response?.data.errors);
        })

  });
};

export const fetchRealtySubTypes = () => dispatch => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/apartment/subtypes`)
        .then(response => {
          dispatch({
            type: actionTypes.FETCH_REALTY_SUBTYPES,
            realty_subtypes: response.data.results
          })
        })
        .catch(error => {
          reject(error?.response?.data.errors);
        })

  });
};

export const fetchPaymentActions = ()  => {
  return new Promise((resolve, reject) => {
    axios.get(`payments/actions`)
        .then((response) => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data.errors);
        })

  });
};
