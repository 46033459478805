import {PureComponent} from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends PureComponent {
  componentDidUpdate = (prevProps) => {
    if (this.props.location.key !== prevProps.location.key) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    return null;
  };
}

export default withRouter(ScrollToTop);
