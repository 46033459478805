import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
// import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-multiline': {
      padding: `15px 14px`,
    },
  },
}));


const PasswordField = ({handlePassword, showPassword, ...props}) => {
  const classes = useStyles();

  return (
      <MuiTextField
          {...props}
          className={classes.root}
          fullWidth
          error={!!props.error}
          helperText={props.error || ''}
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="Показать пароль"
                              onClick={handlePassword}
                              tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>}
                  </IconButton>
                </InputAdornment>
            ),
          }}
      />
  );
};

// TextField.propTypes = {
//   error: PropTypes.array,
//   value: PropTypes.string,
//   onChange: PropTypes.func,
//   label: PropTypes.string,
//   placeholder: PropTypes.string,
// };

export default PasswordField;
