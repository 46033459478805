import React, {PureComponent} from 'react';
import {Divider, Grid, Typography} from '@material-ui/core';
import Errors from '../Errors';
import {
  archiveRealtyManagementCompany,
  createRealtyManagementCompany,
  fetchRealtyManagementCompany,
  removeRealtyManagementCompany
} from '../../actions/realtyActions';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import ManagementCompaniesSelect from '../ManagementCompany/ManagementCompaniesSelect';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import AddingButton from '../AddingButton';
import {ArrowDownIcon, PlusIcon} from '../../icons';
import RealtyManagementCompanyRow from './RealtyManagementCompanyRow';
import Title from '../Title';
import ButtonWithProgress from '../ButtonWithProgress';
import {ERROR, SUCCESS} from '../Constants';


class RealtyManagementCompanyForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      realty: {
        id: this.props.id,
        companies: [],
        management_company_id: '',
        comment: '',
        account: '',
        login: '',
        password: '',
        payment_details: '',
        debt: ''

      },
      show: false,
      addingCompany: false,
      errors: new Errors(),
      loading: false,
    };
  }

  componentDidMount () {
    this.loadManagementCompanies();
  }

  handleShowArchiveCompany = () => {
    this.setState({show: !this.state.show});
  };


  handleChange = (e) => {
    const {name, type, value, checked} = e.target;

    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value
      }
    }));
  };

  loadManagementCompanies = () => {
    const {realty: {id}} = this.state;

    fetchRealtyManagementCompany(id)
        .then(companies => {
          this.setState(prevState => ({
            realty: {
              ...prevState.realty,
              companies: companies
            }
          }));
        });
  };

  handleAddingCompany = () => {
    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        management_company_id: '',
        comment: '',
        account: '',
        login: '',
        password: '',
        payment_details: '',
        debt: ''
      },
      addingCompany: !this.state.addingCompany
    }));
  };


  handleSubmitCompany = () => {
    const {realty} = this.state;

    this.setState({loading: true});

    const data = {
      management_company_id: realty.management_company_id,
      account: realty.account,
      comment: realty.comment,
      login: realty.login,
      password: realty.password,
      payment_details: realty.payment_details,
      debt: realty.debt
    };

    createRealtyManagementCompany(realty.id, data)
        .then(() => {
          this.setState(prevState => ({
            realty: {
              ...prevState.realty,
              management_company_id: '',
              comment: '',
              account: '',
              login: '',
              password: '',
              payment_details: '',
              debt: ''
            },
            addingCompany: false,
            errors: new Errors(),
            loading: false
          }));
          this.loadManagementCompanies();
          this.props.openSnackbar(SUCCESS, 'Управляющая компания успешно создана');
        })
        .catch(errors => {
          this.setState({
            loading: false,
            errors: new Errors(errors)
          });
        });

  };


  handleRemoveManagementCompany = (management_company_id, realty_id) => {
    removeRealtyManagementCompany(realty_id, management_company_id)
        .then(() => {
          this.loadManagementCompanies();
          this.props.openSnackbar(SUCCESS, 'Управляющая компания успешно удалена');
        }).catch(errors => {
      this.setState({
        errors: new Errors(errors)
      });
      this.props.openSnackbar(ERROR, errors);
    });
  };

  handleArchiveManagementCompany = (management_company_id) => {
    archiveRealtyManagementCompany(management_company_id)
        .then(() => {
          this.loadManagementCompanies();
          this.props.openSnackbar(SUCCESS, 'Управляющая компания успешно заархивирована');
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          });
          this.props.openSnackbar(ERROR, errors);
        });
  };


  render () {
    const {realty, addingCompany, show, errors, loading} = this.state;
    return (
        <Grid container
              spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }
                  style={ {alignItems: 'center'} }>
              <Grid item>
                <Title size="xl"
                       style={ {marginBottom: 0} }
                       title="Управляющие компании"/>
              </Grid>
              <Grid item>
                <AddingButton icon={ () => <PlusIcon/> }
                              tooltip="Добавить управляющую компанию"
                              onClick={ this.handleAddingCompany }/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }>
              <Grid item
                    xs={ 12 }>
                { addingCompany &&
                <>
                  <FormContainer>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <ManagementCompaniesSelect onChange={ this.handleChange }
                                                   value={ realty.management_company_id }
                                                   error={ errors.getError('management_company_id') }
                                                   name="management_company_id"/>
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField fullWidth
                                   error={ errors.getError('account') }
                                   name="account"
                                   value={ realty.account }
                                   onChange={ this.handleChange }
                                   label="Аккаунт"
                                   placeholder="Введите аккаунт"
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField fullWidth
                                   error={ errors.getError('login') }
                                   name="login"
                                   value={ realty.login }
                                   onChange={ this.handleChange }
                                   label="Логин"
                                   placeholder="Введите логин"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField fullWidth
                                   error={ errors.getError('password') }
                                   name="password"
                                   value={ realty.password }
                                   onChange={ this.handleChange }
                                   label="Пароль"
                                   placeholder="Введите пароль"
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField fullWidth
                                   error={ errors.getError('payment_details') }
                                   name="payment_details"
                                   value={ realty.payment_details }
                                   onChange={ this.handleChange }
                                   label="Назначение платежа"
                                   placeholder="Введите назначение платежа"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <TextField fullWidth
                                   error={ errors.getError('comment') }
                                   name="comment"
                                   value={ realty.comment }
                                   onChange={ this.handleChange }
                                   label="Коментарий"
                                   placeholder="Введите коментарий"
                        />
                      </FormColumn>
                    </FormRow>
                    <FormRow>
                      <FormColumn sm={ 2 }>
                        <TextField fullWidth
                                   error={ errors.getError('debt') }
                                   name="debt"
                                   type="number"
                                   value={ realty.debt }
                                   onChange={ this.handleChange }
                                   label="Долг"
                                   placeholder="Введите размер долга"
                        />
                      </FormColumn>
                      <FormColumn sm={ 2 }>
                        <ButtonWithProgress loading={ loading }
                                            onClick={ this.handleSubmitCompany }
                                            label="Сохранить"/>
                      </FormColumn>
                    </FormRow>
                  </FormContainer>
                  <Grid container spacing={ 2 }>
                    <Grid item xs={ 12 }>
                      <Divider/>
                    </Grid>
                  </Grid>
                </>
                }
              </Grid>

              <Grid item
                    xs={ 12 }>
                { realty.companies &&
                <Grid container
                      spacing={ 2 }>
                  { realty.companies
                      .filter(c => (c.archived_at == null && c.archived_by == null))
                      .map(company => (
                          <RealtyManagementCompanyRow key={ company.id }
                                                      loadManagementCompanies={ this.loadManagementCompanies }
                                                      handleRemoveManagementCompany={ this.handleRemoveManagementCompany }
                                                      handleArchiveManagementCompany={ this.handleArchiveManagementCompany }
                                                      realty_id={ realty.id }
                                                      company={ company }/>
                      )) }
                </Grid>
                }
              </Grid>

              <Grid item
                    xs={ 12 }>
                { !!realty.companies.filter(c => (c.archived_at !== null && c.archived_by !== null)).length &&
                <Grid item
                      xs={ 12 }>
                  <Grid container
                        spacing={ 2 }>
                    <Grid item
                          sm={ 4 }
                          md={ 3 }
                          lg={ 2 }
                          style={ {
                            display: 'flex',
                            alignItems: 'center'
                          } }>
                      <Typography style={ {
                        fontSize: '20px',
                        cursor: 'pointer'
                      } }
                                  onClick={ this.handleShowArchiveCompany }>
                        Архив УК
                      </Typography>
                      <AddingButton position="right"
                                    onClick={ this.handleShowArchiveCompany }
                                    icon={ props => <ArrowDownIcon { ...props } style={ {
                                      transform: show ? 'rotate(180deg)' : '',
                                      transition: '.3s all',
                                    } }/> }/>
                    </Grid>


                    { show &&
                      <Grid item xs={ 12 }>
                        <Grid container
                              spacing={ 2 }>
                          { realty.companies
                              .filter(c => (c.archived_at !== null && c.archived_by !== null))
                              .map(company => <RealtyManagementCompanyRow key={ company.id }
                                                                          realty_id={ realty.id }
                                                                          company={ company }/>) }
                        </Grid>
                      </Grid>
                    }

                  </Grid>
                </Grid>
                }
              </Grid>
            </Grid>


          </Grid>
        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(RealtyManagementCompanyForm);
