import axios from 'axios';
import queryString from 'query-string';

export const fetchModerators = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/moderators?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchModerator = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/moderators/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createModerator = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/moderators`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateModerator = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/moderators/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const deleteModerator = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/moderators/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

