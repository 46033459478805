import {InputLabel, Select, MenuItem, FormControl, FormHelperText} from '@material-ui/core';
import React, {PureComponent} from 'react';
import {fetchRoles} from '../../actions/permissionsActions';


class RolesSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      permission: []
    }
  }

  componentDidMount() {
    fetchRoles()
        .then(res => {
          this.setState({
            roles: res
          })
        })

  }

  render() {
    const {roles} = this.state;
    const {error, value, onChange, name} = this.props;

    return (
        <FormControl variant="outlined"
                     style={{width: `100%`}} error={!!error}>

          <InputLabel id="roleId">
            Права доступа
          </InputLabel>
          <Select
              labelId="roleId"
              name={name}
              error={!!error || false}
              value={value}
              onChange={onChange}
              label="Права доступа"
          >
            {roles.map(role => <MenuItem value={role.id}
                                         key={role.id}>
              {role.name}
            </MenuItem>)}
          </Select>

          {error &&
             <FormHelperText>{error}</FormHelperText>
          }

        </FormControl>
    )
  }

}

export default RolesSelect;
