import React, {PureComponent} from 'react';
import {Provider} from 'react-redux';
import {fetchUser} from '../actions/authActions';


class StoreProvider extends PureComponent {
  constructor(props) {
    super(props);


    this.state = {
      render: false
    }

  }

  componentDidMount() {
    this.props.store.dispatch(fetchUser())
        .then(() => {
          this.setState({
            render: true,
          });
        })
        .catch(() => {
          this.setState({
            render: true,
          });
        })
  }


  render() {
    const { render } = this.state;

    if (!render) {
      return null;
    }



    return (
        <React.Fragment>
          <Provider store={ this.props.store }>
            { this.props.children }
          </Provider>
        </React.Fragment>
    )
  }
}

export default StoreProvider
