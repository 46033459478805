import React, {useState} from 'react';
import './BookingDocumentsRow.scss';
import {TableRow, TableCell} from '@material-ui/core';
import {ApproveIcon, DeleteIcon, DownloadIcon, ExclamationIcon, FileIcon, UploadIcon} from '../../icons';
import AddingButton from '../AddingButton';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import {downloadBookingDocument, uploadBookingDocument} from '../../actions/bookingsActions';
import ProgressBar from '../ProgressBar';
import {getFormatTypeDownloadDocument} from '../Tools';
import DialogsRemove from '../DialogsRemove';
import fileDownload from 'js-file-download';
import {ERROR, SUCCESS} from '../Constants';

const BookingDocumentsRow = ({booking_name, booking_id, type, title, upload, document, handleRemoveDocument, handlerErrorUpload, ...props}) => {
  const [percentLoading, setPercent] = useState(null)

  let handleDownloadDocument = (docId, doc_type) => {

    let config = {
      onDownloadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (percentCompleted < 100) {
          setPercent(percentCompleted)
        } else {
          setPercent(null)
        }
      }
    };

    downloadBookingDocument(booking_id, docId, config)
        .then(response => {
          let fileFormat = getFormatTypeDownloadDocument(response.data.type);
          fileDownload(response.data, `${booking_name}-${doc_type}.${fileFormat}`)
        })
  };


  let handleUploadFile = (file, type) => {
    const data = new FormData();

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);


        if (percentCompleted < 100) {
          setPercent(percentCompleted)
        } else {
          setPercent(null)
        }
      }
    };

    if (type) {
      data.append('type', type.replace('type_', ''));
    }

    data.append('file', file[0].src.file);

    uploadBookingDocument(booking_id, data, config)
        .then(() => {
          props.openSnackbar(SUCCESS, 'Файл успешно загружен');
          props.loadDocuments();
        }).catch(errors => {
      props.openSnackbar(ERROR, errors.file || errors)
    })
  };


  return (
      <TableRow className="booking-document">

        {upload &&
        document.map(doc => (
                <React.Fragment key={doc.id}>
                  <TableCell>
                    <div className="booking-document__table-cell">
                      <div className="booking-document__icon booking-document__icon--success">
                        <ApproveIcon/>
                      </div>
                      <div className="booking-document__type booking-document__type--success">
                        {title}
                      </div>
                    </div>

                  </TableCell>
                  <TableCell>
                    <div className="booking-document__table-cell">
                      <div className="booking-document__name-icon">
                        <FileIcon/>
                      </div>
                      <div className="booking-document__name">
                        {doc.name}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{doc.user.name}</TableCell>
                  <TableCell>{doc.created_at}</TableCell>
                  <TableCell>
                    <div className="booking-document__table-cell">
                      <AddingButton icon={() => <DownloadIcon/>}
                                    tooltip={`Скачать ${title}`}
                                    disabled={!doc.can_be_downloaded || (percentLoading > 1 && percentLoading < 100)}
                                    style={{
                                      marginRight: 10,
                                      cursor: !doc.can_be_downloaded ? 'default' : 'pointer'
                                    }}
                                    onClick={() => handleDownloadDocument(doc.id, title)}/>

                                    <DialogsRemove icon={() => <DeleteIcon/>}
                                                   remover={handleRemoveDocument}
                                                   negative_answer="Нет"
                                                   positive_answer="Да"
                                                   question="Удалить документ?"
                                                   question_text={`Действительно удалить ${title}?`}
                                                   tooltip={`Удалить ${title}`}
                                                   id={doc.id}/>
                    </div>
                    {(percentLoading && percentLoading < 100) && <ProgressBar percent={percentLoading}
                                                                              style={{
                                                                                minWidth: '75px',
                                                                                marginTop: 16
                                                                              }}
                    />}
                  </TableCell>
                </React.Fragment>
            )
        )
        }

        {!upload &&
        <React.Fragment>
          <TableCell>
            <div className="booking-document__table-cell">
              <div className="booking-document__icon booking-document__icon--error">
                <ExclamationIcon/>
              </div>
              <div className="booking-document__type">
                {title}
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className="booking-document--empty">
              Нет документа
            </div>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <UploadFilesButton handleChange={file => handleUploadFile(file, type)}
                               handleError={handlerErrorUpload}
                               component={props => <AddingButton {...props}
                                   tooltip={`Загрузить ${title}`}
                                                                 icon={() => <UploadIcon/>}/>}/>
            {(percentLoading && percentLoading < 100) && <ProgressBar percent={percentLoading}
                                                                      style={{
                                                                        minWidth: '75px',
                                                                        marginTop: 16
                                                                      }}/>}
          </TableCell>
        </React.Fragment>

        }


      </TableRow>
  )
};

export default connect(null, {openSnackbar})(BookingDocumentsRow);
