import React, {useState} from 'react';
import {Grid} from '@material-ui/core';
import TextField from '../TextField';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import AddingButton from '../AddingButton';
import {SaveOutlined} from '@material-ui/icons';
import {DeleteIcon} from '../../icons';
import FormRow from '../FormRow';
import {deleteRealtyRoomItem, updateRealtyRoomItem} from '../../actions/realtyActions';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import RealtyRoomItemCondition from './RealtyRoomItemCondition';
import DialogsRemove from '../DialogsRemove';
import Errors from '../Errors';
import {errorHandler} from '../Tools';
import {ERROR, SUCCESS} from '../Constants';

const RealtyInventoryRoomObject = ({realty_id, room_id, id, quantity,
                                     fetchRooms,
                                     fetchRealtyRoomItems, user, comment, name, condition, openSnackbar,  ...props}) => {

  const [state, setState] = useState({
    inventory: {
      name: name,
      condition: condition,
      quantity: quantity,
      comment: comment
    }
  })

  const [errors, setErrors] = useState(new Errors())

  let handleRemoveRoomItem = (room_id, id) => {
    deleteRealtyRoomItem(realty_id, room_id, id)
        .then(() => {
          openSnackbar(SUCCESS, 'Инвентарь успешно удален');
          fetchRooms();
          fetchRealtyRoomItems();
        })
        .catch(errors => {
          openSnackbar(ERROR, errorHandler(errors));
        })

  }

  let handleUpdateRoomItem = () => {
    const data = {
      ...state.inventory
    }

    updateRealtyRoomItem(realty_id, room_id, id, data)
        .then(() => {
          fetchRealtyRoomItems()
          openSnackbar(SUCCESS, 'Инвентарь успешно обновлен');
          setErrors(new Errors())
        })
        .catch(errors => {
          setErrors(new Errors(errors))
        })
  }

  let handleChange = (e) => {
    const {value, name} = e.target;

    setState(prevState => ({
      ...prevState,
      inventory: {
        ...prevState.inventory,
        [name]: value
      }
    }));
  };

  return (
      <FormRow>
        <Grid item xs={12}>
          <Grid container
                spacing={2}>
            <Grid item sm={2}>
              <TextField name="name"
                         error={errors.getError('name')}
                         value={state.inventory.name}
                         onChange={handleChange}
                         label="Название"
                         placeholder="Введите название"
              />
            </Grid>
            <Grid item
                  sm={2}>
              <ManagersAutocomplete label="Менеджер"
                                    value={user}
                                    disabled
                                    placeholder="Виберите менеджера"/>
            </Grid>
            <Grid item
                  sm={2}>
              <TextField name="quantity"
                         error={errors.getError('quantity')}
                         value={state.inventory.quantity}
                         onChange={handleChange}
                         label="Количество"
                         placeholder="Введите количество шт."/>
            </Grid>
            <Grid item
                  sm={2}>

              <RealtyRoomItemCondition label="Состояние"
                                       error={errors.getError('condition')}
                                       name="condition"
                                       fullWidth
                                       onChange={handleChange}
                                       value={state.inventory.condition}/>
            </Grid>
            <Grid item
                  sm={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
              <AddingButton icon={() => <SaveOutlined/>}
                            onClick={handleUpdateRoomItem}
                            tooltip="Сохранить"/>

              <DialogsRemove question_text="Действительно удалить инвентарь?"
                             question="Удалить инвентарь?"
                             icon={() => <DeleteIcon/>}
                             companyId={room_id}
                             id={id}
                             remover={handleRemoveRoomItem}
                             negative_answer="Нет"
                             positive_answer="Да"
                             position_button="right"
                             tooltip="Удалить инвентарь"/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item
              xs={12}
              sm={6}
              md={4}>
          <TextField name="comment"
                     error={errors.getError('comment')}
                     multiline
                     rows={3}
                     value={state.inventory.comment}
                     onChange={handleChange}
                     label="Комментарий"
                     placeholder="Введите комментарий"
          />
        </Grid>
      </FormRow>
  )
}

export default connect(null, {openSnackbar})(RealtyInventoryRoomObject);
