import React from 'react';
import {Grid} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import SearchField from '../SearchField';
import StatisticAdvancedFilter from './StatisticAdvancedFilter';
import SelectField from '../SelectField';
import {COMPANY_ID, REALTY_TYPES} from '../Constants';


const StatisticCompanyFilter = ({company_id, ...props}) => {

  // let handlerTabsChange = (e, newValue) => {
  //   const search = queryString.parse(props.location.search);
  //
  //   props.history.push({
  //     pathname: window.location.pathname,
  //     search: queryString.stringify({
  //       ...search,
  //       [`apartments${company_id}`]: 1,
  //       realtiable_type: newValue,
  //     }),
  //   });
  //
  // };

  let handleChange = (e) => {
    const {name, value} = e.target;
    const search = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        [`apartments${COMPANY_ID}`]: 1,
        [name]: value,
      }),
    });

  };

  let getParam = (key) => {
    const search = queryString.parse(props.location.search);

    return search[key] || ''
  };

  return (
      <Grid container
            spacing={2}
            style={{alignItems: 'center'}}>
        {/*<Grid item>*/}
        {/*  <RealtySortTabs value={getParam('realtiable_type')}*/}
        {/*                  handleChange={handlerTabsChange}/>*/}
        {/*</Grid>*/}
        <Grid item>
          <SelectField label={'Типы объектов'}
                       options={REALTY_TYPES}
                       style={{minWidth: 220}}
                       onChange={handleChange}
                       value={getParam('realtiable_type')}
                       name="realtiable_type"
                       fullWidth/>
        </Grid>
        <Grid item>
          <SearchField fulWidth
                       labelWidth={40}
                       searchField="name"
                       label="Поиск"
                       placeholder="Введите имя"/>
        </Grid>
        <StatisticAdvancedFilter/>
      </Grid>
  )
};

export default withRouter(StatisticCompanyFilter);
