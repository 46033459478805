import React from 'react';
import { Grid } from '@material-ui/core';
import FilterCheckboxField from '../FilterCheckboxField';

const RealtyGroupFilter = () => {
  return (
    <Grid container
          spacing={ 2 }
          style={ { alignItems: 'center' } }>
      <Grid item>
        <FilterCheckboxField name="without_sold"
                             label="Не учитывать проданные"/>
      </Grid>
    </Grid>
  );
};

export default RealtyGroupFilter;
