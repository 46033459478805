import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import {openSnackbar} from '../../actions/snackbarActions';
import SwitchField from '../SwitchField';
import FormContainer from '../FormContainer';
import PhoneInput from '../PhoneInput';
import {createClient} from '../../actions/clientsActions';
import PasswordField from '../PasswordField';
import LocationsAutocomplete from '../Autocomplete/LocationsAutocomplete';
import {withRouter} from 'react-router-dom';
import SuggestPasswordNotification from '../UI/SuggestPasswordNotification';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';
import {reformatPhoneNumber} from '../Tools';

const initialState = {
  client: {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    password: undefined,
    passwordConfirmation: undefined,
    active: false,
    location: {},
    phone: undefined
  },
  errors: new Errors(),
  showPassword: false,
  showPasswordConfirm: false,
  suggestPassword: false,
};


class ClientCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  };


  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      client: {
        ...prevState.client,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handlePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handlePasswordConfirm = () => {
    this.setState({
      showPasswordConfirm: !this.state.showPasswordConfirm
    });
  };

  handleLocationsChange = (e, value) => {
    this.setState(prevState => ({
      client: {
        ...prevState.client,
        location: value
      }
    }));
  };

  handleSuggestPassword = () => {
    this.setState({
      suggestPassword: true
    });
  }

  handleCloseSuggestPassword = () => {
    this.setState({
      suggestPassword: false
    });
  }

  handleSetSuggestPassword = (generatePassword) => {
    this.setState(prevState => ({
      client: {
        ...prevState.client,
        password: generatePassword,
        passwordConfirmation: generatePassword
      },
      suggestPassword: false
    }));
  }


  handleSubmit = (event) => {
    event.preventDefault();

    const {client} = this.state;

    createClient({
      first_name: client.firstName,
      last_name: client.lastName,
      email: client.email,
      password: client.password,
      password_confirmation: client.passwordConfirmation,
      active: client.active,
      phone: reformatPhoneNumber(client.phone),
      location_id: client.location.id
    }).then(() => {
      this.setState(initialState);
      this.props.loadClients();
      this.props.openSnackbar(SUCCESS, 'Клиент добавлен успешно');
      this.setState({errors: new Errors({})});
      this.props.location.state = {open: false};
    }).catch(errors =>
        this.setState({
          errors: new Errors(errors)
        })
    );
  };

  render() {
    const {client, errors, showPassword, showPasswordConfirm, suggestPassword} = this.state;

    return (
        <React.Fragment>
          <FormContainer>
            <FormRow>
              <FormColumn sm={6}>
                <TextField error={errors.getError('first_name')}
                           fullWidth
                           name="firstName"
                           value={client.firstName}
                           onChange={this.handleChange}
                           label="Имя"
                           placeholder="Введите имя"
                />
              </FormColumn>

              <FormColumn sm={6}>
                <TextField error={errors.getError('last_name')}
                           fullWidth
                           name="lastName"
                           value={client.lastName}
                           onChange={this.handleChange}
                           label="Фамилия"
                           placeholder="Введите фамилию"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <TextField error={errors.getError('email')}
                           fullWidth
                           name="email"
                           value={client.email}
                           onChange={this.handleChange}
                           label="Электронная почта"
                           placeholder="Введите электронную почту"
                />
              </FormColumn>
              <FormColumn sm={6}>
                <PhoneInput error={errors.getError('phone')}
                            name="phone"
                            type="phone"
                            onChange={this.handleChange}
                            value={client.phone}
                            label="Телефон"
                            placeholder="Введите телефон"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <LocationsAutocomplete error={errors.getError('location_id')}
                                       value={client.location}
                                       onChange={this.handleLocationsChange}/>
              </FormColumn>

              <FormColumn sm={6}
                          style={flex}>

                <SwitchField error={errors.getError('active')}
                             name="active"
                             value={client.active}
                             onChange={this.handleChange}
                             label="Активность"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <PasswordField error={errors.getError('password')}
                               name="password"
                               autoComplete={'false'}
                               type={showPassword ? 'text' : 'password'}
                               value={client.password}
                               onChange={this.handleChange}
                               label="Пароль"
                               onFocus={this.handleSuggestPassword}
                               placeholder="Введите пароль"
                               handlePassword={this.handlePassword}
                               showPassword={showPassword}
                />
              </FormColumn>
              <FormColumn sm={6}>
                <PasswordField error={errors.getError('password')}
                               autoComplete={'false'}
                               name="passwordConfirmation"
                               type={showPasswordConfirm ? 'text' : 'password'}
                               value={client.passwordConfirmation}
                               onChange={this.handleChange}
                               label="Пароль"
                               placeholder="Введите пароль"
                               handlePassword={this.handlePasswordConfirm}
                               showPassword={showPasswordConfirm}
                />
              </FormColumn>
            </FormRow>

            {(suggestPassword && (client.phone?.length > 1)) &&
              <FormRow>
                <FormColumn sm={12}>
                  <SuggestPasswordNotification phone={client.phone}
                                               onClick={this.handleSetSuggestPassword}
                                               onClose={this.handleCloseSuggestPassword}/>
                </FormColumn>
              </FormRow>
            }


            <FormRow>
              <FormColumn xs="auto" sm="auto" style={{marginLeft: 'auto'}}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleSubmit}
                >
                  Добавить
                </Button>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(ClientCreateForm));
