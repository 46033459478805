import React from 'react';
import {Grid} from '@material-ui/core';
import SearchField from '../../SearchField';
import ClearFiltersButton from '../../ClearFiltersButton';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import ManagementCompaniesSelect from '../../ManagementCompany/ManagementCompaniesSelect';
import {flexAlignCenter} from '../../JSStylesConstants';
import {LABEL_WIDTH_SM, REALTY_TYPES} from '../../Constants';
import SelectField from '../../SelectField';


const PaymentOrdersFilters = ({...props}) => {

  // let handlerTabsChange = (e, newValue) => {
  //   const search = queryString.parse(props.location.search);
  //
  //   props.history.push({
  //     pathname: window.location.pathname,
  //     search: queryString.stringify({
  //       ...search,
  //       realty_type: newValue,
  //     }),
  //   });
  //
  // };

  let handleFilterSelectChange = (e) => {
    const {name, value} = e.target;
    const search = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        [name]: value,
      }),
    });
  };


  let getParam = (key) => {
    const search = queryString.parse(props.location.search);

    return search[key] || ''
  };


  return (
      <Grid container
            spacing={2}
            style={{alignItems: 'center'}}>
        <Grid item>
          {/*<RealtySortTabs value={getParam('realty_type')}*/}
          {/*                handleChange={handlerTabsChange}/>*/}
          <SelectField label={'Типы объектов'}
                       options={REALTY_TYPES}
                       style={{minWidth: 220}}
                       onChange={handleFilterSelectChange}
                       value={getParam('realty_type')}
                       name="realty_type"
                       fullWidth/>
        </Grid>
        <Grid item>
          <ManagementCompaniesSelect onChange={handleFilterSelectChange}
                                     style={{minWidth: 220}}
                                     value={getParam('management_company_id')}
                                     name="management_company_id"/>
        </Grid>
        <Grid item>
          <SearchField searchField="realty_name"
                       label="Поиск по объекту"
                       labelWidth={LABEL_WIDTH_SM}
                       placeholder="Введите имя объекта"/>
        </Grid>
        <Grid item
              style={flexAlignCenter}>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  )
};

export default withRouter(PaymentOrdersFilters);
