import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {capitalizeFirstLetter, setPageTitle, uuidv4} from '../../components/Tools';
import moment from 'moment-timezone';
import {Card, CardHeader, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import PaymentActsRow from '../../components/Utilities/PaymentActs/PaymentActsRow';
import PaymentActsFilter from '../../components/Utilities/PaymentActs/PaymentActsFilter';
import PageHeader from '../../components/PageHeader';
import {fetchPaymentOrdersLogs} from '../../actions/utilitiesActions';


const PaymentActsListPage = ({...props}) => {
  const [actLogs, setActLogs] = useState(null);
  const [currentMonths, setMonths] = useState(null);
  const location = useLocation();

  let createTableLabelFromMonth = useCallback(() => {
    let currentMonthNumber = moment().format('M');
    let allMonth = moment.months();
    let monthsBeforeCurrent = allMonth.slice(0, Number(currentMonthNumber));
    let transformMonth = monthsBeforeCurrent.map(m => ({title: capitalizeFirstLetter(m)}));

    setMonths(transformMonth);
  }, []);

  useEffect(() => {
    createTableLabelFromMonth();
  }, [createTableLabelFromMonth]);

  const getActLogs = useCallback(() => {
    const query = queryString.parse(location.search);

    fetchPaymentOrdersLogs(query)
        .then(response => {
          setActLogs(Object.values(response));
        });
  }, [location.search]);

  useEffect(() => {
    setPageTitle('Акты оплаты');
    getActLogs();
  }, [getActLogs]);

  return (
      <Grid container
            spacing={ 2 }>
        <Grid item>
          <PageHeader title="Акты оплаты"
                      size="md"/>
        </Grid>
        <Grid item
              xs={ 12 }>
          <PaymentActsFilter/>
        </Grid>

        <Grid item
              xs={ 12 }>
          <Paper>
            { (!actLogs || !!actLogs.length) &&
              <TableContainer style={ {
                maxWidth: '100%',
                overflow: 'auto',
              } }>
                <Table style={ {overflowX: 'auto'} }>
                  <TableHead>
                    <TableRow>
                      <TableCell style={ {
                        position: 'sticky',
                        minWidth: 200,
                        maxWidth: 250,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        backgroundColor: 'white',
                        left: 0
                      } }>
                        Объекты
                      </TableCell>
                      <TableCell>УК</TableCell>

                      { currentMonths && currentMonths.map(month => (
                          <TableCell key={ uuidv4() }>
                            <div style={ {display: 'flex', flexWrap: 'wrap', textAlign: 'center'} }>
                              <div style={ {flex: '0 0 100%', textAlign: 'center'} }>{ month.title }</div>
                              {/*<div style={{flex: '0 0 50%', textAlign: 'center'}}>Начислено</div>*/ }
                              <div style={ {flex: '0 0 100%', textAlign: 'center'} }>Оплачено</div>
                            </div>
                          </TableCell>
                      )) }


                      {/*<TableCell style={{textAlign: 'center'}}>Σ Начислено</TableCell>*/ }
                      <TableCell style={ {textAlign: 'center'} }>Σ Оплачено</TableCell>
                      {/*<TableCell style={ {textAlign: 'center'} }>Итого</TableCell>*/ }
                    </TableRow>

                  </TableHead>
                  <TableBody>
                    { actLogs && actLogs.map(item => <PaymentActsRow key={ uuidv4() }
                                                                     item={ item }/>) }
                  </TableBody>
                </Table>
              </TableContainer>
            }

            {(!actLogs || !actLogs.length) &&
              <Card>
                <CardHeader style={{textAlign: 'center'}}
                            title="Актов по заданным критериям не найдено"/>
              </Card>
            }


          </Paper>
        </Grid>
      </Grid>
  );
};

export default PaymentActsListPage;
