import React, {memo, useEffect, useState} from 'react';
import StatisticGroups from './StatisticGroups';
import queryString from 'query-string';
import {useLocation} from 'react-router-dom';
import {COMPANY_ID} from '../Constants';
import {fetchStatisticGroups} from '../../actions/statisticsActions';

const StatisticGroupsContainer = ({...props}) => {
  const location = useLocation();
  const [groups, setGroups] = useState(null);

  useEffect(() => {
    const {date_from, date_to, ...query} = queryString.parse(location.search);

    if(date_from && date_to) {
      const params = {
        ...query,
        date_from,
        date_to,
        company_id: COMPANY_ID
      };

      fetchStatisticGroups(params)
          .then(response => {
            setGroups(response);
          });
    }
  }, [location.search]);


  if(!groups) {
    return null;
  }

  return (
      <>
        {groups.map(group => <StatisticGroups key={group.id}
                                              {...group}/>)}
      </>
  );
};

export default memo(StatisticGroupsContainer);
