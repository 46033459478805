export const ACTIVE_SELECT_OPTIONS = [
  { value: '1', name: 'Активен' },
  { value: '2', name: 'Закрыт' },
];

export const RENT_TYPE_OPTIONS = [
  { value: '1', name: 'Посуточно' },
  { value: '2', name: 'На длительный срок' },
];


export const RENT_SORT_TYPE_OPTIONS = [
  { value: 'day', name: 'Дни' },
  { value: 'week', name: 'Недели' },
];
