import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';


const DialogsRemoveWithCustomField = ({
                                        style, closer, remover, id, companyId = null, tooltip,
                                        position_button, component: Component = null, negative_answer, positive_answer,
                                        question, question_text, disabled, isOpen, ...props
                                      }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    closer();
    setOpen(false);
  };

  const handleRemove = () => {
    remover();
    handleClose();
  };

  return (
      <React.Fragment>
        <Component disabled={disabled}
                   style={style}
                   tooltip={tooltip}/>

        <Dialog open={open}
                onClose={handleClose}
                maxWidth="xs">
          <DialogTitle>{question}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {question_text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              {negative_answer}
            </Button>
            <Button onClick={handleRemove} color="primary" autoFocus>
              {positive_answer}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

export default DialogsRemoveWithCustomField;
