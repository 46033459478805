import React from 'react';
import {Grid} from '@material-ui/core';
import FormRow from '../FormRow';
import BasicUtilitiesIcon from '../Utilities/BasicUtilitiesIcon';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import PickDateField from '../PickDateField';
import moment from 'moment';


const RealtyManagementCompanyArchiveRow = ({counter, ...props}) => {

  return (
      <Grid item
            xs={12}>
        <FormRow>
            <div style={{
              padding: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <BasicUtilitiesIcon icon_id={counter.basic_utility_id}/>
            </div>
            <FormColumn sm={2}>
              <TextField fullWidth
                         name="meter_name"
                         disabled
                         value={counter.meter_name}
                         label="Счетчик"
                         placeholder="Введите название счетчика"
              />
            </FormColumn>
            <FormColumn sm={2}>
              <TextField fullWidth
                         disabled
                         name="meter_value"
                         type="number"
                         value={counter.meter_value}
                         label="Последний показатель"
              />
            </FormColumn>
          <FormColumn sm={2}>
            <PickDateField name="archived_at"
                           type="archived_at"
                           disabled
                           value={moment(counter.archived_at).format('DD/MM/YYYY')}
                           label="Дата переноса в архив"/>
          </FormColumn>
          <FormColumn sm={2}>
            <TextField fullWidth
                       disabled
                       name="archived_by"
                       value={counter.archived_by}
                       label="Кто архивировал"
            />
          </FormColumn>

        </FormRow>
      </Grid>
  )
}

export default RealtyManagementCompanyArchiveRow;
