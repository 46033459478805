import React, {PureComponent} from 'react';
import SelectField from '../SelectField';
import moment from 'moment';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';

let previousMonth = moment().subtract('1', 'month'),
    previousYear = moment().subtract('3', 'month');


const options = [
  {value: moment(previousMonth).format('DD/MM/YYYY'), name: 'За месяц'},
  {value: moment(previousYear).format('DD/MM/YYYY'), name: 'За три месяца'},
];

class PeriodSelectField extends PureComponent {

  handleChange = (e) => {
    const {value} = e.target;
    const query = queryString.parse(this.props.location.search);


    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        'date_from': moment(value, 'DD/MM/YYYY').format('X'),
      })
    })

  };

  render() {
    const {date_from} = queryString.parse(this.props.location.search);

    return (
          <SelectField {...this.props}
                       value={moment(date_from, 'X').format('DD/MM/YYYY')}
                       label="Период"
                       options={options}
                       onChange={this.handleChange}
          />

    )
  }
}

export default withRouter(PeriodSelectField);
