import React from 'react';
import './Loader.scss';

const Loader = ({...props}) => {
  return (
      <div className="loader-wrap">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
  );
};

export default Loader;
