import React from 'react';
import Tabs from '../Tabs';

const labels = [
  {label: "Основное"},
  {label: "Сайт"},
  {label: "Документы"},
  {label: "Цены и услуги"},
  {label: "Ресурсы"},
  {label: "Счетчики и УК"},
  {label: "Инвентарь"},
];

const RealtyEditTabs = ({ handleChange, value, ...props}) => {
  return (
      <Tabs options={labels}
            handleChange={handleChange}
            value={value}/>
  )
};

export default RealtyEditTabs;
