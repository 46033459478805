import * as actionTypes from '../actions/actionTypes';

const initialState = {
  company: null
};

const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STATISTIC_COMPANY:
      return {
        ...state,
        company: action.payload
      };
    default:
      return state;
  }
};

export default statisticReducer;
