import React from 'react';
import {Button, Link, TableCell, TableRow} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {CLIENTS_ROUTE, MEETINGS_ROUTE, REALTY_LIST_ROUTE} from '../RouterConstants';
import moment from 'moment-timezone';

const MeetingsTableRow = ({meeting, ...props}) => {
  return (
      <TableRow hover>
        <TableCell>{ moment(meeting.datetime).format('DD.MM.YYYY HH:mm') }</TableCell>
        <TableCell>{ meeting.manager?.name }</TableCell>
        <TableCell>
          <Link color="inherit"
                component={ React.forwardRef((props, ref) => (
                    <RouterLink { ...props }
                                ref={ ref }
                                to={ `${ CLIENTS_ROUTE }/${ meeting.client?.id }` }
                    />
                )) }>
            <b>{ meeting?.client?.name }</b><br/>
            <a href={ `tel:${ meeting.client?.phone }` }>{ meeting?.client?.phone }</a>
          </Link>
        </TableCell>

        <TableCell>{ meeting.text_type }</TableCell>
        <TableCell>
          {meeting?.realty ?
              <Link color="inherit"
                    component={ React.forwardRef((props, ref) => (
                        <RouterLink { ...props }
                                    ref={ ref }
                                    to={ `${ REALTY_LIST_ROUTE }/${ meeting.realty?.id }` }
                        />
                    )) }>
                <b>{ meeting.realty.name }</b>
              </Link>
          : null
          }

        </TableCell>
        <TableCell>{ meeting.text_status }</TableCell>
        <TableCell>{ meeting.comment }</TableCell>
        <TableCell>
          <RouterLink to={ `${ MEETINGS_ROUTE }/${ meeting.id }` }
                style={ {textDecoration: 'none'} }>
            <Button color="primary"
                    variant="contained">
              Редактировать
            </Button>
          </RouterLink>
        </TableCell>
      </TableRow>
  );
};

export default MeetingsTableRow;
