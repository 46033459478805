import React, { PureComponent } from 'react';
import Errors from '../Errors';
import { Grid } from '@material-ui/core';
import { fetchRealty, updateRealtyTariffs } from '../../actions/realtyActions';
import Title from '../Title';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import SwitchField from '../SwitchField';
import TextField from '../TextField';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import CheckboxField from '../CheckboxField';
import ButtonWithProgress from '../ButtonWithProgress';
import { LAND, SUCCESS } from '../Constants';
import { getCurrentCurrency, getCurrentPriceDay } from '../Tools';

class RealtyEditTariffsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      realty: {
        id: this.props.id,
        status: '',
        expected_yearly_profit: '',
        sellable: '',
        sale_price: '',
        prices: [],
        rent_type: '',
        tax: false,
        tax_price: '',
        is_deleted: false,
        type: '',

        is_bought: false,
        purchase_price: '',
        with_deposit: false,
        deposit_amount: '',
        mortgagable: false,
        mortgage_amount: '',
        with_investment: false,
        investment_price: '',
        with_registration: false,
        registration_price: ''
      },
      errors: new Errors(),
      loading: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevState.realty.sale_price !== this.state.realty.sale_price ) {
      this.setState(prevState => ( {
        realty: {
          ...prevState.realty,
          tax_price: ( ( this.state.realty.sale_price * 6 ) / 100 ) + parseInt(this.state.realty.sale_price)
        }
      } ));
    }
  }

  componentDidMount() {
    const { realty: { id } } = this.state;

    fetchRealty(id).then(realty => {
      this.setRealty(realty);
    });
  }

  setRealty = ({
                 id, sale_price, sellable, prices, expected_yearly_profit, is_bought, purchase_price, with_deposit,
                 deposit_amount, mortgagable, mortgage_amount, with_investment, investment_price, with_registration,
                 registration_price, status, is_deleted, type,
               }) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        id: id,
        sale_price: sale_price,
        sellable: sellable,
        expected_yearly_profit: expected_yearly_profit || '',
        prices: prices,
        status: status,
        is_deleted: is_deleted,
        type: type,

        is_bought: is_bought,
        purchase_price: purchase_price,
        with_deposit: with_deposit,
        deposit_amount: deposit_amount,
        mortgagable: mortgagable,
        mortgage_amount: mortgage_amount,
        with_investment: with_investment,
        investment_price: investment_price,
        with_registration: with_registration,
        registration_price: registration_price
      },
    } ));
  };

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));

  };

  handleSaleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value,
        sale_price: checked ? '' : ''
      }
    } ));

  };


  handleRentType = (e) => {
    const { checked, name } = e.target;

    const newTariff = {
      rent_type: +name,
      price: '',
      discount_price: ''
    };

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        prices: checked
          ? [...prevState.realty.prices.filter(x => x.rent_type !== +name), newTariff]
          : prevState.realty.prices.filter(x => x.rent_type !== +name)
      }
    } ));
  };

  handlePriceChange = (event, type) => {
    const { value, name } = event.target;


    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        prices: [...prevState.realty.prices.filter(x => x.rent_type !== type),
          {
            [name]: value,
            rent_type: type,
            discount_price: prevState.realty.prices.find(x => x.rent_type === type).discount_price
          }]

      }
    } ));
  };

  handleDiscountChange = (event, type) => {
    const { value, name } = event.target;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        prices: [...prevState.realty.prices.filter(x => x.rent_type !== type),
          { [name]: value, rent_type: type, price: prevState.realty.prices.find(x => x.rent_type === type).price }]

      }
    } ));
  };

  handleSubmit = () => {
    const { realty } = this.state;
    this.setState({ loading: true });


    const data = {
      expected_yearly_profit: realty.expected_yearly_profit,
      sellable: realty.sellable,
      sale_price: realty.sale_price,
      tariffs: realty.prices,

      is_bought: realty.is_bought,
      purchase_price: realty.purchase_price,
      with_deposit: realty.with_deposit,
      deposit_amount: realty.deposit_amount,
      mortgagable: realty.mortgagable,
      mortgage_amount: realty.mortgage_amount,
      with_investment: realty.with_investment,
      investment_price: realty.investment_price,
      with_registration: realty.with_registration,
      registration_price: realty.registration_price
    };

    updateRealtyTariffs(realty.id, data)
      .then(realty => {
        this.setRealty(realty);
        this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
        this.setState({
          loading: false,
          errors: new Errors({})
        });
      })
      .catch(errors => {
        this.setState({
          loading: false,
          errors: new Errors(errors.errors)
        });
        // this.props.openSnackbar('error', errors);
      });
  };


  render() {
    const { realty, errors, loading } = this.state;


    return (
      <Grid container
            spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <Title size="xl"
                 title="Цены"
                 style={ { paddingBottom: 24 } }/>

          <FormContainer>
            <FormRow>
              <FormColumn ssm={ 3 }
                          md={ 2 }>
                <SwitchField name="sellable"
                             label="Продажа"
                             value={ realty.sellable }
                             onChange={ this.handleSaleChange }/>
              </FormColumn>
              { realty.sellable &&
              <>
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <TextField error={ errors.getError('sale_price') }
                             name="sale_price"
                             type="number"
                             value={ realty.sale_price }
                             onChange={ this.handleChange }
                             placeholder="Введите сумму продажи"
                             label="Цена на продажу"
                  />
                </FormColumn>
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <CheckboxField label="+ налог 6%"
                                 name="tax"
                                 handleChange={ this.handleChange }
                                 value={ realty.tax }/>
                </FormColumn>

                { realty.tax &&
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <TextField name="tax_price"
                             type="number"
                             disabled
                             value={ realty.tax_price }
                             placeholder="Цена с налогом"
                             label="Цена с налогом"
                  />
                </FormColumn>
                }


              </>
              }
            </FormRow>


            { ( realty.type !== LAND ) &&
            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="2"
                             error={ errors.getError('tariffs') }
                             checked={ realty.prices.map(x => x.rent_type).includes(2) }
                             label="Посуточная Аренда"
                             value={ realty.prices.find(x => x.rent_type === 2) }
                             onChange={ this.handleRentType }/>
              </FormColumn>
              { realty.prices.map(x => x.rent_type).includes(2) &&
              <>
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <TextField error={ errors.getError('tariffs.0.price') }
                             name="price"
                             type="number"
                             value={ realty.prices.find(x => x.rent_type === 2)?.price }
                             onChange={ event => this.handlePriceChange(event, 2) }
                             placeholder={ getCurrentPriceDay() }
                             label="Введите сумму"
                  />
                </FormColumn>
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <TextField error={ errors.getError('tariffs.0.discount_price') }
                             name="discount_price"
                             type="number"
                             value={ realty.prices.find(x => x.rent_type === 2)?.discount_price }
                             onChange={ event => this.handleDiscountChange(event, 2) }
                             placeholder={ getCurrentCurrency() }
                             label="Введите сумму co скидкой"
                  />
                </FormColumn>
              </>
              }
            </FormRow>
            }


            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="1"
                             error={ errors.getError('tariffs') }
                             label="Долгосрочная Аренда"
                             checked={ realty.prices.map(x => x.rent_type).includes(1) }
                             value={ realty.prices.find(x => x.rent_type === 1) }
                             onChange={ this.handleRentType }/>
              </FormColumn>

              { realty.prices.map(x => x.rent_type).includes(1) &&
              <React.Fragment>
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <TextField error={ errors.getError('tariffs.1.price') }
                             name="price"
                             type="number"
                             value={ realty.prices.find(x => x.rent_type === 1)?.price }
                             onChange={ event => this.handlePriceChange(event, 1) }
                             placeholder={ getCurrentCurrency() }
                             label="Введите сумму"
                  />
                </FormColumn>
                <FormColumn sm={ 3 }
                            md={ 2 }>
                  <TextField error={ errors.getError('tariffs.1.discount_price') }
                             name="discount_price"
                             type="number"
                             value={ realty.prices.find(x => x.rent_type === 1)?.discount_price }
                             onChange={ event => this.handleDiscountChange(event, 1) }
                             placeholder={ getCurrentCurrency() }
                             label="Введите сумму co скидкой"
                  />
                </FormColumn>
              </React.Fragment>
              }

            </FormRow>

            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="is_bought"
                             error={ errors.getError('is_bought') }
                             label="Цена покупки"
                             checked={ realty.is_bought }
                             value={ realty.is_bought }
                             onChange={ this.handleChange }/>
              </FormColumn>

              { realty.is_bought &&
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <TextField error={ errors.getError('purchase_price') }
                           name="purchase_price"
                           type="number"
                           value={ realty.purchase_price }
                           onChange={ this.handleChange }
                           placeholder="Введите цену покупки"
                           label="Цена покупки"
                />
              </FormColumn>
              }
            </FormRow>

            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="with_deposit"
                             error={ errors.getError('with_deposit') }
                             label="Залог"
                             checked={ realty.with_deposit }
                             value={ realty.with_deposit }
                             onChange={ this.handleChange }/>
              </FormColumn>

              { realty.with_deposit &&
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <TextField error={ errors.getError('deposit_amount') }
                           name="deposit_amount"
                           type="number"
                           value={ realty.deposit_amount }
                           onChange={ this.handleChange }
                           placeholder="Введите суму залога"
                           label="Сума залога"
                />
              </FormColumn>
              }
            </FormRow>

            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="mortgagable"
                             error={ errors.getError('mortgagable') }
                             label="Ипотека"
                             checked={ realty.mortgagable }
                             value={ realty.mortgagable }
                             onChange={ this.handleChange }/>
              </FormColumn>

              { realty.mortgagable &&
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <TextField error={ errors.getError('mortgage_amount') }
                           name="mortgage_amount"
                           type="number"
                           value={ realty.mortgage_amount }
                           onChange={ this.handleChange }
                           placeholder="Введите суму ипотеки"
                           label="Сума ипотеки"
                />
              </FormColumn>
              }
            </FormRow>

            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="with_investment"
                             error={ errors.getError('with_investment') }
                             label="Вложения (Ремонт)"
                             checked={ realty.with_investment }
                             value={ realty.with_investment }
                             onChange={ this.handleChange }/>
              </FormColumn>

              { realty.with_investment &&
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <TextField error={ errors.getError('investment_price') }
                           name="investment_price"
                           type="number"
                           value={ realty.investment_price }
                           onChange={ this.handleChange }
                           placeholder="Введите суму вложений"
                           label="Сума вложений"
                />
              </FormColumn>
              }
            </FormRow>

            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <SwitchField name="with_registration"
                             error={ errors.getError('with_registration') }
                             label="Оформления (Документы)"
                             checked={ realty.with_registration }
                             value={ realty.with_registration }
                             onChange={ this.handleChange }/>
              </FormColumn>

              { realty.with_registration &&
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <TextField error={ errors.getError('registration_price') }
                           name="registration_price"
                           type="number"
                           value={ realty.registration_price }
                           onChange={ this.handleChange }
                           placeholder="Введите суму оформлений"
                           label="Сума оформлений"
                />
              </FormColumn>
              }
            </FormRow>


            <FormRow>
              <FormColumn sm={ 3 }
                          md={ 2 }>
                <TextField error={ errors.getError('expected_yearly_profit') }
                           name="expected_yearly_profit"
                           value={ realty.expected_yearly_profit }
                           onChange={ this.handleChange }
                           type="number"
                           placeholder="Укажите целое число"
                           label="Ожидаемый % годовых"
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <ButtonWithProgress loading={ loading }
                  // disabled={ realty.is_deleted }
                                    onClick={ this.handleSubmit }
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, { openSnackbar })(RealtyEditTariffsForm);
