import axios from 'axios';
import queryString from 'query-string';

export const fetchClients = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/clients?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchClient = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/clients/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createClient = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/clients`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateClient = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/clients/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createClientsDetailsEntity = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/clients/${id}/entity`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createClientsDetailsIndividual = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/clients/${id}/individual`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};
