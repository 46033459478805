import React, {PureComponent} from 'react';
import {Grid, TextField} from '@material-ui/core';
import Errors from '../Errors';
import {sendChatMessage} from '../../actions/chatActions';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import ButtonWithProgress from '../ButtonWithProgress';
import {translateErrors} from '../Tools';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import FileChip from '../FileChip';
import {ENTER_KEY, ERROR} from '../Constants';
import Typography from '@material-ui/core/Typography';
import {flexWrap} from '../JSStylesConstants';

const initialState = {
  message: '',
  loading: false,
  filesToView: null,
  filesToSend: null,
  errors: new Errors({}),
};

class ChatForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (e) => {
    const {value} = e.target;

    this.setState({
      message: value
    });
  };

  handlePressEnter = (e) => {
    if ( e.keyCode === ENTER_KEY ) {
      this.handleSubmit(e);
    }
  };

  handlerErrorUpload = (errors) => {
    this.props.openSnackbar(ERROR, translateErrors(errors));
  };

  handleUploadFile = (files) => {
    this.setState({
      filesToView: files,
      filesToSend: files
    });
  };

  handleDeleteUploadFile = (id) => {
    const {filesToView, filesToSend} = this.state;

    this.setState({
      filesToView: filesToView.filter(f => f.id !== id),
      filesToSend: filesToSend.filter(f => f.id !== id)
    });
  };


  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({loading: true});
    const {booking_id} = this.props;
    const {message, filesToSend} = this.state;

    const data = new FormData();

    data.append('booking_id', booking_id);
    data.append('message', message);

    filesToSend
        ?.map(f => f.src.file)
        .forEach((file, index) => {
      data.append(`attachments[${ index }]`, file);
    });


    sendChatMessage(data)
        .then(() => {
          this.setState(initialState);
          this.props.onSumit();
        })
        .catch(errors => {
          this.setState({
            loading: false,
            errors: new Errors(errors),
          });
        });
  };

  render () {
    const {message, errors, loading, filesToView} = this.state;

    return (
        <>
          <Grid container
                spacing={ 4 }
                style={ {padding: 16} }>
            <Grid item
                  xs={ 11 }
                  sm={ 8 }>
              <TextField onKeyDown={ e => this.handlePressEnter(e) }
                         error={ !!errors.getError('message') }
                         helperText={ errors.getError('message') }
                         fullWidth
                         placeholder="Введите сообщение"
                         InputLabelProps={ {
                           shrink: true
                         } }
                         multiline
                         maxRows="8"
                         value={ message }
                         onChange={ this.handleChange }/>
            </Grid>
            <Grid item
                  xs={ 1 }
                  style={ {
                    padding: '16px 0', display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  } }
                  sm={ 1 }>
              <UploadFilesButton handleChange={ file => this.handleUploadFile(file) }
                                 multiple
                                 handleError={ this.handlerErrorUpload }
                                 component={ props => <AttachFileOutlinedIcon { ...props }
                                                                              style={ {cursor: 'pointer'} }
                                                                              color="primary"/> }/>
            </Grid>
            <Grid item
                  xs={ 12 }
                  sm={ 3 }>
              <ButtonWithProgress fullWidth
                                  type="submit"
                                  loading={ loading }
                                  label="Отправить"
                                  onClick={ this.handleSubmit }/>
            </Grid>
          </Grid>
          { filesToView &&
          <Grid container
                spacing={ 4 }
                style={ {padding: '0 16px 16px 16px'} }>
            <Grid item
                  xs={ 12 }
                  style={ {...flexWrap} }>
              { filesToView.map((file, index) => (
                  <div key={ file.id }>
                    <FileChip label={ file.name }
                              style={{marginBottom: 8}}
                              color={`${ !errors.getError(`attachments.${ index }`) ? 'primary' : 'secondary'}`}
                              onDelete={ () => this.handleDeleteUploadFile(file.id) }/>
                    <Typography color="secondary"
                                style={ {fontSize: 10} }>
                      { errors.getError(`attachments.${ index }`) }
                    </Typography>
                  </div>
              )) }
            </Grid>
          </Grid>
          }

        </>
    );
  }
}

export default connect(null, {openSnackbar})(ChatForm);
