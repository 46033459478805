import React, {memo} from 'react';
import FormColumn from '../FormColumn';
import Title from '../Title';
import TextField from '../TextField';
import FormRow from '../FormRow';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import {flexAlignCenter} from '../JSStylesConstants';

const RealtyResourcesRow = ({errors, handleChangeLink, rent_link, sale_link, resource, index, ...props}) => {

  return (
      <FormRow>
        <FormColumn sm={ 12 }
                    md={ 2 }
                    lg={ 1 }
                    style={ {
                      display: 'flex',
                      alignItems: 'center'
                    } }>
          <Title title={ resource }
                 style={ {
                   margin: 0,
                   padding: 0
                 } }
                 size="xl"/>
        </FormColumn>
        <FormColumn sm={ 11 }
                    md={ 4 }>
          <TextField error={ errors.getError(`resources.${ index }.rent_link`) }
                     name={ `rent_link/${ resource }` }
                     value={ rent_link ?? '' }
                     onChange={ handleChangeLink }
                     label="Аренда"
                     placeholder="Введите URL"
          />
        </FormColumn>

        <FormColumn sm={ 1 }
                    style={ flexAlignCenter }>
          { rent_link &&
          <a href={ rent_link ?? '' }
             rel="noopener noreferrer"
             style={ {textDecoration: 'none', color: 'inherit'} }
             target="_blank">
            <LanguageOutlinedIcon/>
          </a>
          }
        </FormColumn>


        <FormColumn sm={ 11 }
                    md={ 4 }>
          <TextField error={ errors.getError(`resources.${ index }.sale_link`) }
                     name={ `sale_link/${ resource }` }
                     value={ sale_link ?? '' }
                     onChange={ handleChangeLink }
                     label="Продажа"
                     placeholder="Введите URL"
          />
        </FormColumn>
        { sale_link &&
        <FormColumn sm={ 1 }
                    style={ flexAlignCenter }>
          <a href={ sale_link ?? '' }
             rel="noopener noreferrer"
             style={ {textDecoration: 'none', color: 'inherit'} }
             target="_blank">
            <LanguageOutlinedIcon/>
          </a>
        </FormColumn>
        }
      </FormRow>
  );
};

export default memo(RealtyResourcesRow);
