import React from 'react';
import SelectField from '../SelectField';

const roomCounts = [
  {name: '1 комната', value: 1},
  {name: '2 комнаты', value: 2},
  {name: '3 комнаты', value: 3},
  {name: '4 комнаты', value: 4},
  {name: 'Квартира-студия', value: -1},
];

const RealtyRoomCountSelect = ({label, ...props}) => {
  return (
      <SelectField {...props}
                   fullWidth
                   label={label || 'Количество комнат'}
                   name="room_count"
                   options={roomCounts}/>
  );
};

export default RealtyRoomCountSelect;
