import { useDispatch } from 'react-redux';
import axios from 'axios';
import { openSnackbar } from '../actions/snackbarActions';
import { ERROR, ERROR_500_MESSAGE, ERROR_NETWORK, NETWORK_ERROR, RESPONSE_STATUS_500 } from './Constants';

const GlobalErrorHandler = ({ ...props }) => {
  const dispatch = useDispatch();


  const errorHandler = (error) => {
    if ( error.response?.status === RESPONSE_STATUS_500 ) {
      dispatch(openSnackbar(ERROR, ERROR_500_MESSAGE));
    } else if ( !navigator.onLine && error.message === NETWORK_ERROR ) {
      dispatch(openSnackbar(ERROR, ERROR_NETWORK));
    } else {
      // dispatch(openSnackbar(ERROR, ERROR_500_MESSAGE));
    }
  };

  axios.interceptors.request.use(function (config) {

    return config;
  }, function (error) {

    errorHandler(error);

    return Promise.reject(error);
  });

  axios.interceptors.response.use(function (response) {

    return response;
  }, function (error) {

    errorHandler(error);

    return Promise.reject(error);
  });


  return null;
};

export default GlobalErrorHandler;
