import React from 'react';
import './TechnicalSupportChatLinks.scss';
import {Button} from '@material-ui/core';
import {Link} from 'react-router-dom';

const TechnicalSupportChatLinks = ({chat_info, ...props}) => {

  return (
      <div className="chat-links">
        <Button color="primary"
                className="chat-links__button"
                variant="contained">
          <Link to={ `/clients/${ chat_info.client_id }` }
                target="_blank"
                className="chat-links__link"
                rel="noopener noreferrer">
            { chat_info.name }
          </Link>
        </Button>

        { chat_info.bookings.length > 1 &&
        <Button color="primary"
                className="chat-links__button"
                variant="contained">
          <Link to={ `/bookings?client=${ chat_info.name }` }
                target="_blank"
                className="chat-links__link"
                rel="noopener noreferrer">
            Заказы клиента
          </Link>
        </Button>
        }
        {!!chat_info.bookings.length &&
        <>
          <Button color="primary"
                    className="chat-links__button"
                    variant="contained">
              <Link to={ `/bookings/${ chat_info.bookings[0]?.id }` }
                    target="_blank"
                    className="chat-links__link"
                    rel="noopener noreferrer">
                Заказ №{ chat_info.bookings[0]?.id }
              </Link>
            </Button>

            <Button color="primary"
                    className="chat-links__button"
                    variant="contained">
              <Link to={ `/realty/${ chat_info.bookings[0]?.realty_id }` }
                    className="chat-links__link"
                    target="_blank"
                    rel="noopener noreferrer">
                { chat_info.bookings[0]?.realty_name }
              </Link>
            </Button>
          </>
        }

      </div>
  );
};

export default TechnicalSupportChatLinks;
