import React from 'react';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Hot } from './hot.svg';
import { ReactComponent as Cold } from './cold.svg';
import { ReactComponent as Electro } from './electro.svg';
import { ReactComponent as Gas } from './gas.svg';
import { ReactComponent as Rentabelno } from './rentabelno.svg';




export const HryvnaIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-hryvna ${className}`} />;
};

export const RubIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-rub ${className}`} />;
};

export const UserIcon = (props) => {
  return <User {...props}/>;
};

export const ColdIcon = (props) => {
  return <Cold {...props}/>;
};

export const GasIcon = (props) => {
  return <Gas {...props}/>;
};

export const HotIcon = (props) => {
  return <Hot {...props}/>;
};

export const ElectroIcon = (props) => {
  return <Electro {...props}/>;
};

export const LogoIcon = (props) => {
  return <Rentabelno {...props}/>;
};


export const ArchiveIcon = ({className = '', ...props}) => {
  return <i className={`icon-box-filled ${className}`} {...props} />;
};

export const NotificationIcon = ({className = '', ...props}) => {
  return <i className={`icon-notification ${className}`} {...props} />;
};

export const TimeIcon = ({className = '', ...props}) => {
  return <i className={`icon-time ${className}`} {...props} />;
};
export const SmallArrowDownIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-down-arrow ${className}`} />;
};

export const DownloadIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-download ${className}`} />;
};

export const ExclamationIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-exclamation ${className}`} />;
};

export const ExitIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-exit ${className}`} />;
};

export const FileIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-file ${className}`} />;
};

export const FilterIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-filter ${className}`} />;
};

export const EyeHideIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-hide ${className}`} />;
};

export const ArrowLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-left ${className}`} />;
};

export const SmallArrowLeftIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-left-arrow ${className}`} />;
};

export const MenuIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-menu ${className}`} />;
};

export const MinusIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-minus ${className}`} />;
};

export const PlusIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-plus ${className}`} />;
};

export const QuestionIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-question ${className}`} />;
};

export const ArrowRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-right ${className}`} />;
};

export const SmallArrowRightIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-right-arrow ${className}`} />;
};

export const SearchIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-search ${className}`} />;
};

export const SettingsIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-settings ${className}`} />;
};

export const EyeShowIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-show ${className}`} />;
};

export const ArrowUpIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-up ${className}`} />;
};

export const SmallArrowUpIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-up-arrow ${className}`} />;
};

export const UploadIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-upload ${className}`} />;
};

export const AttachIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-attach ${className}`} />;
};

export const BellIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-bell ${className}`} />;
};

export const BulbIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-bulb ${className}`} />;
};

export const CalendarIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-calendar ${className}`} />;
};

export const ApproveIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-check ${className}`} />;
};

export const ClockIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-clock ${className}`} />;
};

export const CloseIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-close ${className}`} />;
};

export const DeleteIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-delete ${className}`} />;
};

export const ArrowDownIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-down ${className}`} />;
};

export const PhoneIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-phone ${className}`} />;
};

export const VkIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-vk ${className}`} />;
};

export const InstaIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-insta ${className}`} />;
};

export const FbIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-fb ${className}`} />;
};

export const StarIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-star ${className}`} />;
};

export const MoonIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-moon ${className}`} />;
};

export const EditIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-edit ${className}`} />;
};

export const CashBoxIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-cashbox ${className}`} />;
};

export const BackInTimeIcon = ({className = '', ...props}) => {
  return <i {...props} className={`icon-back-in-time ${className}`} />;
};
