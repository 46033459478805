import React from 'react';
import './Employment.scss';
import Number from '../Number';
import Title from '../Title';

const Employment = ({percent, days, ...props}) => {
  return (
      <React.Fragment>
        <Title title={'Занятость'}/>
        <div className="employment__wrap">
          <div className="employment__piece">
            <Number size={'sm'}
                    value={days}/>
            <Title title={'По дням'}
                   size={'sm'}/>
          </div>
          <div className="employment__piece">
            <Number value={percent}
                    size={'sm'}
                    green
                    percent/>
            <Title title={'Процент занятости'}
                   size={'sm'}/>
          </div>
        </div>
      </React.Fragment>
  )
};

export default Employment;
