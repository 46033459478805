import React from 'react';
import {Grid, Link, ListItem, Typography} from '@material-ui/core';
import {flex} from '../JSStylesConstants';

const Message = ({message, downloadDocument, ...props}) => {

  if ( !message.is_admin ) {
    return (
        <ListItem divider>
          <Grid container
                spacing={ 2 }>
            <Grid item
                  xs={ 12 }>
              <Typography variant="body2"
                          style={ {fontWeight: 500} }>
                { message?.client?.name }
              </Typography>
              <Typography variant="body2"
                          color="textSecondary">
                { message.message }
              </Typography>
            </Grid>

            {!!message?.attachments.length &&
              <Grid item
                    xs={ 12 }>
                {  message.attachments.map(att => (
                    <Link key={att.id}
                          component="button"
                          style={{textDecoration: 'underline', marginRight: 8}}
                          onClick={att.can_be_downloaded ? () => downloadDocument(att.id, att.name) : null}>
                      { att.name }
                    </Link>
                ))}
              </Grid>
            }


            <Grid item
                  xs={12}>
              <Typography variant="caption">
                { message.created_at }
              </Typography>
            </Grid>

          </Grid>
        </ListItem>
    );
  }

  if ( message.is_admin ) {
    return (
        <ListItem divider
                  style={ {background: '#F2F2F2'} }>
          <Grid container
                spacing={ 2 }
                style={ {
                  justifyContent: 'flex-end',
                  textAlign: 'right'
                } }>
            <Grid item
                  xs={ 12 }>
              <div style={ {
                ...flex,
                justifyContent: message.readed_at ? 'space-between' : 'flex-end'
              } }>
                { message.readed_at &&
                  <Typography variant="caption">
                    Прочтено в { message.readed_at }
                  </Typography>
                }
                <Typography variant="body2"
                            style={ {fontWeight: 500} }>
                  &nbsp;[Вы]
                </Typography>
              </div>

              <Typography variant="body2"
                          color="textSecondary">
                { message.message }
              </Typography>
            </Grid>

            {!!message?.attachments.length &&
              <Grid item
                    xs={ 12 }>
                {  message.attachments.map(att => (
                    <Link key={att.id}
                          style={{textDecoration: 'underline', marginLeft: 8}}
                          component="button"
                          onClick={att.can_be_downloaded ? () => downloadDocument(att.id, att.name) : null}>
                      { att.name }
                    </Link>
                ))
                }
              </Grid>
            }

            <Grid item
                  xs={12}>
              <Typography variant="caption">
                { message.created_at }
              </Typography>
            </Grid>

          </Grid>
        </ListItem>
    );
  }
};


export default Message;
