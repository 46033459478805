import React, { useEffect, useState } from 'react';
import FilterSelectField from '../FilterFields/FilterSelectField';
import { fetchCompanies } from '../../actions/autocompleteActions';

const CompaniesFilterSelect = ({ label, ...props }) => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    fetchCompanies()
      .then((companies) => {
        setOptions(companies);
      });
  }, []);

  return (
    <FilterSelectField options={ options }
                       fullWidth
                       style={ { minWidth: 220 } }
                       label={ label || 'Компания' }
                       type="company_id"/>
  );
};

export default CompaniesFilterSelect;
