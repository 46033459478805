import React from 'react';
import SelectField from '../SelectField';
import {BOOKED, CLEANING, ENGAGED, FREE, PARTIAL_RENT, SOLD} from '../Constants';

const statusOptions = [
  {value: FREE, name: 'Свободно'},
  {value: BOOKED, name: 'Забронировано'},
  {value: ENGAGED, name: 'Занято'},
  {value: CLEANING, name: 'Требуется уборка'},
  {value: PARTIAL_RENT, name: 'Частичная занятость'},
  {value: SOLD, name: 'Продан'},
];

const RealtyStatusField = ({value, handleChange, ...props}) => {

  return (
      <SelectField {...props}
                   onChange={handleChange}
                   options={statusOptions}
                   label="Статус"
                   value={value ?? ''}/>
  )
};

export default RealtyStatusField;
