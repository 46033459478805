import React from 'react';
import FormDrawerWithPayload from '../../components/FormDrawerWithPayload/FormDrawerWithPayload';
import { closePaydeskTransferModal } from '../../actions/formDrawerActions';
import { connect } from 'react-redux';
import PaydeskPaymentTransferForm from './PaydeskPaymentTransferForm';

const PaydeskPaymentTransferModal = ({ isOpen, closePaydeskTransferModal, loadPayments, loadBalances, ...props }) => {

  return (
    <React.Fragment>
      <FormDrawerWithPayload isOpen={ isOpen }
                             closeModal={ closePaydeskTransferModal }>
        <PaydeskPaymentTransferForm loadPayments={ loadPayments }
                                    loadBalances={ loadBalances }
                                    closeModal={ closePaydeskTransferModal }/>
      </FormDrawerWithPayload>
    </React.Fragment>
  );
};

let matStateToProps = (state) => ( {
  isOpen: state.formDrawer.paydeskTransfer.isOpen
} );

let mapDispatchToProps = (dispatch) => ( {
  closePaydeskTransferModal: () => dispatch(closePaydeskTransferModal())
} );


export default connect(matStateToProps, mapDispatchToProps)(PaydeskPaymentTransferModal);
