import React from 'react';
import SelectField from '../SelectField';

const notificationsType = [
  {value: 1, name: 'Новая заявка'},
  {value: 2, name: 'Нужна поддержка клиентов'},
  {value: 3, name: 'Клиент внёс все показания счетчиков корректно'},
  {value: 4, name: 'Клиент внес подозрительные показания'},
  {value: 5, name: 'Ошибка в передаче показаний в УК'},
  {value: 6, name: 'Клиент проживает дольше проплаченого времени!'},
  {value: 7, name: 'У клиента заканчивается сврок действия паспорта!'},
]

const NotificationsTypeSelect = ({value, ...props}) => {
  return (
      <SelectField options={notificationsType}
                   {...props}
                   fullWidth
                   value={value}
                   placehplder="Выберите тип"
                   label="Тип уведомления"/>
  )
};

export default NotificationsTypeSelect;
