import React from 'react';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import {withRouter} from 'react-router-dom';


const ClearFiltersButton = ({handlerClear, ...props}) => {

  let clearParams = () => {
    props.history.push({
      pathname: window.location.pathname,
    });
  };


  return (
      <React.Fragment>
        <HighlightOffOutlinedIcon fontSize="large"
                                  style={{cursor: 'pointer'}}
                                  onClick={handlerClear ? handlerClear : clearParams}/>
      </React.Fragment>
  )
};

export default withRouter(ClearFiltersButton);
