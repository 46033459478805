import React, { PureComponent } from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { createPayment } from '../../actions/paydeskActions';
import FormColumn from '../FormColumn';
import { Button } from '@material-ui/core';
import Errors from '../Errors';
import SelectField from '../SelectField';
import RealtyAutocomplete from '../Autocomplete/RealtyAutocomplete';
import TextField from '../TextField';
import { withRouter } from 'react-router-dom';
import PaydeskPaymentSelect from './PaydeskBalanceSelect';
import { SUCCESS } from '../Constants';


const incomeType = [
  { value: '0', name: 'Расход' },
  { value: '1', name: 'Приход' }
];

const incomeCategory = [
  { value: '5', name: 'Разное' },
  { value: '11', name: 'РД' },
  { value: '15', name: 'Оплата за уборку' },
  { value: '16', name: 'Компенсация ущерба / штрафы' },
  { value: '17', name: 'Ввод от учредителей' },
  { value: '21', name: 'Представитель прнес в кассу' },
];

const outcomeCategory = [
  { value: '3', name: 'Оплата коммуналки' },
  { value: '5', name: 'Разное' },
  { value: '6', name: 'Зарплата' },
  { value: '7', name: 'Погрузка' },
  { value: '8', name: 'Средства для офиса' },
  { value: '9', name: 'Ремонт квартир' },
  { value: '10', name: 'Интерьер' },
  { value: '11', name: 'РД' },
  { value: '12', name: 'Автомобиль' },
  { value: '14', name: 'Возврат депозита' },
  { value: '15', name: 'Уборка' },
  { value: '17', name: 'Вывод от учредителей' },
  { value: '18', name: 'Размещение обьявления(й)' },
  { value: '19', name: 'Интернет, ТБ, почта' },
  { value: '20', name: 'Содержание объекта' },
  { value: '21', name: 'Выдать представителю' },
  { value: '23', name: 'Комиссия банка' },
];

const initialState = {
  payment: {
    company: '',
    balance: '',
    amount: '',
    income: '',
    action: '',
    realty: '',
    comment: ''
  },
  loading: false,
  errors: new Errors()
};

class PaydeskPaymentCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }


  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState(prevState => ( {
      payment: {
        ...prevState.payment,
        [name]: type === 'checkbox' ? checked : value
      }
    } ));
  };


  handleChangeRealty = (e, value) => {
    this.setState(prevState => ( {
      payment: {
        ...prevState.payment,
        realty: value
      }
    } ));
  };


  handleSubmit = () => {
    const { payment } = this.state;

    this.setState({ loading: true });

    const data = {
      company_id: payment.balance.company_id,
      balance_id: payment.balance.id,
      amount: payment.amount,
      income: payment.income,
      action: payment.action,
      realty_id: payment.realty?.id,
      comment: payment.comment
    };

    createPayment(data)
      .then(() => {
        this.props.openSnackbar(SUCCESS, 'Платеж успешно создан');
        this.setState(initialState);
        this.props.loadPayments();
        this.props.loadBalances();
        this.props.location.state = { open: false };
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors)
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { payment, errors } = this.state;

    return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 6 }>
              <PaydeskPaymentSelect handleChange={ this.handleChange }
                                    name="balance"
                                    label="Баланс"
                                    fullWidth
                                    error={ errors.getError('balance_id') }
                                    value={ payment.balance }/>
            </FormColumn>
            <FormColumn sm={ 6 }>
              <SelectField options={ incomeType }
                           error={ errors.getError('income') }
                           style={ { minWidth: 220 } }
                           fullWidth
                           onChange={ this.handleChange }
                           name="income"
                           value={ payment.income }
                           label="Приход или расход"/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }>
              <RealtyAutocomplete value={ payment.realty }
                                  onChange={ this.handleChangeRealty }/>
            </FormColumn>
            <FormColumn sm={ 6 }>
              <SelectField fullWidth
                           name="action"
                           error={ errors.getError('action') }
                           disabled={ !payment.income }
                           label="Категория платежа"
                           value={ payment.action }
                           options={ payment.income > 0 ? incomeCategory : outcomeCategory }
                           onChange={ this.handleChange }/>
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn sm={ 12 }>
              <TextField
                multiline
                rows={ 3 }
                error={ errors.getError('comment') }
                name="comment"
                value={ payment.comment }
                onChange={ this.handleChange }
                label="Коментарий"
                placeholder="Введите коментарий"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField
                error={ errors.getError('amount') }
                name="amount"
                value={ payment.amount }
                onChange={ this.handleChange }
                label="Сумма"
                placeholder="Введите сумму"
              />
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn xs="auto" sm="auto" style={ { marginLeft: 'auto' } }>
              <Button color="primary"
                      disabled={ this.state.loading }
                      variant="contained"
                      onClick={ this.handleSubmit }>
                Добавить
              </Button>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>

    );
  }
}

export default connect(null, { openSnackbar })(withRouter(PaydeskPaymentCreateForm));
