import React from "react";
import {Grid, Typography, ListItem, makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  rightSide: {
    marginLeft: 'auto',
    textAlign: 'right'
  },
  countBadge: {
    borderRadius: '100%',
    background: 'rgb(233, 30, 99)',
    padding: 4,
    fontSize: 10,
    color: theme.palette.background.paper,
    lineHeight: 1,
    width: 'auto',
    display: 'inline-flex',
    minWidth: 18,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
  }
}));

const ChatListItem = ({ chat, ...props }) => {
  const classes = useStyles();

  return (
    <ListItem {...props}
              style={{cursor: 'pointer'}}
              divider>
      <Grid container>
        <Grid item>
          <Typography variant="body2" style={{fontWeight: 500}}>
            {chat?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {chat?.client_name}
          </Typography>
        </Grid>
        <Grid item className={ classes.rightSide }>
          <Typography variant="body2" style={{fontSize: 13}}>
            {chat?.last_created_at}
          </Typography>
          { chat?.unread_count > 0 && (
            <div className={ classes.countBadge }>
              {chat?.unread_count}
            </div>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ChatListItem;
