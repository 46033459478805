import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import theme from '../../theme';
import { Link } from 'react-router-dom';
import { BOOKINGS_ROUTE } from '../RouterConstants';
import { getFormatNumber } from '../Tools';
import { lightOrangeBackground } from '../JSStylesConstants';

const PaydeskPaymentRow = ({ payment, handleOpenModal, ...props }) => {
  const [show, setShow] = useState(false);

  let handleShowLogs = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <TableRow style={ payment.canceled_at
        ? { backgroundColor: 'rgba(255, 157, 58, .25)', cursor: 'pointer' }
        : { backgroundColor: 'inherit', cursor: 'pointer' } }
                onClick={ handleShowLogs }
                hover>
        <TableCell>
          { payment.id }
        </TableCell>
        <TableCell>
          { payment.created_at }
        </TableCell>
        <TableCell>
          { payment?.realty?.name }
        </TableCell>
        <TableCell>{ payment.action }</TableCell>
        <TableCell>{ payment.balance.name }</TableCell>
        <TableCell>{ payment.amount > 0 ? payment.amount : null }</TableCell>
        <TableCell>{ payment.amount < 0 ? payment.amount : null }</TableCell>
        <TableCell style={ { maxWidth: 400 } }>
          { payment.comment.split('\n')[0] }
          <br/>
          { payment.comment.split('\n')[1] }
          <br/>

          { payment.agreement_title &&
            <Link to={ `${ BOOKINGS_ROUTE }?rental_agreement=${ payment.agreement_title }` }
                  style={ { textDecoration: 'none', color: theme.palette.primary.main } }>
              Договор { payment.agreement_title }
            </Link>
          }

        </TableCell>
        <TableCell>
          { !payment.canceled_at
            ? <HighlightOffOutlinedIcon onClick={ () => handleOpenModal(payment) }
                                        style={ {
                                          cursor: 'pointer',
                                          color: theme.palette.secondary.main
                                        } }/>
            : <strong style={ { color: theme.palette.secondary.main } }>
              Отменен
            </strong>
          }
        </TableCell>
      </TableRow>
      { show &&
        <React.Fragment>
          { payment.logs && payment.logs.map(log => (
            <TableRow key={ log.id } style={ { ...lightOrangeBackground } }>
              <TableCell></TableCell>
              <TableCell>{ log.created_at }</TableCell>
              <TableCell>{ log?.manager?.name }</TableCell>
              <TableCell>{ log.action }</TableCell>
              <TableCell></TableCell>
              <TableCell>{ log.amount > 0 ? log.amount : null }</TableCell>
              <TableCell>{ log.amount < 0 ? log.amount : null }</TableCell>
              <TableCell>Баланс до: { getFormatNumber(log.balance_before) }</TableCell>
              <TableCell></TableCell>
            </TableRow>
          )) }
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default PaydeskPaymentRow;
