import React, {PureComponent} from 'react';
import {Grid} from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import RealtyEditTabs from '../../components/Realty/RealtyEditTabs';
import RealtyEditMainForm from '../../components/Realty/RealtyEditMainForm';
import RealtyEditSeoForm from '../../components/Realty/RealtyEditSeoForm';
import RealtyEditTariffsForm from '../../components/Realty/RealtyEditTariffsForm';
import RealtyEditDocumentsForm from '../../components/Realty/RealtyEditDocumentsForm';
import RealtyEditResourcesForm from '../../components/Realty/RealtyEditResourcesForm';
import RealtyManagementCompanyForm from '../../components/Realty/RealtyManagementCompanyForm';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import RealtyInventoryForm from '../../components/Realty/RealtyInventoryForm';
import {setPageTitle} from '../../components/Tools';


class RealtyEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      activeTab: 0,
    };
  }

  componentDidMount() {
    setPageTitle('Редактирование объекта');

    this.setState({
      id: this.getId(),
    });
  }

  getId = () => {
    const {match: {params}} = this.props;

    return params.id || null;
  };

  handleChangeTab = (e, value) => {
    this.setState({
      activeTab: value
    })
  };


  render() {
    const {id, activeTab} = this.state;

    if (!id) {
      return null;
    }

    return (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader title="Редактирование объекта"
                          size="md"/>
            </Grid>
            <Grid item xs={12}>
              <RealtyEditTabs value={activeTab}
                              handleChange={this.handleChangeTab}/>
            </Grid>

            <Grid item xs={12}>
              {activeTab === 0 && <RealtyEditMainForm id={id}/>}
              {activeTab === 1 && <DndProvider backend={HTML5Backend}>
                                    <RealtyEditSeoForm id={id}/>
                                  </DndProvider>}
              {activeTab === 2 && <RealtyEditDocumentsForm id={id}/>}
              {activeTab === 3 && <RealtyEditTariffsForm id={id}/>}
              {activeTab === 4 && <RealtyEditResourcesForm id={id}/>}
              {activeTab === 5 && <RealtyManagementCompanyForm id={id}/>}
              {activeTab === 6 && <RealtyInventoryForm id={id}/>}
            </Grid>

          </Grid>
        </React.Fragment>
    )
  }
}

export default RealtyEditPage;
