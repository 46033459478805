import React, {PureComponent} from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {openSnackbar} from '../../actions/snackbarActions';
import {fetchBookings, getBookingsTotalDeposit} from '../../actions/bookingsActions';
import {Link as RouterLink} from 'react-router-dom';
import BookingFilter from '../../components/Booking/BookingFilter';
import {connect} from 'react-redux';
import PageHeader from '../../components/PageHeader';
import {PlusIcon} from '../../icons';
import AddingButton from '../../components/AddingButton';
import {MODERATOR, NOT_FOUND} from '../../components/Constants';
import {getFormatNumber, setPageTitle} from '../../components/Tools';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import Errors from '../../components/Errors';
import BookingTableRow from '../../components/Booking/BookingTableRow';


class BookingsListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      meta: null,
      bookings: null,
      total_deposit: null,
      errors: new Errors()
    };
  }

  componentDidMount = () => {
    setPageTitle('Заказы');
    this.setDefaultFilter();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadBookings();
    }
  }

  getTotalDeposits = () => {
    getBookingsTotalDeposit()
        .then(response => {
          this.setState(prevState => ({
            ...prevState,
            total_deposit: response.total_deposit
          }));
        })
        .catch(errors => {
          this.setState(prevState => ({
            ...prevState,
            errors: new Errors(errors)
          }));
        });
  };


  loadBookings = () => {
    const {perPage} = this.state;
    const query = queryString.parse(this.props.location.search);


    fetchBookings({...query, limit: perPage})
        .then(response => {
          this.setState({
            bookings: response.data,
            meta: response.meta,
          });
        });
  };

  setDefaultFilter = () => {
    const search = queryString.parse(this.props.location.search);

    if ( Object.keys(search).length > 0 ) {
      this.loadBookings();
    } else {
      this.props.history.push({
        pathname: window.location.pathname,
        search: queryString.stringify({
          ...search,
          sort: 'departure_date',
          direction: 'desc',
          status: 1
        }),
      });
    }

    this.getTotalDeposits();

  };


  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  handleSort = (key) => {
    const {sort, direction, ...query} = queryString.parse(this.props.location.search);

    if ( sort === key && direction === 'desc' ) {
      this.props.history.push({
        pathname: window.location.pathname,
        search: queryString.stringify({
          ...query,
          direction: 'asc',
          sort: key,
          page: 1
        }),
      });
    } else {
      this.props.history.push({
        pathname: window.location.pathname,
        search: queryString.stringify({
          ...query,
          direction: 'desc',
          sort: key,
          page: 1
        }),
      });
    }
  };


  getParams = (key) => {
    const query = queryString.parse(this.props.location.search);

    return query[key] || '';
  };

  render () {
    const {bookings, meta, total_deposit} = this.state;
    const {user} = this.props;


    return (
        <Grid container spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }
                  style={ {alignItems: 'center'} }>
              <Grid item>
                <PageHeader title="Заказы"
                            size="md"/>
              </Grid>
              { (user.userable_type !== MODERATOR) &&
              <Grid item>
                <RouterLink to={ '/bookings/new' }
                            style={ {textDecoration: 'none'} }>
                  <AddingButton tooltip="Создать заказ"
                                icon={ () => <PlusIcon/> }/>
                </RouterLink>
              </Grid>
              }

            </Grid>
          </Grid>

          <Grid item
                xs={ 12 }>
            <BookingFilter/>
          </Grid>


          <Grid item
                xs={ 12 }>
            { bookings
            && <Paper>
              <TableContainer style={ {
                maxWidth: '100%',
                overflow: 'auto'
              } }>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel onClick={ () => this.handleSort('id') }
                                        active={ this.getParams('sort') === 'id' }
                                        direction={ this.getParams('sort') === 'id' ? this.getParams('direction') : 'asc' }>
                          ID
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Статус</TableCell>
                      <TableCell>
                        <TableSortLabel onClick={ () => this.handleSort('client.last_name') }
                                        active={ this.getParams('sort') === 'client.last_name' }
                                        direction={ this.getParams('sort') === 'client.last_name' ? this.getParams('direction') : 'asc' }>
                          Клиент
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        Объект
                      </TableCell>
                      <TableCell>
                        Депозит<br/>
                        { getFormatNumber(total_deposit) }
                      </TableCell>
                      <TableCell>Текущий тариф</TableCell>
                      {/*<TableCell>*/ }
                      {/*  <TableSortLabel onClick={ () => this.handleSort('arrival_date') }*/ }
                      {/*                  active={ this.getParams('sort') === 'arrival_date' }*/ }
                      {/*                  direction={ this.getParams('sort') === 'arrival_date' ? this.getParams('direction') : 'asc' }>*/ }
                      {/*    Дата въезда*/ }
                      {/*  </TableSortLabel>*/ }
                      {/*</TableCell>*/ }
                      <TableCell>
                        <TableSortLabel onClick={ () => this.handleSort('paid_until') }
                                        active={ this.getParams('sort') === 'paid_until' }
                                        direction={ this.getParams('sort') === 'paid_until' ? this.getParams('direction') : 'asc' }>
                          Оплачено до
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel onClick={ () => this.handleSort('departure_date') }
                                        active={ this.getParams('sort') === 'departure_date' }
                                        direction={ this.getParams('sort') === 'departure_date' ? this.getParams('direction') : 'asc' }>
                          Дата выезда
                        </TableSortLabel>
                      </TableCell>
                      <TableCell/>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { bookings.map(booking => <BookingTableRow key={ booking.id } booking={booking}/>) }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            }

            { !bookings?.length && <ErrorLoadingDataMessage title={ NOT_FOUND }/> }
          </Grid>

          { (meta?.last_page && meta?.last_page > 1) &&
          <Grid item xs={ 12 }
                style={ {
                  paddingTop: `24px`,
                  display: `flex`,
                  justifyContent: 'center'
                } }>
            <Pagination current={ meta.current_page }
                        onChange={ this.handlePageChange }
                        perPage={ meta.per_page }
                        total={ meta.total }
            />
          </Grid>
          }

        </Grid>
    );
  }
}

let mapStateToProps = (state) => ({
  user: state.auth.user
});


export default connect(mapStateToProps, {openSnackbar})(BookingsListPage);
