import React, {useRef} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import './RealtyApartmentPhoto.scss';
import {Grid} from '@material-ui/core';
import AddingButton from '../AddingButton';
import {DeleteIcon} from '../../icons';

const ItemTypes = {
  Files: 'files'
};

const RealtyApartmentPhoto = ({id, src, index, handleRemoveImage, handleRemoveUploadImage, upload, main, moveImage, priority, ...props}) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemTypes.Files,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    }
  });

  const [{isDragging}, drag] = useDrag({
    item: {type: ItemTypes.Files, id, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
      <Grid item
            xs={12}
            sm={6}
            md={4}
            lg={2}
            style={{opacity}}
            ref={ref}>
        <div className={`realty-photo ${index === 0 ? 'realty-photo--active' : ''}`}
             {...props}>
          <img src={src}
               alt=""
               className="realty-photo__img"/>

          <div className="realty-photo__remove">
            <AddingButton icon={() => <DeleteIcon/>}
                          onClick={upload ? () => handleRemoveImage(id)
                              : () => handleRemoveUploadImage(id)}/>
          </div>

        </div>
      </Grid>
  )
};

export default RealtyApartmentPhoto;
