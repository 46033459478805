import React from 'react';
import './Title.scss';

const Title = ({title, className = '', size = '', style, ...props}) => {
  return (
      <div style={style}
           className={`title ${className}
                      ${size ? `title--${size}` : ''}
                      `}>
        {title}
      </div>
  )
};

export default Title;
