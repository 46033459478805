import React, {useState} from 'react';
import './RealtyDocumentsRow.scss';
import {TableCell, TableRow} from '@material-ui/core';
import {ApproveIcon, DeleteIcon, DownloadIcon, ExclamationIcon, FileIcon, UploadIcon} from '../../icons';
import AddingButton from '../AddingButton';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import ProgressBar from '../ProgressBar';
import {downloadRealtyDocument, uploadRealtyDocument} from '../../actions/realtyActions';
import fileDownload from 'js-file-download'
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import DialogsRemove from '../DialogsRemove';
import {getFormatTypeDownloadDocument} from '../Tools';
import {ERROR, SUCCESS} from '../Constants';

const RealtyDocumentsRow = ({realty_id, realty_name, type, title, upload, document,
                              handleRemoveDocument, handlerErrorUpload, realty_deleted, ...props
                            }) => {
  const [percentLoading, setPercent] = useState(null)


  let handleDownloadDocument = (docId, docType) => {
    let config = {
      onDownloadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        if (percentCompleted < 100) {
          setPercent(percentCompleted)
        } else {
          setPercent(null)
        }
      }
    };

    downloadRealtyDocument(realty_id, docId, config)
        .then(response => {
          let fileFormat = getFormatTypeDownloadDocument(response.data.type);
          fileDownload(response.data, `${realty_name}-${docType}.${fileFormat}`)
        })
  };

  let handleUploadFile = (file, type) => {

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);


        if (percentCompleted < 100) {
          setPercent(percentCompleted)
        } else {
          setPercent(null)
        }
      }
    };

    const data = new FormData();

    if (type) {
      data.append('type', type.replace('type_', ''));
    }

    data.append('file', file[0].src.file);

    uploadRealtyDocument(realty_id, data, config)
        .then(() => {
          props.openSnackbar(SUCCESS, 'Файл успешно загружен');
          props.loadDocuments();
        }).catch(errors => {
      props.openSnackbar(ERROR, errors.file)
    })
  };

  return (
      <TableRow className="realty-document">

        {upload &&
        document.map(doc => (
                <React.Fragment key={doc.id}>
                  <TableCell>
                    <div className="realty-document__table-cell">
                      <div className="realty-document__icon realty-document__icon--success">
                        <ApproveIcon/>
                      </div>
                      <div className="realty-document__type realty-document__type--success">
                        {title}
                      </div>
                    </div>

                  </TableCell>
                  <TableCell>
                    <div className="realty-document__table-cell">
                      <div className="realty-document__name-icon">
                        <FileIcon/>
                      </div>
                      <div className="realty-document__name">
                        {doc.name}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>{doc.user.name}</TableCell>
                  <TableCell>{doc.created_at}</TableCell>
                  <TableCell>
                    <div className="realty-document__table-cell">
                      <AddingButton icon={() => <DownloadIcon/>}
                                    tooltip={`Скачать ${title}`}
                                    disabled={!doc.can_be_downloaded || (percentLoading > 1 && percentLoading < 100)}
                                    style={{
                                      marginRight: 10,
                                      cursor: !doc.can_be_downloaded ? 'default' : 'pointer'
                                    }}
                                    onClick={() => handleDownloadDocument(doc.id, title)}/>

                      {!realty_deleted &&
                        <DialogsRemove icon={() => <DeleteIcon/>}
                                       remover={handleRemoveDocument}
                                       negative_answer="Нет"
                                       positive_answer="Да"
                                       question="Удалить документ?"
                                       question_text={`Действительно удалить ${title}?`}
                                       tooltip={`Удалить ${title}`}
                                       id={doc.id}/>
                      }

                    </div>
                    {(percentLoading && percentLoading < 100) && <ProgressBar style={{minWidth: '75px',
                                                                                        marginTop: 16}}
                                                                              percent={percentLoading}/>}
                  </TableCell>
                </React.Fragment>
            )
        )
        }

        {!upload &&
        <React.Fragment>
          <TableCell>
            <div className="realty-document__table-cell">
              <div className="realty-document__icon realty-document__icon--error">
                <ExclamationIcon/>
              </div>
              <div className="realty-document__type">
                {title}
              </div>
            </div>
          </TableCell>
          <TableCell>
            <div className="realty-document--empty">
              Нет документа
            </div>
          </TableCell>
          <TableCell/>
          <TableCell/>
          <TableCell>
            <UploadFilesButton handleChange={file => handleUploadFile(file, type)}
                               handleError={handlerErrorUpload}
                               component={props => <AddingButton {...props}
                                                                 tooltip={`Загрузить ${title}`}
                                                                 icon={() => <UploadIcon/>}/>}/>
            {(percentLoading && percentLoading < 100) && <ProgressBar style={{
                                                                minWidth: '75px',
                                                                marginTop: 16
                                                              }}
                                                                  percent={percentLoading}/>}
          </TableCell>
        </React.Fragment>

        }


      </TableRow>
  )
};

export default connect(null, {openSnackbar})(RealtyDocumentsRow);
