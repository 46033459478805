import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import {openSnackbar} from '../../actions/snackbarActions';
import SwitchField from '../SwitchField';
import FormContainer from '../FormContainer';
import {createManagementCompany} from '../../actions/managementCompanyActions';
import {withRouter} from 'react-router-dom';
import ButtonWithProgress from '../ButtonWithProgress';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';


const initialState = {
  management_company: {
    name: '',
    gspm: false,
    account_number: '',
    bank_bic: '',
    inn: '',
    counterparty_name: '',
    url: '',
    kpp: '',
  },
  errors: new Errors(),
  loading: false,
};


class ManagementCompanyCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  };


  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      management_company: {
        ...prevState.management_company,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };


  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({loading: true})

    const {management_company} = this.state;

    createManagementCompany({
      name: management_company.name,
      counterparty_account_number: management_company.account_number,
      counterparty_bank_bic: management_company.bank_bic,
      counterparty_inn: management_company.inn,
      counterparty_name: management_company.counterparty_name,
      gspm: management_company.gspm,
      url: management_company.url,
      counterparty_kpp: management_company.kpp
    }).then(() => {
      this.setState(initialState);
      this.props.loadManagementCompanies();
      this.props.openSnackbar(SUCCESS, 'Управляющая компания добавлена успешно');
      this.props.location.state = {open: false};
    }).catch(errors =>
        this.setState({
          loading: false,
          errors: new Errors(errors)
        })
    );
  };

  render() {
    const {management_company, errors, loading} = this.state;

    return (
        <React.Fragment>
          <FormContainer>
            <FormRow>
              <FormColumn sm={6}>
                <TextField error={errors.getError('name')}
                    name="name"
                    type="text"
                    value={management_company.name}
                    onChange={this.handleChange}
                    label="Имя управ. компании"
                    placeholder="Введите имя"/>
              </FormColumn>
              <FormColumn sm={6}>
                <TextField error={errors.getError('url')}
                    name="url"
                    type="text"
                    value={management_company.url}
                    onChange={this.handleChange}
                    label="URL"
                    placeholder="Введите url"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <TextField error={errors.getError('counterparty_account_number')}
                    name="account_number"
                    type="text"
                    value={management_company.account_number}
                    onChange={this.handleChange}
                    label="Счёт получателя"
                    placeholder="Введите cчёт"/>
              </FormColumn>

              <FormColumn sm={6}>
                <TextField error={errors.getError('counterparty_bank_bic')}
                    name="bank_bic"
                    type="text"
                    value={management_company.bank_bic}
                    onChange={this.handleChange}
                    label="БИК банка получателя"
                    placeholder="Введите БИК"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <TextField error={errors.getError('counterparty_inn')}
                    name="inn"
                    type="text"
                    value={management_company.inn}
                    onChange={this.handleChange}
                    label="ИНН получателя"
                    placeholder="Введите ИНН"/>
              </FormColumn>

              <FormColumn sm={6}>
                <TextField error={errors.getError('counterparty_kpp')}
                    name="kpp"
                    type="text"
                    value={management_company.kpp}
                    onChange={this.handleChange}
                    label="КПП получателя"
                    placeholder="Введите КПП"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <TextField error={errors.getError('counterparty_name')}
                    name="counterparty_name"
                    type="text"
                    value={management_company.counterparty_name}
                    onChange={this.handleChange}
                    label="Получатель платежа"
                    placeholder="Введите получателя"/>
              </FormColumn>
              <FormColumn sm={6}
                          style={flex}>
                <SwitchField error={errors.getError('gspm')}
                    name="gspm"
                    value={management_company.gspm}
                    onChange={this.handleChange}
                    label="Система GSPM"/>
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn xs="auto"
                          sm="auto"
                          style={{marginLeft: 'auto'}}>
                <ButtonWithProgress onClick={this.handleSubmit}
                                    loading={loading}
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(ManagementCompanyCreateForm));
