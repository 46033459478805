import React, {PureComponent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Grid, Paper, Table, Link, TableHead, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FormDrawer from './../../components/FormDrawer';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {fetchClients} from '../../actions/clientsActions';
import ClientCreateForm from '../../components/Client/ClientCreateForm';
import {withRouter} from 'react-router-dom/';
import ClientsFilter from '../../components/Client/ClientsFilter';
import {PlusIcon} from '../../icons';
import AddingButton from '../../components/AddingButton';
import PageHeader from '../../components/PageHeader';
import { formatPhoneNumber, setPageTitle } from '../../components/Tools';

class ClientsListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      meta: null,
      clients: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Клиенты');
    this.loadClients();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadClients();
    }
  }

  loadClients = () => {
    const query = queryString.parse(this.props.location.search);

    fetchClients(query)
        .then(response => {
          this.setState({
            clients: response.data,
            meta: response.meta
          });
        });
  };

  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  render () {
    const {clients, meta} = this.state;

    if ( !clients ) {
      return null;
    }

    return (
        <Grid container spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }
                  style={ {alignItems: 'center'} }>
              <Grid item>
                <PageHeader title="Клиенты"
                            size="md"/>
              </Grid>
              <Grid item>
                <FormDrawer title="Добавление клиента"
                            button={ props => <AddingButton { ...props }
                                                            tooltip="Добавить клиента"
                                                            icon={ () => <PlusIcon/> }/> }>
                  <ClientCreateForm loadClients={ this.loadClients }/>
                </FormDrawer>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={ 12 }>
            <ClientsFilter/>
          </Grid>

          <Grid item xs={ 12 }>
            <Paper>
              <TableContainer style={ {
                maxWidth: '100%',
                overflow: 'auto'
              } }>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>ФИО</TableCell>
                      <TableCell>Телефон</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Заказы</TableCell>
                      <TableCell>Последняя активность</TableCell>
                      <TableCell>Активность</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { clients.map(client => (
                        <TableRow key={ client.id } hover>
                          <TableCell>#{ client.id }</TableCell>
                          <TableCell>
                            <Link
                                style={ {
                                  maxWidth: 300,
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  display: 'block'
                                } }

                                color="inherit"
                                component={ React.forwardRef((props, ref) => (
                                    <RouterLink
                                        { ...props }
                                        ref={ ref }
                                        to={ `/clients/${ client.id }` }
                                    />
                                )) }
                            >
                              <strong>
                                { client.name }&nbsp;
                                { client.is_verified && <DoneIcon style={ {fontSize: 14} }/> }
                              </strong>
                            </Link>
                          </TableCell>
                          <TableCell>{ formatPhoneNumber(client.phone) }</TableCell>
                          <TableCell>{ client.email }</TableCell>
                          <TableCell>
                            <Link
                                color="inherit"
                                component={ React.forwardRef((props, ref) => (
                                    <RouterLink
                                        { ...props }
                                        ref={ ref }
                                        to={ `/bookings?client=${ client.last_name }` }
                                    />
                                )) }
                            >
                              { client.bookings_count } шт.
                            </Link>
                          </TableCell>
                          <TableCell>{ client.last_activity }</TableCell>
                          <TableCell>{ client.active ? 'Вкл' : 'Выкл' }</TableCell>
                        </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          {(meta && (meta.last_page > 1)) &&

            <Grid item xs={ 12 }
                  style={ {
                    paddingTop: `24px`,
                    display: `flex`,
                    justifyContent: 'center'
                  } }>
              <Pagination current={ meta.current_page }
                          onChange={ this.handlePageChange }
                          perPage={ meta.per_page }
                          total={ meta.total }
              />
            </Grid>
          }

        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(ClientsListPage));
