import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {EditIcon} from '../../icons';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import {uuidv4} from '../Tools';
import {REALTY_LIST_ROUTE} from '../RouterConstants';

const ResourcesRow = ({item, ...props}) => {
  return (
      <TableRow hover>
        <TableCell>
          #{ item.realty_id }
        </TableCell>
        <TableCell style={ {borderRight: `1px solid rgba(224, 224, 224, 1)`} }>
          <Link style={ {textDecoration: 'none', color: 'inherit'} }
                to={ `${ REALTY_LIST_ROUTE }/${ item.realty_id }` }>
            { item.realty_name }&nbsp; <EditIcon/>
          </Link>
        </TableCell>

        { item?.external_resources && item.external_resources.map(resource => (
            <TableCell key={ uuidv4() }
                       style={ {borderRight: `1px solid rgba(224, 224, 224, 1)`} }>
              <div style={ {
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center'
              } }>

                { resource?.rent_link
                    ? <a href={ resource.rent_link }
                         style={ {color: 'inherit'} }
                         rel="noopener noreferrer"
                         target="_blank">
                      <VerifiedUserOutlinedIcon fontSize={ 'large' }/>
                    </a>
                    : <Link to={ `${ REALTY_LIST_ROUTE }/${ item.realty_id }` }
                            style={ {color: 'red'} }
                            rel="noopener noreferrer"
                            target="_blank">
                      <IndeterminateCheckBoxOutlinedIcon fontSize={ 'large' }/>
                    </Link>
                }

                { resource?.sale_link
                    ? <a href={ resource.sale_link }
                         rel="noopener noreferrer"
                         style={ {color: 'inherit'} }
                         target="_blank">
                      <VerifiedUserOutlinedIcon fontSize={ 'large' }/>
                    </a>
                    : <Link to={ `${ REALTY_LIST_ROUTE }/${ item.realty_id }` }
                            style={ {color: 'red'} }
                            rel="noopener noreferrer"
                            target="_blank">
                      <IndeterminateCheckBoxOutlinedIcon fontSize={ 'large' }/>
                    </Link>
                }
              </div>
            </TableCell>
        )) }


      </TableRow>
  );
};

export default ResourcesRow;
