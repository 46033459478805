import React, {Fragment, useState} from 'react';
import './UtilitiesInvoice.scss';
import {getBookingStatus, getCurrentCurrency, getFormatNumber, uuidv4} from '../../Tools';
import {ArrowRightIcon, EditIcon} from '../../../icons';
import {Link} from 'react-router-dom';
import {BOOKINGS_ROUTE, REALTY_LIST_ROUTE} from '../../RouterConstants';
import UtilitiesInvoiceEditModal from './UtilitiesInvoiceEditModal';
import UtilityInvoices from './UtilityInvoices';
import {flex} from '../../JSStylesConstants';

const UtilitiesInvoicesGroupRow = ({
                                     loadUtilityInvoices, booking, utilities,
                                     id, status, period, sum, ...props
                                   }) => {
  const [show, setShow] = useState(false);

  let handleOpen = () => {
    setShow(!show);
  };

  return (
      <Fragment>
        <div className="invoice-group"
             onClick={ handleOpen }>
          <div className="invoice-group__inner">
            <div className="invoice-group__part invoice-group__realty">
              <Link style={ {textDecoration: 'none', color: 'inherit'} }
                    target="_blank"
                    to={ `${ REALTY_LIST_ROUTE }/${ booking?.realty?.id }` }>
                <EditIcon/>&nbsp;
                { booking?.realty?.name }
              </Link>
            </div>
            <div className="invoice-group__part invoice-group__utilities-count">
              { status }
            </div>
            <div className="invoice-group__part invoice-group__utility">
              <div className="utilities-meter">
                { utilities && utilities.map(utility => (
                    <img className="utilities-meter__icon"
                         src={ utility.icon }
                         alt={ utility.alias }
                         key={ uuidv4() }/>
                )) }
              </div>
            </div>
            <div className="invoice-group__part invoice-group__period">
              { period }
            </div>
            <div className="invoice-group__part invoice-group__amount">
              { getFormatNumber(sum) } {getCurrentCurrency()}
            </div>
            <div className="invoice-group__part invoice-group__link">
              <Link style={ {textDecoration: 'none', ...flex} }
                    color="inherit"
                    to={ `${ BOOKINGS_ROUTE }/${ booking?.id }` }>
                { getBookingStatus(booking) }
              </Link>
            </div>
            <div className="invoice-group__part invoice-group__arrow">
              <div className={ `arrow-button ${ show ? 'arrow-button--rotate' : '' }` }>
                <ArrowRightIcon/>
              </div>
            </div>
          </div>
        </div>

        { show &&
        <UtilityInvoices booking_id={ booking?.id }
                         period={ period }
                         loadInvoices={ loadUtilityInvoices }/>
        }


        <UtilitiesInvoiceEditModal loadInvoices={ loadUtilityInvoices }/>
      </Fragment>

  );
};

export default UtilitiesInvoicesGroupRow;
