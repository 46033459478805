import * as actionTypes from '../actions/actionTypes';

const initialState = {
  clientMessageCount: {
    count: null
  },
  supportMessageCount: {
    count: null
  },
  newBookingsRequestsCount: {
    count: null
  }
};

const menuDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DRAWER_COUNT:
      return {
        ...state,
        [action.countType] : {
          count: action.count
        }
      };
    default:
      return state;
  }
};

export default menuDrawerReducer;
