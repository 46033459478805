import React from 'react';
import PageHeader from '../PageHeader';
import {Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';

const StatisticCompanyTitle = ({...props}) => {
  const company = useSelector(state => state.statistic.company);
  return (
      <>
        <PageHeader title={ `Статистика по объектам  
                                    [${ company?.name ? company?.name : '' }: ${ company?.realty_count ? company?.realty_count : 0 }]` }
                    className="statistic-page__title"/>
        <Typography style={ {fontWeight: 500} }>
          с { company?.date_from } по { company?.date_to }
        </Typography>
      </>
  );
};

export default StatisticCompanyTitle;
