import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { fetchTochkaBalances, tochkaBalanceUpdate, updateTochkaPayments } from '../../actions/tochkaActions';
import { DownloadIcon } from '../../icons';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import { openSnackbar } from '../../actions/snackbarActions';
import { BALANCE_UPDATE, ERROR, PAYMENTS_SUCCESS, SUCCESS } from '../Constants';
import { getFormatNumber } from '../Tools';


class TochkaBalance extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      lastDateUpdate: '',
      disableBalance: false,
      disablePayments: false,
      tochka: ''
    };

  }

  componentDidMount() {
    // tochkaBalanceUpdate();
    this.loadTochkaBalance();
  }

  loadTochkaBalance = () => {
    fetchTochkaBalances()
      .then(tochka => {
        this.setState({
          tochka: tochka
        });
      });
  };

  handleUpdateTochkaBalance = () => {
    this.setState({ disableBalance: true });

    tochkaBalanceUpdate()
      .then(() => {
        this.setState({ disableBalance: false });
        this.loadTochkaBalance();
        this.props.openSnackbar(SUCCESS, BALANCE_UPDATE);
      })
      .catch(errors => {
        this.props.openSnackbar(ERROR, errors);
      });


  };

  handleUpdateTochkaLog = () => {
    this.setState({ disablePayments: true });

    updateTochkaPayments()
      .then(res => {
        this.setState({ disablePayments: false });
        this.props.loadTochkaPayments();
        this.props.openSnackbar(SUCCESS, PAYMENTS_SUCCESS);
      })
      .catch(errors => {
        this.props.openSnackbar(ERROR, errors);
      });

  };


  render() {
    const { tochka, disabledPayments, disabledBalance } = this.state;

    return (
      <Paper>
        <TableContainer style={ {
          maxWidth: '100%',
          overflow: 'auto'
        } }>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Баланс:</TableCell>
                <TableCell>
                  { getFormatNumber(tochka.balance) }
                </TableCell>
                <TableCell>
                  <Button color="primary"
                          disabled={ disabledBalance }
                          onClick={ this.handleUpdateTochkaBalance }
                          variant="contained">
                    <CachedOutlinedIcon/>&nbsp;
                    Обновить
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Платежи:</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button color="primary"
                          disabled={ disabledPayments }
                          onClick={ this.handleUpdateTochkaLog }
                          variant="contained">
                    <DownloadIcon/>&nbsp;
                    Получить
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
}


export default connect(null, { openSnackbar })(TochkaBalance);
