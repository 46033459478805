import axios from 'axios';

export const fetchBasicUtilities = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/basic_utilities`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};
