import React from 'react';
import MuiPagination from '@material-ui/lab/Pagination';

const Pagination = ({perPage, total, current, onChange, ...props}) => {
  return (
      <MuiPagination
          {...props}
          count={Math.ceil(total / perPage)}
          color="primary"
          showFirstButton
          showLastButton
          page={parseInt(current)}
          siblingCount={2}
          onChange={onChange}
      />
  )
};

export default Pagination;
