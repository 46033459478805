import React, {PureComponent} from 'react';
import Errors from '../Errors';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Title from '../Title';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import {translateErrors} from '../Tools';
import AddingButton from '../AddingButton';
import {PlusIcon} from '../../icons';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import BookingDocumentsRow from './BookingDocumentsRow';
import {fetchBookingDocuments, removeBookingDocument, uploadBookingDocument} from '../../actions/bookingsActions';
import BookingOtherDocumentsRow from './BookingOtherDocumentsRow';
import ProgressBar from '../ProgressBar';
import {ERROR, SUCCESS} from '../Constants';



class BookingDocumentsForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      booking: {
        id: this.props.id,
        name: this.props.rental_agreement,
        otherDocuments: null,
        mainDocuments: null,
      },
      errors: new Errors(),
      percentLoading: null,
    }
  }


  componentDidMount() {
    this.loadDocuments();
  }


  loadDocuments = () => {
    const {id} = this.props;

    fetchBookingDocuments(id)
        .then(documents => {
          this.transformDocuments(documents)
        })

  };


  transformDocuments = (documents) => {
    let otherDocuments = documents.other;
    delete documents.other;

    let mainDocuments = [];

    for (const i in documents) {
      mainDocuments.push(...documents[i])
    }

    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        otherDocuments: otherDocuments,
        mainDocuments: mainDocuments
      }
    }));

  };

  handlerErrorUpload = (errors) => {
    this.props.openSnackbar(ERROR, translateErrors(errors));
  };


  handleRemoveDocument = (_, docId) => {
    const {id} = this.props;

    removeBookingDocument(id, docId)
        .then(() => {
          this.loadDocuments();
          this.props.openSnackbar(SUCCESS, 'Файл успешно удален');
        })
  };

  handleUploadFile = (file, type) => {
    const {id} = this.props;
    const data = new FormData();

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);


        if (percentCompleted < 100) {
          this.setState({percentLoading: percentCompleted})
        } else {
          this.setState({percentLoading: null})
        }
      }
    };

    if (type) {
      data.append('type', type.replace('type_', ''));
    }

    data.append('file', file[0].src.file);

    uploadBookingDocument(id, data, config)
        .then(() => {
          this.props.openSnackbar(SUCCESS, 'Файл успешно загружен');
          this.loadDocuments();
        }).catch(errors => {
      this.props.openSnackbar(ERROR, errors.file || errors)
    })
  };


  render() {
    const {booking, percentLoading} = this.state;
    const {status} = this.props;
    let documents;


    //status close
    if (status === 2) {
      documents = [
        {type: 'type_1', title: 'Договор'},
        {type: 'type_2', title: 'Акт сдачи помещения'},
        {type: 'type_3', title: 'Акт возврата помещения'},
        {type: 'type_4', title: 'Шаблон договора'},
      ];
    } else {
      documents = [
        {type: 'type_1', title: 'Договор'},
        {type: 'type_2', title: 'Акт сдачи помещения'},
        {type: 'type_4', title: 'Шаблон договора'},
      ];
    }


    return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Title title="Основные"
                   size="xl"/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper>
                  <TableContainer style={{
                    maxWidth: '100%',
                    overflow: 'auto'
                  }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Тип</TableCell>
                          <TableCell>Файл</TableCell>
                          <TableCell>Кем загружен</TableCell>
                          <TableCell>Дата загрузки</TableCell>
                          <TableCell>Действия</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {documents.map(document => <BookingDocumentsRow key={document.type}
                                                                        booking_id={booking.id}
                                                                        booking_name={booking.name}
                                                                        loadDocuments={this.loadDocuments}
                                                                        handlerErrorUpload={this.handlerErrorUpload}
                                                                        handleRemoveDocument={this.handleRemoveDocument}
                                                                        document={booking.mainDocuments?.filter(x => x.type === document.type)}
                                                                        upload={booking.mainDocuments?.map(x => x.type).includes(document.type)}
                                                                        {...document}/>)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Grid>


          <Grid item
                xs={12}
                sm={4}
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
            <Title title="Дополнительные документы"
                   style={{
                     marginBottom: 0,
                     marginRight: 16
                   }}
                   size="xl"/>
            <UploadFilesButton handleChange={file => this.handleUploadFile(file)}
                               handleError={this.handlerErrorUpload}
                               component={props => <AddingButton {...props}
                                                                 tooltip="Загрузить дополнительные документы"
                                                                 icon={() => <PlusIcon/>}/>}/>
            {(percentLoading && percentLoading < 100) && <ProgressBar percent={percentLoading}
                                                                      style={{
                                                                        minWidth: '75px',
                                                                        marginLeft: 16
                                                                      }}/>}
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <TableContainer style={{
                maxWidth: '100%',
                overflow: 'auto'
              }}>
                <Table>
                  <TableBody>
                    {booking.otherDocuments && booking.otherDocuments.map(document => (
                        <BookingOtherDocumentsRow key={document.id}
                                                  booking_name={booking.name}
                                                  booking_id={booking.id}
                                                  handleRemoveDocument={this.handleRemoveDocument}
                                                  document={document}/>

                    ))}

                    {!booking.otherDocuments &&
                    <React.Fragment>
                      <TableRow>
                        <TableCell>
                          <div style={{fontSize: 15, color: '#7D9AA5', textAlign: 'center'}}>
                            Нет документов
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
    )
  }

}

export default connect(null, {openSnackbar})(BookingDocumentsForm);
