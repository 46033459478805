import React from 'react';
import './StatisticCompanyOutcome.scss';
import Title from '../Title';
import Number from '../Number';

const StatisticCompanyOutcome = ({realty_outcome, staff_outcome, ...props}) => {
  return (
      <div className="statistic-outcome">
        <div className="statistic-outcome__inner">
          <div className="statistic-outcome__part">
            <Title title="Затраты на объекты"/>
            <Number size={'sm'}
                    icon
                    orange
                    value={realty_outcome}/>
          </div>
          <div className="statistic-outcome__part">
            <Title title="Затраты на персонал"/>
            <Number size={'sm'}
                    green
                    icon
                    value={staff_outcome}/>
          </div>
        </div>
      </div>
  );
};

export default StatisticCompanyOutcome;
