import React from 'react';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import FormContainer from '../FormContainer';
import TextField from '../TextField';
import UndergroundStationAutocomplete from '../Autocomplete/UndergroundStationAutocomplete';
import LocationsAutocomplete from '../Autocomplete/LocationsAutocomplete';
import {LAND} from '../Constants';

const RealtyAddressFields = ({
                               location, address,
                               underground_station, underground_station_distance, floor, handleLocationsChange,
                               handleStationChange, handleChange, errors, section, room, realty_type, ...props
                             }) => {
  return (
      <FormContainer>
        <FormRow>
          <FormColumn sm={3}
                      md={2}>
            <LocationsAutocomplete error={errors.getError('location_id')}
                                   label="Город*"
                                   value={location?.id}
                                   onChange={handleLocationsChange}/>

          </FormColumn>
        </FormRow>
        <FormRow>
          <FormColumn sm={6}
                      md={4}>
            <TextField error={errors.getError('address')}
                       name="address"
                       value={address}
                       onChange={handleChange}
                       label="Адрес*"
                       placeholder="Введите адрес"
            />
          </FormColumn>
        </FormRow>


        {(realty_type !== LAND) &&
          <>
            <FormRow>
              <FormColumn sm={3}
                          md={2}>
                <UndergroundStationAutocomplete errors={errors.getError('underground_station')}
                                                disabled={!location}
                                                location={location}
                                                value={underground_station}
                                                onChange={handleStationChange}/>
              </FormColumn>
              <FormColumn sm={3}
                          md={2}>
                <TextField disabled={!underground_station}
                           error={errors.getError('underground_station_distance')}
                           name="underground_station_distance"
                           value={underground_station_distance}
                           onChange={handleChange}
                           label="Время до метро"
                           placeholder="Количество минут"
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn sm={3}
                          md={2}>
                <TextField
                    error={errors.getError('floor')}
                    name="floor"
                    value={floor}
                    onChange={handleChange}
                    label="Этаж"
                    placeholder="Введите этаж"
                />
              </FormColumn>
            </FormRow>
          </>
        }

      </FormContainer>
  );
};

export default RealtyAddressFields;
