import React from 'react';
import {Grid} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import ClearFiltersButton from '../../ClearFiltersButton';
import SearchField from '../../SearchField';
import moment from 'moment';
import PickPeriodField from '../../PickPeriodField';
import {flexAlignCenter} from '../../JSStylesConstants';
import RealtyAutocomplete from '../../Autocomplete/RealtyAutocomplete';


const UtilitiesInvoicesFilter = ({...props}) => {
  const query = queryString.parse(props.location.search);

  let handleChangeRealty = (e, value) => {

    let newQuery = {
      ...query,
      page: 1,
      realty: value?.id
    };

    if (!value) delete newQuery.realty;

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    })

  };

  // let handleChangeSelect = (e) => {
  //   const {name, value} = e.target;
  //
  //   const newQuery = {
  //     ...query,
  //     page: 1,
  //     [name]: value
  //   };
  //
  //   props.history.push({
  //     pathname: window.location.pathname,
  //     search: queryString.stringify(newQuery)
  //   })
  // };

  let handleChangeDate = (key, value) => {
    const query = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...query,
        page: 1,
        [key]: moment(value, 'MM.YYYY').format('MM.YYYY')
      })
    })
  };

  let getParam = (key) => {
    const query = queryString.parse(props.location.search);


    return query[key] || ''
  };


  return (
      <Grid container
            spacing={2}
            style={{alignItems: 'center'}}>
        {/*<Grid item*/}
        {/*      xs={12}*/}
        {/*      sm={4}*/}
        {/*      md={3}*/}
        {/*      lg={2}>*/}
        {/*  <ManagementCompaniesSelect onChange={handleChangeSelect}*/}
        {/*                             name="management_company_id"*/}
        {/*                             value={getParam('management_company_id')}/>*/}
        {/*</Grid>*/}
        <Grid item
              xs={12}
              sm={4}
              md={3}
              lg={2}>
          <SearchField fulWidth
                       labelWidth={50}
                       searchField="client"
                       label="Клиент"
                       placeholder="Имя / телефон / email"/>
        </Grid>
        {/*<Grid item*/}
        {/*      xs={12}*/}
        {/*      sm={4}*/}
        {/*      md={3}*/}
        {/*      lg={2}>*/}
        {/*  <BasicUtilitiesSelect onChange={handleChangeSelect}*/}
        {/*                        name="basic_utility_id"*/}
        {/*                        value={getParam('basic_utility_id')}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item
              xs={12}
              sm={4}
              md={3}
              lg={2}>
          <SearchField fulWidth
                       labelWidth={40}
                       searchField="booking"
                       label="Заказ"
                       placeholder="Введите имя заказа"/>
        </Grid>
        <Grid item
              xs={12}
              sm={4}
              md={3}
              lg={2}>
          <PickPeriodField views={['year', 'month']}
                           disableToolbar={false}
                           openTo={'month'}
                           name="period"
                           type="period"
                           value={getParam('period')}
                           // defaultDateFrom={moment().startOf('month')}
                           onChange={handleChangeDate}
                           label="Период"/>
        </Grid>
        <Grid item
              xs={12}
              sm={4}
              md={3}
              lg={2}>
          <RealtyAutocomplete value={getParam('realty')}
                              onChange={handleChangeRealty}/>
        </Grid>
        <Grid item
              xs={12}
              sm={2}
              style={flexAlignCenter}>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  )
};

export default withRouter(UtilitiesInvoicesFilter);
