import axios from 'axios';
import queryString from 'query-string';

export const fetchManagementCompanies = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/utilities/management_companies?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchManagementCompany = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/utilities/management_companies/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchManagementCompanyTariffs = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/utilities/management_companies/${id}/tariffs`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createManagementCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/utilities/management_companies`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateManagementCompany = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/utilities/management_companies/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createManagementCompanyTariff = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/utilities/management_companies/${id}/tariffs`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const readManagementCompanyDebt = (id) => {
  return new Promise((resolve, reject) => {
    axios.post(`/utilities/management_companies/${id}/read`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const sendManagementCompanyDebt = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/utilities/management_companies/${id}/send`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};
