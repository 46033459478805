import React from 'react';
import {Switch, Typography, FormControlLabel, FormHelperText, FormControl} from '@material-ui/core';

const SwitchField = ({...props}) => {
  return (
      <FormControl error={!!props.error}>
        <FormControlLabel style={props.style}
                          labelPlacement={props.labelPlacement}
                          control={
                            <Switch color="primary"
                                    name={props.name}
                                    value={props.value}
                                    disabled={props.disabled}
                                    checked={Boolean(props.value)}
                                    onChange={props.onChange}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              {props.label}
                            </Typography>
                          }
        />
        {!!props.error && (
            <FormHelperText>{props.error || ''}</FormHelperText>
        )}
      </FormControl>
  );
};

export default SwitchField;
