import React, {PureComponent} from 'react';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {ColdIcon, ElectroIcon, GasIcon, HotIcon} from '../../icons';
import UtilitiesMetersDataRow from '../../components/Utilities/MetersData/UtilitiesMetersDataRow';
import UtilitiesMeterDataModal from '../../components/Utilities/MetersData/UtilitiesMeterDataModal';
import queryString from 'query-string';
import {fetchUtilitiesMeterData} from '../../actions/utilitiesActions';
import PageHeader from '../../components/PageHeader';
import {getCurrentAppVersion, setPageTitle} from '../../components/Tools';
import {VER_UA} from '../../components/Constants';


class UtilitiesMetersDataListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 10,
      total: 0,
      current: 0,
      meters_data: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Коммунальные показания');
    this.loadMetersData();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadMetersData();
    }
  }

  loadMetersData = () => {
    const {perPage} = this.state;
    const query = queryString.parse(this.props.location.search);

    fetchUtilitiesMeterData({...query, limit: perPage})
        .then(response => {
          this.setState({
            meters_data: response,
          })
        });
  };


  render() {
    const {meters_data} = this.state;

    if (!meters_data) {
      return null;
    }

    return (
        <React.Fragment>
          <Grid container
                spacing={2}>

            <Grid item
                  xs={12}>
              <PageHeader title="Коммунальные показания"
                          size="md"/>
            </Grid>

            <Grid item
                  xs={12}>
              <Paper>
                <TableContainer style={{
                  maxWidth: '100%',
                  overflow: 'auto'
                }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Объект</TableCell>
                        <TableCell>Лог</TableCell>
                        <TableCell><HotIcon/></TableCell>
                        <TableCell><ColdIcon/></TableCell>
                        <TableCell><ElectroIcon/></TableCell>
                        {(getCurrentAppVersion() === VER_UA) &&
                          <TableCell><GasIcon/></TableCell>
                        }
                        <TableCell>Внести</TableCell>
                        <TableCell>Отправить показания в УК</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {meters_data.map(meter => (
                          <UtilitiesMetersDataRow key={meter.realty_id}
                                                  loadMetersData={this.loadMetersData}
                                                  meter={meter}/>

                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>

          <UtilitiesMeterDataModal loadMetersData={this.loadMetersData}/>
        </React.Fragment>
    )
  }
}

export default UtilitiesMetersDataListPage;
