import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import FormRow from '../../FormRow';
import FormColumn from '../../FormColumn';
import Errors from '../../Errors';
import {openSnackbar} from '../../../actions/snackbarActions';
import {withStyles, Button} from '@material-ui/core';
import FormContainer from '../../FormContainer';
import {createUtilitiesMeterData} from '../../../actions/utilitiesActions';
import TextField from '../../TextField';
import {transformErrors} from '../../Tools';
import {flexAlignCenter} from '../../JSStylesConstants';
import {ERROR, SUCCESS} from '../../Constants';

const styles = theme => ({
  title: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 24,
  },
});


class UtilitiesMetersDataCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      meters_data: [],
      new_utilities_meters_data: [],
      errors: new Errors(),
    };
  };

  componentDidMount() {
    this.setState({
      meters_data: this.transformMetersDataUtilities(this.props.meters_data.utilities)})
  }

  transformMetersDataUtilities = (utilities) => {
    const meters = [];

    for (let x in utilities) {
      for (let p = 0; p < utilities[x].length; p++) {
        meters.push({
          ...utilities[x][p],
          name: x === 'coldWater' ? 'Холодная вода' : x === 'hotWater' ? 'Горячая вода' : 'Электричество'
        })
      }
    }

    return meters.sort((a,b) => a.meter_id - b.meter_id)
  }


  handleMeterChange = (event) => {
    const {value, id} = event.target;

    let newMeterData = {
      id: +id,
      meter_value: value
    };


    this.setState(prevState => ({
      ...prevState,
      new_utilities_meters_data: value.length > 0
          ? [...prevState.new_utilities_meters_data.filter(x => x.id !== +id), newMeterData]
          : prevState.new_utilities_meters_data.filter(x => x.id !== +id)
    }));
  };


  handleSubmit = (event) => {
    event.preventDefault();

    const {new_utilities_meters_data} = this.state;
    const {meters_data} = this.props;

    if (!new_utilities_meters_data.length) {
      this.props.openSnackbar(ERROR, 'Необходимо внести показания счетчиков');
      return
    }


    createUtilitiesMeterData({
      realty_id: meters_data.realty_id,
      realty_utilities: new_utilities_meters_data.sort((a,b) => a.id - b.id)
    }).then(() => {
      this.setState({errors: new Errors({})});
      this.props.loadMetersData();
      this.props.openSnackbar(SUCCESS, 'Показания добавлены успешно');
      this.props.closeModal();
    }).catch(errors => {
        this.setState({
          errors: new Errors(transformErrors(errors))
        })
      }
    );
  };

  render() {
    const {meters_data, errors, new_utilities_meters_data} = this.state;
    const {classes} = this.props;

    return (
        <React.Fragment>
          <div className={classes.title}>
            Внести новые показатели {meters_data.realty_name}
          </div>
          <FormContainer>
            <FormRow>
              <FormColumn sm={6}>
                Посл. показатель
              </FormColumn>
              <FormColumn sm={6}>
                Новый
              </FormColumn>
            </FormRow>

            {meters_data?.filter(m => !m.archived_at).map((data, index) => (
                <FormRow key={index}>
                  <FormColumn sm={6}
                              style={flexAlignCenter}>
                    <div>{data.name} {data.value}</div>
                  </FormColumn>
                  <FormColumn sm={6}>
                    <TextField error={errors.getError(`${data.meter_name}`)}
                               fullWidth
                               type="number"
                               id={`${data.meter_id}`}
                               value={new_utilities_meters_data.filter(x => x.id === data.meter_id).meter_value}
                               onChange={this.handleMeterChange}
                               label={`Счетчик ${data.meter_name}`}
                               placeholder="Введите показатели"
                    />
                  </FormColumn>
                </FormRow>

            ))}

            <FormRow>
              <FormColumn xs="auto" sm="auto" style={{marginLeft: 'auto'}}>
                <Button color="primary"
                        variant="contained"
                        onClick={this.handleSubmit}>
                  Добавить
                </Button>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>
    );
  }
}

let mapStateToProps = (state) => ({
  meters_data: state.formDrawer.metersData.payload
});

export default connect(mapStateToProps, {openSnackbar})(withStyles(styles)(UtilitiesMetersDataCreateForm));
