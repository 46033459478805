import axios from 'axios';
import queryString from 'query-string';

export const fetchNotifications = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/notifications?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};
