import React, {PureComponent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import {fetchManagers} from '../../actions/managersActions';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import ManagerCreateForm from '../../components/Manager/ManagerCreateForm';
import ManagerRemove from '../../components/Manager/ManagerRemove';
import {PlusIcon} from '../../icons';
import AddingButton from '../../components/AddingButton';
import PageHeader from '../../components/PageHeader';
import {setPageTitle} from '../../components/Tools';

class ManagersListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 10,
      total: 0,
      current: 0,
      managers: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Менеджеры');
    this.loadManagers();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadManagers();
    }
  }

  loadManagers = () => {
    const {perPage} = this.state;
    const query = queryString.parse(this.props.location.search);

    fetchManagers({...query, limit: perPage})
        .then(response => {
          this.setState({
            managers: response.data,
            total: response.meta.total || 0,
            current: response.meta.current_page
          })
        });
  };


  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  render() {
    const {managers, total, perPage, current} = this.state;

    if (!managers) {
      return null;
    }

    return (
        <Grid container spacing={2}>
          <Grid item
                xs={12}>
            <Grid container
                  spacing={2}
                  style={{alignItems: 'center'}}>
              <Grid item>
                <PageHeader title="Менеджеры"
                            size="md"/>
              </Grid>
              <Grid item>
                <FormDrawer title="Добавление менеджера"
                            button={props => <AddingButton {...props}
                                                           tooltip="Добавить менеджера"
                                                           icon={() => <PlusIcon/>}/>}>
                  <ManagerCreateForm loadManagers={this.loadManagers}/>
                </FormDrawer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <TableContainer style={{
                maxWidth: '100%',
                overflow: 'auto'
              }}>
                <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ФИО</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Главный менеджер</TableCell>
                    <TableCell>Активность</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {managers.map(manager => (
                      <TableRow key={manager.id} hover>
                        <TableCell>
                          {manager.update_url
                              ? <Link
                                  color="inherit"
                                  component={React.forwardRef((props, ref) => (
                                      <RouterLink {...props}
                                                  ref={ref}
                                                  to={`/managers/${manager.manager_id}`}
                                      />
                                  ))}>
                                <b>{manager.name}</b>
                              </Link>
                              : <b>{manager.name}</b>

                          }

                        </TableCell>
                        <TableCell>{manager.email}</TableCell>
                        <TableCell>{manager.chief ? 'Вкл' : 'Выкл'}</TableCell>
                        <TableCell>{manager.active ? 'Вкл' : 'Выкл'}</TableCell>
                        <TableCell padding="none" style={{width: 75}}>
                          {manager.delete_url
                              ? <ManagerRemove id={manager.manager_id}
                                               loadManagers={this.loadManagers}/>
                              : null
                          }

                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}
                style={{
                  paddingTop: `24px`,
                  display: `flex`,
                  justifyContent: 'center'
                }}>
            <Pagination
                current={current}
                onChange={this.handlePageChange}
                perPage={perPage}
                total={total}
            />
          </Grid>
        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(ManagersListPage);
