import React from 'react';
import {Chip} from '@material-ui/core';

const FileChip = ({label, color = 'primary', style, ...props}) => {
  return (
      <Chip label={label}
            clickable
            style={{...style, marginRight:8}}
            {...props}
            color={color}
            variant="outlined"
      />
  );
};

export default FileChip;
