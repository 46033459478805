import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Button} from '@material-ui/core';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import {openSnackbar} from '../../actions/snackbarActions';
import {createModerator} from '../../actions/moderatorsActions';
import SwitchField from '../SwitchField';
import FormContainer from '../FormContainer';
import RolesSelect from '../Moderator/RolesSelect';
import PasswordField from '../PasswordField';
import {withRouter} from 'react-router-dom';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';


const initialState = {
  moderator: {
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    roleId: 1,
    active: false,
  },
  errors: new Errors(),
  showPassword: false,
  showPasswordConfirm: false,
};


class ModeratorCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  };


  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      moderator: {
        ...prevState.moderator,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handlePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handlePasswordConfirm = () => {
    this.setState({
      showPasswordConfirm: !this.state.showPasswordConfirm
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {moderator} = this.state;

    createModerator({
      name: moderator.name,
      email: moderator.email,
      password: moderator.password,
      password_confirmation: moderator.passwordConfirmation,
      role_id: moderator.roleId,
      active: moderator.active,
    }).then(() => {
      this.setState(initialState);
      this.props.loadModerators();
      this.props.openSnackbar(SUCCESS, 'Модератор добавлен успешно');
      this.props.location.state = {open: false};
    }).catch(errors =>
        this.setState({
          errors: new Errors(errors)
        })
    );
  };

  render() {
    const {moderator, errors, showPassword, showPasswordConfirm} = this.state;

    return (
        <React.Fragment>
          <FormContainer>
            <FormRow>
              <FormColumn sm={6}>
                <TextField
                    error={errors.getError('name')}
                    fullWidth
                    name="name"
                    value={moderator.name}
                    onChange={this.handleChange}
                    label="Имя"
                    placeholder="Введите имя"
                />
              </FormColumn>
              <FormColumn sm={6}>
                <TextField
                    error={errors.getError('email')}
                    fullWidth
                    name="email"
                    value={moderator.email}
                    onChange={this.handleChange}
                    label="Электронная почта"
                    placeholder="Введите электронную почту"
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn sm={6}>
                <PasswordField
                    error={errors.getError('password')}
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={moderator.password}
                    onChange={this.handleChange}
                    label="Пароль"
                    placeholder="Введите пароль"
                    handlePassword={this.handlePassword}
                    showPassword={showPassword}
                />
              </FormColumn>
              <FormColumn sm={6}>
                <PasswordField
                    error={errors.getError('password')}
                    name="passwordConfirmation"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    value={moderator.passwordConfirmation}
                    onChange={this.handleChange}
                    label="Повторите пароль"
                    placeholder="Введите пароль"
                    handlePassword={this.handlePasswordConfirm}
                    showPassword={showPasswordConfirm}
                />

              </FormColumn>

            </FormRow>
            <FormRow>
              <FormColumn sm={6}>
                <RolesSelect value={moderator.roleId}
                             name="roleId"
                             error={errors.getError('role_id')}
                             onChange={this.handleChange}/>
              </FormColumn>

              <FormColumn
                  sm={6}
                  style={flex}
              >
                <SwitchField
                    error={errors.getError('active')}
                    name="active"
                    value={moderator.active}
                    onChange={this.handleChange}
                    label="Активность"
                />
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn xs="auto" sm="auto" style={{marginLeft: 'auto'}}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.handleSubmit}
                >
                  Добавить
                </Button>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(ModeratorCreateForm));
