import React from 'react';
import FilterSelectField from '../FilterFields/FilterSelectField';
import {MEETING_STATUSES} from '../Constants';

const MeetingStatusFilterSelectField = ({label, ...props}) => {
  return (
      <FilterSelectField options={ MEETING_STATUSES }
                         fullWidth
                         style={ {minWidth: 220} }
                         label="Статус встречи"
                         type="status"/>
  );
};

export default MeetingStatusFilterSelectField;
