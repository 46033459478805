import React, { PureComponent } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import { withRouter } from 'react-router-dom';
import { fetchBalances, fetchPayments } from '../../actions/paydeskActions';
import PageHeader from '../../components/PageHeader';
import PaydeskFilter from '../../components/Paydesk/PaydeskFilter';
import PaydeskBalance from '../../components/Paydesk/PaydeskBalance';
import { openPaydeskCancelModal, openPaydeskTransferModal } from '../../actions/formDrawerActions';
import PaydeskPaymentCancelModal from '../../components/Paydesk/PaydeskPaymentCancelModal';
import PaydeskPaymentCreateForm from '../../components/Paydesk/PaydeskPaymentCreateForm';
import PaydeskPaymentRow from '../../components/Paydesk/PaydeskPaymentRow';
import PaydeskPaymentTransferModal from '../../components/Paydesk/PaydeskPaymentTransferModal';
import AddingButton from '../../components/AddingButton';
import { PlusIcon } from '../../icons';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import moment from 'moment-timezone';
import { flex, lightOrangeBackground } from '../../components/JSStylesConstants';
import { getFormatNumber, setPageTitle } from '../../components/Tools';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import { NOT_FOUND } from '../../components/Constants';


class PaydeskPaymentsListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 25,
      meta: null,
      payments: [],
      balances: [],
      total: ''
    };
  }

  componentDidMount = () => {
    setPageTitle('Касса');
    this.setDefaultFilter();
    this.loadBalances();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadPayments();
    }
  }

  loadBalances = () => {
    fetchBalances()
      .then((balances) => {
        this.setState({
          balances: balances
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  loadPayments = () => {
    const { perPage } = this.state;
    const query = queryString.parse(this.props.location.search);

    fetchPayments({ ...query, limit: perPage })
      .then(response => {
        this.setState({
          payments: response.data,
          meta: response.meta,
          total: response.total,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  setDefaultFilter = () => {
    const query = queryString.parse(this.props.location.search);

    if ( query.realty_id ) {
      this.loadPayments();

    } else {
      this.props.history.push({
        pathname: window.location.pathname,
        search: queryString.stringify({
          ...query,
          'period[from]': moment().subtract(7, 'days').startOf('day').unix(),
          'period[to]': moment().endOf('day').unix()
        }),
      });
    }
  };


  handleOpenModal = (payment) => {
    this.props.openPaydeskCancelModal(payment);
  };

  handleOpenTransferModal = () => {
    this.props.openPaydeskTransferModal();
  };

  render() {
    const { payments, meta, perPage, balances, total } = this.state;

    if ( !payments ) {
      return null;
    }

    return (
      <Grid container
            spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <Grid container
                spacing={ 2 }
                alignItems="center">
            <Grid item>
              <PageHeader title="Касса"/>
            </Grid>
            <Grid item
                  style={ flex }>
              <FormDrawer title="Добавление платежа"
                          button={ props => <AddingButton { ...props }
                                                          tooltip="Добавление платежа"
                                                          icon={ () => <PlusIcon/> }
                                                          style={ { marginRight: 16 } }/> }>
                <PaydeskPaymentCreateForm loadPayments={ this.loadPayments }
                                          loadBalances={ this.loadBalances }/>
              </FormDrawer>


              <AddingButton onClick={ this.handleOpenTransferModal }
                            tooltip="Перенос средств"
                            icon={ () => <SwapHorizOutlinedIcon/> }/>
            </Grid>

            <Grid item
                  sm={ 9 }
                  md={ 6 }
                  lg={ 3 }
                  style={ { marginLeft: 'auto' } }>
              <PaydeskBalance balances={ balances }/>
            </Grid>

          </Grid>

        </Grid>


        <Grid item xs={ 12 }>
          <PaydeskFilter balances={ balances }/>
        </Grid>

        <Grid item xs={ 12 }>
          <TableContainer component={ Paper }
                          style={ {
                            maxWidth: '100%',
                            overflow: 'auto'
                          } }>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Дата</TableCell>
                  <TableCell>Объект</TableCell>
                  <TableCell>Категория платежа</TableCell>
                  <TableCell>Баланс</TableCell>
                  <TableCell>Приход</TableCell>
                  <TableCell>Расход</TableCell>
                  <TableCell>Комментарий</TableCell>
                  <TableCell/>
                </TableRow>
              </TableHead>
              <TableBody>
                { ( total && ( total.income || total.expenses ) ) &&
                  <TableRow style={ { ...lightOrangeBackground } }>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                    <TableCell>{ total?.income && getFormatNumber(total?.income) }</TableCell>
                    <TableCell>{ total?.expenses && getFormatNumber(total?.expenses) }</TableCell>
                    <TableCell/>
                    <TableCell/>
                  </TableRow>
                }

                { payments.map(payment => (
                  <PaydeskPaymentRow payment={ payment }
                                     handleOpenModal={ this.handleOpenModal }
                                     key={ payment.id }/>
                )) }
              </TableBody>
            </Table>
          </TableContainer>

          { !payments.length && <ErrorLoadingDataMessage title={ NOT_FOUND }/> }
        </Grid>

        { ( meta && meta.last_page > 1 ) &&
          <Grid item xs={ 12 }
                style={ {
                  paddingTop: `24px`,
                  display: `flex`,
                  justifyContent: 'center'
                } }>
            <Pagination current={ meta.current_page }
                        onChange={ this.handlePageChange }
                        perPage={ perPage }
                        total={ meta.total }
            />
          </Grid>
        }


        <PaydeskPaymentCancelModal loadPayments={ this.loadPayments }/>
        <PaydeskPaymentTransferModal loadPayments={ this.loadPayments }
                                     loadBalances={ this.loadBalances }/>
      </Grid>
    );
  }
}

export default connect(null, {
  openSnackbar,
  openPaydeskCancelModal,
  openPaydeskTransferModal
})(withRouter(PaydeskPaymentsListPage));
