import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import SwitchField from '../SwitchField';
import {Divider} from '@material-ui/core';
import Errors from '../Errors';

import {openSnackbar} from '../../actions/snackbarActions';
import {fetchRole, updateRole} from '../../actions/permissionsActions';
import PermissionsCheckboxes from './PermissionsCheckboxes';
import ButtonWithProgress from '../ButtonWithProgress';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';


class PermissionsEditFrom extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      role: {
        id: this.props.id,
        name: '',
        active: false,
        permissions: []
      },
      errors: new Errors(),
    };
  }

  componentDidMount() {
    const {role: {id}} = this.state;

    fetchRole(id).then(role => {
      this.setRole(role);
    });

  }

  setRole = ({id, name, active, permissions}) => {
    this.setState(prevState => ({
      role: {
        ...prevState.role,
        id: id,
        name: name,
        permissions: permissions,
        active: active,
      },
    }));
  };


  handleChangeMainPermission = event => {
    const {value, checked} = event.target;

    let newValue = value.split(',');

    this.setState(prevState => ({
      role: {
        ...prevState.role,
        permissions: checked
            ? [...prevState.role.permissions, ...newValue]
            : prevState.role.permissions.filter(x => x.split('.')[0] !== newValue[0].split('.')[0])
      }
    }));
  };

  handleChangeMinorPermission = event => {
    const {value, checked} = event.target;

    this.setState(prevState => ({
      role: {
        ...prevState.role,
        permissions: checked
            ? [...prevState.role.permissions, value]
            : prevState.role.permissions.filter(x => x !== value)
      }
    }));
  };

  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      role: {
        ...prevState.role,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({loading: true});

    const {id, ...role} = this.state.role;

    const data = {
      name: role.name,
      permissions: role.permissions,
      active: role.active,
    };

    updateRole(id, data)
        .then(role => {
          this.setRole(role);
          this.setState({loading: false});
          this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
        })
        .catch(errors =>
            this.setState({
              loading: false,
              errors: new Errors(errors)
            })
        );
  };

  render() {
    const {role, errors, loading} = this.state;

    return (
        <div style={{paddingLeft: 24, paddingRight: 24}}>
          <FormContainer>
            <FormRow>
              <FormColumn>
                <TextField error={errors.getError('name')}
                           name="name"
                           value={role.name}
                           onChange={this.handleChange}
                           label="Название прав доступа"
                           placeholder="Введите название"/>
              </FormColumn>
              <FormColumn style={flex}>
                <SwitchField error={errors.getError('active')}
                             name="active"
                             value={role.active}
                             onChange={this.handleChange}
                             label="Активность"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn xs={12}
                          sm={12}>
                <Divider style={{marginBottom: 16}}/>

                <PermissionsCheckboxes oldPermissions={role.permissions}
                                       changeMainPermission={this.handleChangeMainPermission}
                                       changeMinorPermission={this.handleChangeMinorPermission}/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn xs={12}
                          sm={12}
                          style={{textAlign: 'right'}}>
                <Divider style={{marginBottom: 16}}/>

                <ButtonWithProgress onClick={this.handleSubmit}
                                    loading={loading}
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </div>
    );
  }
}

export default connect(null, {openSnackbar})(PermissionsEditFrom);
