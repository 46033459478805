import { useCallback, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import { USE_FETCH_ACTION } from './fetchActions';
import { fetchReducer } from './fetchReducer';

const initialState = {
  data: null,
  meta: null,
  error: null,
  loading: false
};

export const useFetch = ({ url, hasMeta = false }) => {
  const location = useLocation();
  const [state, dispatch] = useReducer(fetchReducer, initialState);

  const updateState = (data) => {
    dispatch({ type: USE_FETCH_ACTION.UPDATE_STATE,  payload: data});
  }

  const createItem = (data, id, place) => {
    dispatch({
      type: USE_FETCH_ACTION.CREATE_ITEM,
      payload: {
        data,
        place,
        id
      }
    });
  }

  const deleteItem = (id, subItemId, place) => {
    dispatch({
      type: USE_FETCH_ACTION.REMOVE_ITEM,
      payload: {
        id,
        subItemId,
        place
      }
    })
  }

  const fetching = useCallback((signal) => {
    const params = queryString.parse(location.search);

    dispatch({ type: USE_FETCH_ACTION.SET_ERROR, payload: null });
    dispatch({ type: USE_FETCH_ACTION.SET_DATA, payload: null });
    dispatch({ type: USE_FETCH_ACTION.SET_META, payload: null });
    dispatch({ type: USE_FETCH_ACTION.LOADING });

    axios.get(`${ url }?${ queryString.stringify(params) }`, { signal })
      .then(({ data }) => {
        dispatch({ type: USE_FETCH_ACTION.SET_DATA, payload: data });

        if ( hasMeta ) {
          dispatch({ type: USE_FETCH_ACTION.SET_META, payload: data.meta });
        }

        dispatch({ type: USE_FETCH_ACTION.LOADING });
      })
      .catch((error) => {
        dispatch({ type: USE_FETCH_ACTION.SET_ERROR, payload: error });
        dispatch({ type: USE_FETCH_ACTION.LOADING });
      });
  }, [location.search, url, hasMeta]);

  const refetch = fetching;

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    fetching(signal);

    return () => {
      abortController.abort();
      console.info('Загрузка прервана для оптимизации');
    };
  }, [fetching]);

  return {
    data: state.data,
    loading: state.loading,
    error: state.error,
    meta: state.meta,
    refetch,
    updateState,
    createItem,
    deleteItem
  };
};
