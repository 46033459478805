import React, {useEffect, useState} from 'react';
import Autocomplete from './Autocomplete';
import {fetchUndergroundStation} from '../../actions/autocompleteActions';

const UndergroundStationAutocomplete = ({value, location,  ...props}) => {
  const [stations, setStation] = useState([]);


  useEffect(() => {
    loadStation();
    //eslint-disable-next-line
    }, [location]);

  let loadStation = (search) => {
    fetchUndergroundStation({location_id: location?.id})
        .then(stations => {
          setStation(stations)
        });
  };



  return (
      <Autocomplete {...props}
                    value={(typeof value === 'string' ? stations.find(station => station.id === value) : value) || null}
                    options={stations}
                    label={`Станция метро`}
                    placeholder={`Выберите станцию`}
      />
  );
};


export default UndergroundStationAutocomplete;
