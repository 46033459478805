import React, {useState} from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {deleteManager} from '../../actions/managersActions';
import {ERROR, SUCCESS} from '../Constants';


const ManagerRemove = ({id, loadManagers, ...props}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {

    deleteManager(id)
        .then(res => {
          props.openSnackbar(SUCCESS, 'Менеджер успешно удален');
          loadManagers();
        })
        .catch(errors => {
          props.openSnackbar(ERROR, errors);
        });

    handleClose();
  };

  return (
      <React.Fragment>
        <Button
            style={{marginTop: -16, marginBottom: -16, marginLeft: -8}}
            color="secondary"
            onClick={handleClickOpen}
        >
          <DeleteIcon fontSize="small"/>
        </Button>

        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="xs"
        >
          <DialogTitle>{'Удалить менеджера?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Вы действительно хотите удалить менеджера?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Нет
            </Button>
            <Button onClick={handleRemove} color="primary" autoFocus>
              Да
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

export default connect(null, {openSnackbar})(ManagerRemove);
