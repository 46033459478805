import React from 'react';
import './RealtyFeedStatistic.scss';
import Title from '../Title';
import Number from '../Number';

const RealtyFeedStatistic = ({...props}) => {
  return (
      <div className={ `realty-statistic` }>
        <div className="realty-statistic__wrapper">
          <div className="realty-statistic__part realty-statistic__payments">
            <Title title={ 'Оплаты' }/>
            <div className="realty-statistic__inner-wrap realty-statistic__inner-wrap--column">
             <div className="realty-statistic__inner">
               {/*{ !!debt &&*/ }
               <div className="realty-statistic__piece">
                 <Number size={ 'sm' }
                         green
                         value={ 453 }
                         icon/>
                 <Title size={ 'sm' }
                        title={ 'Общая касса' }/>
               </div>
               {/*}*/ }

               {/*{ !!realty_outcome &&*/ }
               <div className="realty-statistic__piece">
                 <Number size={ 'sm' }
                         red
                         value={ 34534 }
                         icon/>
                 <Title size={ 'sm' }
                        title={ 'Общий расход' }/>
               </div>
               {/*}*/ }

               {/*{ !!staff_outcome &&*/ }
               <div className="realty-statistic__piece">
                 <Number size={ 'sm' }
                         orange
                         value={ 6476 }
                         icon/>
                 <Title size={ 'sm' }
                        title={ 'Доход' }/>

               </div>
               {/*}*/ }
               <div className="realty-statistic__piece">
                 <Number size={ 'sm' }
                         green
                         value={ 164843 }
                         icon/>
                 <Title size={ 'sm' }
                        title={ 'Окупаемость' }/>

               </div>
             </div>

              <div className="realty-statistic__inner">
                <div className="realty-statistic__piece">
                  <Number size={ 'sm' }
                          value={ 45000 }
                          icon/>
                  <Title size={ 'sm' }
                         title={ 'Тариф за месяц' }/>
                </div>

                <div className="realty-statistic__piece">
                  <Number size={ 'sm' }
                          value={ 1500 }
                          icon/>
                  <Title size={ 'sm' }
                         title={ 'Тариф за день' }/>
                </div>
              </div>


            </div>
          </div>

          <div className="realty-statistic__part realty-statistic__efficiency">
            <Title title={ 'Эфективность' }/>
            <div className="realty-statistic__inner-wrap">
              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        green
                        value={ '1/2 дней' }/>
                <Title size={ 'sm' }
                       title={ 'Занятость' }/>
              </div>
              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        orange
                        percent
                        value={ 20 }/>
                <Title size={ 'sm' }
                       title={ '% Занятости' }/>
              </div>

              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        green
                        percent
                        est={ 98 }
                        value={ 35 }/>
                <Title size={ 'sm' }
                       title={ '% Годовых' }/>
              </div>

            </div>
          </div>

          <div className="realty-statistic__part realty-statistic__bookings">
            <Title title={ 'Заказы' }/>
            <div className="realty-statistic__inner-wrap">
              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        green
                        value={ 20 }/>
                <Title size={ 'sm' }
                       title={ 'Заказов' }/>
              </div>
              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        orange
                        value={ 57 }/>
                <Title size={ 'sm' }
                       title={ 'К-во обращений' }/>
              </div>

              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        green
                        value={ 24 }/>
                <Title size={ 'sm' }
                       title={ 'Заявок' }/>
              </div>

              <div className="realty-statistic__piece">
                <Number size={ 'sm' }
                        green
                        value={ 11 }/>
                <Title size={ 'sm' }
                       title={ 'Встреч' }/>
              </div>
            </div>
          </div>

        </div>
      </div>
  );
};

export default RealtyFeedStatistic;
