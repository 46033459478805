import { combineReducers } from 'redux';
import drawerReducer from './drawerReducer';
import authReducer from './authReducers';
import snackbarReducer from './snackbarReducer';
import formDrawerReducer from './formDrawerReducer';
import autocompleteReducer from './autocompleteReducer';
import menuDrawerReducer from './menuDrawerReducer';
import statisticReducer from './statisticReducer';

const appReducer = combineReducers({
  drawer: drawerReducer,
  auth: authReducer,
  snackbar: snackbarReducer,
  formDrawer: formDrawerReducer,
  autocomplete: autocompleteReducer,
  menuDrawer: menuDrawerReducer,
  statistic: statisticReducer,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
