import React, {PureComponent} from 'react';
import {CardHeader, Divider, Card} from '@material-ui/core';
import BookingRequestEditForm from '../../components/BookingRequests/BookingRequestEditForm';
import {setPageTitle} from '../../components/Tools';

class BookingRequestEditPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount () {
    setPageTitle('Редактирование заявки');
    this.setState({
      id: this.getId(),
    });
  }

  getId = () => {
    const {match: {params}} = this.props;

    return params.id || null;
  };

  render () {
    const {id} = this.state;

    if ( !id ) {
      return null;
    }

    return (
        <React.Fragment>
          <Card>
            <CardHeader title="Редактирование заявки"
                        subheader={ `ID: ${ id }` }/>
            <Divider/>
            <div style={ {paddingTop: 24, paddingBottom: 24} }>
              <BookingRequestEditForm id={ id }/>
            </div>
          </Card>
        </React.Fragment>
    );
  }
}

export default BookingRequestEditPage;
