import React, {PureComponent} from 'react';
import {Grid} from '@material-ui/core';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {createRealtyExternalResources, fetchRealtyExternalResources} from '../../actions/externalResourcesActions';
import RealtyResourcesRow from './RealtyResourcesRow';
import ButtonWithProgress from '../ButtonWithProgress';
import {SUCCESS} from '../Constants';
import {sortable} from '../Tools';


class RealtyEditResourcesForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      realty: {
        id: this.props.id,
        is_deleted: false,
        resources: null
      },
      loading: false,
      errors: new Errors(),
    };
  }

  componentDidMount () {
    const {realty: {id}} = this.state;

    fetchRealtyExternalResources(id)
        .then(realty => {
          this.setRealty(realty);
        });
  }


  setRealty = ({id, external_resources, is_deleted}) => {
    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        id: id,
        is_deleted: is_deleted,
        resources: sortable(external_resources)
      },
    }));
  };

  handleChange = (e) => {
    const {name, value, type, checked} = e.target;

    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));

  };

  handleResourcesLink = (event) => {
    const {value, name} = event.target;
    const type_resource = name.split('/')[0];
    const name_resource = name.split('/')[1];

    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        resources: [...prevState.realty.resources.map(resource => resource.resource === name_resource
            ? {...resource, [type_resource]: value}
            : resource)]
      }
    }));

  };


  handleSubmit = () => {
    const {realty} = this.state;
    this.setState({loading: true});

    const data = {
      resources: sortable(realty.resources)
    };

    createRealtyExternalResources(realty.id, data)
        .then(realty => {
          this.setRealty(realty);
          this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
          this.setState({
            loading: false,
            errors: new Errors({}),
          });
        })
        .catch(errors => {
          this.setState({
            loading: false,
            errors: new Errors(errors),
          });
        });
  };


  render () {
    const {realty, errors, loading} = this.state;

    return (
        <Grid container
              spacing={ 2 }
              style={ {paddingTop: 24} }>
          <Grid item xs={ 12 }
                sm={ 12 }
                md={ 10 }
                lg={ 12 }>
            <FormContainer>
              { realty.resources && realty?.resources.map((resource, index) => (
                  <RealtyResourcesRow key={ index }
                                      index={ index }
                                      handleChangeLink={ this.handleResourcesLink }
                                      errors={ errors }
                                      { ...resource }/>
              )) }

            </FormContainer>
          </Grid>

          <Grid item xs={ 12 } style={ {paddingTop: 24} }>
            <ButtonWithProgress loading={ loading }
                                disabled={ realty.is_deleted }
                                onClick={ this.handleSubmit }
                                label="Сохранить"/>
          </Grid>
        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(RealtyEditResourcesForm);
