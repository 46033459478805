import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {fetchRealtyFeed} from '../../actions/realtyActions';
import queryString from 'query-string';
import {Grid} from '@material-ui/core';
import {setPageTitle, uuidv4} from '../../components/Tools';
import PageHeader from '../../components/PageHeader';
import Paper from '@material-ui/core/Paper';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import {NOT_FOUND} from '../../components/Constants';
import Pagination from '../../components/Pagination';
import RealtyFeedItem from '../../components/Realty/RealtyFeedItem';
import Loader from '../../components/Loader';
import RealtyFeedStatistic from '../../components/RealtyFeedStatistic';


const RealtyFeedsListPage = ({...props}) => {
  const {id} = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [feeds, setFeeds] = useState(null);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    setPageTitle('Лента Объекта');
  });


  let loadFeeds = useCallback(() => {
    const query = queryString.parse(location.search);

    setLoading(true);
    fetchRealtyFeed(id, query)
        .then(response => {
          setFeeds(response.data);
          setMeta(response.meta);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [location.search, id]);


  useEffect(() => {
    loadFeeds();
  }, [loadFeeds]);


  let handlePageChange = (e, page) => {
    const search = queryString.parse(location.search);

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };


  return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <PageHeader title="Лента объекта"
                      size="md"/>
        </Grid>

        <Grid item xs={ 12 }>
          <RealtyFeedStatistic/>
        </Grid>


        <Grid item xs={ 12 }>
          { loading && <Loader/> }

          { feeds &&
            <Paper style={ {padding: 16} }>
              <ul className="realty-feeds-list">
                { feeds.map(item => <RealtyFeedItem key={ uuidv4() }
                                                    className="realty-feeds-list__item"
                                                    { ...item }/>) }
              </ul>
            </Paper>
          }

          { (!feeds?.length && !loading) && <ErrorLoadingDataMessage title={ NOT_FOUND }/> }
        </Grid>


        { (meta && meta.last_page > 1) &&
        <Grid item xs={ 12 }
              style={ {
                paddingTop: `24px`,
                display: `flex`,
                justifyContent: 'center'
              } }>
          <Pagination current={ meta.current_page }
                      onChange={ handlePageChange }
                      perPage={ meta.per_page }
                      total={ meta.total }/>
        </Grid>
        }
      </Grid>
  );
};

export default RealtyFeedsListPage;
