import React, {memo, useCallback, useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import Title from '../Title';
import AddingButton from '../AddingButton';
import {PlusIcon} from '../../icons';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import RealtyInventoryRoomRow from './RealtyInventoryRoomRow';
import TextField from '../TextField';
import {createRealtyRoom, getRealtyRooms} from '../../actions/realtyActions';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import Errors from '../Errors';
import {SUCCESS} from '../Constants';


const RealtyInventoryForm = ({id, openSnackbar, ...props}) => {
  const [rooms, setRooms] = useState([]);
  const [newRoom, addNewRoom] = useState(false);
  const [newRoomName, setNewRoomName] = useState('');
  const [errors, setErrors] = useState(new Errors())

  let fetchRooms = useCallback(() => {
    getRealtyRooms(id)
        .then(allRooms => {
          setRooms(allRooms);
        });
  }, [id]);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  let handleNewRooms = () => {
    addNewRoom(!newRoom);
  };

  let handleAddNewRoom = (e) => {
    const {value} = e.target;
    setNewRoomName(value);
  };

  let handleCreateRoom = () => {
    const data = {
      name: newRoomName
    };

    createRealtyRoom(id, data)
        .then(() => {
          openSnackbar(SUCCESS, 'Комната успешно создана');
          addNewRoom(false);
          setNewRoomName('');
          setErrors(new Errors())
          fetchRooms();
        })
        .catch(errors => {
          setErrors(new Errors(errors))
        });
  };

  return (
      <Grid container
            spacing={2}>
        <Grid item
              xs={12}>
          <Grid container
                spacing={2}
                style={{alignItems: 'center'}}>
            <Grid item>
              <Title title="Комнаты"
                     style={{marginBottom: 0}}
                     size="xl"/>
            </Grid>
            <Grid item>
              <AddingButton icon={() => <PlusIcon/>}
                            onClick={handleNewRooms}
                            tooltip="Добавить комнату"/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item
              xs={12}>
          <FormContainer>

            {newRoom &&
            <FormRow>
              <FormColumn sm={2}>
                <TextField name="name"
                           value={newRoomName}
                           error={errors.getError('name')}
                           onChange={handleAddNewRoom}
                           label="Тип комнаты"
                           placeholder="Введите тип"/>
              </FormColumn>
              <FormColumn sm={2} style={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <AddingButton icon={() => <SaveOutlinedIcon/>}
                              onClick={handleCreateRoom}
                              tooltip="Сохранить комнату"/>
              </FormColumn>

            </FormRow>
            }

            {rooms &&
            rooms.map((room, index) => <RealtyInventoryRoomRow key={room.id}
                                                               realty_id={id}
                                                               fetchRooms={fetchRooms}
                                                               {...room}/>)
            }

          </FormContainer>

        </Grid>
      </Grid>
  );
};

export default connect(null, {openSnackbar})(memo(RealtyInventoryForm));
