import React from 'react';
import {Grid} from '@material-ui/core';

const FormContainer = (props) => {
  return (
      <Grid
          container
          spacing={3}
          {...props}
      />
  );
};

export default FormContainer;
