import React from 'react';
import {FormControl, InputLabel, OutlinedInput, InputAdornment} from '@material-ui/core';


const TextFieldWithIcon = ({label, value, handleChange, icon: Icon = null, fullWidth = false, labelWidth = 60, ...props}) => {
  return (
      <FormControl variant="outlined"
                   fullWidth>
        <InputLabel htmlFor={label}>
          {label}
        </InputLabel>
        <OutlinedInput
            {...props}
            id={label}
            value={value}
            onChange={handleChange}
            endAdornment={<InputAdornment position="end">
                                  <Icon/>
                                </InputAdornment>}
            labelWidth={labelWidth}
        />
      </FormControl>
  )
};

export default TextFieldWithIcon;
