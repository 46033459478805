import React from 'react';

const RealtyFeedChanges = ({name, value, ...props}) => {
  let checkInValue = (val) => {
    if ( typeof val === 'boolean' ) {
      if ( val ) {
        return 'Да';
      } else {
        return 'Нет';
      }
    } else {
      return val;
    }
  };


  return (
      <div className="realty-feed-data-row">
        <div className="realty-feed-data-row__title">
          { name }:
        </div>
        <div className="realty-feed-data-row__value">
          &nbsp;{ checkInValue(value) }
        </div>
      </div>
  );
};

export default RealtyFeedChanges;
