import React, {PureComponent} from 'react';
import {Grid, List, withStyles} from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import ChatListItem from '../../components/ClientsSupport/ChatListItem';
import Chat from '../../components/ClientsSupport/Chat';
import {fetchChatList} from '../../actions/chatActions';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import ClientSupportFilter from '../../components/ClientsSupport/ClientSupportFilter';
import {HIDDEN, TIME_INTERVAL_REQUEST_CHAT, VISIBLE} from '../../components/Constants';
import {setPageTitle} from '../../components/Tools';

const styles = theme => ({
  listItem: {
    // margin: '-16px 0 0 -16px',
    background: '#fff',
    // width: 360,
    minHeight: '75vh',
    maxHeight: '75vh',
    overflow: 'auto',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'auto',
      marginBottom: 16
    },
  }
});

class ClientsSupportPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      chats: [],
      booking_id: null,
    };

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadChats();
    }
  }

  componentDidMount () {
    setPageTitle('Поддержка клиентов');
    this.loadChats();
    this.handleDialogsFromInterval();
    this.handleWindowActive();
  }

  componentWillUnmount () {
    this.handleClearInterval();
    document.removeEventListener('visibilitychange', this.handleVisibility);
  }

  handleWindowActive = () => {
    document.addEventListener('visibilitychange', this.handleVisibility);
  };

  handleVisibility = () => {
    if(document.visibilityState === VISIBLE) {
      this.handleDialogsFromInterval();
    }

    if(document.visibilityState === HIDDEN) {
      this.handleClearInterval();
    }
  }

  handleDialogsFromInterval = () => {
    this.dialogsInterval = setInterval(() => {
      this.loadChats();
    }, TIME_INTERVAL_REQUEST_CHAT);
  };

  handleClearInterval = () => {
    clearInterval(this.dialogsInterval);
  };


  loadChats = () => {
    const query = queryString.parse(this.props.location.search);

    fetchChatList(query)
        .then(chats => {
          this.setState({
            chats: chats,
          });
        });
  };

  handleOpenChat = (e, chat) => {
    this.setState({
      booking_id: chat.booking_id,
    });
  };

  render () {
    const {chats, booking_id} = this.state;
    const {classes} = this.props;


    if ( !chats ) {
      return null;
    }

    return (
        <Grid container
              spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <PageHeader title="Поддержка клиентов"
                        size="md"/>
          </Grid>

          <Grid item
                xs={ 12 }>
            <ClientSupportFilter/>
          </Grid>

          { chats.length > 0 &&
          <Grid item
                xs={ 12 }>
            <Grid container>
              <Grid item
                    xs={ 12 }
                    sm={ 4 }
                    md={ 4 }
                    lg={ 3 }
                    xl={ 2 }>
                <List className={ classes.listItem }>
                  { chats.map((chat, index) => <ChatListItem key={ index }
                                                             selected={ booking_id === chat.booking_id }
                                                             onClick={ (e) => this.handleOpenChat(e, chat) }
                                                             chat={ chat }/>
                  ) }
                </List>
              </Grid>

              { booking_id &&
              <Chat key={ booking_id }
                    loadChats={ this.loadChats }
                    chat_info={ chats.find(x => x.booking_id === booking_id) }
                    booking_id={ booking_id }/>
              }

            </Grid>
          </Grid>
          }

        </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(ClientsSupportPage));
