import React from 'react';
import './PageHeader.scss';
import {PlusIcon} from '../../icons';
import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';


const PageHeader = ({title = '', filter = false, className = '', sort = false, hasBottom = false, addButton = false, size = '', search = false, ...props}) => {
  return (
      <Grid container spacing={ 2 }
            className={ `page-header ${ className }` }
            { ...props }>

        { title &&
        <Grid item xs={ 12 } sm={ 12 }>
          <div className="page-header__top">
            <h1 className={ `page-header__title 
                        ${ size ? `page-header__title--${ size }` : '' }
                        ` }>
              { title }
            </h1>

            { addButton &&
            <Link to={ addButton }
                  className="page-header__link-new">
              <PlusIcon/>
            </Link>
            }
          </div>
        </Grid>
        }


        { hasBottom &&
        <Grid item xs={ 12 } sm={ 12 }>
          <Grid container
                spacing={ 2 }
                style={ {alignItems: 'center'} }>

            { sort &&
            <Grid item>
              { sort }
            </Grid>
            }


            { search &&
            <Grid item>
              { search }
            </Grid>
            }

            { filter &&
            <Grid item>
              { filter }
            </Grid>
            }
          </Grid>
        </Grid>
        }

      </Grid>
  );
};

export default PageHeader;
