import React, {PureComponent} from 'react';
import {fetchPermissions} from '../../actions/permissionsActions';
import {FormControlLabel, Checkbox} from '@material-ui/core';


class PermissionsCheckboxes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      permissions: []
    };

  }

  componentDidMount() {

    fetchPermissions()
        .then((response) => {
          this.transformPermissions(response)
        })
      .catch((error) => {
        console.error(error);
      })

  }

  transformPermissions(data) {
    let transformedPermissions = [];

    for (const i in data) {
      transformedPermissions.push(
          (transformedPermissions[i] = {
            name: i,
            all: []
          })
      );

      for (const x in data[i]) {
        transformedPermissions[i].all.push({
          name: `${x} ${data[i][x]}`,
          value: x
        });
      }

    }

    this.setState({
      permissions: transformedPermissions
    });
  }



  render() {
    const {permissions} = this.state;
    const {changeMainPermission, changeMinorPermission, oldPermissions} = this.props;

    return (
        <div>
          {permissions.map((item, index) => (
              <div key={index}
                   style={{
                     display: 'flex',
                     flexDirection: 'column'
                   }}>

                <FormControlLabel control={<Checkbox value={item.all.map(x => x.value)}
                                                     color="primary"
                                                     onChange={changeMainPermission}
                                                     name={item.name}/>} label={item.name}/>

                <div style={{
                  paddingLeft: '30px',
                  display: 'flex',
                  flexDirection: 'column'
                }}>

                  {item.all.map((subPermission, index) => (
                      <FormControlLabel key={index}
                                        control={<Checkbox checked={oldPermissions.includes(subPermission.value)}
                                                           value={subPermission.value}
                                                           color="primary"
                                                           onChange={changeMinorPermission}
                                                           name={subPermission.name}/>}
                                        label={subPermission.name}/>
                  ))}
                </div>
              </div>
          ))}

        </div>

    )
  }
}

export default PermissionsCheckboxes;
