import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    // display: 'flex',
    height: '100%',
    background: '#f5f6f8',
  }
}));

const Container = (props) => {
  const classes = useStyles();

  return (
      <div
          {...props}
          className={classes.container}
      />
  );
};

export default Container;
