import React, {useEffect, useState} from 'react';
import {Button} from '@material-ui/core';
import FormRow from './../FormRow';
import FormColumn from './../FormColumn';
import Errors from './../Errors';
import TextField from './../TextField';
import FormContainer from '../FormContainer';
import PhoneInput from '../PhoneInput';
import {createClient} from '../../actions/clientsActions';
import {SUCCESS} from '../Constants';
import {useDispatch, useSelector} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {closeCreateClientModal} from '../../actions/formDrawerActions';
import {reformatPhoneNumber} from '../Tools';

const initialState = {
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  phone: undefined,
};

const ClientCreateFormFromModal = ({...props}) => {
  const dispatch = useDispatch();
  const client = useSelector(state => state.formDrawer.createClient.payload);
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(new Errors());

  useEffect(() => {

    if(client) {
      setState(prevState => ({
        ...prevState,
        first_name: client?.first_name,
        phone: client?.phone,
        last_name: client?.last_name,
        email: client?.email
      }));
    }

  }, [client]);


  let handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    setState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  let handleSubmit = (event) => {
    event.preventDefault();

    const formData = {
      ...state,
      phone: reformatPhoneNumber(state.phone)
    }

    createClient(formData)
        .then(() => {
          setState(initialState);
          dispatch(openSnackbar(SUCCESS, 'Клиент добавлен успешно'));
          dispatch(closeCreateClientModal())
        })
        .catch(errors => {
              setErrors(new Errors(errors));
            }
        );
  };

  return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField error={ errors.getError('first_name') }
                         fullWidth
                         name="first_name"
                         value={ state?.first_name }
                         onChange={ handleChange }
                         label="Имя"
                         placeholder="Введите имя"
              />
            </FormColumn>

            <FormColumn sm={ 6 }>
              <TextField error={ errors.getError('last_name') }
                         fullWidth
                         name="last_name"
                         value={ state?.last_name }
                         onChange={ handleChange }
                         label="Фамилия"
                         placeholder="Введите фамилию"
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField error={ errors.getError('email') }
                         fullWidth
                         name="email"
                         value={ state?.email }
                         onChange={ handleChange }
                         label="Электронная почта"
                         placeholder="Введите электронную почту"
              />
            </FormColumn>
            <FormColumn sm={ 6 }>
              <PhoneInput error={ errors.getError('phone') }
                          name="phone"
                          type="phone"
                          onChange={ handleChange }
                          value={ state?.phone }
                          label="Телефон"
                          placeholder="Введите телефон"/>
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn xs="auto"
                        sm="auto"
                        style={ {marginLeft: 'auto'} }>
              <Button color="primary"
                      variant="contained"
                      onClick={ handleSubmit }>
                Добавить
              </Button>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>
  );
};

export default ClientCreateFormFromModal;
