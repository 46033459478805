import React from 'react';
import {Tooltip} from '@material-ui/core';
import {TOOLTIP_TOP} from '../Constants';


const RealtyDocument = ({name, uploaded, type, ...props}) => {
  return (
      <React.Fragment>
        <Tooltip title={ uploaded ? name : '' }
                 placement={ TOOLTIP_TOP }
                 arrow>
          <div className="document">
            <div className="document__inner">
              <div className={ `document__number 
                  ${ uploaded ? 'document__number--active' : '' }
                    ` }>
                { type }
              </div>
            </div>
          </div>
        </Tooltip>
      </React.Fragment>
  );
};

export default RealtyDocument;
