import React, {useCallback, useState} from 'react';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import AddingButton from '../AddingButton';
import {ArrowDownIcon, DeleteIcon, PlusIcon} from '../../icons';
import RealtyInventoryRoomObject from './RealtyInventoryRoomObject';
import {Grid} from '@material-ui/core';
import TextField from '../TextField';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import {SaveOutlined} from '@material-ui/icons';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import {
  createRealtyRoomItem,
  deleteRealtyRoom,
  getRealtyRoomItems,
  updateRealtyRoom
} from '../../actions/realtyActions';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import RealtyRoomItemCondition from './RealtyRoomItemCondition';
import DialogsRemove from '../DialogsRemove';
import Errors from '../Errors';
import {errorHandler} from '../Tools';
import {ERROR, SUCCESS} from '../Constants';

const RealtyInventoryRoomRow = ({user, realty_id, name, id, fetchRooms, items_count, openSnackbar, ...props}) => {
  const [openInventory, setOpenInventory] = useState(false);
  const [addInventory, setAddInventory] = useState(false);
  const [roomItems, setRoomItems] = useState([]);
  const [updateRoomName, setUpdateRoomName] = useState(name);

  const initialState = {
    inventory: {
      comment: '',
      condition: '',
      quantity: '',
      name: '',
      manager: {id: user.userable_id, name: user.name}
    }
  }

  const [state, setState] = useState(initialState);
  const [roomErrors, setRoomErrors] = useState(new Errors())
  const [itemErrors, setItemErrors] = useState(new Errors())


  let handleChange = (e) => {
    const {value, name} = e.target;

    setState(prevState => ({
      ...prevState,
      inventory: {
        ...prevState.inventory,
        [name]: value
      }
    }));
  };

  let handleChangeManager = (value, type) => {
    setState(prevState => ({
      ...prevState,
      inventory: {
        ...prevState.inventory,
        [type]: value
      }
    }));
  };

  let fetchRealtyRoomItems = useCallback(() => {
    getRealtyRoomItems(realty_id, id)
        .then(items => {
          setRoomItems(items)
          if (!items.length) setOpenInventory(false)
        })
        .catch(errors => {
          openSnackbar(ERROR, errorHandler(errors));
        });
  }, [realty_id, id, openSnackbar])


  let handleOpenInventory = () => {

    if(!openInventory) {
      fetchRealtyRoomItems()
    }

    setOpenInventory(!openInventory);
  };

  let handleAddInventory = () => {
    if(!addInventory) {
      setState(initialState)
      setItemErrors(new Errors())
    }
    setAddInventory(!addInventory);
  };

  let handleChangeRoomName = (e) => {
    const {value} = e.target;
    setUpdateRoomName(value);
  };

  let handleUpdateRoomName = () => {
    const data = {
      name: updateRoomName
    };

    updateRealtyRoom(realty_id, id, data)
        .then(() => {
          openSnackbar(SUCCESS, 'Название комнаты успешно изменено');
          fetchRooms();
          setRoomErrors(new Errors())
        })
        .catch(errors => {
          setRoomErrors(new Errors(errors))
        });
  };

  let handleRemoveInventory = (_, id) => {
    deleteRealtyRoom(realty_id, id)
        .then(() => {
          openSnackbar(SUCCESS, 'Комната успешно удалена');
          fetchRooms();
        })
        .catch(errors => {
          openSnackbar(ERROR, errorHandler(errors));
        });
  };

  let handleSaveRoomItem = () => {
    const data = {
      ...state.inventory
    };

    createRealtyRoomItem(realty_id, id, data)
        .then(() => {
          fetchRealtyRoomItems();
          setState(initialState)
          fetchRooms();
          openSnackbar(SUCCESS, 'Инвентарь успешно добавлен');
          setAddInventory(false);
          setOpenInventory(true);
          setItemErrors(new Errors())
        })
        .catch(errors => {
          setItemErrors(new Errors(errors))
        });
  };

  return (
      <>
        <FormRow>
          <FormColumn sm={2}>
            <TextField name="name"
                       error={roomErrors.getError('name')}
                       value={updateRoomName}
                       onChange={handleChangeRoomName}
                       label="Тип комнаты"
                       placeholder="Введите тип"/>
          </FormColumn>
          <FormColumn xs={12}
                      sm={6}
                      md={4}
                      style={{
                        display: 'flex',
                        alignItems: 'center'
                      }}>
            <AddingButton icon={props => <ArrowDownIcon {...props}
                                                        style={{
                                                          transform: openInventory ? 'rotate(180deg)' : '',
                                                          transition: '.3s all',
                                                        }}/>}
                          disabled={!items_count}
                          style={{cursor: items_count === 0 ? 'default' : 'pointer'}}
                          onClick={handleOpenInventory}
                          position="right"
                          tooltip={items_count === 0 ? "" : "Открыть инвентарь"}/>
            <AddingButton icon={() => <SaveOutlinedIcon/>}
                          position="right"
                          onClick={handleUpdateRoomName}
                          tooltip="Сохранить комнату"/>
            <DialogsRemove question_text="Действительно удалить комнату?"
                           question="Удалить комнату?"
                           icon={() => <DeleteIcon/>}
                           disabled={items_count !== 0}
                           id={id}
                           style={{cursor: items_count  !== 0 ? 'default' : 'pointer'}}
                           remover={handleRemoveInventory}
                           negative_answer="Нет"
                           positive_answer="Да"
                           position_button="right"
                           tooltip={items_count !== 0 ? "" : "Удалить комнату"}/>

            <AddingButton icon={() => <PlusIcon/>}
                          onClick={handleAddInventory}
                          position="right"
                          tooltip="Добавить инвентарь"/>
          </FormColumn>

        </FormRow>

        {addInventory &&
        <FormRow>
          <Grid item xs={12}>
            <Grid container
                  spacing={2}>
              <Grid item
                    sm={2}>
                <TextField name="name"
                           error={itemErrors.getError('name')}
                           value={state.inventory.name}
                           onChange={handleChange}
                           label="Название"
                           placeholder="Введите название"/>
              </Grid>
              <Grid item
                    sm={2}>
                <ManagersAutocomplete label="Менеджер"
                                      onChange={(e, value) => handleChangeManager(value, 'manager')}
                                      value={state.inventory.manager}
                                      placeholder="Виберите менеджера"/>
              </Grid>
              <Grid item
                    sm={2}>
                <TextField name="quantity"
                           error={itemErrors.getError('quantity')}
                           value={state.inventory.quantity}
                           onChange={handleChange}
                           label="Количество"
                           placeholder="Введите количество шт."/>
              </Grid>
              <Grid item
                    sm={2}>
                <RealtyRoomItemCondition label="Состояние"
                                         error={itemErrors.getError('condition')}
                                         name="condition"
                                         fullWidth
                                         value={state.inventory.condition}
                                         onChange={handleChange}/>
              </Grid>
              <Grid item
                    sm={2}
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                <AddingButton icon={() => <SaveOutlined/>}
                              onClick={handleSaveRoomItem}
                              tooltip="Сохранить"/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item
                xs={12}
                sm={8}
                md={6}>
            <TextField name="comment"
                       multiline
                       error={itemErrors.getError('comment')}
                       rows={3}
                       value={state.inventory.comment}
                       onChange={handleChange}
                       label="Комментарий"
                       placeholder="Введите комментарий"
            />
          </Grid>
        </FormRow>
        }

        {(openInventory && !!roomItems.length) &&
        roomItems.map(obj => <RealtyInventoryRoomObject room_id={id}
                                                        fetchRooms={fetchRooms}
                                                        fetchRealtyRoomItems={fetchRealtyRoomItems}
                                                        realty_id={realty_id}
                                                        {...obj}
                                                        key={obj.id}/>)
        }


      </>
  );
};

let mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {openSnackbar})(RealtyInventoryRoomRow);
