import React from 'react';
import UtilitiyInvoiceEditForm from './UtilitiyInvoiceEditForm';
import FormDrawerWithPayload from '../../FormDrawerWithPayload/FormDrawerWithPayload';
import {connect} from 'react-redux';
import {closeInvoiceEditModal} from '../../../actions/formDrawerActions';

const UtilitiesInvoiceEditModal = ({isOpen, closeInvoiceEditModal, loadInvoices, ...props}) => {
  return (
      <FormDrawerWithPayload isOpen={isOpen}
                             closeModal={closeInvoiceEditModal}>
        <UtilitiyInvoiceEditForm closeInvoiceEditModal={closeInvoiceEditModal}
                                 loadInvoices={loadInvoices}/>
      </FormDrawerWithPayload>
  )
}

let matStateToProps = (state) => ({
  isOpen: state.formDrawer.invoiceEdit.isOpen
});

export default connect(matStateToProps, {closeInvoiceEditModal})(UtilitiesInvoiceEditModal);
