import React, {useEffect, useState} from 'react';
import {getLogsActions} from '../../actions/logsActions';
import FilterSelectField from '../FilterFields/FilterSelectField';

const LogsActionsSelect = ({...props}) => {
  const [actions, setActions] = useState(null);

  useEffect(() => {
    getLogsActions()
        .then(response => {
          setActions(response.map(a => ({name: a.title, ...a})));
        });
  }, []);

  if(!actions) {
    return null
  }

  return (
      <FilterSelectField options={actions}
                         fullWidth
                         style={{minWidth: 220}}
                         label="Действие"
                         type="action"/>
  );
};

export default LogsActionsSelect;

