import React, {memo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Autocomplete from './Autocomplete';
import {fetchLocations} from '../../actions/autocompleteActions';

const LocationsAutocomplete = ({value, label, ...props}) => {
  const dispatch = useDispatch();
  const locations = useSelector(state => state.autocomplete.locations)

  useEffect(() => {
    dispatch(fetchLocations())
  }, [dispatch])


  if (!locations) {
    return null;
  }

  return (
      <Autocomplete
          {...props}
          filterOptions={(options, state) => options}
          value={(typeof value === 'string' ? locations.find(location => location.id === value) : value) || null}
          options={locations}
          label={label || `Город`}
          placeholder={`Выберите город`}
      />
  );
};

export default memo(LocationsAutocomplete);
