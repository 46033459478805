import React, {PureComponent} from 'react';
import {Paper,Link,  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {fetchManagementCompanies} from '../../actions/managementCompanyActions';
import {Link as RouterLink} from 'react-router-dom';
import ManagementCompanyCreateForm from '../../components/ManagementCompany/ManagementCompanyCreateForm';
import PageHeader from '../../components/PageHeader';
import AddingButton from '../../components/AddingButton';
import {PlusIcon} from '../../icons';
import {setPageTitle} from '../../components/Tools';


class ManagementCompaniesListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      management_companies: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Управляющие компании');
    this.loadManagementCompanies();

  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadManagementCompanies();
    }
  }

  loadManagementCompanies = () => {
    const query = queryString.parse(this.props.location.search);

    fetchManagementCompanies(query)
        .then(response => {
          this.setState({
            management_companies: response,
          })
        });
  };


  render() {
    const {management_companies} = this.state;

    if (!management_companies) {
      return null;
    }

    return (
        <Grid container spacing={2}>
          <Grid item
                xs={12}>
            <Grid container
                  spacing={2}
                  style={{alignItems: 'center'}}>
              <Grid item>
                <PageHeader title="Управляющие компании"
                            size="md"/>
              </Grid>
              <Grid item>
                <FormDrawer title="Добавление управляющей компании"
                            button={props => <AddingButton {...props}
                                                           tooltip="Добавить управляющую компанию"
                                                           icon={() => <PlusIcon/>}/>}>
                  <ManagementCompanyCreateForm loadManagementCompanies={this.loadManagementCompanies}/>
                </FormDrawer>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <TableContainer style={{
                maxWidth: '100%',
                overflow: 'auto'
              }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Навание</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell>Счёт получателя</TableCell>
                      <TableCell>БИК банка получателя</TableCell>
                      <TableCell>ИНН получателя</TableCell>
                      <TableCell>КПП получателя</TableCell>
                      <TableCell>Получатель платежа</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {management_companies.map(company => (
                        <TableRow key={company.id} hover>
                          <TableCell>
                            <Link
                                color="inherit"
                                component={React.forwardRef((props, ref) => (
                                    <RouterLink
                                        {...props}
                                        ref={ref}
                                        to={`/management-companies/${company.id}`}
                                    />
                                ))}
                            >
                              <b>{company.name}</b>
                            </Link>
                          </TableCell>
                          <TableCell>{company.url}</TableCell>
                          <TableCell>{company.counterparty_account_number}</TableCell>
                          <TableCell>{company.counterparty_bank_bic}</TableCell>
                          <TableCell>{company.counterparty_inn}</TableCell>
                          <TableCell>{company.counterparty_kpp}</TableCell>
                          <TableCell>{company.counterparty_name}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

          </Grid>

        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(ManagementCompaniesListPage);
