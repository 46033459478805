import React, {createRef, PureComponent} from 'react';
import {List} from '@material-ui/core';
import Message from './ChatMessage';

class ChatMessageList extends PureComponent {
  constructor(props) {
    super(props);

    this.messages = createRef();
  }

  scrollToBottom = () => {
    this.messages.current.scrollTo({
      top: this.messages.current.scrollHeight,
      y: 0,
      behavior: 'smooth'
    })
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const {messages, downloadDocument} = this.props;

    return (
        <List style={{overflow: 'auto'}}
              ref={this.messages}>
          {messages.map((message, index) => <Message key={index}
                                                     downloadDocument={downloadDocument}
                                                     message={message}/>)}
        </List>
    );
  }
}

export default ChatMessageList;
