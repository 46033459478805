import React, {PureComponent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Link, Paper, Grid, Table, TableHead, TableBody, TableRow, TableCell, TableContainer} from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import ModeratorCreateForm from './../../components/Moderator/ModeratorCreateForm';
import {fetchModerators} from '../../actions/moderatorsActions';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import ModeratorRemove from '../../components/Moderator/ModeratorRemove';
import {withRouter} from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import AddingButton from '../../components/AddingButton';
import {PlusIcon} from '../../icons';
import {setPageTitle} from '../../components/Tools';
import { ERROR } from '../../components/Constants';
import { BOOKINGS_ROUTE } from '../../components/RouterConstants';

class ModeratorsListPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 10,
      total: 0,
      current: 0,
      moderators: [],
    };
  }

  componentDidMount = () => {
    setPageTitle('Модераторы');
    this.loadModerators();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.loadModerators();
    }
  }

  loadModerators = () => {
    const {perPage} = this.state;
    const query = queryString.parse(this.props.location.search);

    fetchModerators({...query, limit: perPage})
        .then(response => {
          this.setState({
            moderators: response.data,
            total: response.meta.total || 0,
            current: response.meta.current_page
          })
        })
      .catch((error) => {
        this.props.openSnackbar(ERROR, error)

        this.props.history.push({
          pathname: BOOKINGS_ROUTE
        })
      })
  };

  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  render() {
    const {moderators, total, perPage, current} = this.state;

    if (!moderators) {
      return null;
    }

    return (
        <Grid container spacing={2}>
          <Grid item
                xs={12}>
            <Grid container
                  spacing={2}
                  style={{alignItems: 'center'}}>
              <Grid item>
                <PageHeader title="Модераторы"
                            size="md"/>
              </Grid>
              <Grid item>
                <FormDrawer title="Добавление модератора"
                            button={props => <AddingButton {...props}
                                                           tooltip="Добавить модератора"
                                                           icon={() => <PlusIcon/>}/>}>
                  <ModeratorCreateForm loadModerators={this.loadModerators}/>
                </FormDrawer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <TableContainer style={{
                maxWidth: '100%',
                overflow: 'auto'
              }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ФИО</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Активность</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {moderators.map(moderator => (
                        <TableRow key={moderator.id} hover>
                          <TableCell>
                            {moderator.update_url
                                ? <Link color="inherit"
                                        component={React.forwardRef((props, ref) => (
                                            <RouterLink
                                                {...props}
                                                ref={ref}
                                                to={`/moderators/${moderator.moderator_id}`}
                                            />
                                        ))}
                                >
                                  <b>{moderator.name}</b>
                                </Link>

                                : <b>{moderator.name}</b>
                            }

                          </TableCell>
                          <TableCell>{moderator.email}</TableCell>
                          <TableCell>{moderator.active ? 'Вкл' : 'Выкл'}</TableCell>
                          <TableCell padding="none" style={{width: 75}}>
                            {moderator.delete_url
                                ? <ModeratorRemove id={moderator.moderator_id}
                                                   loadModerators={this.loadModerators}/>

                                : null
                            }

                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}
                style={{
                  paddingTop: `24px`,
                  display: `flex`,
                  justifyContent: 'center'
                }}>
            <Pagination
                current={current}
                onChange={this.handlePageChange}
                perPage={perPage}
                total={total}
            />
          </Grid>
        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(withRouter(ModeratorsListPage));
