import React, {PureComponent} from 'react';
import {Paper, Table, TableHead, TableBody, TableRow, TableCell, Button, Grid, TableContainer} from '@material-ui/core';
import FormDrawer from './../../components/FormDrawer';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import {withRouter} from 'react-router-dom/';
import {
  fetchBookingRequestCountNew,
  fetchBookingRequests,
  updateBookingRequestStatus
} from '../../actions/bookingReqestsActions';
import BookingRequestFilters from '../../components/BookingRequests/BookingRequestsFilters';
import {Link} from 'react-router-dom';
import RequestStatusSelect from '../../components/BookingRequests/RequestStatusSelect';
import Errors from '../../components/Errors';
import BookingRequestCreateForm from '../../components/BookingRequests/BookingRequestCreateForm';
import PageHeader from '../../components/PageHeader';
import AddingButton from '../../components/AddingButton';
import {PlusIcon} from '../../icons';
import {fetchCountBookingRequest} from '../../actions/drawerActions';
import {setPageTitle} from '../../components/Tools';
import {ERROR, SUCCESS} from '../../components/Constants';

class BookingRequestsListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      perPage: 20,
      total: 0,
      current: 0,
      requests: [],
      errors: new Errors()
    };
  }

  componentDidMount = () => {
    setPageTitle('Заявки');
    this.loadRequests();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadRequests();
    }
  }

  loadRequests = () => {
    const {perPage} = this.state;
    const query = queryString.parse(this.props.location.search);

    fetchBookingRequests({...query, limit: perPage})
        .then(response => {
          this.setState({
            requests: response.data,
            total: response.meta.total || 0,
            current: response.meta.current_page
          });
        });
  };

  handlePageChange = (e, page) => {
    const search = queryString.parse(this.props.location.search);

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  handleStatusChange = (e, id) => {
    const {value} = e.target;

    updateBookingRequestStatus(id, {status: value})
        .then(() => {
          this.loadRequests();
          this.handleUpdateNewRequestCount();
          this.props.openSnackbar(SUCCESS, 'Статус успешно изменен');
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          });
          this.props.openSnackbar(ERROR, errors);
        });
  };

  handleUpdateNewRequestCount = () => {
    fetchBookingRequestCountNew()
        .then(response => {
          this.props.fetchCountBookingRequest(response.total);
        });
  };

  render () {
    const {requests, total, perPage, current} = this.state;

    if ( !requests ) {
      return null;
    }

    return (
        <Grid container spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }
                  style={ {alignItems: 'center'} }>
              <Grid item>
                <PageHeader title="Заявки"
                            size="md"/>
              </Grid>

              <Grid item>
                <FormDrawer title="Добавление заявки"
                            button={ props => <AddingButton { ...props }
                                                            tooltip="Создать заявку"
                                                            icon={ () => <PlusIcon/> }/> }>
                  <BookingRequestCreateForm loadRequests={ this.loadRequests }/>
                </FormDrawer>
              </Grid>
            </Grid>
          </Grid>

          <Grid item
                xs={ 12 }>
            <BookingRequestFilters/>
          </Grid>

          <Grid item xs={ 12 }>
            <Paper>
              <TableContainer style={ {
                maxWidth: '100%',
                overflow: 'auto'
              } }>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Статус</TableCell>
                      <TableCell>Тип</TableCell>
                      <TableCell>Клиент</TableCell>
                      <TableCell>Объект</TableCell>
                      <TableCell>Создано</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { requests.map(request => (
                        <TableRow key={ request.id } hover>
                          <TableCell>#{ request.id }</TableCell>
                          <TableCell>
                            <RequestStatusSelect value={ request.status }
                                                 onChange={ (e) => this.handleStatusChange(e, request.id) }/>
                          </TableCell>
                          <TableCell>
                            { request.text_request_type }
                          </TableCell>
                          <TableCell>{ request.name }</TableCell>
                          <TableCell>{ request?.realty?.name }</TableCell>
                          <TableCell>{ request.created_at }</TableCell>
                          <TableCell>
                            <Link to={ `/booking/requests/${ request.id }` }
                                  style={ {textDecoration: 'none'} }>
                              <Button color="primary"
                                      variant="contained">
                                Детали
                              </Button>
                            </Link>

                          </TableCell>
                        </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={ 12 }
                style={ {
                  paddingTop: `24px`,
                  display: `flex`,
                  justifyContent: 'center'
                } }>
            <Pagination
                current={ current }
                onChange={ this.handlePageChange }
                perPage={ perPage }
                total={ total }
            />
          </Grid>
        </Grid>
    );
  }
}

export default connect(null, {openSnackbar, fetchCountBookingRequest})(withRouter(BookingRequestsListPage));
