import React, {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {fetchExternalResources} from '../../actions/externalResourcesActions';
import PageHeader from '../../components/PageHeader';
import {setPageTitle, uuidv4} from '../../components/Tools';
import ResourcesRow from '../../components/Resources/ResourcesRow';
import Loader from '../../components/Loader';


const ResourcesListPage = () => {
  const location = useLocation();
  const [resources, setResources] = useState(null);
  const [tableTitle, setTableTitles] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageTitle('Ресурсы');
  });

  let loadResources = useCallback(() => {
    const query = queryString.parse(location.search);

    setLoading(true);
    fetchExternalResources(query)
        .then(({resources, titles}) => {
          setTableTitles(titles);
          setResources(resources);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [location.search]);

  useEffect(() => {
    loadResources();
  }, [loadResources]);

  if ( loading ) {
    return <Loader/>;
  }


  return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <PageHeader title="Ресурсы"
                      size="md"/>
        </Grid>

        <Grid item xs={ 12 }>
          <Paper>
            <TableContainer style={ {
              maxWidth: '100%',
              overflow: 'auto'
            } }>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell style={ {borderRight: `1px solid rgba(224, 224, 224, 1)`} }>
                      Объекты ({ resources ? resources?.length - 1 : 0 })
                    </TableCell>

                    { tableTitle && tableTitle.map(title => (
                        <TableCell key={ title }
                                   style={ {
                                     borderRight: `1px solid rgba(224, 224, 224, 1)`,
                                     textAlign: 'center'
                                   } }>
                          { title }
                          <br/>
                          Аренда/Продажа
                        </TableCell>
                    )) }
                  </TableRow>
                </TableHead>
                <TableBody>


                  { resources && resources.map(item => <ResourcesRow key={ uuidv4() }
                                                                     item={ item }/>) }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

      </Grid>
  );
};

export default ResourcesListPage;
