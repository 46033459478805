import React, { useCallback, useEffect, useState } from 'react';
import SelectWithCategoriesField from '../SelectWithCategoriesField';
import { fetchPaymentActions } from '../../actions/selectFieldsActions';

const FilterCategorySelect = ({ ...props }) => {
  const [options, setOptions] = useState([]);

  const getOptions = useCallback(() => {
    fetchPaymentActions()
      .then((options) => {
        setOptions(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <SelectWithCategoriesField subMenuName={ 'nested' }
                               disabledMainOption={ true }
                               mergedNestedValue={ true }
                               name={ 'action_typed' }
                               options={ options }
                               label={ `Категория платежа` }
                               placeholder={ `Выберите категорию` }
                               { ...props }
    />
  );
};


export default FilterCategorySelect;
