import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';


const SelectWithCustomValueField = ({
                                      label,
                                      onChange,
                                      value,
                                      options,
                                      name,
                                      error,
                                      disabled,
                                      fullWidth,
                                      optionValue,
                                      optionName,
                                      ...props
                                    }) => {

  return (
    <React.Fragment>
      <FormControl variant="outlined"
                   fullWidth={ fullWidth }
                   style={ props.style }
                   error={ !!error }>
        <InputLabel id={ label }>
          { label }
        </InputLabel>
        <Select name={ name }
                disabled={ disabled }
                labelId={ label }
                value={ value }
                onChange={ onChange }
                label={ label }>
          { options && options.map((option, index) => <MenuItem key={ index }
                                                                value={ option[optionValue] }>{ option[optionName] }</MenuItem>) }

        </Select>
        { error && <FormHelperText>{ error }</FormHelperText> }
      </FormControl>
    </React.Fragment>
  );
};

export default SelectWithCustomValueField;
