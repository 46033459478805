import React, { Fragment } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import { PAYMENTS_ROUTE } from '../RouterConstants';
import { VER_RU } from '../Constants';
import { getFormatNumber } from '../Tools';


const PaydeskBalance = ({ title, balances, ...props }) => {

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                { balances?.length && balances.map((balance, index) => (
                  <TableCell key={ index }
                             style={ { border: 'none' } }>
                    <div>
                      <small>{ balance.name }</small>
                      <br/>{ getFormatNumber(balance.balance) }

                      { process.env.REACT_APP_LANGUAGE === VER_RU &&
                        <Fragment>
                          &nbsp;&nbsp;
                          { balance.driver &&
                            <Link style={ { textDecoration: 'none', color: theme.palette.primary.main } }
                                  to={ `${ PAYMENTS_ROUTE }/${ balance.driver }` }>Лог</Link>
                          }
                        </Fragment>
                      }

                    </div>
                  </TableCell>
                )) }
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};


export default PaydeskBalance;
