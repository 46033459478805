import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

const ConfirmDialog = ({title, description, negativeAnswer, positiveAnswer, open, handleClose, handleConfirm, ...props}) => {

  return (
      <Dialog open={ open }
              onClose={ handleClose }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
        <DialogTitle>
          { title }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { description }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ handleClose }
                  color="secondary">
            { negativeAnswer }
          </Button>
          <Button onClick={ handleConfirm }
                  color="primary" autoFocus>
            { positiveAnswer }
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default ConfirmDialog;
