import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import PageHeader from '../../components/PageHeader';
import {setPageTitle} from '../../components/Tools';
import {fetchMeetings} from '../../actions/meetingsActions';
import {NEW_MEETING_ROUTE} from '../../components/RouterConstants';
import SearchField from '../../components/SearchField';
import MeetingsTableRow from '../../components/Meetings/MeetingsTableRow';
import MeetingsFilter from '../../components/Meetings/MeetingsFilter';
import MeetingStatusFilterSelectField from '../../components/Meetings/MeetingStatusFilterSelectField';
import {LABEL_WIDTH_SM} from '../../components/Constants';

const MeetingsListPage = ({...props}) => {
  const location = useLocation();
  const history = useHistory();
  const [meetings, setMeetings] = useState([]);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    setPageTitle('Встречи');
  });


  let loadManagers = useCallback(() => {
    const query = queryString.parse(location.search);

    fetchMeetings(query)
        .then(response => {
          setMeetings(response.data);
          setMeta(response.meta);
        })
      .catch((error) => {
        console.error(error);
      })
  }, [location.search]);


  useEffect(() => {
    loadManagers();
  }, [loadManagers]);

  let handlePageChange = (e, page) => {
    const search = queryString.parse(location.search);

    history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };


  if ( !meetings ) {
    return null;
  }

  return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <Grid container
                spacing={ 2 }
                style={ {alignItems: 'center'} }>
            <Grid item>
              <PageHeader title="Встречи"
                          size="md"
                          hasBottom
                          addButton={ NEW_MEETING_ROUTE }
                          sort={ <MeetingStatusFilterSelectField/>}
                          search={ <SearchField searchField="client"
                                                label="Поиск по имени"
                                                labelWidth={ LABEL_WIDTH_SM }
                                                placeholder="Введите имя клиента"/> }
                          filter={ <MeetingsFilter/> }
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={ 12 }>
          <Paper>
            <TableContainer style={ {
              maxWidth: '100%',
              overflow: 'auto'
            } }>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Дата встречи</TableCell>
                    <TableCell>Кто встречает</TableCell>
                    <TableCell>Клиент</TableCell>
                    <TableCell>Тип</TableCell>
                    <TableCell>Объект</TableCell>
                    <TableCell>Статус</TableCell>
                    <TableCell>Комментарий</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { meetings.map(meeting => <MeetingsTableRow key={meeting.id}
                                                              meeting={meeting}/>) }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>


        { meta && meta.last_page > 1 &&
        <Grid item xs={ 12 }
              style={ {
                paddingTop: `24px`,
                display: `flex`,
                justifyContent: 'center'
              } }>
          <Pagination current={ meta.current_page }
                      onChange={ handlePageChange }
                      perPage={ meta.per_page }
                      total={ meta.total }/>
        </Grid>
        }
      </Grid>
  );
};

export default MeetingsListPage;
