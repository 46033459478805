import React, {useEffect, useState} from 'react';
import FilterSelectField from '../FilterFields/FilterSelectField';
import {fetchRealtyGroups} from '../../actions/autocompleteActions';

const RealtyGroupSelectField = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchRealtyGroups()
        .then(groups => {
          setOptions(groups);
        })
      .catch((error) => {
        console.error(error);
      })
  }, []);

  return (
      <FilterSelectField options={ options }
                         fullWidth
                         style={ {minWidth: 220} }
                         label="Група объектов"
                         type="group_id"/>
  );
};

export default RealtyGroupSelectField;
