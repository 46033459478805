import React, {PureComponent} from 'react';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import ClientAutocomplete from '../Autocomplete/ClientAutocomplete';
import {Button, Link, Typography} from '@material-ui/core';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {createBooking, getRealtyContractName} from '../../actions/bookingsActions';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import TextField from '../TextField';
import moment from 'moment';
import PickDateField from '../PickDateField';
import SwitchField from '../SwitchField';
import ManagersAutocomplete from '../Autocomplete/ManagersAutocomplete';
import SelectWithCustomValueField from '../SelectWithCustomValueField';
import {MANAGER, SUCCESS} from '../Constants';
import RealtyFreeAutocomplete from '../Autocomplete/RealtyFreeAutocomplete';
import {flex} from '../JSStylesConstants';
import { getCurrentCurrency, getCurrentRealtyProlongTariff, uuidv4 } from '../Tools';

const initialState = {
  booking: {
    client: '',
    realty: null,
    rental_agreement: '',
    deposit: '',
    deposit_type: 1,
    arrival_date: moment().startOf('day'),
    rent_type: '',
    price_per_unit: '',
    comment: '',
    status: 1,
    departure_date: moment().endOf('day'),
    partial_rent: false,
    rent_area: '',
    with_utility_bills: false,
    parking_pass: false,
    door_keys: '',
    arrival_manager: '',
    responsible_manager: '',
    costInWords: '',
    agreements_number: '',
    deposit_obligatory: false,
    expected_deposit: '',
    expected_deposit_in_words: '',
  },
  errors: new Errors(),
};


class BookingCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidMount() {
    this.setDefaultManager();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.booking.realty?.id !== this.state.booking.realty?.id) {
      this.fetchRealtyContractName();
    }

    // if(prevState.booking.realty?.id !== this.state.booking.realty?.id) {
    //   if(this.state.booking?.realty?.realty_type !== PARKING) {
    //     this.setState(prevState => ({
    //       ...prevState,
    //       booking: {
    //         ...prevState.booking,
    //         deposit_obligatory: true
    //       }
    //     }))
    //   } else {
    //     this.setState(prevState => ({
    //       ...prevState,
    //       booking: {
    //         ...prevState.booking,
    //         deposit_obligatory: false
    //       }
    //     }))
    //   }
    // }
  }

  fetchRealtyContractName = () => {
    const {booking} = this.state;

    if(booking.realty?.id) {
      getRealtyContractName(booking.realty.id)
          .then(contract_name => {
            this.setState(prevState => ({
              booking: {
                ...prevState.booking,
                rental_agreement: contract_name
              }
            }));
          })
    } else {
      this.setState(prevState => ({
        booking: {
          ...prevState.booking,
          rental_agreement: ''
        }
      }));
    }
  }


  handleChangeClient = (e, value) => {
    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        client: value
      }
    }));
  };

  handleChangeRealty = (e, value) => {
    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        realty: value
      }
    }));
  };

  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  handleChangeDate = (key, value) => {

    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        [key]: value
      }
    }));
  };

  handleChangeManager = (value, type) => {

    this.setState(prevState => ({
      booking: {
        ...prevState.booking,
        [type]: value
      }
    }));
  };


  setDefaultManager = () => {
    const {user} = this.props;

    if (user.userable_type === MANAGER) {
      this.setState(prevState => ({
        booking: {
          ...prevState.booking,
          arrival_manager: {id: user.userable_id, name: user.name},
          responsible_manager: {id: user.userable_id, name: user.name},
        }
      }));
    }
  };


  handleSubmit = () => {
    const {booking} = this.state;

    const data = {
      client_id: booking.client?.id,
      realty_id: booking.realty?.id,
      status: 2,
      rent_type: booking.rent_type,
      price_per_unit: booking.price_per_unit,
      arrival_date: moment(booking.arrival_date, 'DD-MM-YYYY').unix(),
      comment: booking.comment,
      departure_date: moment(booking.departure_date, 'DD-MM-YYYY').unix(),
      rental_agreement: booking.rental_agreement,
      partial_rent: booking.partial_rent,
      rent_area: booking.partial_rent ? booking.rent_area : 0,
      parking_pass: booking.parking_pass,
      with_utility_bills: booking.with_utility_bills,
      door_keys: booking.door_keys,
      arrival_manager_id: booking.arrival_manager?.id,
      responsible_manager_id: booking.responsible_manager?.id,
      costInWords: booking.costInWords,
      agreements_number: Number(booking?.agreements_number),
      expected_deposit: booking.expected_deposit,
      expected_deposit_in_words: booking.expected_deposit_in_words,
      deposit_obligatory: booking.deposit_obligatory,
    };

    createBooking(data)
        .then(booking => {
          this.setState(initialState);
          this.props.openSnackbar(SUCCESS, 'Заказ успешно создан');
          window.location.pathname = `/bookings/${booking.id}`;
        })
        .catch(errors => {
          this.setState({
            errors: new Errors(errors)
          });
        });

  };


  render() {
    const {booking, errors} = this.state;

    return (
        <FormContainer>
          <FormRow>
            <FormColumn sm={2}>
              <ClientAutocomplete doubleLabel
                                  error={errors.getError('client_id')}
                                  onChange={this.handleChangeClient}/>
            </FormColumn>
            <FormColumn sm={2}>
              <Link color="inherit"
                    style={{textDecoration: 'none'}}
                    component={React.forwardRef((props, ref) => (
                        <RouterLink
                            {...props}
                            ref={ref}
                            to={{pathname: '/clients', state: {open: true}}}
                        />
                    ))}>
                <Button color="primary"
                        variant="contained">
                  Создать клиента
                </Button>
              </Link>

            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={2}>
              <RealtyFreeAutocomplete error={errors.getError('realty_id')}
                                      value={booking?.realty}
                                      onChange={this.handleChangeRealty}/>
            </FormColumn>

            {booking.realty?.text_status &&
            <FormColumn sm={2}>
              <TextField name="status"
                         disabled
                         value={booking.realty?.text_status}
                         label="Статус"
              />
            </FormColumn>
            }
          </FormRow>


            <FormRow>
              {!!booking?.realty?.prices &&
                <FormColumn sm={2}>
                    {booking.realty.prices.map(price => (
                        <Typography key={uuidv4()}>
                          {price.text_rent_type}&nbsp;{price.price} {getCurrentCurrency()}
                        </Typography>
                    ))}
                </FormColumn>
              }

              <FormColumn sm={2}
                          style={flex}>
                <SwitchField error={errors.getError('with_utility_bills')}
                             name="with_utility_bills"
                             value={booking.with_utility_bills}
                             onChange={this.handleChange}
                             label="С коммунальными платежами"/>
              </FormColumn>
            </FormRow>




          <FormRow>
            <FormColumn sm={2}>
              <TextField error={errors.getError('rental_agreement')}
                         name="rental_agreement"
                         value={booking.rental_agreement}
                         onChange={this.handleChange}
                         label="Договор*"
                         placeholder="Введите номер договора"/>
            </FormColumn>

            <FormColumn sm={2}>
              <SelectWithCustomValueField label="Тип аренды*"
                                          fullWidth
                                          error={errors.getError('rent_type')}
                                          disabled={!booking.realty?.prices?.length}
                                          options={booking.realty?.prices}
                                          optionValue="rent_type"
                                          optionName="text_rent_type"
                                          name="rent_type"
                                          onChange={this.handleChange}
                                          value={booking.rent_type}/>
            </FormColumn>
          </FormRow>

          {(booking.realty?.agreements_number === null) &&
          <FormRow>
            <FormColumn sm={4}
                        md={3}
                        lg={2}>
              <TextField error={errors.getError('agreements_number')}
                         name="agreements_number"
                         type="number"
                         value={booking.agreements_number}
                         onChange={this.handleChange}
                         label="Кол-во договоров, включая текущий заказ"
                         placeholder="Введите кол-во"
              />
            </FormColumn>
          </FormRow>
          }

          <FormRow>
            <FormColumn sm={2}>
              <TextField error={errors.getError('price_per_unit')}
                         name="price_per_unit"
                         type="number"
                         value={booking.price_per_unit}
                         onChange={this.handleChange}
                         label="Тариф*"
                         placeholder={`${getCurrentCurrency()} в ${getCurrentRealtyProlongTariff()}`}
              />
            </FormColumn>

            <FormColumn sm={4}
                        md={3}
                        lg={2}>
              <TextField error={errors.getError('costInWords')}
                         name="costInWords"
                         value={booking.costInWords}
                         onChange={this.handleChange}
                         label="Стоимость аренды прописью*"
                         placeholder="Стоимость аренды прописью"
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={2}>
              <PickDateField name="arrival_date"
                             type="arrival_date"
                             error={errors.getError('arrival_date')}
                             value={booking.arrival_date}
                             defaultDateFrom={moment().startOf('day')}
                             onChange={this.handleChangeDate}
                             label="Дата въезда*"/>

            </FormColumn>
            <FormColumn sm={2}>
              <PickDateField name="departure_date"
                             type="departure_date"
                             error={errors.getError('departure_date')}
                             value={booking.departure_date}
                             defaultDateFrom={moment().endOf('day')}
                             onChange={this.handleChangeDate}
                             label="Дата выезда"/>

            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={2}
                        style={flex}>
              <SwitchField error={errors.getError('deposit_obligatory')}
                           name="deposit_obligatory"
                           value={booking.deposit_obligatory}
                           onChange={this.handleChange}
                           label="Обязательный депозит"/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={4}
                        md={3}
                        lg={2}>
              <TextField error={errors.getError('expected_deposit')}
                         name="expected_deposit"
                         type="number"
                         value={booking.expected_deposit}
                         onChange={this.handleChange}
                         label="Ожидаемый депозит"
                         placeholder="Введите ожидаемый депозит"
              />
            </FormColumn>
            <FormColumn sm={4}
                        md={3}
                        lg={2}>
              <TextField error={errors.getError('expected_deposit_in_words')}
                         name="expected_deposit_in_words"
                         value={booking.expected_deposit_in_words}
                         onChange={this.handleChange}
                         label="Депозит прописью"
                         placeholder="Введите депозит прописью"
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={4}>
              <TextField error={errors.getError('comment')}
                         name="comment"
                         rows={3}
                         multiline
                         value={booking.comment}
                         onChange={this.handleChange}
                         label="Коментарий"
                         placeholder="Введите коментарий"
              />
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={2}>
              <TextField error={errors.getError('door_keys')}
                         name="door_keys"
                         value={booking.door_keys}
                         onChange={this.handleChange}
                         label="Ключи"
                         placeholder="Введите текст"/>
            </FormColumn>
            <FormColumn sm={2}
                        style={flex}>
              <SwitchField error={errors.getError('parking_pass')}
                           name="parking_pass"
                           value={booking.parking_pass}
                           onChange={this.handleChange}
                           label="Пропуск на парковку"/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={2}
                        style={flex}>
              <SwitchField
                  error={errors.getError('partial_rent')}
                  name="partial_rent"
                  value={booking.partial_rent}
                  onChange={this.handleChange}
                  label="Частичная занятость"
              />
            </FormColumn>

            {booking.partial_rent &&
              <FormColumn sm={2}>
                <TextField
                    error={errors.getError('rent_area')}
                    name="rent_area"
                    value={booking.rent_area}
                    onChange={this.handleChange}
                    label="Площадь"
                    placeholder="Введите площадь"
                />
              </FormColumn>
            }

          </FormRow>

          <FormRow>
            <FormColumn sm={2}>
              <ManagersAutocomplete label="Поселил"
                                    onChange={(e, value) => this.handleChangeManager(value, 'arrival_manager')}
                                    value={booking.arrival_manager}
                                    placeholder="Виберите менеджера"/>
            </FormColumn>

            <FormColumn sm={2}>
              <ManagersAutocomplete label="Ответственный"
                                    onChange={(e, value) => this.handleChangeManager(value, 'responsible_manager')}
                                    value={booking.responsible_manager}
                                    placeholder="Виберите менеджера"/>
            </FormColumn>
          </FormRow>


          <FormRow>
            <FormColumn>
              <Button color="primary"
                      variant="contained"
                      onClick={this.handleSubmit}>
                Добавить
              </Button>
            </FormColumn>
          </FormRow>
        </FormContainer>
    );
  }
}

let mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {openSnackbar})(withRouter(BookingCreateForm));
