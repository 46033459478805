import React, {useEffect, useState} from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import {Button} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import Errors from '../Errors';
import TextField from '../TextField';
import CompaniesAutocomplete from '../Autocomplete/CompaniesAutocomplete';
import {createRealtyGroups} from '../../actions/realtyGroupsActions';
import {SUCCESS} from '../Constants';
import {flex} from '../JSStylesConstants';
import SwitchField from '../SwitchField';

const initialState = {
  group: {
    name: '',
    company: '',
    is_default: false,
  },
  errors: new Errors(),
};

const RealtyGroupsCreateForm = ({...props}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if ( state.group.is_default ) {
      setState(prevState => ({
        ...prevState,
        group: {
          ...prevState.group,
          name: `${ prevState.group.company?.name } (По умолчанию)`
        }
      }));
    }
  }, [state.group.is_default]);

  let handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    setState(prevState => ({
      ...prevState,
      group: {
        ...prevState.group,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };


  let handleCompanyChange = (e, value) => {
    setState(prevState => ({
      ...prevState,
      group: {
        ...prevState.group,
        company: value,
      }
    }));
  };

  let handleSubmit = (event) => {
    event.preventDefault();

    const {group} = state;

    createRealtyGroups({
      name: group.name,
      company_id: group?.company?.id,
      is_default: group.is_default

    }).then(() => {
      setState(initialState);
      props.loadRealtyGroups();
      props.openSnackbar(SUCCESS, 'Група объектов добавлена успешно');
      props.location.state = {open: false};
    }).catch(errors => {
      setState(prevState => ({
        ...prevState,
        errors: new Errors(errors)
      }));
    });
  };


  return (
      <React.Fragment>
        <FormContainer>
          <FormRow>
            <FormColumn sm={ 6 }>
              <TextField error={ state.errors.getError('name') }
                         fullWidth
                         name="name"
                         disabled={ state.group.is_default }
                         value={ state.group.name }
                         onChange={ handleChange }
                         label="Название группы"
                         placeholder="Введите название"
              />
            </FormColumn>
            <FormColumn sm={ 6 }>
              <CompaniesAutocomplete value={ state.group.company }
                                     onChange={ handleCompanyChange }
                                     disabled={ state.group.is_default }
                                     error={ state.errors.getError('company_id') }/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn sm={ 6 }
                        style={ flex }>
              <SwitchField error={ state.errors.getError('is_default') }
                           name="is_default"
                           disabled={!state.group.company?.name}
                           value={ state.group.is_default }
                           onChange={ handleChange }
                           label="По умолчанию"/>
            </FormColumn>
          </FormRow>

          <FormRow>
            <FormColumn xs="auto"
                        sm="auto"
                        style={ {marginLeft: 'auto'} }>
              <Button color="primary"
                      variant="contained"
                      onClick={ handleSubmit }>
                Добавить
              </Button>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </React.Fragment>
  );
};

export default connect(null, {openSnackbar})(withRouter(RealtyGroupsCreateForm));
