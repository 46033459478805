import React, {PureComponent} from 'react';
import FormContainer from '../FormContainer';
import {fetchBasicUtilities} from '../../actions/basicUtilitiesActions';
import TariffsFieldsRow from './TariffsFieldsRow';


class TariffsFields extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      utilities: [],
    }
  }


  componentDidMount() {
    this.loadUtilities();
  }

  loadUtilities = () => {
    fetchBasicUtilities()
        .then(utilities => {
          this.setState({
            utilities: utilities
          })
        })
  };

  render() {
    const {handleChange, currentTariffs} = this.props;
    const {utilities} = this.state;

    return (
        <FormContainer>
            {utilities.map((utility) => (
                <TariffsFieldsRow key={utility.id}
                                  utility={utility}
                                  utilities={utilities}
                                  currentTariffs={currentTariffs}
                                  handleChange={handleChange}/>
            ))}
        </FormContainer>
    )
  }
}

export default TariffsFields;
