import React, {PureComponent} from 'react';
import Errors from '../Errors';
import Title from '../Title';
import {
  fetchRealty,
  fetchRealtyDocuments,
  removeRealtyDocument,
  uploadRealtyDocument,
} from '../../actions/realtyActions';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import RealtyDocumentsRow from '../RealtyDocumentsRow';
import {openSnackbar} from '../../actions/snackbarActions';
import {connect} from 'react-redux';
import {translateErrors} from '../Tools';
import AddingButton from '../AddingButton';
import {PlusIcon} from '../../icons';
import UploadFilesButton from '../UploadFiles/UploadFilesButton';
import RealtyOtherDocumentsRow from './RealtyOtherDocumentsRow';
import ProgressBar from '../ProgressBar';
import RealtyDocumentsCertificateNumberForm from './RealtyDocumentsCertificateNumberForm';
import {flexAlignCenter} from '../JSStylesConstants';
import {ERROR, REALTY_DOCUMENTS, SUCCESS} from '../Constants';


class RealtyEditDocumentsForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      realty: {
        id: this.props.id,
        name: '',
        status: '',
        is_deleted: false,
        certificate_number: '',
        otherDocuments: [],
        mainDocuments: [],
      },
      errors: new Errors(),
      percentLoading: null,
    };
  }


  componentDidMount () {
    this.loadDocuments();
    this.setRealtyName();
  }

  setRealtyName = () => {
    const {realty: {id}} = this.state;

    fetchRealty(id)
        .then(realty => {
          this.setState(prevState => ({
            ...prevState,
            realty: {
              ...prevState.realty,
              name: realty.name,
              status: realty.status,
              is_deleted: realty.is_deleted,
              certificate_number: realty.certificate_number
            }
          }));
        });
  };

  loadDocuments = () => {
    const {id} = this.props;

    fetchRealtyDocuments(id)
        .then(documents => {
          this.transformDocuments(documents);
        });

  };


  transformDocuments = (documents) => {
    let otherDocuments = documents.other;
    delete documents.other;

    let mainDocuments = [];

    for ( const i in documents ) {
      mainDocuments.push(...documents[i]);
    }

    this.setState(prevState => ({
      realty: {
        ...prevState.realty,
        otherDocuments: otherDocuments,
        mainDocuments: mainDocuments
      }
    }));

  };

  handlerErrorUpload = (errors) => {
    this.props.openSnackbar(ERROR, translateErrors(errors));
  };

  handleUploadFile = (file, type) => {
    const {realty} = this.state;

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);


        if ( percentCompleted < 100 ) {
          this.setState({percentLoading: percentCompleted});
        } else {
          this.setState({percentLoading: null});
        }
      }
    };

    const data = new FormData();

    if ( type ) {
      data.append('type', type.replace('type_', ''));
    }

    data.append('file', file[0].src.file);

    uploadRealtyDocument(realty.id, data, config)
        .then(() => {
          this.props.openSnackbar(SUCCESS, 'Файл успешно загружен');
          this.loadDocuments();
        })
        .catch(errors => {
          this.props.openSnackbar(ERROR, errors.file);
        });
  };


  handleRemoveDocument = (_, docId) => {
    const {id} = this.props;

    removeRealtyDocument(id, docId)
        .then(() => {
          this.loadDocuments();
          this.props.openSnackbar(SUCCESS, 'Файл успешно удален');
        });
  };


  render () {
    const {realty, percentLoading} = this.state;

    return (
        <Grid container
              spacing={ 2 }>
          <RealtyDocumentsCertificateNumberForm id={ realty.id }
                                                is_deleted={realty.is_deleted}
                                                certificate_number={ realty.certificate_number }/>

          <Grid item xs={ 12 }>
            <Title title="Правоустанавливающие"
                   size="xl"/>
          </Grid>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Paper>
                  <TableContainer style={ {
                    maxWidth: '100%',
                    overflow: 'auto'
                  } }>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Тип</TableCell>
                          <TableCell>Файл</TableCell>
                          <TableCell>Кем загружен</TableCell>
                          <TableCell>Дата загрузки</TableCell>
                          <TableCell>Действия</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { REALTY_DOCUMENTS.map(document => <RealtyDocumentsRow key={ document.type }
                                                                              realty_id={ realty.id }
                                                                              realty_deleted={realty.is_deleted}
                                                                              realty_name={ realty.name }
                                                                              loadDocuments={ this.loadDocuments }
                                                                              handlerErrorUpload={ this.handlerErrorUpload }
                                                                              handleRemoveDocument={ this.handleRemoveDocument }
                                                                              document={ realty.mainDocuments.filter(x => x.type === document.type) }
                                                                              upload={ realty.mainDocuments.map(x => x.type).includes(document.type) }
                                                                              { ...document }/>) }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Grid>


          <Grid item xs={ 12 }
                sm={ 2 }
                style={ flexAlignCenter }>
            <Title title="Другие"
                   style={ {marginBottom: 0, marginRight: 16} }
                   size="xl"/>
            <UploadFilesButton handleChange={ file => this.handleUploadFile(file) }
                               handleError={ this.handlerErrorUpload }
                               component={ props => <AddingButton { ...props }
                                                                  tooltip="Загрузить другие документы"
                                                                  icon={ () => <PlusIcon/> }/> }/>
            { (percentLoading && percentLoading < 100) && <ProgressBar style={ {
              minWidth: '75px',
              marginLeft: 16
            } }
                                                                       percent={ percentLoading }/> }
          </Grid>
          <Grid item xs={ 12 }>
            <Paper>
              <TableContainer style={ {
                maxWidth: '100%',
                overflow: 'auto'
              } }>
                <Table>
                  <TableBody>
                    { realty.otherDocuments && realty.otherDocuments.map(document => (
                        <RealtyOtherDocumentsRow key={ document.id }
                                                 realty_id={ realty.id }
                                                 realty_deleted={realty.is_deleted}
                                                 realty_name={ realty.name }
                                                 handleRemoveDocument={ this.handleRemoveDocument }
                                                 document={ document }/>
                    )) }

                    { !realty.otherDocuments &&
                    <React.Fragment>
                      <TableRow>
                        <TableCell>
                          <div style={ {fontSize: 15, color: '#7D9AA5', textAlign: 'center'} }>
                            Нет документов
                          </div>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
    );
  }

}

export default connect(null, {openSnackbar})(RealtyEditDocumentsForm);
