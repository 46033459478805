import React, {useState} from 'react';
import FormColumn from '../FormColumn';
import TariffField from './TariffField';
import {ArrowRightIcon} from '../../icons';
import {Grid, Paper, Table, TableBody, TableRow, TableCell} from '@material-ui/core';
import FormRow from '../FormRow';
import {flexAlignCenter} from '../JSStylesConstants';


const TariffsFieldsRow = ({utility, utilities, handleChange, currentTariffs, ...props}) => {
  const [show_logs, setShowLogs] = useState(false);

  let handleShowLogs = () => {
    setShowLogs(!show_logs)
  };

  return (
      <>
        <FormRow>
          <FormColumn sm={2}
                      style={flexAlignCenter}>
            <TariffField name={`${utility.name} ${utility.unit_of_measure}`}
                         handleChange={handleChange}
                         field={currentTariffs.filter(x => x.id === utility.id)}
                         id={utility.id}/>
            {currentTariffs?.find(x => x.id === utility.id)?.logs &&
              <ArrowRightIcon style={{marginLeft: 16,
                                      cursor: 'pointer',
                                      transition: '.3s all',
                                      transform: show_logs ? 'rotate(90deg)' : ''}}
                              onClick={handleShowLogs}/>
            }

          </FormColumn>
        </FormRow>

        {(currentTariffs?.find(x => x.id === utility.id)?.logs?.length > 0 && show_logs) &&

        <Grid item xs={6}>
          <Paper>
            <Table>
              <TableBody>
                {currentTariffs.find(x => x.id === utility.id)?.logs.map(log => (
                    <TableRow key={log.id}>
                      <TableCell>{log.created_at}</TableCell>
                      <TableCell>{log.price_per_unit}</TableCell>
                      <TableCell>{utility.name}</TableCell>
                      <TableCell>{log?.manager?.name}</TableCell>
                    </TableRow>
                ))}

              </TableBody>
            </Table>
          </Paper>
        </Grid>
        }
      </>

  )
};

export default TariffsFieldsRow;
