import React from 'react';
import FormDrawerWithPayload from '../FormDrawerWithPayload/FormDrawerWithPayload';
import {useDispatch, useSelector} from 'react-redux';
import {closeContractInfoUpdateModal} from '../../actions/formDrawerActions';
import BookingUpdateContractInfoForm from './BookingUpdateContractInfoModalForm';


const BookingUpdateContractInfoModal = ({loadBooking, client,  ...props}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.formDrawer.contractInfo.isOpen);

  const closeModal = () => {
    dispatch(closeContractInfoUpdateModal())
  }

  return (
      <FormDrawerWithPayload isOpen={isOpen}
                             closeModal={closeModal}>
          <BookingUpdateContractInfoForm closeModal={closeModal}
                                         client={client}
                                         loadBooking={loadBooking}/>
      </FormDrawerWithPayload>
  );
};

export default BookingUpdateContractInfoModal;
