import React, {useEffect, useState} from 'react';
import Title from '../Title';
import {Grid} from '@material-ui/core';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import {createCertificateNumber} from '../../actions/realtyActions';
import {useDispatch} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import ButtonWithProgress from '../ButtonWithProgress';
import Errors from '../Errors';
import {SUCCESS} from '../Constants';

const RealtyDocumentsCertificateNumberForm = ({certificate_number, id, is_deleted, ...props}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(new Errors({}));
  const [certificate, setCertificate] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCertificate(certificate_number);
  }, [certificate_number]);

  const handleChange = (e) => {
    const {value} = e.target;
    setCertificate(value);
  };

  const handleSubmit = () => {
    setLoading(true);

    createCertificateNumber(id, {certificate_number: certificate})
        .then(() => {
          setLoading(false);
          dispatch(openSnackbar(SUCCESS, 'Кадастровый номер успешно сохранен'));
          setErrors(new Errors({}));
        })
        .catch(error => {
          setLoading(false);
          setErrors(new Errors(error));
        });
  };

  return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title title="Кадастровый номер"
                   size="xl"/>
          </Grid>

          <Grid item xs={12}>
            <FormContainer>
              <FormRow>
                <FormColumn sm={2}>
                  <TextField name="certificate_number"
                             error={errors.getError('certificate_number')}
                             value={certificate || ''}
                             onChange={handleChange}
                             label="Кадастровый номер"
                             placeholder="Введите номер"
                  />
                </FormColumn>
                <FormColumn sm={2}
                            style={{
                              display: 'flex',
                              alignItems: 'center'
                            }}>

                  <ButtonWithProgress loading={loading}
                                      disabled={ is_deleted }
                                      onClick={handleSubmit}
                                      label="Сохранить"/>
                </FormColumn>
              </FormRow>
            </FormContainer>

          </Grid>
        </Grid>
      </Grid>
  );
};

export default RealtyDocumentsCertificateNumberForm;
