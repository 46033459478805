import React, {useEffect} from 'react';
import LoginForm from '../../components/LoginForm';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {setPageTitle} from '../../components/Tools';

const LoginPage = ({...props}) => {
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    setPageTitle('Логин')
  })

  if (auth.isAuthenticated) {
    return <Redirect to="/"/>
  }

  return (
      <React.Fragment>
        <LoginForm/>
      </React.Fragment>
  )
};


export default LoginPage;
