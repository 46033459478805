import React from 'react';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';

const FilterCheckboxField = ({name, label, value, ...props}) => {

  let handleSearch = (e) => {
    const {name, checked} = e.target;
    const {page, ...params} = queryString.parse(props.location.search);

    const newQuery = {
      ...params,
      page: page ? 1 : undefined,
      [name]: checked ? 1 : undefined
    };

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newQuery)
    });
  };

  let getParams = () => {
    const params = queryString.parse(props.location.search);

    return !!params[name];
  };


  return (
      <FormControlLabel
          control={ <Checkbox onChange={ handleSearch }
                              color="primary"
                              checked={ getParams() }
                              name={ name }/> }
          label={ label }
      />
  );
};

export default withRouter(FilterCheckboxField);
