import React, {memo} from 'react';
import {Alert, AlertTitle} from '@material-ui/lab';
import {makeStyles} from '@material-ui/core/styles';
import {reformatPhoneNumber} from '../Tools';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    marginBottom: 0
  },
}));

const SuggestPasswordNotification = ({phone, onClose, onClick, ...props}) => {
  const classes = useStyles();

  return (
      <Alert severity="info"
             onClose={onClose}>
        <AlertTitle onClick={() => onClick(reformatPhoneNumber(phone))}
                    className={classes.root}>
          Использовать сгенерированный пароль<br/>
          {reformatPhoneNumber(phone)}
        </AlertTitle>
      </Alert>
  )
}

export default memo(SuggestPasswordNotification);
