import React, {memo, useEffect, useState} from 'react';
import Autocomplete from './Autocomplete';
import {fetchRealtyFreeAutocomplete} from '../../actions/autocompleteActions';

const RealtyFreeAutocomplete = ({value, ...props}) => {
  const [realty, setRealty] = useState([]);

  useEffect(() => {
    fetchFreeAutocomplete()
  }, [])

  let fetchFreeAutocomplete = () => {
    fetchRealtyFreeAutocomplete()
        .then(countries => {
          setRealty(countries)
        })
  };

  return (
      <Autocomplete {...props}
                    value={(typeof value === 'string' ? realty.find(country => country.id === value) : value) || null}
                    options={realty}
                    label={`Объект`}
                    placeholder={`Выберите объект`}
      />
  );
};

export default memo(RealtyFreeAutocomplete);
