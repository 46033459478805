import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import TextField from '../TextField';
import SwitchField from '../SwitchField';
import {Divider} from '@material-ui/core';
import Errors from '../Errors';
import {openSnackbar} from '../../actions/snackbarActions';
import {fetchManager, updateManager} from '../../actions/managersActions';
import PhoneInput from '../PhoneInput';
import PasswordField from '../PasswordField';
import ButtonWithProgress from '../ButtonWithProgress';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';
import {reformatPhoneNumber} from '../Tools';

class ManagerEditForm extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      manager: {
        id: this.props.id,
        name: '',
        email: '',
        password: '',
        active: false,
        company: '',
        chief: false,
        phone: ''
      },
      errors: new Errors(),
      showPassword: false,
      loading: false,
    };
  }

  componentDidMount () {
    const {manager: {id}} = this.state;

    fetchManager(id).then(manager => {
      this.setManager(manager);
    });
  }

  setManager = ({manager_id, name, email, active, company, chief, phone}) => {
    this.setState(prevState => ({
      manager: {
        ...prevState.manager,
        id: manager_id,
        name: name,
        email: email,
        active: active,
        company: company.name,
        chief: chief,
        phone: phone
      },
    }));
  };

  handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    this.setState(prevState => ({
      manager: {
        ...prevState.manager,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };


  handlePassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({loading: true});

    const {id, ...manager} = this.state.manager;

    updateManager(id, {
      name: manager.name,
      email: manager.email,
      password: manager.password,
      active: manager.active,
      chief: manager.chief,
      phone: reformatPhoneNumber(manager.phone)
    })
        .then(manager => {
          this.setManager(manager);
          this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
          this.setState({
            errors: new Errors({}),
            loading: false
          });
        })
        .catch(errors =>
            this.setState({
              loading: false,
              errors: new Errors(errors)
            })
        );
  };

  render () {
    const {manager, errors, showPassword, loading} = this.state;

    return (
        <div style={ {paddingLeft: 24, paddingRight: 24} }>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }>
                <TextField error={ errors.getError('name') }
                           name="name"
                           type="text"
                           value={ manager.name }
                           onChange={ this.handleChange }
                           label="Имя"
                           placeholder="Введите имя"
                />
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }>
                <TextField error={ errors.getError('email') }
                           name="email"
                           type="email"
                           value={ manager.email }
                           onChange={ this.handleChange }
                           label="Электронная почта"
                           placeholder="Введите электронную почту"
                />
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }>
                <PasswordField error={ errors.getError('password') }
                               name="password"
                               type={ showPassword ? 'text' : 'password' }
                               value={ manager.password }
                               onChange={ this.handleChange }
                               label="Пароль"
                               placeholder="Введите пароль"
                               handlePassword={ this.handlePassword }
                               showPassword={ showPassword }
                />
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }>
                <TextField error={ errors.getError('company') }
                           name="company"
                           type="text"
                           disabled
                           value={ manager.company }
                           label="Компания"
                           placeholder="Выберите компанию"/>
              </FormColumn>
              <FormColumn sm={ 4 }
                          md={ 3 }>
                <PhoneInput error={ errors.getError('phone') }
                            name="phone"
                            type="phone"
                            onChange={ this.handleChange }
                            value={ manager.phone }
                            label="Телефон"
                            placeholder="Введите телефон"/>
              </FormColumn>

              <FormColumn sm={ 4 }
                          md={ 3 }
                          style={ flex }>
                <SwitchField error={ errors.getError('chief') }
                             name="chief"
                             value={ manager.chief }
                             onChange={ this.handleChange }
                             label="Главный менеджер"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 4 }
                          md={ 3 }
                          style={ flex }>
                <SwitchField error={ errors.getError('active') }
                             name="active"
                             value={ manager.active }
                             onChange={ this.handleChange }
                             label="Активность"/>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn xs={ 12 }
                          sm={ 12 }
                          style={ {textAlign: 'right'} }>
                <Divider style={ {marginBottom: 16} }/>

                <ButtonWithProgress onClick={ this.handleSubmit }
                                    loading={ loading }
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </div>
    );
  }
}

export default connect(null, {openSnackbar})(ManagerEditForm);
