import React from 'react';
import { MenuItem, Select, InputLabel, FormHelperText, FormControl } from '@material-ui/core';


const SelectField = ({ label, onChange, value, options, name, error, disabled, fullWidth, ...props }) => {

  return (
    <React.Fragment>
      <FormControl variant="outlined"
                   fullWidth={ fullWidth }
                   style={ props.style }
                   error={ !!error }>
        <InputLabel id={ label }>
          { label }
        </InputLabel>
        <Select name={ name }
                disabled={ disabled }
                labelId={ label }
                value={ value }
                onChange={ onChange }
                label={ label }>
          { options && options.map((option, index) => <MenuItem key={ index }
                                                                value={ option.value || option.id }>{ option.name }</MenuItem>) }

        </Select>
        { error && <FormHelperText>{ error }</FormHelperText> }
      </FormControl>
    </React.Fragment>
  );
};

export default SelectField;
