import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: 80,
    background: '#f5f6f8',
    height: '100%'
  }
}));

const Content = (props) => {
  const classes = useStyles();

  return (
      <div
          {...props}
          className={classes.content}
      />
  );
};

export default Content;
