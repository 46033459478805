import React, {memo, useCallback, useEffect, useState} from 'react';
import Errors from '../Errors';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import {Divider} from '@material-ui/core';
import TextField from '../TextField';
import CompaniesAutocomplete from '../Autocomplete/CompaniesAutocomplete';
import {getRealtyGroup, updateRealtyGroup} from '../../actions/realtyGroupsActions';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import SwitchField from '../SwitchField';
import ButtonWithProgress from '../ButtonWithProgress';
import {flex} from '../JSStylesConstants';
import {SUCCESS} from '../Constants';


const initialState = {
  group: {
    name: '',
    company: '',
    contract_code: '',
    is_default: false,
    first_letter_from_type: ''
  }
};

const RealtyGroupsEditForm = ({id, ...props}) => {
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState(new Errors());
  const [loading, setLoading] = useState(false);

  let fetchRealtyGroup = useCallback(() => {

    getRealtyGroup(id)
        .then(group => {
          setState(prev => ({
            ...prev,
            group: {
              name: group.name,
              company: group.company,
              contract_code: group.contract_code,
              first_letter_from_type: group.first_letter_from_type,
              is_default: group.is_default
            }
          }));
        })
        .catch(errors => {
          setErrors(new Errors(errors));
        });
  }, [id]);

  useEffect(() => {
    fetchRealtyGroup();
  }, [fetchRealtyGroup]);


  let handleChange = (event) => {
    const {name, value, type, checked} = event.target;

    setState(prevState => ({
      ...prevState,
      group: {
        ...prevState.group,
        [name]: type === 'checkbox' ? checked : value,
      }
    }));
  };

  let handleCompanyChange = (e, value) => {
    setState(prevState => ({
      ...prevState,
      group: {
        ...prevState.group,
        company: value,
      }
    }));
  };

  let handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const {group} = state;

    const data = {
      name: group.name,
      company_id: group?.company?.id,
      first_letter_from_type: group.first_letter_from_type,
      contract_code: group.contract_code,
      is_default: group.is_default
    };

    updateRealtyGroup(id, data)
        .then(updatedGroup => {
          setState(prev => ({
            ...prev,
            group: {
              name: updatedGroup.name,
              company: updatedGroup.company,
              first_letter_from_type: updatedGroup.first_letter_from_type,
              contract_code: updatedGroup.contract_code,
              is_default: updatedGroup.is_default,

            }
          }));
          setLoading(false);
          setErrors(new Errors({}));
          props.openSnackbar(SUCCESS, 'Група объектов обновлена успешно');
        })
        .catch(errors => {
          setLoading(false);
          setErrors(new Errors(errors));
        });
  };


  return (
      <div style={{paddingLeft: 24, paddingRight: 24}}>
        <FormContainer>
          <FormRow>
            <FormColumn sm={3}>
              <TextField error={errors.getError('name')}
                         fullWidth
                         name="name"
                         value={state.group.name}
                         onChange={handleChange}
                         label="Название группы"
                         placeholder="Введите название"/>
            </FormColumn>
            <FormColumn sm={3}>
              <CompaniesAutocomplete value={state.group.company}
                                     onChange={handleCompanyChange}
                                     error={errors.getError('company_id')}/>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn sm={3}>
              <TextField error={errors.getError('contract_code')}
                         fullWidth
                         name="contract_code"
                         value={state.group.contract_code}
                         onChange={handleChange}
                         label="Код договора"
                         placeholder="Введите код договора"/>
            </FormColumn>
            <FormColumn sm={3}
                        style={flex}>
              <SwitchField error={errors.getError('first_letter_from_type')}
                           name="first_letter_from_type"
                           value={state.group.first_letter_from_type}
                           onChange={handleChange}
                           label="Брать первую букву с типа объекта"/>
            </FormColumn>

          </FormRow>

          <FormRow>
            <FormColumn sm={6}
                        style={flex}>
              <SwitchField error={errors.getError('is_default')}
                           name="is_default"
                           disabled
                           value={state.group.is_default}
                           label="По умолчанию"/>
            </FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn xs={12}
                        sm={12}
                        style={{textAlign: 'right'}}>
              <Divider style={{marginBottom: 16}}/>

              <ButtonWithProgress onClick={handleSubmit}
                                  loading={loading}
                                  label="Сохранить"/>
            </FormColumn>
          </FormRow>
        </FormContainer>
      </div>
  );
};

export default connect(null, {openSnackbar})(memo(RealtyGroupsEditForm));
