import React from 'react';
import {Grid} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import SearchField from '../SearchField';
import ClearFiltersButton from '../ClearFiltersButton';
import {flexAlignCenter} from '../JSStylesConstants';
import FilterCheckboxField from '../FilterCheckboxField';
import {LABEL_WIDTH_SM} from '../Constants';


const ClientSupportFilter = ({...props}) => {

  return (
      <Grid container
            spacing={2}>
        <Grid item>
          <SearchField searchField="realty"
                       label="Поиск по объекту"
                       labelWidth={LABEL_WIDTH_SM}
                       placeholder="Имя объекта"/>
        </Grid>
        <Grid item>
          <SearchField searchField="client"
                       label="Клиент"
                       labelWidth={50}
                       placeholder="Имя/телефон клиента"/>
        </Grid>
        <Grid item>
          <FilterCheckboxField name="active"
                               label="Активные"/>
        </Grid>
        <Grid item
              style={flexAlignCenter}>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  )
};

export default withRouter(ClientSupportFilter);
