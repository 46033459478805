import React from 'react';
import './StatisticOutcome.scss';
import Number from '../Number';
import Title from '../Title';

const StatisticOutcome = ({debt, staff_outcome, realty_outcome, outcome, title, orange, green, ...props}) => {
  return (
      <React.Fragment>
        <Title title={title}/>
        <div className="statistic-outcome__wrap">
          {!!debt &&
            <div className="statistic-outcome__piece">
              <Number size={'sm'}
                      value={debt}
                      icon/>
              <Title size={'sm'}
                     title={'Долги'}/>
            </div>
          }

          {!!realty_outcome &&
            <div className="statistic-outcome__piece">
              <Number size={'sm'}
                      value={realty_outcome}
                      icon/>
              <Title size={'sm'}
                     title={'Затраты на обьекты'}/>
            </div>
          }

          {!!staff_outcome &&
            <div className="statistic-outcome__piece">
              {/*{yearly_profit > 0 &&*/}
              {/*  <div className="statistic-outcome__sum">*/}
              {/*    est. {yearly_profit} %*/}
              {/*  </div>*/}
              {/*}*/}

                <Number size={'sm'}
                        orange={orange}
                        value={staff_outcome}
                        icon/>
                <Title size={'sm'}
                  title={'Затраты на стафф'}/>

            </div>
          }
        </div>
      </React.Fragment>
  )
};

export default StatisticOutcome;
