import React, {PureComponent} from 'react';
import PageHeader from '../../components/PageHeader';
import queryString from 'query-string';
import {Grid, Table, TableBody, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import CheckboxField from '../../components/CheckboxField';
import AssignmentsFilters from '../../components/Utilities/PaymentOrders/PaymentOrdersFilters';
import {createPaymentOrders, fetchPaymentOrders} from '../../actions/utilitiesActions';
import {connect} from 'react-redux';
import {openSnackbar} from '../../actions/snackbarActions';
import TextField from '../../components/TextField';
import Errors from '../../components/Errors';
import {setPageTitle, transformPaymentOrders} from '../../components/Tools';
import ButtonWithProgress from '../../components/ButtonWithProgress';
import {ERROR_500_MESSAGE, SUCCESS} from '../../components/Constants';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import Loader from '../../components/Loader';


class PaymentOrdersListPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      orders: null,
      selected_orders: [],
      totalAmount: '',
      loading: false,
      ordersLoading: false,
      errors: new Errors()
    };
  }

  componentDidMount = () => {
    setPageTitle('Платежные поручения');
    this.loadPaymentOrders();
  };

  componentDidUpdate (prevProps, prevState, snapshot) {
    if ( prevProps.location.key !== this.props.location.key ) {
      this.loadPaymentOrders();
      this.setState({
        selected_orders: []
      });
    }

    if ( prevState.selected_orders !== this.state.selected_orders ) {
      this.handleTotalAmount();
    }
  }

  loadPaymentOrders = () => {
    const query = queryString.parse(this.props.location.search);

    this.setState({
      ordersLoading: true
    })

    fetchPaymentOrders(query)
        .then(orders => {
          this.setState({
            orders: orders,
            ordersLoading: false
          });
        });
  };

  handleSelectedAll = () => {
    const {selected_orders, orders} = this.state;

    if ( selected_orders.length === orders.length ) {
      this.setState({
        selected_orders: []
      });
      return;
    }

    if ( (selected_orders.length !== orders.length) && selected_orders.length > 0 ) {
      this.setState(prevState => ({
        ...prevState,
        selected_orders: transformPaymentOrders(prevState.orders)
      }));
      return;
    } else {
      this.setState(prevState => ({
        ...prevState,
        selected_orders: transformPaymentOrders(prevState.orders)
      }));
    }

  };


  handleChange = (e, id) => {
    const {value} = e.target;
    if ( !value ) return;

    this.setState(prevState => ({
      selected_orders: value.length > 0
          ? [...prevState.selected_orders.filter(x => x.account_id !== id), {account_id: id, amount: value}]
          : [...prevState.selected_orders.filter(x => x.account_id !== id)]
    }));
  };

  handleCheckboxChange = (e, id) => {
    const {value, checked} = e.target;

    this.setState(prevState => ({
      selected_orders: checked
          ? [...prevState.selected_orders.filter(x => x.account_id !== id), {account_id: id, amount: value}]
          : [...prevState.selected_orders.filter(x => x.account_id !== id)]
    }));
  };


  handleTotalAmount = () => {
    const {selected_orders} = this.state;
    let total = 0;

    for ( let i = 0; i < selected_orders.length; i++ ) {
      if ( selected_orders[i].amount ) {
        total = total + parseFloat(selected_orders[i].amount);
      }
    }

    this.setState(prevState => ({
      ...prevState,
      totalAmount: total.toFixed(2)
    }));
  };

  handleFieldValue = (selected_orders, order) => {
    if ( selected_orders.find(x => x.account_id === order.id) ) {
      return selected_orders.find(x => x.account_id === order.id)?.amount;
    }

    if ( order.debt ) {
      return order.debt;
    }

    if ( order.debt < 0 ) {
      return 0;
    }

    return '';
  };

  handleCreateAssignment = () => {
    const {selected_orders} = this.state;
    this.setState({
      loading: true
    })

    const data = {
      data: selected_orders.filter(o => o.amount > 0)
    };

    createPaymentOrders(data)
        .then(response => {
          this.props.openSnackbar(SUCCESS, response);
          this.loadPaymentOrders();
          this.setState({
            selected_orders: [],
            loading: false,
            errors: new Errors({})
          });
        })
        .catch(errors => {
          this.setState({
            loading: false,
            errors: new Errors(errors)
          });
        });
  };


  render () {
    const {orders, selected_orders, errors, totalAmount, loading, ordersLoading} = this.state;

    return (
        <Grid container
              spacing={ 2 }>
          <Grid item
                xs={ 12 }>
            <PageHeader title="Платежные поручения"
                        size="md"/>
          </Grid>
          <Grid item
                xs={ 12 }>
            <Grid container
                  spacing={ 2 }
                  style={ {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  } }>
              <Grid item>
                <AssignmentsFilters/>
              </Grid>
              <Grid item>
                <ButtonWithProgress label="Сформировать черновики"
                                    loading={ loading }
                                    onClick={ this.handleCreateAssignment }
                                    disabled={ !selected_orders.length }/>
              </Grid>
            </Grid>
          </Grid>

          {ordersLoading &&
            <Grid item xs={12}>
              <Loader/>
            </Grid>
         }

          {!!orders?.length &&
            <Grid item xs={ 12 }>
              <Paper>
                <TableContainer style={ {
                  maxWidth: '100%',
                  overflow: 'auto'
                } }>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Объект</TableCell>
                        <TableCell>УК</TableCell>
                        <TableCell>Задолженность</TableCell>
                        <TableCell>
                          <CheckboxField checked={ selected_orders.length === orders.length }
                                         handleChange={ this.handleSelectedAll }/>
                        </TableCell>
                        <TableCell>
                          <TextField style={ {maxWidth: 150} }
                                     label="Оплатить"
                                     type="number"
                                     disabled
                                     placeholder="Введите суму задолжености"
                                     value={ totalAmount ?? '' }/>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { orders && orders.map((order, index) => (
                          <TableRow key={ order.id }>
                            <TableCell>{ order.realty.name }</TableCell>
                            <TableCell>{ order.management_company.name }</TableCell>
                            <TableCell>{ order.debt }</TableCell>
                            <TableCell>
                              <CheckboxField checked={ !!selected_orders.find(x => x.account_id === order.id) }
                                             value={ order.debt ?? '' }
                                             handleChange={ (e) => this.handleCheckboxChange(e, order.id) }/>
                            </TableCell>
                            <TableCell>
                              <TextField style={ {maxWidth: 150} }
                                         disabled={ !selected_orders.find(x => x.account_id === order.id) }
                                         error={ errors.getError(`data.${ index }.amount`) }
                                         onChange={ (e) => this.handleChange(e, order.id) }
                                         label="Задолженность"
                                         type="number"
                                         placeholder="Введите суму задолжености"
                                         value={ this.handleFieldValue(selected_orders, order) }
                                  // value={ selected_orders.find(x => x.account_id === order.id)?.amount ?? order.debt > 0 ? order.debt : 0 ?? '' }
                              />
                            </TableCell>
                          </TableRow>
                      )) }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          }


          {(!orders?.length && !ordersLoading) && <ErrorLoadingDataMessage title={ERROR_500_MESSAGE}/>}


        </Grid>
    );
  }
}

export default connect(null, {openSnackbar})(PaymentOrdersListPage);
