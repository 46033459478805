import React, {memo, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import RealtyGroupsEditForm from '../../components/RealtyGroups/RealtyGroupsEditForm';
import {setPageTitle} from '../../components/Tools';


const RealtyGroupsEditPage = ({match: {params}, ...props}) => {
  const [id, setId] = useState(null)

  useEffect(() => {
    setPageTitle('Редактирование групы объектов');
    setId(params.id)
  }, [params])

  if (!id) {
    return null
  }

  return (
      <React.Fragment>
        <Card>
          <CardHeader title="Редактирование групы объектов"
                      subheader={`ID: ${id}`}/>

          <Divider/>

          <div style={{paddingTop: 24, paddingBottom: 24}}>
            <RealtyGroupsEditForm id={id}/>
          </div>
        </Card>
      </React.Fragment>
  )
}

export default memo(RealtyGroupsEditPage);

