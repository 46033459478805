import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({percent, style, ...props}) => {
  return (
      <div className="meter animate orange" style={style}>
        <span style={{width: `${percent}%`}}><span></span></span>
      </div>
  )
};

export default ProgressBar;
