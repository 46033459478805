import React from 'react';
import Title from '../Title';
import {Switch, withStyles, FormControlLabel} from '@material-ui/core';

const StyledFormControlLabel = withStyles({
  root: {},
  label: {
    fontSize: 12
  },
})(FormControlLabel);

const RealtyTariffPart = ({ sellable, prices, ...props}) => {
  return (
      <div className="tariff">
        <Title size="md"
               title="Тарифы"/>
         <div className="tariff__wrapper">
           <div className="tariff__fields">
             <StyledFormControlLabel
                 control={
                   <Switch
                       checked={sellable}
                       name="sale"
                       color="primary"
                   />
                 }
                 labelPlacement="end"
                 label="Возможность продажи"
             />
           </div>
           <div className="tariff__prices">
             {prices && prices.map((price, index) => (
                 <div key={index} className="tariff__term">
                   <Title size="sm"
                          title={price.text_rent_type}/>
                     <span>
                       {price.price}
                    </span>
                 </div>
             ))}
           </div>
         </div>
      </div>
  )
};

export default RealtyTariffPart;
