import React from 'react';
import {Grid} from '@material-ui/core';
import ClearFiltersButton from '../../ClearFiltersButton';
import SearchField from '../../SearchField';
import ManagementCompaniesSelect from '../../ManagementCompany/ManagementCompaniesSelect';
import queryString from 'query-string';
import {withRouter} from 'react-router-dom';
import {LABEL_WIDTH_SM} from '../../Constants';

const PaymentActsFilter = ({...props}) => {
  const query = queryString.parse(props.location.search);

  let handleChangeSelect = (e) => {
    const {name, value} = e.target;

    const nextQuery = {
      ...query,
      [name]: value
    };

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(nextQuery)
    })
  };

  let getParam = (key) => {
    const search = queryString.parse(props.location.search);

    return search[key] || ''
  };

  return (
      <Grid container spacing={2}>
        <Grid item>
          <ManagementCompaniesSelect onChange={handleChangeSelect}
                                     name="management_company_id"
                                     style={{minWidth: 220}}
                                     value={getParam('management_company_id')}/>
        </Grid>
        <Grid item>
          <SearchField searchField="realty"
                       label="Поиск по объекту"
                       labelWidth={LABEL_WIDTH_SM}
                       placeholder="Имя объекта"/>
        </Grid>
        <Grid item
              style={{display: 'flex', alignItems: 'center'}}>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  );
};

export default withRouter(PaymentActsFilter);
