import React, {PureComponent} from 'react';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import {connect} from 'react-redux';
import {withStyles, Button, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import {openSnackbar} from '../../actions/snackbarActions';
import Errors from '../Errors';
import {cancelPayment} from '../../actions/paydeskActions';
import {ERROR, SUCCESS} from '../Constants';


const styles = theme => ({
  title: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 16,
  },
});

const initialState = {
  errors: new Errors()
};

class PaydeskPaymetCancelForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = initialState;
  }


  handleRemove = () => {
    const {payment, closeModal} = this.props;

    cancelPayment(payment.id)
        .then(res => {
          this.props.openSnackbar(SUCCESS, 'Транзакция отменена успешно');
          this.setState(initialState);
          closeModal();
          this.props.loadPayments();
        })
        .catch(errors => {
          this.props.openSnackbar(ERROR, errors);
        })

  };


  render() {
    const {classes, payment, closeModal} = this.props;

    return (
        <React.Fragment>
          <div className={classes.title}>
            Отменить транзакцию
          </div>
          <div style={{
            padding: 16,
            textAlign: 'center',
            backgroundColor: `rgba(255, 157, 58, 0.25)`
          }}>
            Вы действительно хотите отменить транзакцию <strong>#{payment.id}</strong> ?
          </div>
          <FormContainer>
            <FormRow>
              <FormColumn sm={12}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell padding="none">Операция</TableCell>
                      <TableCell>{payment.action}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding="none">Описание</TableCell>
                      <TableCell>{payment.comment}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell padding="none">Сумма</TableCell>
                      <TableCell>{payment.amount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={6}>
                <Button onClick={this.handleRemove}
                        color="primary" autoFocus>
                  Да
                </Button>
              </FormColumn>
              <FormColumn sm={6}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}>
                <Button onClick={closeModal}
                        style={{marginLeft: 'auto'}}
                        color="secondary">
                  Нет
                </Button>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </React.Fragment>

    )
  }
}

let mapStateToProps = (state) => ({
  payment: state.formDrawer.paydeskCancel.payload
});

export default connect(mapStateToProps, {openSnackbar})(withStyles(styles)(PaydeskPaymetCancelForm));
