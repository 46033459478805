import axios from 'axios';
import queryString from 'query-string';

export const fetchBookingRequests = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/requests?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};


export const fetchBookingRequest = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/requests/${id}`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};


export const createBookingRequest = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/booking/requests`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};

export const updateBookingRequest = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/booking/requests/${id}`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};

export const updateBookingRequestStatus = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/booking/requests/${id}/status`, data)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};

export const fetchBookingRequestCountNew = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/booking/requests/total/new`)
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  })
};


