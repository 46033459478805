import axios from 'axios';
import queryString from 'query-string';
import {BLOB_FILE} from '../components/Tools';

export const fetchAllRealty = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchRealty = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createRealty = (data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty`,  data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })

  });
};


export const updateRealty = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtySeo = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${id}/seo`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtyTariffs = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${id}/tariffs`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response.data?.errors);
        })
  });
};

export const archiveRealty = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error.response.data?.errors);
        })
  });
};

//realty images methods

export const uploadRealtyImage = (id, data, config) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/${id}/images`,  data, config)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const removeRealtyImage = (id, imageId) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/${id}/images/${imageId}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtyImagesPriority = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/${id}/images/priority`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

//realty documents methods

export const fetchRealtyDocuments = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${id}/documents`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const uploadRealtyDocument = (id, data, config) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/${id}/documents`,  data, config)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const removeRealtyDocument = (id, docId) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/${id}/documents/${docId}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const downloadRealtyDocument = (id, docId, config) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${id}/documents/${docId}`, {...config, responseType: BLOB_FILE})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const createCertificateNumber = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${id}/certificate`, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


//realty amenities

export const fetchRealtyAmenities = (type) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${type}/amenities`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

//realty management company & counters

export const fetchRealtyManagementCompany = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${id}/management_company_accounts`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createRealtyManagementCompany = (id ,data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/${id}/management_company_accounts`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtyManagementCompany = (id, managementCompanyId, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${id}/management_company_accounts/${managementCompanyId}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const removeRealtyManagementCompany = (id, managementCompanyId) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/${id}/management_company_accounts/${managementCompanyId}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


// realty management company counters

export const createRealtyManagementCompanyCounter = (account, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/management_company_accounts/${account}/realty_utilities`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtyManagementCompanyCounter = (account_id, utility_id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/management_company_accounts/${account_id}/realty_utilities/${utility_id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const removeRealtyManagementCompanyCounter = (account_id, utility_id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/management_company_accounts/${account_id}/realty_utilities/${utility_id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const archiveRealtyManagementCompanyCounter = (account_id, utility_id) => {
  return new Promise((resolve, reject) => {
    axios.post(`/management_company_accounts/${account_id}/realty_utilities/${utility_id}/archive`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const archiveRealtyManagementCompany = (managementCompanyId) => {
  return new Promise((resolve, reject) => {
    axios.post(`/management_company_accounts/${managementCompanyId}/archive`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

//realty inventory rooms

export const getRealtyRooms = (realty_id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${realty_id}/rooms`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createRealtyRoom = (realty_id, data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/${realty_id}/rooms`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtyRoom = (realty_id, room_id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${realty_id}/rooms/${room_id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const deleteRealtyRoom = (realty_id, room_id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/${realty_id}/rooms/${room_id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

///realty rooms items

export const getRealtyRoomItems = (realty_id, room_id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${realty_id}/rooms/${room_id}/items`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createRealtyRoomItem = (realty_id, room_id,  data) => {
  return new Promise((resolve, reject) => {
    axios.post(`/realty/${realty_id}/rooms/${room_id}/items`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const updateRealtyRoomItem = (realty_id, room_id, item_id,  data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/realty/${realty_id}/rooms/${room_id}/items/${item_id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


export const deleteRealtyRoomItem = (realty_id, room_id, item_id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/realty/${realty_id}/rooms/${room_id}/items/${item_id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};




export const fetchRealtyFeed = (id, query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/realty/${id}/feed?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};


