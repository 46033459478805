import React, {memo, useCallback, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Pagination from '../../components/Pagination';
import queryString from 'query-string';
import PageHeader from '../../components/PageHeader';
import {fetchLogs} from '../../actions/logsActions';
import FilterLogs from '../../components/Logs/FilterLogs';
import {setPageTitle} from '../../components/Tools';
import ErrorLoadingDataMessage from '../../components/ErrorLoadindDataMessage';
import {NOT_FOUND} from '../../components/Constants';

const LogsListPage = ({...props}) => {
  const [state, setState] = useState({
    limit: 30,
    meta: null,
    history: null,
  });

  const loadActions = useCallback(() => {
    const query = queryString.parse(props.location.search);

    fetchLogs({...query, limit: state.limit})
        .then(response => {
          setState(prevState => ({
            ...prevState,
            history: response.data,
            meta: response.meta
          }));
        });
  }, [props.location.search, state.limit]);


  useEffect(() => {
    setPageTitle('История действий');
    loadActions();
  }, [loadActions, props.location.key]);

  let handlePageChange = (e, page) => {
    const search = queryString.parse(props.location.search);

    props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify({
        ...search,
        page: page,
      }),
    });
  };

  if ( !state.history ) {
    return null;
  }

  return (
      <Grid container spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <PageHeader title="История действий"
                      size="md"/>
        </Grid>

        <Grid item
              xs={ 12 }>
          <FilterLogs/>
        </Grid>

        <Grid item xs={ 12 }>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Дата</TableCell>
                  <TableCell>Пользователь</TableCell>
                  <TableCell>Действие</TableCell>
                  <TableCell>Данные</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { state.history && state.history.map(item => (
                    <TableRow key={ item.id }
                              hover>
                      <TableCell>{ item.created_at }</TableCell>
                      <TableCell>{ item.user }</TableCell>
                      <TableCell>{ item.route }</TableCell>
                      <TableCell style={ {wordBreak: 'break-all', maxWidth: '500px'} }>
                        { JSON.stringify(item.data) }
                      </TableCell>
                    </TableRow>
                )) }
              </TableBody>
            </Table>
          </Paper>
          {!state.history.length && <ErrorLoadingDataMessage title={NOT_FOUND}/>}
        </Grid>


        {(state.meta && state.meta.last_page > 1) &&
          <Grid item xs={ 12 }
                style={ {
                  paddingTop: `24px`,
                  display: `flex`,
                  justifyContent: 'center'
                } }>
            <Pagination current={ state.meta.current_page }
                        onChange={ handlePageChange }
                        perPage={ state.meta.per_page }
                        total={ state.meta.total }/>
          </Grid>
        }

      </Grid>
  );

};

export default memo(LogsListPage);
