import axios from 'axios';
import queryString from 'query-string';


export const fetchPermissions = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/moderator_roles/permissions`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data);
        })
  });
};

export const fetchRoles = (query) =>  {
  return new Promise((resolve, reject) => {
    axios.get(`/moderator_roles?${queryString.stringify(query)}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const fetchRole = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/moderator_roles/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const createRole = (data) => {
  return new Promise((resolve, reject) => {
    axios.get(`sanctum/csrf-cookie`).then(() => {
      axios.post(`/moderator_roles`, data)
          .then((response) => {
            resolve(response.data.results);
          })
          .catch((error) => {
            reject(error?.response?.data?.errors);
          })
    })
  });
};

export const updateRole = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/moderator_roles/${id}`, data)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

export const deleteRole = (id) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/moderator_roles/${id}`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        })
  });
};

