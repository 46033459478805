import React from 'react';
import {Grid} from '@material-ui/core';
import FilterSelectField from '../FilterFields/FilterSelectField';
import {withRouter} from 'react-router-dom';
import ClearFiltersButton from '../ClearFiltersButton';
import {flexAlignCenter} from '../JSStylesConstants';
import FilterCheckboxField from '../FilterCheckboxField';
import RealtyGroupSelectField from './RealtyGroupSelectField';

const statusOptions = [
  {value: '1', name: 'Свободно'},
  {value: '2', name: 'Забронировано'},
  {value: '3', name: 'Занято'},
  {value: '4', name: 'Требуется уборка'}
];

const RealtyFilter = ({...props}) => {

  return (
      <Grid container
            spacing={ 2 }
            style={ {alignItems: 'center'} }>
        <Grid item>
          <FilterSelectField options={ statusOptions }
                             fullWidth
                             style={ {minWidth: 220} }
                             label="Статус"
                             type="status"/>
        </Grid>
        <Grid item>
          <RealtyGroupSelectField/>
        </Grid>
        <Grid item>
          <FilterCheckboxField name="deleted"
                               label="Архивные"/>
        </Grid>
        <Grid item
              style={ flexAlignCenter }>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  );
};

export default withRouter(RealtyFilter);
