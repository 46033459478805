import React, {PureComponent} from 'react';
import ClientEditForm from '../../components/Client/ClientEditForm';
import {Card, CardHeader, Divider, Tab, Tabs} from '@material-ui/core';
import {fetchClient} from '../../actions/clientsActions';
import {TabContext, TabPanel} from '@material-ui/lab';
import ClientDetailsEditForm from '../../components/Client/ClientDetailsEditForm';
import {setPageTitle} from '../../components/Tools';
import {Link} from 'react-router-dom';

class ClientEditPage extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      client: '',
      active_tab: 0
    };
  }

  componentDidMount () {
    setPageTitle('Редактирование клиента');
    this.getClient();
  }

  handleChangeTab = (tabNumber) => {

    this.setState({
      active_tab: tabNumber
    });
  };


  getClient = () => {
    const {match: {params}} = this.props;

    fetchClient(params.id)
        .then(client => {
          this.setState({
            client: client
          });
        });
  };

  render () {
    const {client, active_tab} = this.state;

    if ( !client ) {
      return null;
    }

    return (
        <React.Fragment>
          <Card>
            <CardHeader
                title="Редактирование клиента"
                subheader={ `ID: ${ client.id }` }
            />

            <Divider/>

            <Tabs textColor="primary"
                  indicatorColor="primary"
                  value={ active_tab }>
              <Tab label="Общая информация"
                   onClick={ e => this.handleChangeTab(0) }/>

              <Tab label="Список заказов"
                   onClick={ () => this.props.history.push({
                     pathname: '/bookings',
                     search: `client=${ client.first_name } ${ client.last_name }`
                   }) }/>

              <Tab label="Реквизиты"
                   onClick={ e => this.handleChangeTab(2) }/>

              <Link to={ {
                pathname: '/meetings',
                search: `client=${ client.first_name } ${ client.last_name }`
              } }
                    style={ {textDecoration: 'none'} }
                    target="_blank"
              >
                <Tab label="Встречи" textColor="primary"/>
              </Link>

              <Link to={ {
                pathname: '/booking/requests',
                search: `name=${ client.first_name } ${ client.last_name }`
              } }
                    style={ {textDecoration: 'none'} }
                    target="_blank">
                <Tab label="Заявки" textColor="primary"/>
              </Link>


            </Tabs>

            <Divider style={ {marginTop: -1} }/>


            <div style={ {paddingTop: 24, paddingBottom: 24} }>
              <TabContext value={ active_tab.toString() }>
                <TabPanel value={ active_tab.toString() }
                          hidden={ active_tab !== 0 }
                          index={ 0 }>
                  <ClientEditForm client={ client }/>
                </TabPanel>
                <TabPanel value={ active_tab.toString() }
                          hidden={ active_tab !== 2 }
                          index={ 2 }>
                  <ClientDetailsEditForm client={ client }/>
                </TabPanel>
              </TabContext>

            </div>
          </Card>
        </React.Fragment>
    );
  }
}

export default ClientEditPage;
