import React, {PureComponent} from 'react';
import './StatisticLogs.scss';
import ApartmentLogs from '../ApartmentLogs';
import Pagination from '../Pagination';
import {Grid} from '@material-ui/core';
import axios from 'axios';
import {withRouter} from 'react-router-dom';

const queryString = require('query-string');

class StatisticLogs extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      meta: [],
      apartmentLogs: [],
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.key !== this.props.location.key) {
      this.fetchLogs();
    }
  }


  componentDidMount() {
    this.fetchLogs()
  }

  fetchLogs = () => {
    const {realtyId} = this.props;

    const query = queryString.parse(this.props.location.search);


    const search = queryString.stringify({
      ...query,
      page: query[`logs${realtyId}`]
    });

    axios.get(`/statistics/payments?realty_id=${realtyId}&${search}`)
        .then(response => {
          this.setState({
            apartmentLogs: response.data.results.data,
            meta: response.data.results.meta
          });
        })
        .catch((err) => {
          console.log(err)
        });
  };

  handlePageChange = (e, page) => {
    const {realtyId} = this.props;
    const search = queryString.parse(this.props.location.search);

    const newSearch = {
      ...search,
      [`logs${realtyId}`]: page
    };

    this.props.history.push({
      pathname: window.location.pathname,
      search: queryString.stringify(newSearch),
    });
  };

  render() {
    const {meta, apartmentLogs} = this.state;

    if (apartmentLogs.length === 0) {
      return (
          <div className="apartment-no-logs">Логи за текущее время отсутствуют</div>
      )
    }

    return (
        <React.Fragment>
          <div className="apartment-logs">
            {/*<div className="apartment-logs__title-wrap">*/}
            {/*  <div className="apartment-logs__title">*/}
            {/*    Лог платежей*/}
            {/*  </div>*/}
            {/*</div>*/}
            {apartmentLogs.map(log => <ApartmentLogs key={log.id} {...log}/>)}

            {meta.last_page > 1
                ?
                <Grid item xs={12}
                      style={{
                        paddingTop: `24px`,
                        display: `flex`,
                        justifyContent: 'center'
                      }}>
                  <Pagination
                      current={meta.current_page}
                      total={meta.total}
                      perPage={meta.per_page}
                      onChange={this.handlePageChange}
                  />
                </Grid>
                : null
            }
          </div>
        </React.Fragment>
    )
  }
}


export default withRouter(StatisticLogs);
