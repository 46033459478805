import React, {useEffect, useState} from 'react';
import {fetchBasicUtilities} from '../../actions/basicUtilitiesActions';
import SelectField from '../SelectField';

const BasicUtilitiesSelect = ({value, onChange,  ...props}) => {
  const [utilities, setUtilities] = useState([]);


  useEffect(() => {
        loadUtilities()
      }, []
  );


  let loadUtilities = () => {
    fetchBasicUtilities()
        .then(utilities => {
          setUtilities(utilities)
        });
  };


  return (
      <React.Fragment>
        <SelectField options={utilities}
                     fullWidth
                     {...props}
                     onChange={onChange}
                     value={value}
                     label={`Тип коммуналки`}
                     placeholder={`Выберите тип`}/>
      </React.Fragment>

  )
};


export default BasicUtilitiesSelect;
