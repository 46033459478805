import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {FormHelperText} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-multiline': {
      padding: `15px 14px`,
    },
  },
}));


const TextField = (props) => {
  const classes = useStyles();

  return (
      <React.Fragment>
        <MuiTextField
            { ...props }
            className={classes.root}
            fullWidth
            error={ !!props.error }
            helperText={ props.error || '' }
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            aria-describedby={props.helperText}
        />
        {props.helperText &&
          <FormHelperText id={props.helperText}>{props.helperText}</FormHelperText>
        }
      </React.Fragment>

  );
};

export default TextField;
