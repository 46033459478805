import axios from 'axios';
import queryString from 'query-string';

export const fetchLogs = (query) => {
  return new Promise((resolve, reject) => {
    axios.get(`/logs?${ queryString.stringify(query) }`)
        .then(response => {
          resolve(response.data.results);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};


export const getLogsActions = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/logs/actions`)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error?.response?.data?.errors);
        });
  });
};
