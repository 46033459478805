import React, { PureComponent } from 'react';
import { Divider, Grid } from '@material-ui/core';
import Errors from '../Errors';
import {
  fetchRealty,
  removeRealtyImage,
  updateRealtyImagesPriority,
  updateRealtySeo,
  uploadRealtyImage
} from '../../actions/realtyActions';
import Title from '../Title';
import FormContainer from '../FormContainer';
import FormRow from '../FormRow';
import FormColumn from '../FormColumn';
import SwitchField from '../SwitchField';
import TextField from '../TextField';
import RealtyApartmentPhoto from '../RealtyApartmentPhoto';
import { connect } from 'react-redux';
import { openSnackbar } from '../../actions/snackbarActions';
import DropZone from '../DropZone';
import { translateErrors, uuidv4 } from '../Tools';
import update from 'immutability-helper';
import ProgressBar from '../ProgressBar';
import ButtonWithProgress from '../ButtonWithProgress';
import { ERROR, MAX_SITE_DESCRIPTION, MAX_SITE_META_DESCRIPTION, SUCCESS } from '../Constants';


class RealtyEditSeoForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      realty: {
        id: this.props.id,
        name: '',
        description: '',
        meta_title: '',
        meta_description: '',
        published: false,
        slug: '',
        is_deleted: false,
        images: [],
      },
      errors: new Errors(),
      disabled: false,
      disabledSendPhoto: false,
      percentLoading: null
    };
  }

  componentDidMount() {
    this.loadRealtyInfo();
  }

  loadRealtyInfo = (submitPriorityFn) => {
    const { id } = this.props;

    fetchRealty(id)
      .then((realty) => {
        this.setRealty(realty);
        //update priority after save all photos
        if ( submitPriorityFn ) {
          submitPriorityFn();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setRealty = ({ id, meta_tags, published, description, slug, images, is_deleted }) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        id: id,
        name: meta_tags?.h1,
        description: description,
        meta_title: meta_tags?.title,
        meta_description: meta_tags?.description,
        published: published,
        slug: slug,
        is_deleted: is_deleted,
        images: images?.length ? images.map(x => ( { ...x, upload: true } )) : this.state.realty.images
      },
    } ));
  };

  moveImage = (dragIndex, hoverIndex) => {
    const { realty } = this.state;
    const dragCard = realty.images[dragIndex];
    // dragCard['priority'] = hoverIndex + 1;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        images: update(prevState.realty.images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard]
          ]
        })
      }
    } ));
  };

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if ( name === 'meta_description' && value.length > MAX_SITE_META_DESCRIPTION ) return;
    if ( name === 'description' && value.length > MAX_SITE_DESCRIPTION ) return;

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        [name]: type === 'checkbox' ? checked : value,
      }
    } ));

  };

  handleUploadPhoto = (files) => {
    let convert_files = [];

    for ( let f = 0; f < files.length; f++ ) {
      convert_files.push({
        id: uuidv4(),
        path: files[f].src.base64,
        file: files[f].src.file,
        upload: false
      });
    }

    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        images: [...prevState.realty.images, ...convert_files]
      }
    } ));
  };

  handleUploadError = (errors) => {
    this.props.openSnackbar(ERROR, translateErrors(errors));
  };

  handleSubmit = () => {
    const { realty } = this.state;

    this.setState({ disabled: true });

    const data = {
      h1: realty.name,
      description: realty.description,
      meta_title: realty.meta_title,
      meta_description: realty.meta_description,
      published: realty.published,
      slug: realty.slug
    };

    updateRealtySeo(realty.id, data)
      .then(realty => {
        this.setRealty(realty);
        this.props.openSnackbar(SUCCESS, 'Данные сохранены успешно');
        this.setState({
          errors: new Errors({}),
          disabled: false
        });
      })
      .catch(errors => {
        this.setState({
          errors: new Errors(errors),
          disabled: false
        });
      });
  };


  handleRemoveImage = (id) => {
    removeRealtyImage(this.props.id, id)
      .then(() => {
        this.props.openSnackbar(SUCCESS, 'Фотография удалена');
        this.setState(prevState => ( {
          realty: {
            ...prevState.realty,
            images: prevState.realty.images.filter(f => f.id !== id)
          }
        } ));
      })
      .catch((errors) => {
        this.props.openSnackbar(ERROR, errors);
      });
  };

  handleRemoveUploadImage = (id) => {
    this.setState(prevState => ( {
      realty: {
        ...prevState.realty,
        images: prevState.realty.images.filter(f => f.id !== id)
      }
    } ));
    this.props.openSnackbar(SUCCESS, 'Фотография удалена');
  };


  handleSubmitPriority = () => {
    const { realty } = this.state;
    const files = [];

    realty.images.forEach((item, index) => {
      files.push({
        id: item.id,
        priority: index + 1,
      });
    });

    updateRealtyImagesPriority(realty.id, { files: files })
      .then(() => {
        this.loadRealtyInfo();
      })
      .catch(errors => {
        this.setState({ errors: new Errors(errors) });
      });
  };

  handleSubmitImage = () => {
    const { realty } = this.state;
    this.setState({ disabledSendPhoto: true });

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(( progressEvent.loaded * 100 ) / progressEvent.total);

        if ( percentCompleted <= 100 ) {
          this.setState({ percentLoading: percentCompleted });
        }
      }
    };


    const fileToUpload = {
      files: [...realty.images.filter(image => image.upload === false)]
    };

    const sendPhotos = [];

    fileToUpload.files.forEach(item => {
      const data = new FormData();
      data.append('file', item.file);
      sendPhotos.push(uploadRealtyImage(realty.id, data, config));
    });

    Promise.all(sendPhotos)
      .then(() => {
        this.props.openSnackbar(SUCCESS, 'Все фотографии успешно сохранены');
        this.loadRealtyInfo(this.handleSubmitPriority);
        this.setState({ disabledSendPhoto: false });
      })
      .catch(errors => {
        this.setState({ errors: new Errors(errors), disabledSendPhoto: false });
        this.props.openSnackbar(ERROR, 'Ошибка');
      });
  };


  render() {
    const { realty, errors, disabled, percentLoading, disabledSendPhoto } = this.state;

    return (
      <Grid container
            spacing={ 2 }>
        <Grid item
              xs={ 12 }
              style={ { paddingTop: 24 } }>
          <Title size="xl"
                 title="Фото"
                 style={ { paddingBottom: 24 } }/>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 12 }
                          md={ 10 }>
                <Grid container spacing={ 2 }>
                  { realty.images && realty.images.map((image, index) => (
                    <RealtyApartmentPhoto key={ image.id }
                                          index={ index }
                                          moveImage={ this.moveImage }
                                          src={ image.path }
                                          id={ image.id }
                                          priority={ image.priority }
                                          upload={ image.upload }
                                          handleRemoveUploadImage={ this.handleRemoveUploadImage }
                                          handleRemoveImage={ this.handleRemoveImage }/>
                  )) }
                  <Grid item
                        xs={ 12 }
                        sm={ 6 }
                        md={ 4 }
                        lg={ 2 }>
                    <DropZone handleChange={ this.handleUploadPhoto }
                              handleError={ this.handleUploadError }/>
                  </Grid>
                </Grid>
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn sm={ 2 }>
                { percentLoading < 100 && percentLoading > 0 && <ProgressBar percent={ percentLoading }/> }
              </FormColumn>
            </FormRow>
          </FormContainer>
        </Grid>
        <Grid item xs={ 12 }
              style={ { paddingTop: 24 } }>
          <ButtonWithProgress loading={ disabledSendPhoto }
                              disabled={ realty.is_deleted }
                              onClick={ this.handleSubmitImage }
                              label="Сохранить"/>
        </Grid>

        <Grid item xs={ 12 }>
          <Divider/>
        </Grid>

        <Grid item
              xs={ 12 }
              lg={ 8 }>
          <Title size="xl"
                 title="Параметры"
                 style={ { paddingBottom: 24 } }/>
          <FormContainer>
            <FormRow>
              <FormColumn sm={ 6 }>
                <FormContainer>
                  <FormRow>
                    <FormColumn sm={ 12 }>
                      <SwitchField checked={ realty.published }
                                   style={ { marginLeft: 0, marginRight: 'auto' } }
                                   value={ realty.published }
                                   labelPlacement="start"
                                   name="published"
                                   error={ errors.getError('published') }
                                   onChange={ this.handleChange }
                                   label="Показывать на сайте:"/>
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn sm={ 12 }>
                      <TextField error={ errors.getError('h1') }
                                 name="name"
                                 value={ realty.name }
                                 onChange={ this.handleChange }
                                 label="Название на странице лицевой части сайта (h1 тег)*"
                                 placeholder="Введите название"
                                 helperText="Название объекта на странице лицевой части сайта, которое будет видеть клиент!"/>
                    </FormColumn>
                  </FormRow>
                  <FormRow>
                    <FormColumn sm={ 12 }>
                      <TextField error={ errors.getError('meta_title') }
                                 name="meta_title"
                                 value={ realty.meta_title }
                                 onChange={ this.handleChange }
                                 label="Заглавие веб-страницы (page title)*"
                                 placeholder="Введите название"
                                 helperText="Заголовок вкладки и окна браузера"/>
                    </FormColumn>
                  </FormRow>
                </FormContainer>
              </FormColumn>
              <FormColumn sm={ 6 }>
                <FormContainer>
                  <FormRow>
                    <FormColumn sm={ 12 }>
                      <TextField error={ errors.getError('description') }
                                 name="description"
                                 multiline
                                 rows={ 11 }
                                 value={ realty.description }
                                 onChange={ this.handleChange }
                                 label="Описание*"
                                 placeholder="Введите описание"
                                 helperText={ `${ realty.description.length } из 2000` }/>
                    </FormColumn>
                  </FormRow>
                </FormContainer>
              </FormColumn>
            </FormRow>

            <FormRow>
              <FormColumn sm={ 6 }>
                <TextField error={ errors.getError('slug') }
                           name="slug"
                           value={ realty.slug }
                           onChange={ this.handleChange }
                           label="Ссылка объекта (URL slug)*"
                           placeholder="Введите название"
                           helperText="Для поисковых машин (без пробелов)"/>
              </FormColumn>
              <FormColumn sm={ 6 }>
                <TextField error={ errors.getError('meta_description') }
                           name="meta_description"
                           multiline
                           rows={ 11 }
                           value={ realty.meta_description }
                           onChange={ this.handleChange }
                           label="Описание для SEO (meta description)"
                           placeholder="SEO текст"
                           helperText={ realty.meta_description && `${ realty.meta_description.length } из 158` }/>
              </FormColumn>
            </FormRow>
            <FormRow>
              <FormColumn>
                <ButtonWithProgress loading={ disabled }
                                    disabled={ realty.is_deleted }
                                    onClick={ this.handleSubmit }
                                    label="Сохранить"/>
              </FormColumn>
            </FormRow>
          </FormContainer>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, { openSnackbar })(RealtyEditSeoForm);
