import React, {useEffect, useState} from 'react';
import Autocomplete from './Autocomplete';
import {fetchManagers} from '../../actions/autocompleteActions';
import {connect} from 'react-redux';

const ManagersAutocomplete = ({company_id, value, user, ...props}) => {
  const [managers, setManagers] = useState([]);

  useEffect(() => {
    fetchManagers({company_id: user.company_id})
        .then(managers => {
          setManagers(managers.filter(x => x.active === true))
        });
  }, [user]);


  return (
      <Autocomplete {...props}
                    // filterOptions={(options, state) => options}
                    value={(typeof value === 'string' ? managers.find(manager => manager.id === +value) : value) || null}
                    // label={`Менеджер`}
                    placeholder={`Выберите менеджера`}
                    options={managers}
      />
  )
};

let mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps, {})(ManagersAutocomplete);
