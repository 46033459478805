import axios from 'axios';

export const fetchExternalResources = () => {
  return new Promise((resolve,reject) => {
    axios.get(`/realty/external_resources`)
        .then(response => {
          resolve(response.data.results)
        }).catch(error => {
          reject(error?.response?.data?.errors)
    })
  })
};


export const fetchRealtyExternalResources = (id) => {
  return new Promise((resolve,reject) => {
    axios.get(`/realty/${id}/external_resources`)
        .then(response => {
          resolve(response.data.results)
        }).catch(error => {
      reject(error?.response?.data?.errors)
    })
  })
};


export const createRealtyExternalResources = (id, data) => {
  return new Promise((resolve,reject) => {
    axios.post(`/realty/${id}/external_resources`, data)
        .then(response => {
          resolve(response.data.results)
        }).catch(error => {
      reject(error?.response?.data?.errors)
    })
  })
};
