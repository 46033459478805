import React from 'react';
import { Grid } from '@material-ui/core';
import FilterSelectField from '../FilterFields/FilterSelectField';
import { withRouter } from 'react-router-dom';
import ClearFiltersButton from '../ClearFiltersButton';
import SearchField from '../SearchField';
import { flexAlignCenter } from '../JSStylesConstants';
import { LABEL_WIDTH_SM } from '../Constants';
import { ACTIVE_SELECT_OPTIONS, RENT_TYPE_OPTIONS } from '../../dataConstants';


const BookingFilter = ({ ...props }) => {

  return (
    <React.Fragment>
      <Grid container
            spacing={ 2 }
            style={ { alignItems: 'center' } }>
        <Grid item>
          <SearchField searchField="realty"
                       placeholder="Введите имя объекта"
                       label="Поиск по объекту"
                       labelWidth={ LABEL_WIDTH_SM }/>
        </Grid>
        <Grid item>
          <SearchField searchField="rental_agreement"
                       placeholder="Введите название договора"
                       label="Поиск по договору"
                       labelWidth={ 130 }/>
        </Grid>
        <Grid item>
          <FilterSelectField options={ RENT_TYPE_OPTIONS }
                             fullWidth
                             style={ { minWidth: 220 } }
                             label="Тип съема"
                             type="rent_type"/>
        </Grid>
        <Grid item>
          <SearchField searchField="client"
                       placeholder="Имя / телефон / email"
                       label="Клиент"
                       labelWidth={ 50 }/>
        </Grid>
        <Grid item>
          <FilterSelectField options={ ACTIVE_SELECT_OPTIONS }
                             fullWidth
                             style={ { minWidth: 220 } }
                             label="Активность"
                             type="status"/>
        </Grid>
        <Grid item
              style={ flexAlignCenter }>
          <ClearFiltersButton/>
        </Grid>
      </Grid>

    </React.Fragment>
  );
};

export default withRouter(BookingFilter);
