import React, {useEffect, useState} from 'react';
import {makeStyles, Drawer} from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {withRouter} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    height: '100vh',
    padding: 24,
  },
  drawerContent: {
    margin: 'auto',
    maxWidth: 640,
  },
  drawerTitle: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    fontSize: 17,
    fontWeight: 700,
    marginBottom: 24,
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 16,
  },
}));

const FormDrawer = ({children, button: Button, title,  ...props}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {

    if(props.location.state?.open) {
      setOpen(true);
      props.location.state = {};
    } else if(props.location.state?.open === false) {
      setOpen(false);
      props.location.state = {};
    }

  },[setOpen, props]);

  return (
      <React.Fragment>
        <Button
            onClick={e => {
              e.preventDefault();
              setOpen(true)
            }}
        />
        <Drawer
            open={open}
            anchor="top"
            onClose={() => setOpen(false)}
            classes={{paper: classes.drawerPaper}}
        >
          <MuiButton
              startIcon={<CloseIcon/>}
              onClick={() => setOpen(false)}
              className={classes.closeButton}
          >
            Закрыть
          </MuiButton>
          <div className={classes.drawerContent}>
            <div className={classes.drawerTitle}>
              {title}
            </div>

            {children}
          </div>
        </Drawer>
      </React.Fragment>
  );
};


export default withRouter(FormDrawer);
