import React from 'react';
import MomentFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { Grid } from '@material-ui/core';
import { HighlightOffOutlined } from '@material-ui/icons';
import moment from 'moment';

const PickDateField = ({
                         defaultDateFrom, value, onChange, name, label,
                         type, disabled, views, minDate, maxDate, disableToolbar = true, openTo = 'date', ...props
                       }) => {
  let dateFrom;

  if ( /\d{2}\/\d{2}\/\d{4}/.test(value) ) {
    [dateFrom] = value.split('-');

    dateFrom = moment(dateFrom, 'DD/MM/YYYY');
  } else {
    dateFrom = defaultDateFrom || '';
  }



  return (
    <MuiPickersUtilsProvider utils={ MomentFnsUtils }>
      <Grid item xs={ 12 }>
        <DatePicker views={ views }
                    openTo={ openTo }
          // clearable={ true }
                    autoOk
                    fullWidth
                    disabled={ disabled }
                    disableToolbar={ disableToolbar }
          // minDate={minDate || moment().subtract(1, 'year').startOf('day').toDate()}
          // maxDate={maxDate || moment().add(1, 'year').startOf('day').toDate()}
                    variant="inline"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    value={ dateFrom ? dateFrom.toDate() : null }
                    onChange={ value => onChange(type, moment(value).format('DD/MM/YYYY')) }
                    name={ name }
                    label={ label }
                    error={ !!props.error }
                    helperText={ props.error || '' }
                    InputProps={ {
                      endAdornment: dateFrom
                        ? <HighlightOffOutlined style={ { cursor: 'pointer' } }
                                                onClick={ (e) => {
                                                  e.stopPropagation();
                                                  onChange(type, null);
                                                } }/>
                        : <InsertInvitationIcon style={ { cursor: 'pointer' } }/>
                    } }
                    { ...props }
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default PickDateField;
