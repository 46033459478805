import React, {useEffect, useState} from 'react';
import Autocomplete from './Autocomplete';
import {fetchBookings} from '../../actions/autocompleteActions';
import useDebounce from '../Debounce'

const  BookingsAutocomplete = ({value, ...props}) => {
  const [search, setSearch] = useState('');
  const [bookings, setBookings] = useState([]);

  let loadBookings = (search) => {
    fetchBookings({search: search})
        .then(bookings => {
          setBookings(bookings)
        });
  };

  useEffect(() => {
    setSearch(value)
  }, [value])

  let handleBookingInputChange = (e, value) => {
    setSearch(value)
  };

  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {

  }, [value])

  useEffect(() => {
        if (debouncedSearchTerm) {
          loadBookings(debouncedSearchTerm)
        } else {
          setBookings([]);
        }
      },[debouncedSearchTerm]
  );

  return (
      <Autocomplete
          {...props}
          inputValue={search}
          filterOptions={(options, state) => options}
          onInputChange={(e, value) => handleBookingInputChange(e, value)}
          options={bookings}
          label={`Заказ`}
          placeholder={`Введите номер / имя / ID`}
      />
  )
};


export default BookingsAutocomplete;
