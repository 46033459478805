import React from 'react';
import './NoPhoto.scss';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

const NoPhoto = (props) => {
  return (
      <div className="no-photo">
        <div className="no-photo__inner">
          <PhotoCameraOutlinedIcon/>
          <span>Нет изображения</span>
        </div>
      </div>
  );
};

export default NoPhoto;
