import React, { PureComponent } from 'react';
import { Grid, Divider, } from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import { fetchBooking } from '../../actions/bookingsActions';
import BookingEditTabs from '../../components/Booking/BookingsEditTabs';
import BookingEditMainForm from '../../components/Booking/BookingEditMainForm';
import BookingMoneyForm from '../../components/Booking/BookingMoneyForm';
import BookingRentForm from '../../components/Booking/BookingRentForm';
import BookingUtilitiesForm from '../../components/Booking/BookingUtilitiesForm';
import { withRouter } from 'react-router-dom';
import BookingDocumentsForm from '../../components/Booking/BookingDocumentsForm';
import { setPageTitle } from '../../components/Tools';
import { BOOKINGS_ROUTE } from '../../components/RouterConstants';


class BookingEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      booking: [],
      activeTab: 0
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ( prevState.activeTab !== this.state.activeTab ) {
      this.props.history.push({
        pathname: window.location.pathname,
      });
    }
  }

  componentDidMount() {
    setPageTitle('Редактирование заказа');
    this.setState({
      id: this.getId(),
    });

    this.loadBooking();

  }

  loadBooking = () => {
    const { match: { params } } = this.props;

    fetchBooking(params.id)
      .then(booking => {
        this.setState({
          booking: booking
        });
      })
      .catch(errors => {
        this.props.history.push({
          pathname: BOOKINGS_ROUTE
        });
      });
  };

  getId = () => {
    const { match: { params } } = this.props;

    return params.id || null;
  };

  handleChangeTab = (e, value) => {
    this.setState({
      activeTab: value
    });
  };

  render() {
    const { id, booking, activeTab } = this.state;

    if ( !id || !booking.client ) {
      return null;
    }

    return (
      <Grid container
            spacing={ 2 }>
        <Grid item
              xs={ 12 }>
          <PageHeader title={ `Заказ #${ booking.id } ${ booking?.rental_agreement || '' }` }
                      size="md"/>
        </Grid>

        <Grid item
              xs={ 12 }>
          <BookingEditTabs value={ activeTab }
                           with_utility_bills={ booking.with_utility_bills }
                           handleChange={ this.handleChangeTab }/>
        </Grid>

        <Grid item
              xs={ 12 }>
          { activeTab === 0 &&
          <Grid container
                spacing={ 2 }>
            <Grid item
                  xs={ 12 }>
              <BookingEditMainForm { ...booking }
                                   loadBooking={ this.loadBooking }/>
            </Grid>
            <Grid item
                  xs={ 12 }>
              <Divider/>
            </Grid>
            <Grid item
                  xs={ 12 }>
              <BookingMoneyForm booking={ booking }
                                loadBooking={ this.loadBooking }/>
            </Grid>
          </Grid>
          }

          { activeTab === 1 && <BookingRentForm booking={ booking }/> }
          { activeTab === 2 && <BookingUtilitiesForm booking={ booking }/> }
          { activeTab === 3 && <BookingDocumentsForm { ...booking }/> }
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(BookingEditPage);
