import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Link as RouterLink, useLocation, withRouter } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import FormDrawer from '../../components/FormDrawer';
import AddingButton from '../../components/AddingButton';
import { PlusIcon } from '../../icons';
import { fetchRealtyGroups, removeRealtyGroup } from '../../actions/realtyGroupsActions';
import RealtyGroupsCreateForm from '../../components/RealtyGroups/RealtyGroupsGreateForm';
import DialogsRemove from '../../components/DialogsRemove';
import DeleteIcon from '@material-ui/icons/Delete';
import { openSnackbar } from '../../actions/snackbarActions';
import { connect } from 'react-redux';
import { setPageTitle } from '../../components/Tools';
import { ERROR, SUCCESS } from '../../components/Constants';
import RealtyGroupFilter from '../../components/RealtyGroups/RealtyGroupFilter';
import queryString from 'query-string';


const RealtyGroupsListPage = ({ ...props }) => {
  const location = useLocation();
  const [groups, setGroups] = useState([]);

  let loadRealtyGroups = useCallback(() => {
    const query = queryString.parse(location.search);

    fetchRealtyGroups(query)
      .then(response => {
        setGroups(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [location.search]);

  useEffect(() => {
    setPageTitle('Групы объектов');
    loadRealtyGroups();
  }, [loadRealtyGroups]);


  let handleRemoveGroup = (_, id) => {
    removeRealtyGroup(id)
      .then(() => {
        loadRealtyGroups();
        props.openSnackbar(SUCCESS, 'Група объектов удалена успешно');
      })
      .catch(errors => {
        props.openSnackbar(ERROR, errors);
      });
  };


  return (
    <Grid container spacing={ 2 }>
      <Grid item
            xs={ 12 }>
        <Grid container
              spacing={ 2 }
              style={ { alignItems: 'center' } }>
          <Grid item>
            <PageHeader title="Групы объектов"
                        size="md"/>
          </Grid>
          <Grid item>
            <FormDrawer title="Добавление групы объектов"
                        button={ props => <AddingButton { ...props }
                                                        tooltip="Добавить групу объектов"
                                                        icon={ () => <PlusIcon/> }/> }>
              <RealtyGroupsCreateForm loadRealtyGroups={ loadRealtyGroups }/>
            </FormDrawer>
          </Grid>

          <Grid item xs={ 12 }>
            <RealtyGroupFilter/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={ 12 }>
        <Paper>
          <TableContainer style={ {
            maxWidth: '100%',
            overflow: 'auto'
          } }>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Название</TableCell>
                  <TableCell>Компания</TableCell>
                  <TableCell>К-во объектое в группе</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { groups && groups.map(group => (
                  <TableRow key={ group.id }
                            hover>
                    <TableCell>#{ group.id }</TableCell>
                    <TableCell>
                      <Link color="inherit"
                            component={ React.forwardRef((props, ref) => (
                              <RouterLink{ ...props }
                                         ref={ ref }
                                         to={ `/realty-groups/${ group.id }` }
                              />
                            )) }>
                        <b>{ group.name }</b>
                      </Link>
                    </TableCell>
                    <TableCell>{ group.company.name }</TableCell>
                    <TableCell>{ group.realty_count }</TableCell>
                    <TableCell>
                      { !group.realty_count &&
                        <DialogsRemove positive_answer="Да"
                                       negative_answer="Нет"
                                       question="Удалить групу объектов"
                                       question_text="Действительно удалить групу объектов ?"
                                       remover={ handleRemoveGroup }
                                       icon={ () => <DeleteIcon color="secondary"
                                                                fontSize="small"/> }
                                       id={ group.id }
                                       tooltip="Удалить групу объектов"/>
                      }

                    </TableCell>
                  </TableRow>
                )) }
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default connect(null, { openSnackbar })(withRouter(RealtyGroupsListPage));
