import React from 'react';
import {Grid} from '@material-ui/core';
import ClearFiltersButton from '../ClearFiltersButton';
import SearchField from '../SearchField';
import FilterSelectField from '../FilterFields/FilterSelectField';
import FilterCheckboxField from '../FilterCheckboxField';
import {flexAlignCenter} from '../JSStylesConstants';

const haveBooking = [
  {value: -1, name: 'Без заказов'},
  {value: 1, name: 'C заказами'},
];


const ClientsFilter = ({...props}) => {

  return (
      <Grid container
            spacing={2}
            style={{alignItems: 'center'}}>
        <Grid item>
          <SearchField searchField="name"
                       fulWidth
                       labelWidth={110}
                       label="Поиск по имени"
                       placeholder="Введите имя"/>
        </Grid>
        <Grid item>
          <SearchField searchField="email"
                       fulWidth
                       labelWidth={100}
                       label="Поиск по email"
                       placeholder="Введите email"/>
        </Grid>
        <Grid item>
          <SearchField searchField="phone"
                       fulWidth
                       labelWidth={130}
                       label="Поиск по телефону"
                       placeholder="Введите номер телефона"/>
        </Grid>
        <Grid item>
          <FilterSelectField options={haveBooking}
                             fullWidth
                             style={{minWidth: 220}}
                             label="Заказ клиента"
                             type="bookings"/>
        </Grid>
        <Grid item>
          <FilterCheckboxField name="debt"
                               label="C долгами"/>
        </Grid>
        <Grid item
              style={flexAlignCenter}>
          <ClearFiltersButton/>
        </Grid>
      </Grid>
  )
};

export default ClientsFilter;
