import React from 'react';
import TextField from '../TextField';
import FormColumn from '../FormColumn';
import PickDateField from '../PickDateField';
import CountriesAutocomplete from '../Autocomplete/CountriesAutocomplete';
import PhoneInput from '../PhoneInput';
import moment from 'moment';
import LocationsAutocomplete from '../Autocomplete/LocationsAutocomplete';

const BookingUpdateContractInfoRow = ({state, name, label, placeholder, number, dateFormat,
                                        handleChange, locationFormat, handleChangeDate, handleChangeAutocomplete,
                                        phoneFormat, errors, cityFormat, disabled, ...props
                                      }) => {

  let chekDisabled = (currentName) => {
    // if (currentName === 'depositInWords' && state['deposit'] === 0) {
    //   return true;
    // }

    // if (currentName === 'certEGRNPnubmer' && state['certEGRNPnubmer'].length > 0) {
    //   return true;
    // }

    if(currentName === 'agreementNumber') {
      return true;
    }

    if(currentName === 'date') {
      return true
    }

    if(currentName === 'square' && state['square'].length >= 0) {
      return true
    }

    if(currentName === 'floor' && state['floor'].length >= 0) {
      return true
    }

    return false;
  };

  if (phoneFormat) {
    return (
        <FormColumn sm={6}>
          <PhoneInput error={errors.getError(name)}
                      name={name}
                      type={name}
                      onChange={handleChange}
                      value={state[name] || ''}
                      label={label}
                      placeholder={placeholder}/>
        </FormColumn>
    );
  }

  if (cityFormat) {
    return (
        <FormColumn sm={6}>
          <LocationsAutocomplete error={errors.getError(name)}
                                 value={state[name] || null}
                                 disabled={disabled}/>
        </FormColumn>
    );
  }


  if (locationFormat) {
    return (
        <FormColumn sm={6}>
          <CountriesAutocomplete value={state[name].iso}
                                 onChange={(e, value) => handleChangeAutocomplete(value, name)}
                                 error={errors.getError(name)}/>
        </FormColumn>
    );
  }


  if (dateFormat) {
    return (
        <FormColumn sm={6}>
          <PickDateField views={['year', 'month', 'date']}
                         openTo={'year'}
                         disableToolbar={false}
                         disabled={chekDisabled(name)}
                         name={name}
                         type={name}
                         error={errors.getError(name)}
                         value={moment(state[name], 'DD.MM.YYYY').format('DD/MM/YYYY') || ''}
                         onChange={handleChangeDate}
                         label={label}/>
        </FormColumn>
    );
  }
  return (
      <FormColumn sm={6}>
        <TextField name={name}
                   error={errors.getError(name)}
                   disabled={disabled || chekDisabled(name)}
                   value={String(state[name]) || ''}
                   type={`${number ? 'number' : 'text'}`}
                   onChange={handleChange}
                   label={label}
                   placeholder={placeholder}
        />
      </FormColumn>
  );
};

export default BookingUpdateContractInfoRow;
