import React, {useEffect, useState} from 'react';
import SelectField from '../SelectField';
import {fetchManagementCompanies} from '../../actions/autocompleteActions';


const ManagementCompaniesSelect = ({value, ...props}) => {
  const [managementCompanies, setCompanies] = useState([]);

  useEffect(() => {
    fetchManagementCompanies()
        .then(companies => {
          setCompanies(companies)
        })
  }, []);

  return (
      <SelectField options={managementCompanies}
                   {...props}
                   fullWidth
                   value={value}
                   placehplder="Выберите компанию"
                   label="Управляющая компания"/>
  )
};

export default ManagementCompaniesSelect;
