import React from 'react';
import './RealtyFeedItem.scss';
import {transformRealtyFeedsChanges, uuidv4} from '../Tools';
import {Link} from 'react-router-dom';
import {BOOKINGS_ROUTE, REALTY_LIST_ROUTE} from '../RouterConstants';
import RealtyFeedChanges from './RealtyFeedChanges';
import {getTypeDocument} from '../Constants';
import BasicUtilitiesIcon from '../Utilities/BasicUtilitiesIcon';

const RealtyFeedItem = ({className = '', data, created_at, ...props}) => {
  switch ( data?.type ) {
    case 1:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Создана&nbsp;
                <a href={ data?.url }
                   className="realty-feed__link">новая заявка</a>
                &nbsp;на объект
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 2:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Клиент написал в чат менеджеру.
              </div>
              <div className="realty-feed__created">
                { data?.client_last_name }&nbsp;{ data?.client_first_name } в { created_at }
              </div>
            </div>
          </li>
      );
    case 3:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Клиент внёс все показания счетчиков корректно
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 4:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Клиент внес подозрительные показания. Менеджер будет перепроверять
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 5:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Ошибка в передаче показаний в УК. Менеджер перепроверяет
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 6:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Клиент проживает дольше оплаченого времени!
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 7:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                У клиента заканчивается срок действия паспорта по заказу&nbsp;
                <Link to={ `${ BOOKINGS_ROUTE }/${ data.booking_id }` }>{ data?.agreement }</Link>.&nbsp;
                Менеджер будет перезаключать договор на новые данные
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 8:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Заявка от клиента обработана менеджером { data?.user }
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 9:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                На объект создан новый&nbsp;
                <Link to={ `${ BOOKINGS_ROUTE }/${ data.booking_id }` }>заказ</Link>
              </div>
              <div className="realty-feed__data">
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 10:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Заказ &nbsp;<Link to={ `${ BOOKINGS_ROUTE }/${ data?.booking_id }` }>{ data?.agreement }</Link>&nbsp;
                отредактирован менеджером { data?.user }
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 11:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Объект создан. Вы можете посмотреть его по ссылке -&nbsp;
                <Link to={ `${ REALTY_LIST_ROUTE }/${ data?.realty_id }` }>{ data?.realty_name } ссылка на объект</Link>
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 12:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Объект отредактирован менеджером { data?.user }
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }

              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 13:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Объект продан за { data?.price }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 14:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Обновлены SEO данные объекта
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 15:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Менеджер добавил Ваш объект в объявления.
                Вы можете посмотреть объявление по ссылкам:
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 16:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Обновлены цены на объект
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 17:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Внесён депозит СУММА по договору <Link to={ '/' }>НАЗВАНИЕ_ДОГОВОРА</Link>
              </div>
              <div className="realty-feed__data">

              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 18:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Возврат депозита СУММА по договору <Link to={ '/' }>НАЗВАНИЕ_ДОГОВОРА</Link>'
              </div>
              <div className="realty-feed__data"
              />
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 19:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Оплачена аренда { data?.amount }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 20:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Оплачена коммуналка { data?.amount }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 21:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Отмена платежа { data?.amount }
              </div>
              <div className="realty-feed__data">
                { data?.comment }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 22:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                К документам объекта добавили { getTypeDocument(data?.document_type) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 23:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Менеджер { data?.user } удалил документ типа - { getTypeDocument(data?.document_type) }
              </div>
              <div className="realty-feed__data"
              />
              <div className="realty-feed__created">
                { created_at }
              </div>
            </div>
          </li>
      );
    case 24:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Изменён кадастровый номер
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 25:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                К объекту добавлена комната для лучшего учета инвентаря
              </div>
              <div className="realty-feed__data">
                Название комнаты - { data?.name }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 26:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Комната { data?.name } изменена
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 27:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Комната { data?.name } удалена
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 28:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Добавлен инвентарь { data?.name }
              </div>
              <div className="realty-feed__data"
              />
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 29:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Инвентарь { data?.name } изменён
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 30:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Инвентарь { data?.name } удалён
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 31:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                К объекту добавлена управляющая компания - { data?.management_company_name }
              </div>

              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 32:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Управляющая компания { data?.management_company_name } изменена
              </div>
              <div className="realty-feed__data">
                { transformRealtyFeedsChanges(data?.changes).map(data => (
                    <RealtyFeedChanges key={ uuidv4() }
                                       { ...data }/>
                )) }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 33:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Управляющая компания { data?.management_company_name } удалена
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 34:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Добавлен счётчик { data?.meter_name } на&nbsp;
                { <BasicUtilitiesIcon icon_id={ data?.basic_utility_id }/> }
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 35:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                Счётчик { data?.meter_name } на&nbsp;
                { <BasicUtilitiesIcon icon_id={ data?.basic_utility_id }/> }&nbsp;
                удалён
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 36:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                К объекту загружена фотография
              </div>
              <div className="realty-feed__data">
                <img src={ data?.path } alt=" " />
              </div>
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    case 37:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">
                У объекта удалена фотография
              </div>
              {/*<div className="realty-feed__data">*/ }
              {/*  <img src={data?.path} onLoad={} alt="Фотография удалена"/>*/ }
              {/*</div>*/ }
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
    default:
      return (
          <li className={ className }>
            <div className="realty-feed">
              <div className="realty-feed__type">

              </div>
              <div className="realty-feed__data"
              />
              <div className="realty-feed__created">
                { data?.user } в { created_at }
              </div>
            </div>
          </li>
      );
  }
};

export default RealtyFeedItem;
