import React from 'react';
import FormDrawerWithPayload from '../FormDrawerWithPayload/FormDrawerWithPayload';
import {useDispatch, useSelector} from 'react-redux';
import ClientCreateFormFromModal from './ClientCreateFormFromModal';
import {closeCreateClientModal} from '../../actions/formDrawerActions';

const ClientCreateModal = (props) => {
  const isOpen = useSelector(state => state.formDrawer.createClient.isOpen);
  const dispatch = useDispatch();

  let handleCloseModal = () => {
    dispatch(closeCreateClientModal())
  };

  return (
      <FormDrawerWithPayload isOpen={ isOpen }
                             closeModal={ handleCloseModal }>
        <ClientCreateFormFromModal/>
      </FormDrawerWithPayload>
  );
};

export default ClientCreateModal;
