import * as actionTypes from './actionTypes';

export const openFormDrawer = ({modalType = '', payload = null}) => ({
  type: actionTypes.OPEN_FORM_DRAWER,
  modalType: modalType,
  payload: payload
});

export const closeFormDrawer = ({modalType = ''}) => ({
  type: actionTypes.CLOSE_FORM_DRAWER,
  modalType: modalType,
});

export const openMetersDataModal = (payload) => openFormDrawer({
  modalType: 'metersData',
  payload: payload
});

export const closeMetersDataModal = () => closeFormDrawer({
  modalType: 'metersData',
});

export const openPaydeskCancelModal = (payload) => openFormDrawer({
  modalType: 'paydeskCancel',
  payload: payload
});

export const closePaydeskCancelModal = () => closeFormDrawer({
  modalType: 'paydeskCancel',
});

export const openPaydeskTransferModal = () => openFormDrawer({
  modalType: 'paydeskTransfer',
});

export const closePaydeskTransferModal = () => closeFormDrawer({
  modalType: 'paydeskTransfer',
});

export const openInvoiceEditModal = (payload) => openFormDrawer({
  modalType: 'invoiceEdit',
  payload: payload
});

export const closeInvoiceEditModal = () => closeFormDrawer({
  modalType: 'invoiceEdit',
});

export const openContractInfoUpdateModal = (payload) => openFormDrawer({
  modalType: 'contractInfo',
  payload: payload
});

export const closeContractInfoUpdateModal = () => closeFormDrawer({
  modalType: 'contractInfo',
});

export const openCreateClientModal = (payload) => openFormDrawer({
  modalType: 'createClient',
  payload: payload
});

export const closeCreateClientModal = () => closeFormDrawer({
  modalType: 'createClient',
});
