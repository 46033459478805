import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {STATISTIC_ROUTE} from '../RouterConstants';

const PrivateRoute = ({...props}) => {
  const auth = useSelector(state => state.auth);

  if ( !auth.isAuthenticated ) {
    return <Redirect to={ STATISTIC_ROUTE }/>;
  }

  return (
      <Route { ...props } />
  );
};


export default PrivateRoute;
