import React, {PureComponent} from 'react';
import {CardHeader, Divider, Card} from '@material-ui/core';
import ManagementCompanyEditForm from '../../components/ManagementCompany/ManagementCompanyEditForm';
import {setPageTitle} from '../../components/Tools';

class ManagementCompanyEditPage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    setPageTitle('Редактировать управляющую компанию');
    this.setState({
      id: this.getId(),
    });
  }

  getId = () => {
    const {match: {params}} = this.props;

    return params.id || null;
  };

  render() {
    const {id} = this.state;

    if (!id) {
      return null;
    }

    return (
        <React.Fragment>
          <Card>
            <CardHeader
                title="Редактировать управляющую компанию"
                subheader={`ID: ${id}`}
            />

            <Divider/>

            <div style={{paddingTop: 24, paddingBottom: 24}}>
              <ManagementCompanyEditForm id={id}/>
            </div>
          </Card>
        </React.Fragment>
    );
  }
}

export default ManagementCompanyEditPage;
