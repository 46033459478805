import React from 'react';
import {TextField, Typography} from '@material-ui/core';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';

const Autocomplete = (props) => {

  let optionLabel = (option, doubleLabel = false) => {
    if (doubleLabel) {
      return `${option.name} (${option.phone})`;
    } else {
      return option.name;
    }
  };

  return (
      <MuiAutocomplete style={props.style}
                       disabled={props.disabled}
                       multiple={props.multiple}
                       filterOptions={props.filterOptions}
                       filterSelectedOptions={props.filterSelectedOptions}
                       limitTags={props.limitTags}
                       options={props.options}
                       value={props.value}
                       onInputChange={props.onInputChange}
                       inputValue={props.inputValue}
                       onChange={props.onChange}
                       getOptionLabel={option => optionLabel(option, props.doubleLabel) || ''}
                       renderOption={option => <Typography style={{fontSize: 14}}>
                         {optionLabel(option, props.doubleLabel)}
                       </Typography>}

                       renderInput={params => (
                           <TextField
                               {...params}
                               fullWidth
                               error={!!props.error}
                               helperText={props.error || ''}

                               label={props.label}
                               placeholder={props.placeholder}

                               InputLabelProps={{
                                 shrink: true
                               }}
                               variant="outlined"
                           />
                       )}
      />
  );
};

export default Autocomplete;
