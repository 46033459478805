import React from 'react';
import {Grid} from '@material-ui/core';

const FormColumn = (props) => {
  return (
      <Grid item
            xs={12}
            sm={3}
            {...props}
      />
  );
};

export default FormColumn;
